import React, {useEffect, useState} from 'react';
import DropdownOrder from "../../../common/dropdown/dropdownOrder/dropdownOrder";
import styles from './groupInfo.module.scss'
import classnames from "classnames/bind";
import api from "@/api";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {showToast} from "../../../../helpers/toastMessage";
import FooterGray from "../../../common/footer/footerGray/footerGray";
import HeaderNav from "../../../common/header/headerNav/headerNav";
import ModalAddress from "../../../common/modal/modalAddress/modalAddress";

const GWarning = `${process.env.REACT_APP_RESOURCE_URL}/G_warning.svg`
const CloseRound = `${process.env.REACT_APP_RESOURCE_URL}/close_round.svg`
const BRadioUnCheck = `${process.env.REACT_APP_RESOURCE_URL}/B_radioUnCheck.svg`
const PRadioCheck = `${process.env.REACT_APP_RESOURCE_URL}/P_radioCheck.svg`
const LGArrowRight = `${process.env.REACT_APP_RESOURCE_URL}/LG_arrow_right.svg`

const cx = classnames.bind(styles)
const hours = [
  { id: 0, txt: '시간'},
  { id: 1, txt: '00'},
  { id: 2, txt: '01'},
  { id: 3, txt: '02'},
  { id: 4, txt: '03'},
  { id: 5, txt: '04'},
  { id: 6, txt: '05'},
  { id: 7, txt: '06'},
  { id: 8, txt: '07'},
  { id: 9, txt: '08'},
  { id: 10, txt: '09'},
  { id: 11, txt: '10'},
  { id: 12, txt: '11'},
  { id: 13, txt: '12'},
  { id: 14, txt: '13'},
  { id: 15, txt: '14'},
  { id: 16, txt: '15'},
  { id: 17, txt: '16'},
  { id: 18, txt: '17'},
  { id: 19, txt: '18'},
  { id: 20, txt: '19'},
  { id: 21, txt: '20'},
  { id: 22, txt: '21'},
  { id: 23, txt: '22'},
  { id: 24, txt: '23'},
]

const minutes = [
  { id: 0, txt: '분' },
  { id: 1, txt: '00' },
  { id: 2, txt: '10' },
  { id: 3, txt: '20' },
  { id: 4, txt: '30' },
  { id: 5, txt: '40' },
  { id: 6, txt: '50' },
]
const GroupInfo = () => {
  const navigate = useNavigate()

  const staff = useSelector(state => {
    return state.staff.value
  })

  const [isModalAddressOpen, setIsModalAddressOpen] = useState(false)
  const [isDetailAddressFocus, setIsDetailAddressFocus] = useState(false)
  const [isDetailHomepageFocus, setIsDetailHomepageFocus] = useState(false)
  const [detailAddress, setDetailAddress] = useState('');
  const [detailHomepage, setDetailHomepage] = useState('')
  const [showDetailAddressInput, setShowDetailAddressInput] = useState(false)
  const [telephoneNumber, setTelephoneNumber] = useState('')
  const [timeObj, setTimeObj] = useState({
    'MON': {
      id: 0,
      daykor: '월요일',
      isOn: true,
      startHourId: hours[0].id,
      startMinId: minutes[0].id,
      endHourId: hours[0].id,
      endMinId: minutes[0].id,
      'isDropdownStartHourOpen': false,
      'isDropdownStartMinOpen': false,
      'isDropdownEndStartOpen': false,
      'isDropdownEndMinOpen': false,
    },
    'TUE': {
      id: 1,
      daykor: '화요일',
      isOn: true,
      startHourId: hours[0].id,
      startMinId: minutes[0].id,
      endHourId: hours[0].id,
      endMinId: minutes[0].id,
      'isDropdownStartHourOpen': false,
      'isDropdownStartMinOpen': false,
      'isDropdownEndStartOpen': false,
      'isDropdownEndMinOpen': false,
    },
    'WED': {
      id: 2,
      daykor: '수요일',
      isOn: true,
      startHourId: hours[0].id,
      startMinId: minutes[0].id,
      endHourId: hours[0].id,
      endMinId: minutes[0].id,
      'isDropdownStartHourOpen': false,
      'isDropdownStartMinOpen': false,
      'isDropdownEndStartOpen': false,
      'isDropdownEndMinOpen': false,
    },
    'THU': {
      id: 3,
      daykor: '목요일',
      isOn: true,
      startHourId: hours[0].id,
      startMinId: minutes[0].id,
      endHourId: hours[0].id,
      endMinId: minutes[0].id,
      'isDropdownStartHourOpen': false,
      'isDropdownStartMinOpen': false,
      'isDropdownEndStartOpen': false,
      'isDropdownEndMinOpen': false,
    },
    'FRI': {
      id: 4,
      daykor: '금요일',
      isOn: true,
      startHourId: hours[0].id,
      startMinId: minutes[0].id,
      endHourId: hours[0].id,
      endMinId: minutes[0].id,
      'isDropdownStartHourOpen': false,
      'isDropdownStartMinOpen': false,
      'isDropdownEndStartOpen': false,
      'isDropdownEndMinOpen': false,
    },
    'SAT': {
      id: 5,
      daykor: '토요일',
      isOn: true,
      startHourId: hours[0].id,
      startMinId: minutes[0].id,
      endHourId: hours[0].id,
      endMinId: minutes[0].id,
      'isDropdownStartHourOpen': false,
      'isDropdownStartMinOpen': false,
      'isDropdownEndStartOpen': false,
      'isDropdownEndMinOpen': false,
    },
    'SUN': {
      id: 6,
      daykor: '일요일',
      isOn: false,
      startHourId: hours[0].id,
      startMinId: minutes[0].id,
      endHourId: hours[0].id,
      endMinId: minutes[0].id,
      'isDropdownStartHourOpen': false,
      'isDropdownStartMinOpen': false,
      'isDropdownEndStartOpen':false,
      'isDropdownEndMinOpen': false,
    },
    'HOL': {
      id: 7,
      daykor: '공휴일',
      isOn: false,
      startHourId: hours[0].id,
      startMinId: minutes[0].id,
      endHourId: hours[0].id,
      endMinId: minutes[0].id,
      'isDropdownStartHourOpen': false,
      'isDropdownStartMinOpen': false,
      'isDropdownEndStartOpen': false,
      'isDropdownEndMinOpen': false,
    },
  })
  const [isTelephoneNumberFocus, setIsTelephoneNumberFocus] = useState(false)
  const [clickedTime, setClickedTime] = useState({})
  const [rehabGroupName, setRehabGroupName] = useState('')
  const [isRehabGroupNameFocus, setIsRehabGroupNameFocus] = useState(false)
  // const [addressData, setAddressData] = useState({
  //   address: '',
  //   roadAddress: '',
  //   zipCode: ''
  // })
  const [valueChangeObj, setValueChangeObj] = useState({
    tel: false,
    time: false,
    name: false,
    address: false,
    detail: false,
    homepage: false
  })

  const onTelephoneNumberChange = (e) => {
    let value = e.target.value.replace(/[^0-9-]/g, "")
    if(value !== telephoneNumber) {
      setTelephoneNumber(value)
      setValueChangeObj({
        ...valueChangeObj,
        tel: true
      })
    }
  }

  const onRehabNameGroupNameChange = (e) => {
    let value = e.target.value
    if(value !== rehabGroupName) {
      setRehabGroupName(value)
      setValueChangeObj({
        ...valueChangeObj,
        name: true
      })
    }
  }

  const getSelectedStartHourId = (id) => {
    let tempTimeObj = timeObj
    let selectedDay = Object.keys(tempTimeObj)[clickedTime.id]
    if(id !== tempTimeObj[selectedDay].startHourId) {
      tempTimeObj[selectedDay].startHourId = id
      setTimeObj({...tempTimeObj})
      setValueChangeObj({
        ...valueChangeObj,
        time: true
      })
    }
  }

  const getSelectedStartMinId = (id) => {
    let tempTimeObj = timeObj
    let selectedDay = Object.keys(tempTimeObj)[clickedTime.id]
    if(id !== tempTimeObj[selectedDay].startMinId) {
      tempTimeObj[selectedDay].startMinId = id
      setTimeObj({...tempTimeObj})
      setValueChangeObj({
        ...valueChangeObj,
        time: true
      })
    }

  }

  const getSelectedEndHourId = (id) => {
    let tempTimeObj = timeObj
    let selectedDay = Object.keys(tempTimeObj)[clickedTime.id]
    if(id !== tempTimeObj[selectedDay].endHourId) {
      tempTimeObj[selectedDay].endHourId = id
      setTimeObj({...tempTimeObj})
      setValueChangeObj({
        ...valueChangeObj,
        time: true
      })
    }

  }

  const getSelectedEndMinId = (id) => {
    let tempTimeObj = timeObj
    let selectedDay = Object.keys(tempTimeObj)[clickedTime.id]
    if(id !== tempTimeObj[selectedDay].endMinId) {
      tempTimeObj[selectedDay].endMinId = id
      setTimeObj({...tempTimeObj})
      setValueChangeObj({
        ...valueChangeObj,
        time: true
      })
    }

  }

  const onClickRadio = (type, day) => {
    if((timeObj[day].isOn && type === 'off') || (!timeObj[day].isOn && type === 'on')) {
      let tempTimeObj = timeObj
      tempTimeObj[day].isOn = type === 'on' ? true : false
      setTimeObj({...tempTimeObj})
      setValueChangeObj({
        ...valueChangeObj,
        time: true
      })
    }
  }

  const geRehabGroupInfo = () => {
    api.getRehabGroup(staff.rehabGroupId).then((res) => {
      let hospitalInfo = res.data
      setRehabGroupName(hospitalInfo.name)
      setTelephoneNumber(hospitalInfo.phoneNumber)
      // setAddressData({
      //   address: hospitalInfo.address,
      //   roadAddress: hospitalInfo.roadAddress,
      //   zipCode: hospitalInfo.zipCode,
      // })
      
      setDetailHomepage(hospitalInfo.homepage)
      
      if(hospitalInfo.detailAddress) {
        setDetailAddress(hospitalInfo.detailAddress)
        setShowDetailAddressInput(true)
      }

      let tempTimeObj = timeObj
      let operationTime = hospitalInfo.operationTime
      Object.keys(operationTime).forEach((day) => {
        tempTimeObj[day].isOn = operationTime[day].isDayOff ? false : operationTime[day].isDayOff === false ? true : null

        if(operationTime[day].startTime) {
          let timeArr = operationTime[day].startTime.split(':')
          let hour = timeArr[0]
          let min = timeArr[1]
          hours.forEach((hourItem) => {
            if(hourItem.txt === hour) {
              tempTimeObj[day].startHourId = hourItem.id
            }
          })
          minutes.forEach((minItem) => {
            if(minItem.txt === min) {
              tempTimeObj[day].startMinId = minItem.id
            }
          })
        } else {
          tempTimeObj[day].startHourId = 0
          tempTimeObj[day].startMinId = 0
        }

        if(operationTime[day].endTime) {
          let timeArr = operationTime[day].endTime.split(':')
          let hour = timeArr[0]
          let min = timeArr[1]
          hours.forEach((hourItem) => {
            if(hourItem.txt === hour) {
              tempTimeObj[day].endHourId = hourItem.id
            }
          })
          minutes.forEach((minItem) => {
            if(minItem.txt === min) {
              tempTimeObj[day].endMinId = minItem.id
            }
          })
        } else {
          tempTimeObj[day].endHourId = 0
          tempTimeObj[day].endMinId = 0
        }
      })
      setTimeObj(tempTimeObj)
    })
  }

  const toggleDropdown = (type, timeItem, day) => {
    setClickedTime(timeItem)
    let tempTimeObj = timeObj
    if(type === 'startHour') {
      tempTimeObj[day].isDropdownStartHourOpen = !tempTimeObj[day].isDropdownStartHourOpen
    } else if(type === 'startMin') {
      tempTimeObj[day].isDropdownStartMinOpen = !tempTimeObj[day].isDropdownStartMinOpen
    } else if(type === 'endHour') {
      tempTimeObj[day].isDropdownEndHourOpen = !tempTimeObj[day].isDropdownEndHourOpen
    } else {
      tempTimeObj[day].isDropdownEndMinOpen = !tempTimeObj[day].isDropdownEndMinOpen
    }

    setTimeObj({...tempTimeObj})
  }

  // const getAddressChange = (value) => {
  //   if(value.address !== addressData.address) {
  //     setAddressData(value)
  //     setValueChangeObj({
  //       ...valueChangeObj,
  //       address: true
  //     })
  //   }
  // }

  const getDayOffRadioBtn = (day) => {
    if(timeObj[day].isOn) {
      return BRadioUnCheck
    } else if(timeObj[day].isOn === false) {
      return PRadioCheck
    } else if (day === 'SUN' || day === 'HOL'){
      return PRadioCheck
    } else {
      return BRadioUnCheck
    }
  }

  const getWorkingDayRadioBtn = (day) => {
    if(timeObj[day].isOn) {
      return PRadioCheck
    } else if(timeObj[day].isOn === false) {
      return BRadioUnCheck
    } else if (day !== 'SUN' && day !== 'HOL'){
      return PRadioCheck
    } else {
      return BRadioUnCheck
    }
  }

  const onDetailAddressChange = (e) => {
    let value = e.target.value
    if(value !== detailAddress) {
      setDetailAddress(value)
      setValueChangeObj({
        ...valueChangeObj,
        detail: true
      })
    }
  }

  const onDetailHomepageChange = (e) => {
    let value = e.target.value
    if(value !== detailHomepage) {
      setDetailHomepage(value)
      setValueChangeObj({
        ...valueChangeObj,
        homepage: true
      })
    }
  }

  const saveGroupInfo = () => {
    let valueArr = Object.values(valueChangeObj)
    if(!valueArr.includes(true)) {
      return
    }
    let days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN', 'HOL']
    let tempTimeData = {}
    days.forEach((day) => {
      tempTimeData[day] = {
        'startTimeHour': '',
        'startTimeMin': '',
        'endTimeHour': '',
        'endTimeMin': '',
        'startTime': null,
        'endTime': null,
      }
    })

    Object.keys(timeObj).forEach((day) => {
      hours.forEach((hour) => {
        if(timeObj[day].startHourId && (timeObj[day].startHourId === hour.id)) {
          tempTimeData[day].startTimeHour = hour.txt
        }
        if(timeObj[day].endHourId && (timeObj[day].endHourId === hour.id)) {
          tempTimeData[day].endTimeHour = hour.txt
        }
      })

      minutes.forEach((min) => {
        if(timeObj[day].startMinId && (timeObj[day].startMinId === min.id)) {
          tempTimeData[day].startTimeMin = min.txt
        }
        if(timeObj[day].endMinId && (timeObj[day].endMinId === min.id)) {
          tempTimeData[day].endTimeMin = min.txt
        }
      })

      if(tempTimeData[day].startTimeHour && tempTimeData[day].startTimeMin) {
        tempTimeData[day].startTime = `${tempTimeData[day].startTimeHour}:${tempTimeData[day].startTimeMin}`
      }
      if(tempTimeData[day].endTimeHour && tempTimeData[day].endTimeMin) {
        tempTimeData[day].endTime = `${tempTimeData[day].endTimeHour}:${tempTimeData[day].endTimeMin}`
      }
    })

    let data = {
      phoneNumber: telephoneNumber,
      name: rehabGroupName,
      operationTime: {},
      // address: addressData.address,
      // roadAddress: addressData.roadAddress,
      // zipCode: addressData.zipCode,
      detailAddress: detailAddress,
      homepage: detailHomepage,
    }

    days.forEach((day) => {
      data.operationTime[day] = {
        'isDayOff': timeObj[day].isOn ? false : timeObj[day].isOn === false ? true : (day === 'SUN' || day === 'HOL') ? true : false,
        'startTime': tempTimeData[day].startTime,
        'endTime': tempTimeData[day].endTime
      }
    })

    api.postRehabGroupInfo(staff.rehabGroupId, data).then((res) => {
      setValueChangeObj({
        tel: false,
        name: false,
        time: false,
        address: false,
        detail: false,
        homepage: false,
      })
      showToast('success', '병원정보가 수정되었습니다.')
    }).catch((err) => {
      if(err.response.data.code === '1002') {
        showToast('error', '전화번호를 다시 입력해주세요.')
      }
    })
  }

  useEffect(() => {
    if(staff.rehabGroupId) {
      geRehabGroupInfo()
    }
  }, [staff.rehabGroupId])

  return (
    <div className={cx('groupInfo')}>
      <HeaderNav/>
      <div className={cx('wrapper')}>
        <div className={cx('move')}>
          <span className={cx('home')} onClick={() => navigate('/home')}>홈</span>
          <img src={LGArrowRight} alt='LGArrowRight'/>
          <span className={cx('here')}>그룹 정보 수정</span>
        </div>
        <div className={cx('title')}>
          <p>그룹 정보 수정</p>
        </div>
        <div className={cx('inputContainer')}>
          <div className={cx('inputWrapper')}>
            <p>그룹명</p>
            <input
              className={cx(isRehabGroupNameFocus && 'active')}
              value={rehabGroupName}
              onChange={onRehabNameGroupNameChange}
              onFocus={() => setIsRehabGroupNameFocus(true)}
              onBlur={() => setIsRehabGroupNameFocus(false)}
              placeholder='그룹명을 입력해 주세요'
            />
            { isRehabGroupNameFocus && (
              <img src={CloseRound} onMouseDown={() => setRehabGroupName('')} alt='CloseRound'/>
            )}
          </div>
          <div className={cx('inputWrapper')}>
            <p>전화번호</p>
            <input
              className={cx(isTelephoneNumberFocus && 'active')}
              value={telephoneNumber === null ? '' : telephoneNumber}
              onChange={onTelephoneNumberChange}
              onFocus={() => setIsTelephoneNumberFocus(true)}
              onBlur={() => setIsTelephoneNumberFocus(false)}
              placeholder='전화번호를 입력해 주세요'
            />
            { isTelephoneNumberFocus && (
              <img src={CloseRound} onMouseDown={() => setTelephoneNumber('')} alt='CloseRound'/>
            )}
          </div>
        </div>

        {/*<div className={cx('address')}>*/}
        {/*  <p>주소</p>*/}
        {/*  <div className={cx('inputBox')}>*/}
        {/*    <input*/}
        {/*      readOnly={true}*/}
        {/*      value={addressData.address === null ? '' : addressData.address}*/}
        {/*      placeholder='주소를 검색해 주세요'*/}
        {/*    />*/}
        {/*    <button onClick={() => setIsModalAddressOpen(true)}>주소 찾기</button>*/}
        {/*  </div>*/}
        {/*  { showDetailAddressInput && (*/}
        {/*    <div className={cx('detailAddressInput')}>*/}
        {/*      <input*/}
        {/*        className={cx(isDetailAddressFocus && 'active')}*/}
        {/*        placeholder='상세 주소를 입력해 주세요'*/}
        {/*        value={detailAddress === null ? '' : detailAddress}*/}
        {/*        onChange={(e) => onDetailAddressChange(e)}*/}
        {/*        onFocus={() => setIsDetailAddressFocus(true)}*/}
        {/*        onBlur={() => setIsDetailAddressFocus(false)}*/}
        {/*      />*/}
        {/*      { isDetailAddressFocus && (*/}
        {/*        <img src={CloseRound} onMouseDown={() => setDetailAddress('')} alt='CloseRound'/>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
        <div className={cx('homepage')}>
          <p>홈페이지 링크</p>
          <div className={cx('detailAddressInput')}>
              <input
                className={cx(isDetailHomepageFocus && 'active')}
                placeholder='MORA 이용자에게 안내할 수 있는 홈페이지 주소를 입력해 주세요'
                value={detailHomepage === null ? '' : detailHomepage}
                onChange={(e) => onDetailHomepageChange(e)}
                onFocus={() => setIsDetailHomepageFocus(true)}
                onBlur={() => setIsDetailHomepageFocus(false)}
              />
              { isDetailHomepageFocus && (
                <img src={CloseRound} onMouseDown={() => setDetailHomepage('')} alt='CloseRound'/>
              )}
            </div>
        </div>
        <div className={cx('time')}>
          <p>운영 시간</p>
          <div className={cx('timeTable')}>
            { Object.keys(timeObj).map((day) => (
              <div key={day} className={cx('timeItem')}>
                <span className={cx('day', day)}>{ timeObj[day].daykor }</span>
                <p onClick={() => onClickRadio('off', day)}>
                  <img src={getDayOffRadioBtn(day)} alt='RadioUnCheck'/>
                  <span>휴무</span>
                </p>
                <span className={cx('bar')}>|</span>
                <p className={cx('workingDay')} onClick={() => onClickRadio('on', day)}>
                  <img src={getWorkingDayRadioBtn(day)} alt='RadioCheck'/>
                  <span>운영</span>
                </p>
                <div className={cx('dropdownWrapper')}>
                  <DropdownOrder
                    orderList={hours}
                    isDropdownOrderOpen={timeObj[day]['isDropdownStartHourOpen']}
                    setSelectedOrderId={getSelectedStartHourId}
                    selectedOrderId={timeObj[day]['startHourId']}
                    setIsDropdownOrderOpen={() => toggleDropdown('startHour', timeObj[day], day)}
                    listWidth='180'
                    isScrollList={true}
                    height={52}
                    fixedValueExist={false}
                    disable={timeObj[day].isOn === false}
                  />
                </div>
                <span className={cx('colon')}>:</span>
                <div className={cx('dropdownWrapper')}>
                  <DropdownOrder
                    orderList={minutes}
                    isDropdownOrderOpen={timeObj[day]['isDropdownStartMinOpen']}
                    setSelectedOrderId={getSelectedStartMinId}
                    selectedOrderId={timeObj[day]['startMinId']}
                    setIsDropdownOrderOpen={() => toggleDropdown('startMin', timeObj[day], day)}
                    listWidth='180'
                    isScrollList={true}
                    height={52}
                    fixedValueExist={false}
                    disable={timeObj[day].isOn === false}
                  />
                </div>
                <span className={cx('tilde')}>~</span>
                <div className={cx('dropdownWrapper')}>
                  <DropdownOrder
                    orderList={hours}
                    isDropdownOrderOpen={timeObj[day]['isDropdownEndHourOpen']}
                    setSelectedOrderId={getSelectedEndHourId}
                    selectedOrderId={timeObj[day]['endHourId']}
                    setIsDropdownOrderOpen={() => toggleDropdown('endHour', timeObj[day], day)}
                    listWidth='180'
                    isScrollList={true}
                    height={52}
                    fixedValueExist={false}
                    disable={timeObj[day].isOn === false}
                  />
                </div>
                <span className={cx('colon')}>:</span>
                <div className={cx('dropdownWrapper')}>
                  <DropdownOrder
                    orderList={minutes}
                    isDropdownOrderOpen={timeObj[day]['isDropdownEndMinOpen']}
                    setSelectedOrderId={getSelectedEndMinId}
                    selectedOrderId={timeObj[day]['endMinId']}
                    setIsDropdownOrderOpen={() =>toggleDropdown('endMin', timeObj[day], day)}
                    listWidth='180'
                    isScrollList={true}
                    height={52}
                    fixedValueExist={false}
                    disable={timeObj[day].isOn === false}
                  />
                </div>
              </div>
            )) }

          </div>

        </div>
        <div className={cx('warning')}>
          <img src={GWarning} alt='GWarning'/>
          <span>위 정보는 MORA App 상에서 고객에게 제공돼요.</span>
        </div>
        <button onClick={() => saveGroupInfo()} className={cx(Object.values(valueChangeObj).includes(true) && 'active')}>저장하기</button>

      </div>
      <FooterGray position='relative'/>
      {/*{ isModalAddressOpen && (*/}
      {/*  <ModalAddress*/}
      {/*    bodyTitle='주소 검색'*/}
      {/*    isModalAddressOpen={isModalAddressOpen}*/}
      {/*    setIsModalAddressOpen={setIsModalAddressOpen}*/}
      {/*    setShowDetailAddressInput={setShowDetailAddressInput}*/}
      {/*    setAddress={getAddressChange}*/}
      {/*  />*/}
      {/*) }*/}

    </div>
  );
};

export default GroupInfo;