import React, {useEffect, useRef, useState} from 'react';
import classnames from "classnames/bind";
import styles from './dropdownBasic.module.scss'

const GTriangle = `${process.env.REACT_APP_RESOURCE_URL}/G_triangle.svg`

const cx = classnames.bind(styles)

const DropdownBasic = ({ selectedFilter, listFilters, fetchData }) => {
  const ref = useRef()

  const [isStaffDropdownOpen, setIsStaffDropdownOpen] = useState(false)

  const toggleDropdown = (e) => {
    e.stopPropagation()
    setIsStaffDropdownOpen(!isStaffDropdownOpen)
  }

  const onClickFilter = (filter) => {
    setIsStaffDropdownOpen(!isStaffDropdownOpen)
    if(selectedFilter !== filter) {
      fetchData(filter)
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isStaffDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsStaffDropdownOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isStaffDropdownOpen])

  return (
    <div className={cx('dropdownBasic')}>
      <div className={cx('dropdownBig')} ref={ref}>
        <div className={cx('listSelected', isStaffDropdownOpen && 'borderPrimary')} onClick={(e) => toggleDropdown(e)}>
          <span>{ selectedFilter }</span>
          <img className={cx(isStaffDropdownOpen && 'rotate')} src={GTriangle} alt='GTriangle'/>
        </div>
        { isStaffDropdownOpen && (
          <div className={cx('filterLists')}>
            { listFilters.map((filter) => (
              <div key={filter} className={cx(filter === selectedFilter && 'active')} onClick={() => onClickFilter(filter)}>
                <p>{ filter }</p>
              </div>
            )) }
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownBasic;