import React, { useRef, useState} from 'react';
import classnames from "classnames/bind";
import styles from './rehabCardExercise.module.scss'
import {useSelector} from "react-redux";
import api from "@/api";
import tagKor from "@/data/tagKor"
import ModalExDetail from "../../modal/modalExDetail/modalExDetail";
import Refresh from "../../../../helpers/refresh";

const GRoundStar = `${process.env.REACT_APP_RESOURCE_URL}/LG_roundStar.svg`
const YStarRound = `${process.env.REACT_APP_RESOURCE_URL}/Y_star_round.svg`
const ExNote = `${process.env.REACT_APP_RESOURCE_URL}/ex_note.svg`

const cx = classnames.bind(styles)


const RehabCardExercise = ({ exercise }) => {
  const refs = useRef([])
  const staff = useSelector(state => {
    return state.staff.value
  })
  const lvArr = useSelector(state => {
    return state.rehab.value.lvArr
  })
  const wrapperRef = useRef(null)

  const [modalExerciseDetailContent, setModalExerciseDetailContent] = useState({
    header: '',
    exercise: {},
  })
  const [isModalExerciseDetailOpen, setIsModalExerciseDetailOpen] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false)

  const toggleBookmark = (e, id) => {
    e.stopPropagation()
    let body = {
      exerciseId: id
    }
    if(staff.bookmarkExercise.includes(id)) {
      api.deleteStaffBookmark(body).then(() => {
        setIsRefresh(true)
      }).catch((e) => {
        console.log(e)
      })
    } else {
      api.postStaffBookmark(body).then(() => {
        setIsRefresh(true)
      }).catch((e) => {
        console.log(e)
      })
    }
  }

  const openExerciseDetailModal = (exercise) => {
    setModalExerciseDetailContent({
      header: '미리보기',
      exercise: exercise,
    })

    setIsModalExerciseDetailOpen(true)
  }

  const mouseenter = () => {
    setIsHover(true)
  }

  const mouseleave = () => {
    setIsHover(false)
  }

  const onLoadVideo = () => {
    let video = refs.current[0]
    video.playbackRate = 2.5
  }

  const onTimeupdateVideo = (event) => {
    let video = event.target
    if(!video) {
      return
    }
    if(video.duration - 1 < video.currentTime) {
      video.currentTime = 8
      video.play()
    }
  }

  return (
    <div className={cx('rehabCardExercise')} onClick={() => openExerciseDetailModal(exercise)}>
      { isRefresh && <Refresh isRedirectHome={false} isUpdateToken={false}/> }
      <div className={cx('exerciseItemWrapper')}>
        <div className={cx('iconBox')}>
          <div onClick={(e) => toggleBookmark(e, exercise.id)}>
            <img src={staff.bookmarkExercise.includes(exercise.id) ? YStarRound : GRoundStar} alt='star'/>
          </div>
        </div>
        <div ref={wrapperRef} key={exercise} className={cx('exerciseItem')}>
          { isHover ? (
            <video
              onMouseLeave={mouseleave}
              poster={exercise.urls.IT}
              src={`${exercise.urls.MG}#t=8`}
              type='video/mp4'
              autoPlay={true}
              muted={true}
              ref={(ele) => {refs.current[0] = ele}}
              onLoadedData={onLoadVideo}
              onTimeUpdate={(e) => onTimeupdateVideo(e)}
            />
          ) : (
            <img
              className={cx('exImage')}
              src={exercise.urls.IT}
              alt='exercise'
              onMouseEnter={mouseenter}
            />
          )}
          <div className={cx('exerciseTitleBox')}>
            <img src={ExNote} alt='ExNote'/>
            <div className={cx('exNoteBox')}>
              <p>
                <span>{ tagKor['OB'][exercise.objective]  } /</span>
                { exercise.tag.KC ? (exercise.tag.KC.map((kc) => (
                  <span key={kc}> {tagKor['KC'][kc]} /</span>
                ))) : '' }
                { exercise.tag.AP ? (exercise.tag.AP.map((ap, idx) => (
                  <span key={ap}> {tagKor['AP'][ap]} { idx + 1 === exercise.tag.AP.length ? '' : '/' }</span>
                ))) : '' }
              </p>
              <h6>{ exercise.name }</h6>
              <div className={cx('workoutSubTitleBox')}>
                <p className={cx(lvArr[exercise.level - 1].color)}>
                  { lvArr[exercise.level - 1].lv }
                </p>
              </div>
            </div>

          </div>
        </div>

      </div>
      { isModalExerciseDetailOpen && (
        <ModalExDetail
          header={modalExerciseDetailContent.header}
          exercise={modalExerciseDetailContent.exercise}
          isModalExerciseDetailOpen={isModalExerciseDetailOpen}
          setIsModalExerciseDetailOpen={setIsModalExerciseDetailOpen}
        />
      ) }
    </div>
  );
};

export default RehabCardExercise;