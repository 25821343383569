import React, {useEffect, useState} from 'react';
import styles from './planDates.module.scss'
import classnames from "classnames/bind";
import {useDispatch, useSelector} from "react-redux";
import {formatDate, setMinutes} from "../../../helpers/common";
import {
  resetAddedPlanExerciseArr,
  resetDateExercisesToCopy, resetDateToEdit, resetPlanAssignmentParams,
  resetPlanSchedule, setAddedPlanExerciseArr,
  setDateExercisesToCopy, setDateToEdit, setIsAddNewDate,
  setPlanAssignmentStep,
  setPlanSchedule,
  setIsBackToCalendar,
} from "../../../store/slice/planSlice";
import ModalSmall from "../../common/modal/modalSmall/modalSmall";
import {showToast} from "../../../helpers/toastMessage";
import {setActiveFilterRehabId} from "../../../store/slice/rehabSlice";

const GSquareLeftArrow = `${process.env.REACT_APP_RESOURCE_URL}/G_square_left_arrow.svg`
const LGSquareLeftArrow = `${process.env.REACT_APP_RESOURCE_URL}/LG_square_left_arrow.svg`
const GSquareRightArrow = `${process.env.REACT_APP_RESOURCE_URL}/G_square_right_arrow.svg`
const LGSquareRightArrow = `${process.env.REACT_APP_RESOURCE_URL}/LG_square_right_arrow.svg`
const GKeepCount = `${process.env.REACT_APP_RESOURCE_URL}/G_keep_count.svg`
const PlanCalendar = `${process.env.REACT_APP_RESOURCE_URL}/plan_calendar.svg`
const GPlusSharp = `${process.env.REACT_APP_RESOURCE_URL}/G_plus_sharp.svg`

const cx = classnames.bind(styles)
const PlanDates = () => {
  let dispatch = useDispatch()
  const planSchedule = useSelector(state => {
    return state.plan.value.planSchedule
  })

  const planScheduleLength = Object.keys(planSchedule).length
  const planScheduleWithEmptyNew = {...planSchedule, '99999999': []}
  const planScheduleWithEmptyNewLength = Object.keys(planScheduleWithEmptyNew).length

  const [isRightArrowActive, setIsRightArrowActive] = useState(false)
  const [isLeftArrowActive, setIsLeftArrowActive] = useState(false)
  const [planNestedArrNo, setPlanNestedArrNo] = useState(0)
  const [planNestedArr, setPlanNestedArr] = useState([])
  const [planThreeDates, setPlanThreeDates] = useState([])
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [modalSmallContent, setModalSmallContent] = useState({
    bodyTitle: '',
    bodySub: '',
    isOneBtn: false,
    btnFirstTxt: '',
    btnSecondTxt: '',
    btnFirstEvent: {},
    btnSecondEvent: {},
    isModalSmallOpen: {},
  })

  const setPlanArr = () => {
    let nestedDatesArr = []
    for(let i = 0; i < planScheduleWithEmptyNewLength; i += 3) {
      nestedDatesArr.push(Object.entries(planScheduleWithEmptyNew).slice(i, i + 3))
    }

    setPlanNestedArr(nestedDatesArr)
    setPlanThreeDates(nestedDatesArr[0])
    setPlanNestedArrNo(0)
  }

  const onDateDeleteBtn = (planDate) => {
    setModalSmallContent({
      bodyTitle: '해당 일정을 삭제하시겠습니까?',
      bodySub: '일정을 삭제하면</br>해당 일정의 운동정보가 삭제됩니다.',
      isOneBtn: false,
      btnSecondTxt: '취소',
      btnFirstTxt: '삭제하기',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      btnFirstEvent: () => deleteDate(planDate),
    })
    setIsModalSmallOpen(true)
  }

  const deleteDate = (planDate) => {
    let planScheduleArr = Object.entries(planSchedule)
    let filteredSchedule = planScheduleArr.filter((schedule) => schedule[0] !== planDate[0])

    if(!filteredSchedule.length) {
      dispatch(resetPlanSchedule())
      dispatch(setPlanAssignmentStep(1))
    } else {
      let newPlanSchedule = {}
      filteredSchedule.forEach((schedule) => {
        newPlanSchedule[schedule[0]] = schedule[1]
      })
      dispatch(setPlanSchedule(newPlanSchedule))
    }

    showToast('error', '일정이 삭제되었습니다.')
    setIsModalSmallOpen(false)
  }

  const editExercise = (planDate) => {
    dispatch(setAddedPlanExerciseArr(planDate[1]))
    dispatch(setDateToEdit(planDate[0]))
    dispatch(setPlanAssignmentStep(0))
  }

  const addNewDate = () => {
    dispatch(setIsAddNewDate(true))
    dispatch(resetAddedPlanExerciseArr())
    dispatch(setPlanAssignmentStep(0))
    dispatch(setActiveFilterRehabId(0))
  }

  const onDateAllDeleteBtn = () => {
    setModalSmallContent({
      bodyTitle: '전체 일정을 삭제하시겠습니까?',
      bodySub: '전체 일정을 삭제하면 모든 일정이 삭제됩니다.',
      isOneBtn: false,
      btnSecondTxt: '취소',
      btnFirstTxt: '삭제하기',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      btnFirstEvent: () => deleteDateAll(),
    })
    setIsModalSmallOpen(true)
  }

  const deleteDateAll = () => {
    dispatch(resetPlanSchedule())
    dispatch(resetPlanAssignmentParams())
    dispatch(resetAddedPlanExerciseArr())
    dispatch(setPlanAssignmentStep(0))
    showToast('error', '전체 일정이 삭제되었습니다.')
    setIsModalSmallOpen(false)
  }

  const setArrows = () => {
    if(!planThreeDates.length) {
      return
    }

    if(planScheduleWithEmptyNewLength <= 3) {
      setIsLeftArrowActive(false)
      setIsRightArrowActive(false)
    } else {
      if(planThreeDates[planThreeDates.length - 1][0] === Object.keys(planScheduleWithEmptyNew)[planScheduleWithEmptyNewLength - 1]) {
        setIsLeftArrowActive(true)
        setIsRightArrowActive(false)
      } else if (planThreeDates[0][0] === Object.keys(planScheduleWithEmptyNew)[0]) {
        setIsLeftArrowActive(false)
        setIsRightArrowActive(true)
      } else {
        setIsLeftArrowActive(true)
        setIsRightArrowActive(true)
      }
    }
  }

  const getTotalTime = (exercises) => {
    let timeCount = 0
    exercises.forEach((ex) => {
      timeCount += (ex.media[`M${ex.time ? ex.time : ex.count}`].length * ex.repeat)
    })
    return setMinutes(timeCount)
  }

  const goNext = () => {
    dispatch(setPlanAssignmentStep(3))
  }

  const onArrowClick = (direction) => {
    if(direction === 'left') {
      if(isLeftArrowActive) {
        setPlanNestedArrNo(planNestedArrNo - 1)
        setPlanThreeDates(planNestedArr[planNestedArrNo - 1])
      }
    } else {
      if(isRightArrowActive) {
        setPlanNestedArrNo(planNestedArrNo + 1)
        setPlanThreeDates(planNestedArr[planNestedArrNo + 1])
      }
    }
  }

  const goBack = () => {
    dispatch(setIsBackToCalendar(true))
    dispatch(setPlanAssignmentStep(1))
  }

  const copyDate = (dateExercises) => {
    dispatch(setIsBackToCalendar(true))
    dispatch(setDateExercisesToCopy(dateExercises))
    dispatch(setAddedPlanExerciseArr(dateExercises[1]))
    dispatch(setPlanAssignmentStep(4))
  }

  useEffect(() => {
    setPlanArr()
  }, [planSchedule])

  useEffect(() => {
    setArrows()
  }, [planThreeDates])

  useEffect(() => {
    dispatch(resetDateExercisesToCopy())
    dispatch(resetDateToEdit())
    dispatch(setIsAddNewDate(false))
    dispatch(setIsBackToCalendar(false))

    // position: sticky 작동하려면 body에 걸려있는 overflow hidden 풀어줘야함
    let body = document.getElementsByTagName('body')[0]
    body.style.overflowX = 'visible'
    return () => body.style.overflowX = 'hidden'
  }, [])

  return (
    <div className={cx('planDates')}>
      <div className={cx('rightWrapper')}>
        <div className={cx('arrowBtns')}>
          <img src={isLeftArrowActive ? GSquareLeftArrow : LGSquareLeftArrow} className={cx( isLeftArrowActive && 'active' )} onClick={() => onArrowClick('left')} alt='SquareLeftArrow'/>
          <img src={isRightArrowActive ? GSquareRightArrow : LGSquareRightArrow} className={cx( isRightArrowActive && 'active' )} onClick={() => onArrowClick('right')} alt='SquareRightArrow'/>
        </div>
        <div className={cx('datesContainer')}>
          { planThreeDates.length ? (
            planThreeDates.map((planDate, idx) => (
              planDate[0] !== '99999999' ? (
                <div key={planDate[0]}>
                  <div className={cx('dateBoxTop')}>
                    <p>{ Object.entries(planSchedule).findIndex(item => item[0] === planDate[0]) + 1 }일차</p>
                    <div className={cx('dateTopTitle')}>
                      <span>{ formatDate(planDate[0]) }</span>
                      <div className={cx('dateBtns')}>
                        <button className={cx('btnCopy')} onClick={() => copyDate(planDate)}>복사</button>
                        <button onClick={() => onDateDeleteBtn(planDate)}>삭제</button>
                      </div>
                    </div>
                  </div>
                  <div className={cx('dateBoxMain')}>
                    <div className={cx('mainTop')}>
                      <span>{ planDate[1].length }개 <span>|</span> { getTotalTime(planDate[1]) }분</span>
                      <button onClick={() => editExercise(planDate)}>편집</button>
                    </div>
                    <div className={cx('mainList')}>
                      { planDate[1].map((exercise) => (
                        <div key={exercise.unique}>
                          <div className={cx('grayBox')}></div>
                          <img src={exercise.media.IT.url} alt='exImg'/>
                          <div className={cx('exInfo')}>
                            <p className={cx('exTitle')}>{ exercise.name }</p>
                            <p className={cx('exCount')}>
                              <span>{ exercise.time ? exercise.time + '초' : exercise.count + '회' }</span>
                              <span className={cx('multiply')}>X</span>
                              <span>{ exercise.repeat }세트</span>
                            </p>
                          </div>
                        </div>
                      )) }
                    </div>
                  </div>
                </div>
              ) : (
                <div key={planDate[0]} className={cx('addNew')} onClick={() => addNewDate()}>
                  <img src={GPlusSharp} alt='GPlusSharp'/>
                  <span>새로운 일정 추가</span>
                </div>
              )
            ))
          ) : ''}
        </div>
      </div>
      <div className={cx('leftWrapper')}>
        <div className={cx('floatContainer')}>
          <p>일자별 플랜 정보</p>
          <div className={cx('totalExCount')}>
            <img src={PlanCalendar} alt='PlanCalendar'/>
            <span>총 { planScheduleLength }일 플랜</span>
          </div>
          <div className={cx('planWrap')}>
            { Object.keys(planSchedule).map((date, idx) => (
              <div key={date}>
                <p className={cx('day')}>{ idx + 1 }일차</p>
                <p className={cx('dateWrap')}>
                  <span className={cx('date')}>{ formatDate(date) }</span>
                  <span className={cx('countTime')}>{ planSchedule[date].length }개<span> | </span>{ getTotalTime(planSchedule[date]) }분</span>
                </p>
              </div>
            )) }
          </div>
          <button onClick={() => onDateAllDeleteBtn()}>전체 일정 삭제</button>
          <div className={cx('btnBox')}>
            <button className={cx('backBtn')} onClick={() => goBack()}>뒤로</button>
            <button className={cx('nextBtn')} onClick={() => goNext() }>다음</button>
          </div>
        </div>
      </div>
      { isModalSmallOpen &&
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          bodySub={modalSmallContent.bodySub}
          isOneBtn={modalSmallContent.isOneBtn}
          btnSecondTxt={modalSmallContent.btnSecondTxt}
          btnFirstTxt={modalSmallContent.btnFirstTxt}
          btnSecondEvent={modalSmallContent.btnSecondEvent}
          btnFirstEvent={modalSmallContent.btnFirstEvent}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}/>
      }
    </div>
  );
};

export default PlanDates;