import {createSlice} from "@reduxjs/toolkit";

const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState: { value: {
      unpaidAmount: 0,
      isActiveCardExist: false,
      activeCardId: null,
      memberCount: 0,
    } },
  reducers: {
    setUnpaidAmount: (state, action) => {
      state.value.unpaidAmount = action.payload
    },

    checkIsActiveCardExist: (state, action) => {
      state.value.isActiveCardExist = action.payload
    },

    setActiveCardId: (state, action) => {
      state.value.activeCardId = action.payload
    },
    setMemberCount: (state, action) => {
      state.value.memberCount = action.payload
    }

  }
})

export default paymentSlice
export const { setUnpaidAmount, checkIsActiveCardExist, setMemberCount, setActiveCardId } = paymentSlice.actions