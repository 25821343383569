import React from 'react';
import styles from './changeAccount.module.scss'
import classnames from 'classnames/bind';
import HeaderGray from "@/common/header/headerGray/headerGray";
import {useNavigate} from "react-router-dom";
import FooterGray from "../../common/footer/footerGray/footerGray";

const phoneNumber = `${process.env.REACT_APP_RESOURCE_URL}/icon/phoneNumber.svg`
const password = `${process.env.REACT_APP_RESOURCE_URL}/icon/password.svg`

const cx = classnames.bind(styles);

const ChangeAccount = () => {
  const navigate = useNavigate()

  return (
    <div className={cx('changeAccount')}>
      <HeaderGray/>
      <div className={cx('content')}>
        <p className={cx('title')}>계정 정보 변경</p>
        <div className={cx('subTitle')}>
          <p>아래에서 변경하고 싶은 항목을 선택하세요.</p>
        </div>
        <div className={cx('items')}>
          <div className={cx('item')} onClick={() => navigate('/authentication', { state: { title: 'phoneChange' } })}>
            <img src={phoneNumber} alt='phoneNumber'/>
            <span>휴대폰 번호(ID)</span>
          </div>
          <div className={cx('item')} onClick={() => navigate('/authentication', { state: { title: 'passwordChange' } })}>
            <img src={password} alt='password'/>
            <span>비밀번호</span>
          </div>
        </div>
        <div className={cx('goToLogin')}>
          <button onClick={() => navigate('/')}>로그인하러 가기</button>
        </div>
      </div>
      <FooterGray/>
    </div>
  );
};

export default ChangeAccount;