import React, {useEffect, useState} from 'react';

import classnames from "classnames/bind";
import styles from './noticeDetail.module.scss'
import {useLocation, useNavigate} from "react-router-dom";
import HeaderNav from "@/common/header/headerNav/headerNav";
import FooterGray from "@/common/footer/footerGray/footerGray";
import api from "../../../../utils/api";
const cx = classnames.bind(styles)

const arrowRightThinGray = `${process.env.REACT_APP_RESOURCE_URL}/arrow_right_thin_gray.svg`
const NoticeDetail = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [title, setTitle] = useState('')
  const [date, setDate] = useState('')
  const [content, setContent] = useState('')

  useEffect(() => {
    api.getNoticeDetail(location.pathname.split('/')[3]).then((res) => {
      let data = res.data
      setTitle(data.title)
      setDate(data.createdDateTime.split('T')[0].replaceAll('-', '.'))
      setContent(data.content)
    })
  }, []);

  return (
    <div className={cx('noticeDetail')}>
      <HeaderNav/>
      <div className={cx('detailWrapper')}>
        <div className={cx('breadCrumb')}>
          <span onClick={() => navigate(`/terms/notices?page=${location.search.split('=')[1]}`)}>공지사항</span>
          <img src={arrowRightThinGray} alt='arrowRightThinGray'/>
          <p>{ title }</p>
        </div>
        <div className={cx('top')}>
          <h3>{ title }</h3>
          <p>{ date }</p>
        </div>
        <div className={cx('content')} dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
      <FooterGray position='relative'/>
    </div>
  );
};

export default NoticeDetail;