import {configureStore} from "@reduxjs/toolkit";
import notiSlice from "./slice/notiSlice";
import staffSlice from "./slice/staffSlice";
import hospitalSlice from "./slice/hospitalSlice";
import modalBtnSlice from "./slice/modalBtnSlice";
import patientSlice from "./slice/patientSlice";
import paymentSlice from "./slice/paymentSlice";
import planSlice from "./slice/planSlice";
import rehabSlice from "./slice/rehabSlice";
import programSlice from "./slice/programSlice";
import refreshSlice from "./slice/refreshSlice";
import subscriptionSlice from "./slice/subscriptionSlice";

const store = configureStore({
  reducer: {
    noti: notiSlice.reducer,
    staff: staffSlice.reducer,
    hospital: hospitalSlice.reducer,
    modalBtn: modalBtnSlice.reducer,
    patient: patientSlice.reducer,
    payment: paymentSlice.reducer,
    plan: planSlice.reducer,
    rehab: rehabSlice.reducer,
    program: programSlice.reducer,
    refresh: refreshSlice.reducer,
    subscription: subscriptionSlice.reducer,
  }
})

export default store