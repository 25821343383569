import React from 'react';
import styles from './buttonPrimary.module.scss'
import classnames from "classnames/bind";

const WPlusSharp = `${process.env.REACT_APP_RESOURCE_URL}/W_plus_sharp.svg`
const CalendarArrow = `${process.env.REACT_APP_RESOURCE_URL}/calendar_arrow.svg`

const cx = classnames.bind(styles);

const ButtonPrimary = ({ text, onBtnPrimaryClick, btnDisabled = false, icon = null, width = 0, height = 0, font = '' }) => {
  const getIcon = () => {
    if(icon === 'WPlusSharp') {
      return WPlusSharp
    } else if(icon === 'CalendarArrow') {
      return CalendarArrow
    } else {
      return null
    }
  }

  return (
    <button
      className={cx(`buttonPrimary`, btnDisabled ? `btnDisabled` : '', font && font )}
      onClick={onBtnPrimaryClick}
      style={{ height: height ? height + 'px' : '58px', width: width ? width + 'px' : '100%' }}
    >
      { icon && <img className={cx('primaryBtnIcon')} src={ getIcon() } alt='icon'/> }
      { text }
    </button>
  );
};

export default ButtonPrimary;