import {createSlice} from "@reduxjs/toolkit";

const notiSlice = createSlice({
  name: 'notiSlice',
  initialState: { value: false },
  reducers: {
    updateNoti: (state, action) => {
      state.value = action.payload
    }
  }
})

export default notiSlice
export const { updateNoti } = notiSlice.actions