import React, {useState} from 'react';
import styles from './programExerciseItemDetail.module.scss'
import classnames from 'classnames/bind';
import imgSize from "@/data/imgSize"
import {useSelector} from "react-redux";
import tagKor from '@/data/tagKor'

const GTriangle = `${process.env.REACT_APP_RESOURCE_URL}/G_triangle.svg`

const cx = classnames.bind(styles);
const ProgramExerciseItemDetail = ({exercise}) => {
  const lvArr = useSelector(state => {
    return state.rehab.value.lvArr
  })

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div className={cx('programExerciseItemDetail')}>
      <div className={cx('detailWrap')} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <div className={cx('itemLeft')}>
          <img src={exercise.media.IT.url + imgSize['90']} alt='thumbnail'/>
          <div className={cx('imgBack')}></div>
          <div className={cx('exItem')}>
            <p className={cx('itemTitle')}>{ exercise.name }</p>
            <p className={cx('level', lvArr[exercise.level - 1].color)}>
              { lvArr[exercise.level - 1].lv }
            </p>
          </div>
        </div>
        <div className={cx('itemRight')}>
          <p>
            { exercise.time ? exercise.time + '초 유지' : exercise.count + '회 반복' }
            <span>X</span>
            { exercise.repeat }세트
          </p>
          <img className={cx(isDropdownOpen && 'rotate')} src={GTriangle} alt='GTriangle'/>
        </div>
      </div>
      { isDropdownOpen && (
        <div className={cx('detailDropdown')}>
          <video
            controls
            controlsList="nodownload"
            src={exercise.media.MG.url}
            type="video/mp4"
          ></video>
          <div className={cx('exName')}>
            <p>{ exercise.name }</p>
          </div>
          <div className={cx('exDetail')}>
            <p>
              <span className={cx('title')}>난이도</span>
              <span className={cx(lvArr[exercise.level - 1].color)}>
                { lvArr[exercise.level - 1].lv }
              </span>
            </p>
            <p>
              <span className={cx('title')}>부위</span>
              <span>
                { exercise.tag ? (
                  exercise.tag.MP && (
                    exercise.tag.MP.map((tag, idx) => (
                      <span key={idx}>
                        { tagKor['MP'][tag] }
                        { idx !== exercise.tag.MP.length - 1 ? (
                          <span> / </span>
                        ) : ''}
                      </span>
                    ))
                  )
                ) : (
                  exercise.exerciseTags.MP && (
                    exercise.exerciseTags.MP.map((tag, idx) => (
                      <span key={idx}>
                        { tagKor['MP'][tag] }
                        { idx !== exercise.exerciseTags.MP.length - 1 ? (
                          <span> / </span>
                        ) : ''}
                      </span>
                    ))
                  )
                )}
              </span>
            </p>
            <p>
              <span className={cx('title')}>도구</span>
              <span>
                { exercise.tag ? (
                  exercise.tag.TO && (
                    exercise.tag.TO.map((tag, idx) => (
                      <span key={idx}>
                        { tagKor['TO'][tag] }
                        { idx !== exercise.tag.TO.length - 1 ? (
                          <span> / </span>
                        ) : ''}
                    </span>
                    ))
                  )
                ) : (
                  exercise.exerciseTags.TO && (
                    exercise.exerciseTags.TO.map((tag, idx) => (
                      <span key={idx}>
                        { tagKor['TO'][tag] }
                        { idx !== exercise.exerciseTags.TO.length - 1 ? (
                          <span> / </span>
                        ) : ''}
                    </span>
                    ))
                  )
                )}
              </span>
            </p>
            <p>
              <span className={cx('title')}>목적</span>
              <span>{ exercise.objective ? tagKor['OB'][exercise.objective] : '-' }</span>
            </p>
            <p>
              <span className={cx('title')}>설명</span>
              <span>{ exercise.description ? exercise.description : ''}</span>
            </p>
          </div>

        </div>
      ) }

    </div>
  );
};

export default ProgramExerciseItemDetail;