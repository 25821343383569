import React, {useEffect, useRef} from 'react';
import styles from './modalAddress.module.scss'
import classnames from 'classnames/bind';
import DaumPostcodeEmbed from "react-daum-postcode";

const cx = classnames.bind(styles);

const ModalAddress = ({ bodyTitle, isModalAddressOpen, setIsModalAddressOpen, setShowDetailAddressInput, setAddress }) => {
  const ref = useRef()

  const handleComplete = (data) => {
    let addressData = {
      address: data.address,
      roadAddress: data.roadAddress,
      zipCode: data.zonecode
    }
    setShowDetailAddressInput(true)
    setAddress(addressData)
  };


  useEffect(() => {
    if(isModalAddressOpen) {
      document.documentElement.style.overflow = 'hidden'
    }
    const checkIfClickedOutside = e => {
      if (isModalAddressOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalAddressOpen(false)
      }
    }

    const checkIfKeyEsc = e => {
      if (isModalAddressOpen && ref.current && !ref.current.contains(e.target) && e.keyCode === 27) {
        setIsModalAddressOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)
    document.addEventListener("keyup", checkIfKeyEsc)


    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
      document.removeEventListener("keyup", checkIfKeyEsc)
      document.documentElement.style.overflow = 'auto'
    }
  }, [isModalAddressOpen])

  return (
    <div className={cx('modalAddress')}>
      <div ref={ref}>
        <div className={cx('body')}>
          <p className={cx('title')} dangerouslySetInnerHTML={{__html: bodyTitle}}></p>
        </div>
        <DaumPostcodeEmbed
          onComplete={handleComplete}
          onClose={() => setIsModalAddressOpen(false)}
          className="postModalBody"
          autoClose
          style={{ height: '505px' }}
        />
      </div>
    </div>
  );
};

export default ModalAddress;