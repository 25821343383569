import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import HeaderNav from "@/common/header/headerNav/headerNav";
import classnames from "classnames/bind";
import styles from './subscription.module.scss'
import api from "@/api";
import Cards from "../cards/cards";
import {numberWithCommas} from "@/helpers/common";
import {useSelector} from "react-redux";
import FooterBreadCrumb from "../../../common/footerBreadCrumb/footerBreadCrumb";
import {showToast} from "../../../../helpers/toastMessage";
import ModalSmall from "../../../common/modal/modalSmall/modalSmall";

const PCheckbox = `${process.env.REACT_APP_RESOURCE_URL}/P_checkbox.svg`
const GCheckbox = `${process.env.REACT_APP_RESOURCE_URL}/G_checkbox.svg`
const LGArrowRight = `${process.env.REACT_APP_RESOURCE_URL}/LG_arrow_right.svg`
const WCheckSharp = `${process.env.REACT_APP_RESOURCE_URL}/W_check_sharp.svg`

const cx = classnames.bind(styles)
const minCardPayAmount = 1000

const Subscription = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const holdingDeposit = location.state.deposit
  const staff = useSelector(state => {
    return state.staff.value
  })
  const isActiveCardExist = useSelector(state => {
    return state.payment.value.isActiveCardExist
  })
  const activeCardId = useSelector(state => {
    return state.payment.value.activeCardId
  })

  const [isYearActive, setIsYearActive] = useState(true)
  // const [discountedPercent, setDiscountedPercent] = useState(0)
  const [discountedPercent, setDiscountedPercent] = useState({
    BASIC: 0,
    PRO: 0,
  })
  const [selectedService, setSelectedService] = useState({
    year: {
      id: 2
    },
    month: {
      id: 2
    },
  })
  const [priceListYear, setPriceListYear] = useState([])
  const [priceListMonth, setPriceListMonth] = useState([])
  const [subtractedDeposit, setSubtractedDeposit] = useState(0)
  const [isChecked, setIsChecked] = useState(false)
  const [footerBreadCrumbData, setFooterBreadCrumbData] = useState({
    totalPriceToPay: '',
    isPayBtnActive: false,
  })
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [modalSmallContent, setModalSmallContent] = useState({
    bodyTitle: '',
    bodySub: '',
    isOneBtn: false,
    isHalfBtn: false,
    btnLongTxt: '',
    btnLongEvent: null,
    btnShortTxt: '',
    btnShortEvent: null,
    values: {},
  })

  const togglePeriod = () => {
    setIsYearActive(!isYearActive)
    activePrice(2)
  }

  const activePrice = (priceItemId) => {
    if(isYearActive) {
      setSelectedService({
        ...selectedService,
        year: {
          id: priceItemId
        }
      })
    } else {
      setSelectedService({
        ...selectedService,
        month: {
          id: priceItemId
        }
      })
    }
  }

  const getPricePerMonth = (price, name) => {
    if(isYearActive) {
      let calculatedPrice = 0
      if(name === 'BUSINESS') {
        calculatedPrice = price
      } else {
        calculatedPrice = price * (100 - discountedPercent[name]) / 100
      }
      return numberWithCommas(calculatedPrice)
    } else {
      return numberWithCommas(price)
    }
  }

  const getPriceList = () => {
    api.getSubscriptionModel('YEARLY').then((res) => {
      let data = res.data
      setDiscountedPercent({
        BASIC: data.BASIC.discountPercentage,
        PRO: data.PRO.discountPercentage,
      })
      setPriceListYear([
        {
          id: 0,
          subscriptionModelId: 0,
          name: 'FREE TRIAL',
          color: 'yellow',
          price: 0,
          maxRehabGroupMemberCount: '5명 이하',
          prescription: '최초 20건 (30일 후 만료)',
          discountedAmount: 0
        },
        {
          id: 1,
          subscriptionModelId: data.BASIC.id,
          name: 'BASIC',
          color: 'green',
          price: data.BASIC.amount / 12,
          maxRehabGroupMemberCount: `${data.BASIC.maxRehabGroupMemberCount}명 이하`,
          prescription: `월 ${data.BASIC.planCount}건 이하`,
          discountedAmount: data.BASIC.discountedAmount
        },
        {
          id: 2,
          subscriptionModelId: data.PRO.id,
          name: 'PRO',
          color: 'blue',
          price: data.PRO.amount / 12,
          maxRehabGroupMemberCount: `${data.PRO.maxRehabGroupMemberCount}명 이하`,
          prescription: `월 ${data.PRO.planCount}건 이하`,
          discountedAmount: data.PRO.discountedAmount
        },
        {
          id: 3,
          name: 'BUSINESS',
          color: 'mint',
          price: 4000000,
          maxRehabGroupMemberCount: '별도 협의',
          prescription: '별도 협의',
          discountedAmount: 4000000
        },
      ])
    }).catch((err) => {
      console.log(err)
    })

    api.getSubscriptionModel('MONTHLY').then((res) => {
      let data = res.data
      setPriceListMonth([
        {
        id: 0,
        subscriptionModelId: 0,
        name: 'FREE TRIAL',
        color: 'yellow',
        price: 0,
        maxRehabGroupMemberCount: `5명 이하`,
        prescription: '최초 20건 (30일 후 만료)',
      },
        {
          id: 1,
          subscriptionModelId: data.BASIC.id,
          name: 'BASIC',
          color: 'green',
          price: data.BASIC.amount,
          maxRehabGroupMemberCount: `${data.BASIC.maxRehabGroupMemberCount}명 이하`,
          prescription: `월 ${data.BASIC.planCount}건 이하`,
        },
        {
          id: 2,
          subscriptionModelId: data.PRO.id,
          name: 'PRO',
          color: 'blue',
          price: data.PRO.amount,
          maxRehabGroupMemberCount: `${data.PRO.maxRehabGroupMemberCount}명 이하`,
          prescription: `월 ${data.PRO.planCount}건 이하`,
        },
        {
          id: 3,
          name: 'BUSINESS',
          color: 'mint',
          price: 0,
          maxRehabGroupMemberCount: '별도 협의',
          prescription: '별도 협의',
        }
      ])
    }).catch((err) => {
      console.log(err)
    })
  }

  const getTotalPriceToPay = () => {
    if(!priceListYear.length) {
      return
    }

    let result = 0

    if(isYearActive) {
      result = priceListYear[selectedService.year.id].discountedAmount - getDepositToPay()
    } else {
      result = priceListMonth[selectedService.month.id].price - getDepositToPay()
    }


    if(0 < result && result < minCardPayAmount) {
      setSubtractedDeposit(minCardPayAmount - result)
      result = minCardPayAmount
    }

    return result
  }

  const getDepositToPay = () => {
    if(!priceListYear.length) {
      return
    }

    let totalPrice = 0
    if(isYearActive) {
      totalPrice = priceListYear[selectedService.year.id].discountedAmount
    } else {
      totalPrice = priceListMonth[selectedService.month.id].price
    }

    if(holdingDeposit <= totalPrice) {
      totalPrice = holdingDeposit
    }

    if(subtractedDeposit) {
      totalPrice = totalPrice - subtractedDeposit
    }

    return totalPrice
  }

  const getSelectedServiceName = () => {
    if(!priceListYear.length || !priceListMonth.length) {
      return
    }
    if(isYearActive) {
      return `${ priceListYear[selectedService.year.id].name} 연구독`
    } else {
      return `${ priceListMonth[selectedService.month.id].name} 월구독`
    }
  }

  const getOrderedPrice = () => {
    if(!priceListYear.length) {
      return
    }
    if(isYearActive) {
      return priceListYear[selectedService.year.id].price * 12
    } else {
      return priceListMonth[selectedService.month.id].price
    }
  }

  const getDiscountedPrice = () => {
    if(!priceListYear.length) {
      return
    }
    let planName = selectedService.year.id === 1 ? 'BASIC' : 'PRO'
    if(isYearActive) {
      return priceListYear[selectedService.year.id].price * 12 * discountedPercent[planName] / 100
    } else {
      return priceListMonth[selectedService.month.id].price * 12 * discountedPercent[planName] / 100
    }
  }

  const checkIsPayBtnActive = () => {
    let checkBool = false
    let cardActiveBool = false

    if(isChecked) {
      checkBool = true
    }

    if(getTotalPriceToPay()) {
      cardActiveBool = isActiveCardExist
    } else {
      cardActiveBool = true
    }

    return checkBool && cardActiveBool
  }

  const confirmPayment = () => {
    let subId = null
    if(isYearActive) {
      subId = priceListYear[selectedService.year.id].subscriptionModelId
    } else {
      subId = priceListMonth[selectedService.month.id].subscriptionModelId
    }

    let depositAmt = getDepositToPay() ? getDepositToPay() : 0

    let body = {
      "rehabGroupCardId": activeCardId,
      "subscriptionModelId": subId,
      "depositAmount": depositAmt,
      "paymentAmount": getTotalPriceToPay(),
      "totalAmount": depositAmt + getTotalPriceToPay()
    }

    api.postHospitalGroupSubscription(staff.rehabGroupId, body).then(() => {
      navigate('/admin/payment/subscription/success', { replace: true })
    }).catch((err) => {
      showToast('error', err.response.data.message)
    })

    setIsModalSmallOpen(false)
  }

  const openModalSmall = () => {
    setModalSmallContent({
      bodyTitle: getSelectedServiceName() + ' 서비스가 결제됩니다.<br/>결제하시겠습니까?',
      bodySub: '',
      isOneBtn: false,
      btnFirstTxt: '확인',
      btnFirstEvent: () => confirmPayment(),
      btnSecondTxt: '취소',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      values: {},
    })
    setIsModalSmallOpen(true)
  }

  useEffect(() => {
    getPriceList()
  }, [])

  useEffect(() => {
    setFooterBreadCrumbData({
      totalPriceToPay: numberWithCommas(getTotalPriceToPay()),
      isPayBtnActive: checkIsPayBtnActive(),
      onAction: () => openModalSmall()
    })
  }, [getTotalPriceToPay(), isChecked, isActiveCardExist])

  useEffect(() => {
    getDepositToPay()
  }, [subtractedDeposit])

  return (
    <div className={cx('subscription')}>
      <HeaderNav/>
      <div className={cx('adminBody')}>
        <p className={cx('title')}>
          <span className={cx('back')} onClick={() => navigate('/payment')}>결제 관리</span>
          <img src={LGArrowRight} alt='LGArrowRight'/>
          <span className={cx('here')}>서비스 구독</span>
        </p>
        <div className={cx('contentWrapper')}>
          <div>
            <div className={cx('servicePeriod')}>
              <div className={cx(isYearActive && 'active')} onClick={togglePeriod}>
                <span>연구독</span>
                <div className={cx('redBox')}><span>추가 할인</span></div>
              </div>
              <div className={cx(!isYearActive && 'active')} onClick={togglePeriod}>
                <span>월구독</span>
              </div>
            </div>
            { isYearActive ? (
              <div className={cx('servicePriceList')}>
                { priceListYear.map((priceItem) => (
                  <div
                    key={priceItem.name}
                    className={cx(selectedService.year.id === priceItem.id && 'active')}
                    onClick={() => (priceItem.price && (selectedService.year.id !== priceItem.id) && priceItem.name !== 'BUSINESS') ? activePrice(priceItem.id) : ''}
                  >
                    <p className={cx(priceItem.color)}>{ priceItem.name.toLowerCase().replace(/^[a-z]/, char => char.toUpperCase()) }</p>
                      <div className={cx('priceGuide')}>
                        <p className={cx('standard')}>{(priceItem.name === 'BASIC' || priceItem.name === 'PRO') && numberWithCommas(priceItem.price) + '원' }</p>
                        <div className={cx('monthlyDiscounted')}>
                          <p className={cx('discountedPrice')}>
                            { getPricePerMonth(priceItem.price, priceItem.name) }원
                            <span>{ priceItem.name !== 'BUSINESS' && '/ 월' }</span>
                          </p>
                          { (priceItem.name === 'BASIC' || priceItem.name === 'PRO') && (
                            <div className={cx('discountRedBox')}>
                              <span className={cx('discountRate')}>{ discountedPercent[priceItem.name] }% 할인</span>
                            </div>
                          )}
                        </div>
                        <p className={cx('yearly')}>
                          { priceItem.price ? '연 결제 금액' : '' }
                          <span>{ priceItem.price ? numberWithCommas(priceListYear[priceItem.name === 'BASIC' ? 1 : priceItem.name === 'PRO' ? 2 : 3].discountedAmount) + '원' : '' }</span>
                        </p>
                      </div>
                    <div className={cx('limit', priceItem.name === 'FREE' && 'free')}>
                      <p>배정 건수 <span>{priceItem.prescription}</span></p>
                      <p>참여자 수 <span>{priceItem.maxRehabGroupMemberCount}</span></p>
                    </div>
                    { priceItem.price ? priceItem.name !== 'BUSINESS' && (
                      <button className={cx('selectBtn')}>
                        { selectedService.year.id === priceItem.id && (
                          <img src={WCheckSharp} alt='WCheckSharp'/>
                        ) }
                        선택
                      </button>
                    ) : ''}
                    { priceItem.name === 'BUSINESS' && (
                      <div className={cx('businessInquiry')}>
                        <h6>자세한 내용은 고객센터로 문의 부탁드립니다.</h6>
                        <p>support@everex.co.kr</p>
                      </div>
                    )}
                  </div>
                )) }
              </div>
            ) :(
              <div className={cx('servicePriceList', 'month')}>
                { priceListMonth.map((priceItem) => (
                  <div
                    className={cx(selectedService.month.id === priceItem.id && 'active')}
                    key={priceItem.name}
                    onClick={() => priceItem.price && (selectedService.month.id !== priceItem.id) ? activePrice(priceItem.id) : ''}
                  >
                    <p className={cx(priceItem.color)}>{ priceItem.name.toLowerCase().replace(/^[a-z]/, char => char.toUpperCase()) }</p>
                    { priceItem.name !== 'BUSINESS' ? (
                      <div className={cx('priceGuide')}>
                        <p className={cx('monthlyDiscounted')}>{ getPricePerMonth(priceItem.price) }원<span>/ 월</span></p>
                      </div>
                    ) : (
                      <div className={cx('priceGuide', 'business')}>
                        <p className={cx('inquiry')}>Business 요금제는<br/>연구독 사용만 가능합니다.</p>
                      </div>
                    )}
                    <div className={cx('limit')}>
                      <p>배정 건수 <span>{ priceItem.prescription }</span></p>
                      <p>참여자 수 <span>{priceItem.maxRehabGroupMemberCount}</span></p>
                    </div>
                    { priceItem.price ? (
                      <button className={cx('selectBtn')}>
                        { selectedService.month.id === priceItem.id && (
                          <img src={WCheckSharp} alt='WCheckSharp'/>
                        ) }
                        선택
                      </button>
                    ) : ''}
                    { priceItem.name === 'BUSINESS' && (
                      <div className={cx('businessInquiry')}>
                        <h6>자세한 내용은 고객센터로 문의 부탁드립니다.</h6>
                        <p>support@everex.co.kr</p>
                      </div>
                    )}
                  </div>
                )) }
              </div>
            ) }
            <p className={cx('description')}>* 모든 요금제는 부가세가 포함된 금액입니다.</p>
            <div className={cx('servicePriceSelected')}>
              <p className={cx('serviceName')}>{ getSelectedServiceName() }</p>
              <div className={cx('priceToPay')}>
                { priceListYear.length ? (
                  <span>{getSelectedServiceName()}</span>
                ) : ''}
                <div>
                  <p className={cx('ordered')}>주문 금액<span>{numberWithCommas(getOrderedPrice())}원</span></p>
                  { isYearActive ? (
                    <p className={cx('discount')}>
                      할인<span className={cx('percent')}>{ selectedService.year.id === 1 ? discountedPercent.BASIC : selectedService.year.id === 2 ? discountedPercent.PRO : 0 }%</span>
                      <span className={cx('discounted')}>-{numberWithCommas(getDiscountedPrice())}원</span>
                    </p>
                  ) : (
                    <p className={cx('ordered')}>할인<span>0원</span></p>
                  )}
                </div>
              </div>
              <div className={cx('priceDeposited')}>
                <div>
                  <p className={cx('depositToPay')}>
                    사용 예치금
                    <span>{getDepositToPay() ? '-' + numberWithCommas(getDepositToPay()) : 0}원</span>
                  </p>
                  <p className={cx('deposit')}>
                    <span>보유 예치금 : {numberWithCommas(holdingDeposit)}원</span>
                    <a href="https://forms.gle/CaXHoWymAXTCkxLWA" target="_blank" rel="noreferrer">예치금 충전</a>
                  </p>
                </div>
              </div>
              <p className={cx('totalPrice')}>총 결제 금액<span>{numberWithCommas(getTotalPriceToPay())}원</span></p>
            </div>
            <div className={cx('servicePayment')}>
              <p>카드결제</p>
              <div className={cx('cardInfo')}>
                <Cards height='194' marginRight='32' getTotalPriceToPay={getTotalPriceToPay()} />
              </div>
              <div className={cx('cardPayTerms')}>
                <p className={cx('agree1')} onClick={() => setIsChecked(!isChecked)}>
                  <img src={ isChecked ? PCheckbox : GCheckbox } alt="checkbox"/>
                  <span className={cx('check1')}>(필수) 정기결제에 동의하며, 언제든 해지 신청이 가능합니다.
                    <span> 해당 월에 이용 내역이 없으실 경우, 결제일로부터 7일 이내에 결제 취소 및 환불 신청이 가능합니다.</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterBreadCrumb footerBreadCrumbData={footerBreadCrumbData}/>
      { isModalSmallOpen && (
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          bodySub={modalSmallContent.bodySub}
          isOneBtn={modalSmallContent.isOneBtn}
          btnSecondTxt={modalSmallContent.btnSecondTxt}
          btnFirstTxt={modalSmallContent.btnFirstTxt}
          btnSecondEvent={modalSmallContent.btnSecondEvent}
          btnFirstEvent={modalSmallContent.btnFirstEvent}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}
          values={modalSmallContent.values}
        />
      ) }
    </div>
  );
};

export default Subscription;