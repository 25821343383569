import React, {useEffect, useState} from 'react';
import styles from './HomeContentGroup.module.scss'
import classnames from "classnames/bind";
import {showToast} from "../../../../helpers/toastMessage";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../utils/api";
import {setAccess} from "../../../../store/slice/patientSlice";
import {useNavigate} from "react-router-dom";
import PatientAccess from "../../../patients/patients/patientAccess/patientAccess";
import ModalBig from "../../../common/modal/modalBig/modalBig";
import JobIcon from "../../../common/jobIcon/jobIcon";
import tagKor from '@/data/tagKor'
import {formatBirthNoDot} from "../../../../helpers/common";
import ButtonPrimary from "../../../common/button/buttonPrimary/buttonPrimary";
import {setIsModalBigBtnActive} from "../../../../store/slice/modalBtnSlice";
const RWarning = `${process.env.REACT_APP_RESOURCE_URL}/R_warning.svg`
const cx = classnames.bind(styles)
const HomeContentGroup = ({ joinCode, rehabGroupName }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const staff = useSelector(state => {
    return state.staff.value
  })
  const patientAccessInfo = useSelector(state => {
    return state.patient.value.patientAccessInfo
  })
  const planStatusData = useSelector(state => {
    return state.plan.value.planStatusData
  })
  const subscriptionInfo = useSelector(state => {
    return state.subscription.value.subscriptionInfo
  })
  const memberCount = useSelector(state => {
    return state.payment.value.memberCount
  })
  const [usingServiceDate, setUsingServiceDate] = useState('')
  const [monthlyEndDateTime, setMonthlyEndDateTime] = useState('')
  const [remainPlanCount, setRemainPlanCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [usingServiceId, setUsingServiceId] = useState(null)
  const [currentServiceName, setCurrentServiceName] = useState('')
  const [recentAssignedPlans, setRecentAssignedPlans] = useState([])
  const [isModalBigOpen, setIsModalBigOpen] = useState(false)
  const [modalBigContent, setModalBigContent] = useState({
    header: '',
    btnSmall: '',
    btnBig: '',
    closeModalBig: null,
    onAction: null,
    values: {},
  })
  const [inputValues, setInputValues] = useState({
    name: '',
    phoneNumber: '',
  })
  const [modalBigPatientDetailsProps, setModalBigPatientDetailsProps] = useState({
    patientName: '',
    patientId: null,
  })
  const getCurrentService = ()  => {
    if(!staff.rehabGroupId) {
      return
    }
    setIsLoading(true)

    setRemainPlanCount(subscriptionInfo.remainPlanCount)
    let newServiceId = subscriptionInfo.modelType === 'BASIC' ? 1 : subscriptionInfo.modelType === 'PRO' ? 2 : subscriptionInfo.modelType === 'BUSINESS' ? 3 : null
    setUsingServiceId(newServiceId)

    if(subscriptionInfo.startDateTime) {
      setUsingServiceDate(subscriptionInfo.startDateTime.split('T')[0].replaceAll('-', '.') + ' ~ ' + subscriptionInfo.endDateTime.split('T')[0].replaceAll('-', '.'))
      setMonthlyEndDateTime(subscriptionInfo.monthlyEndDateTime.split('T')[0].replaceAll('-', '.'))
    }

    if(subscriptionInfo.modelType === null) {
      setMonthlyEndDateTime(subscriptionInfo.freeExpiredDateTime.split('T')[0].replaceAll('-', '.'))
    }


    let modelTypeStr1 = ''
    let modelTypeStr2 = ''
    if(subscriptionInfo.modelType) {
      modelTypeStr1 = subscriptionInfo.modelType.slice(0, 1)
      modelTypeStr2 = subscriptionInfo.modelType.slice(1, subscriptionInfo.modelType.length).toLowerCase()
    }

    if(newServiceId) {
      setCurrentServiceName(`${ modelTypeStr1 }${ modelTypeStr2 } ${ subscriptionInfo.dateType === 'YEARLY' ? '연' : '월' }구독`)
    } else {
      setCurrentServiceName('Free Trial')
    }
    setIsLoading(false)
  }
  const copyJoinCode = () => {
    window.navigator.clipboard.writeText(joinCode).then(() => {
      showToast('success', '그룹 코드가 복사되었습니다.')
    })
  }

  const showInviteModel = () => {
    setInputValues({
      name: '',
      phoneNumber: '',
    })
    setModalBigContent({
      header: '사용자 초대',
      btnSmall: '닫기',
      btnBig: '초대하기',
      closeModalBig: () => setIsModalBigOpen(false),
      onAction: (values) => inviteStaff(values),
    })

    setIsModalBigOpen(true)
  }

  const inviteStaff = (values) => {
    if (!values.isInviteBtnActive) {
      return
    }
    let body = {
      phoneNumber: values.phoneNumber,
      name: values.name
    }
    api.postInvitation(staff.rehabGroupId, body).then(() => {
      showToast('success', `${values.name}님이 초대되었습니다.`)
      setIsModalBigOpen(false)
      setInputValues({
        name: '',
        phoneNumber: '',
      })
      navigate('/admin/staff')
    }).catch((err) => {
      if (err.response.data.code === '1002') {
        showToast('error', '이름과 휴대폰 번호를 확인해주세요.')
      } else if (err.response.data.code === '3010') {
        navigate('/admin/staff')
        showToast('error', '다른 그룹에 이미 등록된 사용자입니다.')
        setIsModalBigOpen(false)
        setInputValues({
          name: '',
          phoneNumber: '',
        })
      } else if (err.response.data.code === '3014') {
        navigate('/admin/staff')
        showToast('error', '본 그룹에 이미 등록된 사용자입니다.')
        setIsModalBigOpen(false)
        setInputValues({
          name: '',
          phoneNumber: '',
        })
      }else if (err.response.data.code === '1105') {
        navigate('/admin/staff')
        showToast('error', '이미 초대된 사용자입니다.')
        setIsModalBigOpen(false)
        setInputValues({
          name: '',
          phoneNumber: '',
        })
      }else if (err.response.data.code === '3111') {
        // '그룹 참여자 수 제한 초과'일 경우 사용자 관리 페이지로 넘어가서 모달 띄움
        navigate('/admin/staff', { state: { modal: 'memberLimit' } })
      }
    })
  }

  const onNameChange = (e) => {
    setInputValues({
      name: e.target.value.trim(),
      phoneNumber: inputValues.phoneNumber,
    })
  }
  const onPhoneChange = (e) => {
    setInputValues({
      name: inputValues.name,
      phoneNumber: e.target.value.replace(/[^0-9]/g, ''),
    })
  }
  const isInviteBtnActive = () => {
    let result = false

    if (modalBigContent.btnBig === '초대하기') {
      if (inputValues.name && inputValues.phoneNumber.length === 13) {
        result = true
      }
    }

    return result
  }

  const getRecentAssignedPlans = () => {
    // 그룹 내에서 최근에 배정된 플랜 3개 표시
    // RELEASE, IN_PROGRESS 상태인 플랜만
    // 고객가 플랜 시작안했으면 의료진 정보 없음

    api.getRecentPlans(staff.rehabGroupId, false, 1, 3).then((res) => {
      let plans = res.data.list
      let tempPlans = plans.filter((plan) => {
        if(plan.medicalStaffName && plan.medicalStaffPhoneNumber) {
          plan.staffName = `${plan.medicalStaffName}(${plan.medicalStaffPhoneNumber.slice(7, 11)})`
        } else {
          plan.staffName = '-'
        }
        return plan.patientName
      })
      setRecentAssignedPlans(tempPlans)
    })
  }

  const accessPatient = async () => {
    api.postRecordAccess(patientAccessInfo).then(() => {
        setIsModalBigOpen(false)
      if(modalBigContent.header === '고객 상세 정보 열람') {
        navigate(`/patient/${modalBigPatientDetailsProps.patientId}`)
      } else {
        navigate(`/plan/assignment/${modalBigPatientDetailsProps.patientId}`)
      }

      dispatch(setAccess(true))
    }).catch(err => {
      console.log(err)
    })
  }

  const goToService = () => {
    if(!staff.rehabGroupId) {
      return
    }

    api.getRehabGroup(staff.rehabGroupId).then((res) => {
      navigate('/admin/payment/subscription', { state: { deposit: res.data.deposit } })
    }).catch((err) => {
      if(err.response.data.code === '1001') {
        navigate('/myPage')
      }
    })
  }
  const onPatientClick = (patient) => {
    if(patient.medicalStaffId === staff.id) {
      navigate(`/patient/${patient.patientId}`)
      dispatch(setAccess(true))
    } else {
      api.getRecordAccess(patient.patientId).then((res) => {
        if(res.data.isAvailable) {
          navigate(`/patient/${patient.patientId}`)
          dispatch(setAccess(true))
        } else {
          setModalBigContent({
            header: '고객 상세 정보 열람',
            btnSmall: '닫기',
            btnBig: '열람',
            closeModalBig: () => setIsModalBigOpen(false),
          })
          setIsModalBigOpen(true)
          setModalBigPatientDetailsProps({
            patientName: patient.patientName,
            patientId: patient.patientId,
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }

  }

  useEffect(() => {
    setModalBigContent({
      ...modalBigContent,
      values: ({
        ...inputValues,
        isInviteBtnActive: isInviteBtnActive()
      })
    })

    if (inputValues.phoneNumber.length === 11) {
      setInputValues({
        name: inputValues.name,
        phoneNumber: inputValues.phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/g, '$1-$2-$3'),
      })
    }
  }, [inputValues])

  useEffect(() => {
    dispatch(setIsModalBigBtnActive(isInviteBtnActive()))
  }, [modalBigContent.btnBig, inputValues.name, inputValues.phoneNumber.length])

  useEffect(() => {
    getRecentAssignedPlans()
    getCurrentService()
  }, [staff])

  return (
    <div className={cx('homeContentGroup')}>
      { isLoading || (
        <>
          <div className={cx('group')}>
            <div className={cx('title')}>
              <p>그룹 정보</p>
              {staff.groupRole === 'ADMIN' && (
                <div className={cx('wheel')} onClick={() => navigate('/home/group')}>
                  <div></div>
                </div>
              )}

            </div>
            <div className={cx('groupName')}>
              <h6>그룹명</h6>
              <p>{ staff.rehabGroupName }</p>
            </div>
            <div className={cx('code')}>
              <h6>그룹 코드</h6>
              <div onClick={() => copyJoinCode()}>
                <span>{joinCode}</span>
                <div className={cx('copy')}></div>
              </div>

            </div>
            <ButtonPrimary
              text='그룹으로 초대하기'
              onBtnPrimaryClick={() => showInviteModel()}
              icon=''
              height={41}
              font='small'/>
          </div>
          <div className={cx('service')}>
            <p className={cx('subs')}>구독중인 서비스</p>

            <div className={cx('subContent')}>
              <p className={cx('subsName')}>{ currentServiceName }</p>
              <p className={cx('subsDesc')}>
                남은 배정 건수 :
                <span className={cx('count', remainPlanCount <= 10 && 'red')}>
                { usingServiceId === 3 && subscriptionInfo.monthlyPlanLimitCount >= 10000 ? '무제한' : remainPlanCount + '건' }
                  { remainPlanCount <= 10 ? (
                    <img src={RWarning} alt='chk' className={cx('imgWarning')}/>
                  ) : ''}
                </span>
                { remainPlanCount ? (
                  <span className={cx('date')}>(~{ monthlyEndDateTime })</span>
                ) : (
                  ''
                )}
              </p>

              <p className={cx('subsDesc')}>
                그룹 참여자 수 :
                <span className={cx('count')}>
                { memberCount }명
                </span>
              </p>

              { usingServiceDate && (
                <p className={cx('subsPeriod')}>
                  구독 기간 : <span>{ usingServiceDate }</span>
                </p>
              ) }
            </div>


            <div>
              { !usingServiceId && staff.groupRole === 'ADMIN' ? (
                <ButtonPrimary
                    text='서비스 구독하기'
                    onBtnPrimaryClick={() => goToService()}
                    icon=''
                    height={41}
                    font='small'/>
              ) : (
                ''
              )}
            </div>


          </div>
          <div className={cx('plans')}>
            <p>최근 배정된 플랜</p>
            <div className={cx('planList')}>
              { recentAssignedPlans.length ? (
                recentAssignedPlans.map((plan, idx) => (
                  <div key={idx} className={cx('planItem')} onClick={() => onPatientClick(plan)}>
                    <div className={cx('creator')}>
                      <JobIcon job={plan.medicalStaffJob} isCertified={plan.isCertified}/>
                      <span>{ plan.staffName }</span>
                    </div>
                    <span className={cx('bar')}>|</span>
                    <span className={cx('mainPart')}>{ tagKor['MP'][plan.mainPart] }</span>
                    <span className={cx('bar')}>|</span>
                    <span className={cx('patient')}>
                  { (plan.patientGender && plan.patientBirthday) ? (
                    <>
                      { plan.patientName }
                      <span>/</span>
                      { plan.patientGender }
                      <span>/</span>
                      { formatBirthNoDot(plan.patientBirthday) }
                    </>
                  ) : (plan.patientName && !plan.patientGender && !plan.patientBirthday) ? (
                    plan.patientName
                  ): '-'}
                </span>
                    <span className={cx('bar')}>|</span>
                    <span className={cx('status')}>
                  <span className={cx(planStatusData[plan.planStatus].txtColor)}>{ planStatusData[plan.planStatus].status }</span>
                </span>
                  </div>
                ))
              ) : (
                <div className={cx('noPlans')}><p>배정된 플랜이 없습니다</p></div>
              )}
            </div>
          </div>
        </>
      ) }
      <ModalBig
        header={ modalBigContent.header }
        btnSmall={ modalBigContent.btnSmall }
        btnBig={ modalBigContent.btnBig }
        closeModalBig={modalBigContent.closeModalBig}
        isModalBigOpen={isModalBigOpen}
        onAction={ modalBigContent.header === '고객 상세 정보 열람' ? accessPatient : modalBigContent.onAction}
        values={modalBigContent.values}
      >
        { modalBigContent.header === '고객 상세 정보 열람' ? (
          <PatientAccess
            patientName={modalBigPatientDetailsProps.patientName}
            patientId={modalBigPatientDetailsProps.patientId}
            staffName={staff.name}
          />
        ) : (
          <div className={cx('addCardInputBox')}>
            <div className={cx('infoBox')}>
              <p>그룹 이름</p>
              <input
                type="text"
                value={rehabGroupName}
                disabled
              />
            </div>
            <div className={cx('infoBox')}>
              <p>이름</p>
              <input
                type="text"
                placeholder="사용자 이름을 입력하세요."
                value={inputValues.name}
                onChange={onNameChange}
                name='name'
              />
            </div>
            <div className={cx('infoBox')}>
              <p>휴대폰 번호(숫자만 입력)</p>
              <input
                type="text"
                value={inputValues.phoneNumber}
                onChange={onPhoneChange}
                maxLength={13}
                name='phoneNumber'
              />
            </div>
          </div>
        )}
      </ModalBig>
    </div>
  );
};

export default HomeContentGroup;