import React, {useEffect, useState} from 'react';
import styles from './planAssignment.module.scss'
import HeaderNav from "@/common/header/headerNav/headerNav";
import classnames from "classnames/bind";
import api from "@/api";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import PlanExerciseSelect from "./planExerciseSelect/planExerciseSelect";
import {setPlanAssignmentParams} from "../../store/slice/planSlice";
import { formatBirthNoDot, formatDate} from "../../helpers/common";
import {showToast} from "../../helpers/toastMessage";
import DateSelect from "./dateSelect/dateSelect";
import PlanDates from "./planDates/planDates";
import DateSelectEdit from "./dateSelectEdit/dateSelectEdit";
import Assignment from "./assignment/assignment";
import FooterGray from "../common/footer/footerGray/footerGray";

const PlanStep1 = `${process.env.REACT_APP_RESOURCE_URL}/plan_step1.svg`
const PlanStep2 = `${process.env.REACT_APP_RESOURCE_URL}/plan_step2.svg`
const PlanStep3 = `${process.env.REACT_APP_RESOURCE_URL}/plan_step3.svg`
const PlanStep4 = `${process.env.REACT_APP_RESOURCE_URL}/plan_step4.svg`

const cx = classnames.bind(styles)
const PlanAssignment = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const planAssignmentParams = useSelector(state => {
    return state.plan.value.planAssignmentParams
  })
  const planAssignmentStep = useSelector(state => {
    return state.plan.value.planAssignmentStep
  })
  const dateToEdit = useSelector(state => {
    return state.plan.value.dateToEdit
  })
  const dateExercisesToCopy = useSelector(state => {
    return state.plan.value.dateExercisesToCopy
  })
  const isAddNewDate = useSelector(state => {
    return state.plan.value.isAddNewDate
  })

  const [patientInfo, setPatientInfo] = useState({})


  useEffect(() => {
    let medicalRecordId = location.pathname.split('/')[3]
    if(!medicalRecordId) {
      navigate('/patients')
    }

    let newPlanAssignmentParams = {...planAssignmentParams}
    newPlanAssignmentParams.medicalRecordId = medicalRecordId
    dispatch(setPlanAssignmentParams(newPlanAssignmentParams))

    api.getPatientInfo(medicalRecordId).then((res) => {
      setPatientInfo(res.data)
    }).catch((e) => {
      if(e.response.data.code === '1001') {
        showToast('error', '고객 정보가 없습니다.')
        navigate('/patients')
      }
    })
  }, [])

  return (
    <div className={cx('planAssignment')}>
      <HeaderNav/>
      <div className={cx('subHeader')}>
        { dateToEdit ? (
          <div className={cx('editHeaderWrapper')}>
            <p>{ formatDate(dateToEdit) } 운동 편집</p>
          </div>
        ) : dateExercisesToCopy.length ? (
          <div className={cx('editHeaderWrapper')}>
            <p>{ formatDate(dateExercisesToCopy[0]) } 일정 복사</p>
          </div>
        ) : isAddNewDate ? (
          <div className={cx('editHeaderWrapper')}>
            <p>새 일정 추가</p>
          </div>
        ) : (
          <div className={cx('subHeaderWrapper')}>
            <span className={cx('title')}>플랜 배정</span>
            <span className={cx('patient')}>
            { patientInfo.userName }
              <span>
                { patientInfo.userIsRegistered && (
                  <>
                    <span className={cx('bar')}> / </span>
                    <span className={cx('gender')}>{ patientInfo.userGender }</span>
                    <span className={cx('bar')}> / </span>
                    <span className={cx('birth')}>{ patientInfo.userBirthday && formatBirthNoDot(patientInfo.userBirthday) }</span>
                  </>
                )}
              </span>
            </span>
            <div className={cx('steps')}>
              <img
                src={
                  planAssignmentStep === 0 ? PlanStep1 :
                    planAssignmentStep === 1 ? PlanStep2 :
                      planAssignmentStep === 2 ? PlanStep3 :
                        planAssignmentStep === 3 ? PlanStep4 :
                        ''}
                alt='PlanStep'
              />
            </div>
          </div>
        )}
      </div>
      { planAssignmentStep === 0 ? <PlanExerciseSelect/>
        : planAssignmentStep === 1 ? <DateSelect/>
          : planAssignmentStep === 2 ? <PlanDates/>
            : planAssignmentStep === 3 ? <Assignment/>
              : planAssignmentStep === 4 ? <DateSelectEdit/>
                : ''
      }

      <FooterGray position='relative'/>
    </div>
  );
};

export default PlanAssignment;