import React, {useEffect, useState} from 'react';
import styles from './patients.module.scss'
import HeaderNav from "../../common/header/headerNav/headerNav";
import classnames from "classnames/bind";
import InputSearchBox from "../../common/input/inputSearchBox/inputSearchBox";
import DropdownOrder from "../../common/dropdown/dropdownOrder/dropdownOrder";
import api from "@/api";
import { formatBirthNoDot} from "../../../helpers/common";
import tagKor from '@/data/tagKor'
import { useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ModalBig from "../../common/modal/modalBig/modalBig";
import PatientRegistration from "./patientRegistration/patientRegistration";
import ButtonPrimary from "../../common/button/buttonPrimary/buttonPrimary";
import ModalSmall from "../../common/modal/modalSmall/modalSmall";
import PatientAccess from "./patientAccess/patientAccess";
import {setAccess, setIsFilteredMyPatients} from "../../../store/slice/patientSlice";
import {
  resetAddedPlanExerciseArr, resetDateExercisesToCopy, resetDateToEdit, resetLastEditedPlanSchedule,
  resetPlanAssignmentParams,
  resetPlanSchedule, setIsAddNewDate,
  setPlanAssignmentStep, setPlanProgramSelected, setSelectedPlanStartDateTimestamp
} from "../../../store/slice/planSlice";
import {setActiveFilterRehabId} from "../../../store/slice/rehabSlice";
import FooterGray from "../../common/footer/footerGray/footerGray";
import JobIcon from "../../common/jobIcon/jobIcon";
import LeftTabs from "../../common/tabs/leftTabs/leftTabs";

const NoPlans = `${process.env.REACT_APP_RESOURCE_URL}/no_plans.svg`

const cx = classnames.bind(styles)
const wholeBarWidth = 206
const orderList = [
  {
    id: 0,
    txt: '등록순 (최근)',
    param: 'CREATED_DATE_DESC'
  },
  {
    id: 1,
    txt: '등록순 (오래된)',
    param: 'CREATED_DATE_ASC'
  },
  {
    id: 2,
    txt: '이름순 (ㄱ-ㅎ)',
    param: 'USER_NAME_ASC'
  },
  {
    id: 3,
    txt: '이름순 (ㅎ-ㄱ)',
    param: 'USER_NAME_DESC'
  },
  {
    id: 4,
    txt: '나이순 (많은)',
    param: 'BIRTH_DAY_ASC'
  },
  {
    id: 5,
    txt: '나이순 (적은)',
    param: 'BIRTH_DAY_DESC'
  },
]

const Patients = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const patientToAdd = useSelector(state => {
    return state.patient.value.patientToAdd
  })
  const patientAccessInfo = useSelector(state => {
    return state.patient.value.patientAccessInfo
  })
  const isFilteredMyPatients = useSelector(state => {
    return state.patient.value.isFilteredMyPatients
  })
  const staff = useSelector(state => {
    return state.staff.value
  })
  const planStatusData = useSelector(state => {
    return state.plan.value.planStatusData
  })

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedOrderId, setSelectedOrderId] = useState(0)
  const [activeTabId, setActiveTabId] = useState(0)
  const [patientList, setPatientList] = useState([])
  const [filteredPatientObj, setFilteredPatientObj] = useState({
    ALL: [],
    IN_PROGRESS: [],
    CLOSED: [],
    NO_PLAN: [],
    RELEASE: [],
  })
  const [isModalBigPatientAddOpen, setIsModalBigPatientAddOpen] = useState(false)
  const [isModalBigPatientAccessOpen, setIsModalBigPatientAccessOpen] = useState(false)
  const [modalBigAccessContent, setModalBigAccessContent] = useState({
    header: '',
    btnSmall: '',
    btnBig: '',
  })
  const [modalBigPatientDetailsProps, setModalBigPatientDetailsProps] = useState({
    patientName: '',
    patientId: null,
  })
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [modalSmallContent, setModalSmallContent] = useState({
    bodyTitle: '',
    bodySub: '',
    isOneBtn: false,
    btnFirstTxt: '',
    btnSecondTxt: '',
    btnFirstEvent: {},
    btnSecondEvent: {},
    isModalSmallOpen: {},
  })
  const [tabItems, setTabItems] = useState([
    {
      id: 0,
      tab: '전체 (0)',
      val: 'ALL',
    },
    {
      id: 1,
      tab: '플랜 배정 전 (0)',
      val: 'NO_PLAN',
    },
    {
      id: 2,
      tab: '플랜 시작 대기 (0)',
      val: 'RELEASE',
    },
    {
      id: 3,
      tab: '플랜 수행중 (0)',
      val: 'IN_PROGRESS',
    },
    {
      id: 4,
      tab: '플랜 종료 (0)',
      val: 'CLOSED',
    },
  ])
  const [keyword, setKeyword] = useState('')


  const onTabItemClick = (id) => {
    setKeyword('')
    getPatientList()
    filterList()
    setActiveTabId(id)
  }

  const getKeyword = (keyword) => {
    setKeyword(keyword)
    if(!keyword) {
      getPatientList()
    }
  }

  const onSearch = () => {
    if(keyword) {
      setFilteredPatientObj({
        ALL: [],
        IN_PROGRESS: [],
        CLOSED: [],
        NO_PLAN: [],
        RELEASE: [],
      })
      filterList()
    }
  }

  const getPatientList = () => {
    setIsLoading(true)
    api.getRecord(isFilteredMyPatients, orderList[selectedOrderId].param).then((res) => {
      setFilteredPatientObj({
        ALL: [],
        IN_PROGRESS: [],
        CLOSED: [],
        NO_PLAN: [],
        RELEASE: [],
      })
      setPatientList([...res.data])
      setIsLoading(false)
    }).catch((e) => {
      console.log(e)
    })
  }

  const calculateProgress = (percent) => {
    let calculatedNumber = wholeBarWidth * (percent / 100)
    return calculatedNumber.toString()
  }

  const onPatientClick = (patient) => {
    if(patient.lastPlanCreatorId === staff.id) {
      navigate(`/patient/${patient.id}`)
      dispatch(setAccess(true))
    } else {
      api.getRecordAccess(patient.id).then((res) => {
        if(res.data.isAvailable) {
          navigate(`/patient/${patient.id}`)
          dispatch(setAccess(true))
        } else {
          setModalBigAccessContent({
            header: '고객 상세 정보 열람',
            btnSmall: '닫기',
            btnBig: '열람',
          })
          setIsModalBigPatientAccessOpen(true)
          setModalBigPatientDetailsProps({
            patientName: patient.userName,
            patientId: patient.id,
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }

  }

  const onButtonLightClick = () => {
    setIsModalBigPatientAddOpen(true)
  }

  const toggleSwitch = () => {
    navigate(`?my=${!isFilteredMyPatients}`, { replace: true })
    dispatch(setIsFilteredMyPatients(!isFilteredMyPatients))
  }

  const closeModalBigPatientAdd = () => {
    setIsModalBigPatientAddOpen(false)
  }

  const closeModalBigPatientDetails = () => {
    setIsModalBigPatientAccessOpen(false)
  }

  const closeRegistrationModal = () => {
    setIsModalSmallOpen(false)
    getPatientList()
  }

  const goToPlanAssignment = (patientId) => {
    navigate(`/plan/assignment/${patientId}`)
    dispatch(setPlanAssignmentStep(0))
  }

  const registerPatient = async () => {
    api.addPatient(patientToAdd).then((res) => {
      if(!res.data.duplication) {
        setModalSmallContent({
          bodyTitle: '재활 운동 플랜을 배정하시겠습니까?',
          bodySub: '등록이 완료되었습니다.',
          isOneBtn: false,
          btnSecondTxt: '나중에',
          btnFirstTxt: '플랜 배정하기',
          btnSecondEvent: () => closeRegistrationModal(),
          btnFirstEvent: () => goToPlanAssignment(res.data.id),
        })
      } else {
        setModalSmallContent({
          bodyTitle: '이미 등록된 고객입니다.',
          bodySub: '',
          isOneBtn: false,
          btnSecondTxt: '닫기',
          btnFirstTxt: '고객 바로가기',
          btnSecondEvent: () => setIsModalSmallOpen(false),
          btnFirstEvent: () => goToPatientDetail(res.data.id),
        })
      }
      closeModalBigPatientAdd()
      setIsModalSmallOpen(true)
    })
  }

  const assignPlan = (e, patient) => {
    e.stopPropagation()
    dispatch(setActiveFilterRehabId(0))

    api.getRecordAccess(patient.id).then((res) => {
      if(res.data.isAvailable) {
        api.getRemainPlanCount(staff.rehabGroupId).then((res) => {
          let remainPlanCount = res.data.remainPlanCount
          // let remainPlanCount = 0
          if(remainPlanCount) {
            navigate(`/plan/assignment/${patient.id}`)
          } else {
            setModalSmallContent({
              bodyTitle: '사용 가능한 배정 건수를 초과하였습니다.',
              bodySub: '유료서비스 구독 또는 상위 플랜으로 변경해 주세요.',
              isOneBtn: false,
              btnSecondTxt: '닫기',
              btnFirstTxt: '서비스 구독 바로가기',
              btnSecondEvent: () => setIsModalSmallOpen(false),
              btnFirstEvent: () => navigate('/payment'),
            })
            setIsModalSmallOpen(true)
          }
        })
        dispatch(setAccess(true))
      } else {
        setModalBigAccessContent({
          header: '고객 플랜 배정',
          btnSmall: '닫기',
          btnBig: '배정하기',
        })
        setIsModalBigPatientAccessOpen(true)
        setModalBigPatientDetailsProps({
          patientName: patient.userName,
          patientId: patient.id,
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const goToPatientDetail = (patientId) => {
    api.getRecordAccess(patientId).then((res) => {
      if(res.data.isAvailable) {
        navigate(`/patient/${patientId}`)
        dispatch(setAccess(true))
      } else {
        setIsModalSmallOpen(false)
        setModalBigAccessContent({
          header: '고객 상세 정보 열람',
          btnSmall: '닫기',
          btnBig: '열람',
        })
        setIsModalBigPatientAccessOpen(true)
        setModalBigPatientDetailsProps({
          patientName: patientToAdd.name,
          patientId: patientId,
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const getLastPlanPerformance = (patient) => {
    let result = '-'

    if(patient.lastPlanStatus) {
      if(patient.lastPlanStatus !== 'REJECT' && patient.lastPlanStatus !== 'CANCEL') {
        if(patient.lastPlanInitPainScale !== undefined) {
          result = patient.lastPlanPercent
        }
      }
    }

    return result
  }

  const accessPatient = async () => {
    api.postRecordAccess(patientAccessInfo).then(() => {
      closeModalBigPatientDetails()
      if(modalBigAccessContent.header === '고객 상세 정보 열람') {
        navigate(`/patient/${modalBigPatientDetailsProps.patientId}`)
      } else {
        navigate(`/plan/assignment/${modalBigPatientDetailsProps.patientId}`)
      }

      dispatch(setAccess(true))
    }).catch(err => {
      console.log(err)
    })
  }

  const getEmptyTxt = () => {
    let emptyTxt = ''
    if(activeTabId === 0) {
      emptyTxt = '고객을 등록하고 재활 운동 플랜를 배정해 보세요.'
    } else if(activeTabId === 1) {
      emptyTxt = '플랜이 배정되지 않은 고객이 없어요 :('
    } else if(activeTabId === 2) {
      emptyTxt = '플랜 시작 대기 중인 고객이 없어요 :('
    } else if(activeTabId === 3) {
      emptyTxt = '플랜 수행중인 고객이 없어요 :('
    } else if(activeTabId === 4) {
      emptyTxt = '플랜이 종료된 고객이 없어요 :('
    }

    if(keyword) {
      emptyTxt = '원하시는 결과를 찾지 못했어요 :(<br/>다시 검색해서 찾아보세요.'
    }

    return emptyTxt
  }

  const filterList = () => {
    if(!patientList.length) {
      return
    }

    let planAllList = patientList
    let planInProgressList = patientList.filter((patient) => patient.lastPlanStatus === 'IN_PROGRESS')
    let planClosedList = patientList.filter((patient) => patient.lastPlanStatus === 'CLOSED' || patient.lastPlanStatus === 'CANCEL' || patient.lastPlanStatus === 'REJECT')
    let planNoPlanList = patientList.filter((patient) => !patient.lastPlanStatus)
    let planReleaseList = patientList.filter((patient) => patient.lastPlanStatus === 'RELEASE')

    if(keyword) {
      let selectedList = []
      if(activeTabId === 0) {
        selectedList = planAllList
      } else if(activeTabId === 1) {
        selectedList = planNoPlanList
      } else if(activeTabId === 2) {
        selectedList = planReleaseList
      } else if(activeTabId === 3) {
        selectedList = planInProgressList
      } else if(activeTabId === 4) {
        selectedList = planClosedList
      }

      setFilteredPatientObj({
        ALL: planAllList,
        IN_PROGRESS: planInProgressList,
        CLOSED: planClosedList,
        NO_PLAN: planNoPlanList,
        RELEASE: planReleaseList,
        [tabItems[activeTabId].val]: selectedList.filter(item => item.userName.includes(keyword))
      })
    } else {
      setFilteredPatientObj({
        ALL: planAllList,
        IN_PROGRESS: planInProgressList,
        CLOSED: planClosedList,
        NO_PLAN: planNoPlanList,
        RELEASE: planReleaseList,
      })
    }

    let tempTabItems = tabItems
    tempTabItems[0].tab = '전체 (' + planAllList.length + ')'
    tempTabItems[1].tab = '플랜 배정 전 (' + planNoPlanList.length + ')'
    tempTabItems[2].tab = '플랜 시작 대기 (' + planReleaseList.length + ')'
    tempTabItems[3].tab = '플랜 수행중 (' + planInProgressList.length + ')'
    tempTabItems[4].tab = '플랜 종료 (' + planClosedList.length + ')'

    setTabItems(tempTabItems)
  }

  useEffect(() => {
    filterList()
  }, [patientList])


  useEffect(() => {
    getPatientList()
  }, [isFilteredMyPatients, selectedOrderId])

  useEffect(() => {
    if(staff.id === null) {
      return
    }

    if(staff.rehabGroupId === null) {
      navigate('/myPage')
    }
  }, [staff])

  useEffect(() => {
    dispatch(setPlanAssignmentStep(0))
    dispatch(resetPlanAssignmentParams())
    dispatch(resetAddedPlanExerciseArr())
    dispatch(resetPlanSchedule())
    dispatch(resetDateExercisesToCopy())
    dispatch(resetDateToEdit())
    dispatch(setIsAddNewDate(false))
    dispatch(resetLastEditedPlanSchedule())
    dispatch(setPlanProgramSelected({}))
    dispatch(setSelectedPlanStartDateTimestamp(0))

    navigate(`?my=${isFilteredMyPatients}`, { replace: true })
  }, [])

  return (
    <div className={cx('patients')}>
      <HeaderNav/>
      <div className={cx('wrapper')}>
        <div className={cx('tabContainer')}>
          <LeftTabs tabItems={ tabItems } activeTabId={activeTabId} onTabItemClick={onTabItemClick}/>
          <div className={cx('filter')}>
            <div className={cx('filterTitle')}>
              <p>필터</p>
            </div>
            <div className={cx('filterToggle')}>
              <div>
                <span>내 고객만 보기</span>
                <div className={cx('toggleBox')}>
                  <label className={cx('switch')}>
                    <input
                      onChange={toggleSwitch}
                      type="checkbox"
                      checked={isFilteredMyPatients}
                    />
                    <span className={cx('slider', 'round')}></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('listContainer')}>
          <div className={cx('listWrapper')}>
            <div className={cx('listTop')}>
              <div className={cx('searchContainer')}>
                <InputSearchBox
                  placeholder='고객 이름 검색'
                  getKeyword={getKeyword}
                  onSearch={onSearch}
                  isSmall={true}
                  keyword={keyword}
                />
              </div>
              <div className={cx('topRight')}>
                <div className={cx('dropdownWrapper')}>
                  <DropdownOrder
                    orderList={orderList}
                    selectedOrderId={selectedOrderId}
                    setSelectedOrderId={setSelectedOrderId}
                    isDropdownOrderOpen={isDropdownOpen}
                    setIsDropdownOrderOpen={() => setIsDropdownOpen(!isDropdownOpen)}
                  />
                </div>
              </div>
              <div className={cx('titleContainer')}>
                <div>
                  <div className={cx('buttons')}>
                    <ButtonPrimary text='고객 등록' onBtnPrimaryClick={onButtonLightClick} width={112} height={41} icon='WPlusSharp' font='small'/>
                  </div>
                </div>
              </div>
            </div>
            { filteredPatientObj[tabItems[activeTabId].val].length && !isLoading ? (
              <div className={cx('list')}>
                <div className={cx('tableTitle')}>
                  <p className={cx('name')}>이름</p>
                  <p className={cx('gender')}>성별</p>
                  <p className={cx('birth')}>생년월일</p>
                  {/*<p className={cx('part')}>통증부위</p>*/}
                  <p className={cx('lastPlan')}>마지막 플랜 정보</p>
                  {/*<p className={cx('status')}>플랜 상태</p>*/}
                  <p className={cx('assignmentBtn')}></p>
                </div>
                <div className={cx('tableContent')}>
                  { filteredPatientObj[tabItems[activeTabId].val].map((patient) => (
                    <div key={patient.id} onClick={() => onPatientClick(patient)}>
                      <p className={cx('contentName')}>{ patient.userName }</p>
                      <p className={cx('contentGender')}>{ patient.userGender ? patient.userGender : '-' }</p>
                      <p className={cx('contentBirth')}>{ patient.userBirthday ? formatBirthNoDot(patient.userBirthday) : '-' }</p>
                      {/*<p className={cx('contentPart')}>{ patient.mainPart ? tagKor['MP'][patient.mainPart] : '-' }</p>*/}
                      <div className={cx('contentLastPlan')}>
                        <div className={cx('planBox')}>
                          <p className={cx('mainPart')}>{ tagKor['MP'][patient.mainPart] }</p>
                          <span className={cx('lastPlanStatus')}>
                        <span className={cx(patient.lastPlanStatus ? planStatusData[patient.lastPlanStatus] ? planStatusData[patient.lastPlanStatus].txtColor : 'pink' : 'pink')}>
                          { patient.lastPlanStatus ? planStatusData[patient.lastPlanStatus].status : '배정 전' }
                        </span>
                      </span>
                          <div className={cx('rateBox')}>
                            <div className={cx('backgroundBar')}></div>
                            { (patient.lastPlanStatus && patient.lastPlanStatus !== 'REJECT' && patient.lastPlanStatus !== 'CANCEL') &&
                              <div className={cx(
                                'coloredBar',
                                (patient.lastPlanStatus === 'CLOSED' || patient.lastPlanStatus === 'CANCEL' || patient.lastPlanStatus === 'REJECT') && 'gray',
                                (patient.lastPlanPercent < 30 && patient.lastPlanStatus !== 'CLOSED' && patient.lastPlanStatus !== 'CANCEL' && patient.lastPlanStatus !== 'REJECT') && 'red',
                                (patient.lastPlanPercent >= 30 && patient.lastPlanStatus !== 'CLOSED' && patient.lastPlanStatus !== 'CANCEL' && patient.lastPlanStatus !== 'REJECT') && 'green'
                              )}
                                   style={{ width: calculateProgress(patient.lastPlanPercent) + 'px' }}
                              ></div>
                            }
                            <p className={cx('percent')}>{ getLastPlanPerformance(patient) } %</p>
                          </div>
                          { patient.lastPlanCreatorName ? (
                            <div className={cx('staffName')}>
                              { patient.lastPlanCreatorJob && (
                                <JobIcon job={patient.lastPlanCreatorJob} isCertified={patient.lastPlanCreatorIsCertified}/>
                              ) }
                              <span>{ patient.lastPlanCreatorName }</span>
                            </div>
                          ) : (
                            <div className={cx('staffName')}>-</div>
                          ) }

                        </div>
                      </div>
                      <p className={cx('contentAssignmentBtn')} onClick={(e) => assignPlan(e, patient)}>플랜 배정</p>
                    </div>
                  )) }
                </div>
              </div>
            ) : !filteredPatientObj[tabItems[activeTabId].val].length && !isLoading ? (
              <div className={cx('emptyContainer')}>
                <div>
                  <img src={NoPlans} alt='NoPlans'/>
                  <p className={cx('emptyTxt1')} dangerouslySetInnerHTML={{__html: getEmptyTxt()}}></p>
                </div>
              </div>
            ): isLoading ? (
              <div className={cx('list', 'loading')}>
                <div className={cx('tableTitle')}>
                  <p className={cx('name')}>이름</p>
                  <p className={cx('gender')}>성별</p>
                  <p className={cx('birth')}>생년월일</p>
                  <p className={cx('lastPlan')}>마지막 플랜 정보</p>
                  <p className={cx('assignmentBtn')}></p>
                </div>
                <div className={cx('tableContent')}>
                  { [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => (
                    <div key={idx} >
                      <p className={cx('contentName')}></p>
                      <p className={cx('contentGender')}></p>
                      <p className={cx('contentBirth')}></p>
                      <div className={cx('contentLastPlan')}>
                        <div className={cx('planBox')}></div>
                      </div>
                      <p className={cx('contentAssignmentBtn')}></p>
                    </div>
                  )) }
                </div>
              </div>
            ) : ''}
          </div>
        </div>
      </div>

      <FooterGray position='relative'/>
        <ModalBig
          header='고객 등록'
          btnSmall='닫기'
          btnBig='확인'
          closeModalBig={closeModalBigPatientAdd}
          isModalBigOpen={isModalBigPatientAddOpen}
          onAction={registerPatient}
        >
          <PatientRegistration/>
        </ModalBig>
        <ModalBig
          header={ modalBigAccessContent.header }
          btnSmall={ modalBigAccessContent.btnSmall }
          btnBig={ modalBigAccessContent.btnBig }
          closeModalBig={closeModalBigPatientDetails}
          isModalBigOpen={isModalBigPatientAccessOpen}
          onAction={accessPatient}
        >
          <PatientAccess
            patientName={modalBigPatientDetailsProps.patientName}
            patientId={modalBigPatientDetailsProps.patientId}
            staffName={staff.name}
          />
        </ModalBig>
        { isModalSmallOpen &&
          <ModalSmall
            bodyTitle={modalSmallContent.bodyTitle}
            bodySub={modalSmallContent.bodySub}
            isOneBtn={modalSmallContent.isOneBtn}
            btnSecondTxt={modalSmallContent.btnSecondTxt}
            btnFirstTxt={modalSmallContent.btnFirstTxt}
            btnSecondEvent={modalSmallContent.btnSecondEvent}
            btnFirstEvent={modalSmallContent.btnFirstEvent}
            isModalSmallOpen={isModalSmallOpen}
            setIsModalSmallOpen={setIsModalSmallOpen}/>
        }
    </div>
  );
};

export default Patients;