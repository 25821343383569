import React from 'react';
import styles from './noticeSkeleton.module.scss'
import classnames from "classnames/bind";
const cx = classnames.bind(styles)
const MoraexService = () => {
  return (
    <div className={cx('noticeSkeleton')}>
      <h1></h1>
      <div className={cx('searchContainer')}></div>
      <div className={cx('table')}>
        <div className={cx('column')}>
          <p></p>
          <p></p>
        </div>
        <div className={cx('contentWrapper')}>
          { [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map((item) => (
            <div className={cx('content')} key={item}>
              <p className={cx('date')}></p>
              <p className={cx('title')}></p>
            </div>
          )) }
          <div className={cx('page')}>
            { [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item => (
              <div key={item}></div>
            ))) }

          </div>
        </div>
      </div>
    </div>
  );
}

export default MoraexService