import React, {useEffect, useRef, useState} from 'react';
import styles from './modalExDetail.module.scss'
import classnames from 'classnames/bind';
import ToggleBookmark from "@/helpers/toggleBookmark"
import {useSelector} from "react-redux";
import tagKor from '@/data/tagKor'

const BCloseSharp = `${process.env.REACT_APP_RESOURCE_URL}/B_closeSharp.svg`
const YNewStar = `${process.env.REACT_APP_RESOURCE_URL}/Y_newStar.svg`
const GNewStar = `${process.env.REACT_APP_RESOURCE_URL}/G_newStar.svg`

const cx = classnames.bind(styles);
const ModalExDetail = ({ header, exercise, isModalExerciseDetailOpen, setIsModalExerciseDetailOpen }) => {
  const ref = useRef()
  const [bookmarkClickKey, setBookmarkClickKey] = useState(0)
  const bookmarkExercise = useSelector(state => {
    return state.staff.value.bookmarkExercise
  })
  const lvArr = useSelector(state => {
    return state.rehab.value.lvArr
  })

  const closeModal = (e) => {
    e.stopPropagation()
    setIsModalExerciseDetailOpen(false)
  }

  const onStarClick = (e) => {
    e.stopPropagation()
    setBookmarkClickKey(Math.random())
  }

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden'
    const checkIfClickedOutside = (e) => {
      if (isModalExerciseDetailOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalExerciseDetailOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
      document.documentElement.style.overflow = 'auto'
    }
  }, [isModalExerciseDetailOpen])

  return (
    <>
      { bookmarkClickKey !== 0 ? (
        <ToggleBookmark type='exercise' id={exercise.id} key={bookmarkClickKey}/>
      ) : ''}
      <div className={cx('modalExDetail')}>
        <div ref={ref}>
          <div className={cx('header')}>
            <p>{ header }</p>
            <img onClick={(e) => closeModal(e)} src={BCloseSharp} alt='BCloseSharp'/>
          </div>
          <div className={cx('body')}>
            <img onClick={(e) => onStarClick(e)} src={ bookmarkExercise.includes(exercise.id) ? YNewStar : GNewStar} alt='GNewStar'/>
            <div className={cx('title')}>
              <p className={cx('type')}>운동 동작</p>
              <p className={cx('name')}>{ exercise.name }</p>
            </div>
            <div className={cx('content')}>
              <div>
                <span className={cx('contentTitle')}>난이도</span>
                <span className={cx('contents', 'colorDot', lvArr[exercise.level - 1].color)}>{ lvArr[exercise.level - 1].lv }</span>
              </div>
              <div>
                <span className={cx('contentTitle')}>부위</span>
                <span className={cx('contents')}>
                  { exercise.tag ? (
                    exercise.tag.MP && (
                        exercise.tag.MP.map((mp, idx) => (
                          <span key={idx}>
                        { tagKor['MP'][mp] }
                            { idx !== exercise.tag.MP.length - 1 ? (
                              <span>, &nbsp;</span>
                            ) : ''}
                      </span>
                        ))
                      )
                  ) : (
                    exercise.exerciseTags.MP.map((mp, idx) => (
                      <span key={idx}>
                        { tagKor['MP'][mp] }
                        { idx !== exercise.exerciseTags.MP.length - 1 ? (
                          <span>, &nbsp;</span>
                        ) : ''}
                      </span>
                    ))
                  )}
                </span>
              </div>
              <div>
                <span className={cx('contentTitle')}>도구</span>
                <span className={cx('contents')}>
                  { exercise.tag ? (
                    exercise.tag.TO ? (
                      exercise.tag.TO.map((to, idx) => (
                        <span key={idx}>
                        { tagKor['TO'][to]}
                          { idx !== exercise.tag.TO.length - 1 ? (
                            <span>, &nbsp;</span>
                          ) : ''}
                      </span>
                      ))
                    ) : '-'
                  ) : (
                    exercise.exerciseTags.TO ? (
                      exercise.exerciseTags.TO.map((to, idx) => (
                        <span key={idx}>
                          { tagKor['TO'][to]}
                          { idx !== exercise.exerciseTags.TO.length - 1 ? (
                            <span>, &nbsp;</span>
                          ) : ''}
                        </span>
                      ))
                    ) : '-'
                  )}
                </span>
              </div>
              <div>
                <span className={cx('contentTitle')}>목적</span>
                <span className={cx('contents')}>
                  { exercise.objective && (
                    <span>
                      { tagKor['OB'][exercise.objective]}
                    </span>
                  ) }
                </span>
              </div>
              <div>
                <span className={cx('contentTitle')}>설명</span>
                <span className={cx('contents')}>{ exercise.description }</span>
              </div>
            </div>
            <video
              controls
              controlsList="nodownload"
              src={exercise.urls ? exercise.urls.MG : exercise.media.MG.url}
              type="video/mp4"
            ></video>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalExDetail;