import React, {useEffect} from 'react';
import styles from './modalBig.module.scss'
import classnames from 'classnames/bind';
import {useDispatch, useSelector} from "react-redux";
import {setIsModalBigBtnActive} from "../../../../store/slice/modalBtnSlice";

const cx = classnames.bind(styles);

const ModalBig = ({ children, header, btnSmall, btnBig, closeModalBig, isModalBigOpen, onAction, values, width = 460 }) => {
  const dispatch = useDispatch()
  const isModalBigBtnActive = useSelector(state => {
    return state.modalBtn.value.isModalBigBtnActive
  })

  const getChildren = () => {
    return React.cloneElement(children, {
      isModalBigOpen: Boolean,
    })
  }
  const closeModal = () => {
    closeModalBig()
    dispatch(setIsModalBigBtnActive(false))
  }

  const onClickAction = () => {
    if(isModalBigBtnActive) {
      onAction(values)
      dispatch(setIsModalBigBtnActive(false))
    }
  }

  useEffect(() => {
    if(isModalBigOpen) {
      document.documentElement.style.overflow = 'hidden'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [isModalBigOpen])

  return (
    <>
    { isModalBigOpen && (
      <div className={cx('modalBig')} onClick={closeModal}>
        <div onClick={e => e.stopPropagation()} style={{ width: width + 'px' }}>
          <div className={cx('header')}>
            <p>{ header }</p>
          </div>
          <div className={cx('body')}>
            {  header === '병원(근무지) 찾기' ? getChildren() : children }
          </div>
          <div className={cx('btns')}>
            <button className={cx('btnSmall')} onClick={closeModal}>{ btnSmall }</button>
            <button className={cx('btnBig', isModalBigBtnActive && 'active')} onClick={onClickAction}>{ btnBig }</button>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

export default ModalBig;