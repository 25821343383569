import React, {useEffect, useRef, useState} from 'react';
import styles from './modalOrgan.module.scss'
import classnames from 'classnames/bind';
import cityData from "@/data/city"
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../utils/api";
import useDidMountEffect from "../../../../helpers/useDidMountEffect";
import DaumPostcodeEmbed from "react-daum-postcode";
// import {setIsModalOrganBtnActive} from "../../../../store/slice/modalBtnSlice";
const BCloseSharp = `${process.env.REACT_APP_RESOURCE_URL}/B_closeSharp.svg`
const PHospital = `${process.env.REACT_APP_RESOURCE_URL}/P_hospital.svg`
const GArrowBack = `${process.env.REACT_APP_RESOURCE_URL}/G_arrowBack.svg`
const BArrowDown = `${process.env.REACT_APP_RESOURCE_URL}/B_arrow_down.svg`
const CloseRound = `${process.env.REACT_APP_RESOURCE_URL}/close_round.svg`
const cx = classnames.bind(styles);

const ModalOrgan = ({ closeModalOrgan, selectOrgan, isModalOrganOpen }) => {
  // const dispatch = useDispatch()
  // const isModalOrganBtnActive = useSelector(state => {
  //   return state.modalBtn.value.isModalOrganBtnActive
  // })
  const provinceRef = useRef(null)
  const cityRef = useRef(null)
  const organNameRef = useRef(null)
  const newOrganNameRef = useRef(null)

  const [organName, setOrganName] = useState('')
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false)
  const [addressData, setAddressData] = useState({
    address: '',
    roadAddress: '',
    zipCode: ''
  })
  const [isShowAddressSearch, setIsShowAddressSearch] = useState(false)
  // const [selectedProvince, setSelectedProvince] = useState(Object.keys(cityData)[0])
  // const [selectedCity, setSelectedCity] = useState(Object.values(cityData)[0])
  // const [cityList, setCityList] = useState([])
  const [newOrganName, setNewOrganName] = useState('')
  const [organs, setOrgans] = useState([])
  const [isSearched, setIsSearched] = useState(false)
  const [isAddNewOrgan, setIsAddNewOrgan] = useState(false)
  // const [isProvinceDropdownShow, setIsProvinceDropdownShow] = useState(false)
  // const [isCityDropdownShow, setIsCityDropdownShow] = useState(false)
  const [listSize,setListSize] = useState(20)
  const [showMoreBtn, setShowMoreBtn] = useState(false)
  const [isOrganNameInputFocused,setIsOrganNameInputFocused] = useState(false)
  const [isNewOrganNameInputFocused,setIsNewOrganNameInputFocused] = useState(false)

  // const getChildren = () => {
  //   return React.cloneElement(children, {
  //     isModalOrganOpen: Boolean,
  //   })
  // }
  const closeModal = () => {
    closeModalOrgan()
  }

  const searchOrgans = () => {
    if(!organName.trim()) {
      return
    }

    // listSize
    api.getOrgans(organName.trim()).then((res) => {
      setIsSearched(true)
      // let totalPage = 30
      // let totalPage = res.data.totalPage
      // if(totalPage > listSize) {
      //   setListSize((prev) => prev + 20)
      //   setShowMoreBtn(true)
      // } else {
      //   setShowMoreBtn(false)
      // }
    setOrgans(res.data.list)
    })

  }

  const goToAddNewOrgan = () => {
    setIsAddNewOrgan(true)
  }

  const registerNewOrgan = () => {
    if(!newOrganName || !addressData.address) {
      return
    }
    setIsAlreadyRegistered(false)
    let body = {
      name: newOrganName,
      address: addressData.address,
      detailAddress: '',
      zipCode: ''
    }
    api.postOrgan(body).then((res) => {
      selectOrgan({
        id: res.data.id,
        name: newOrganName,
        verified: false,
        address: addressData.address
      })
      closeModalOrgan()
    }).catch((e) => {
      if(e.response.data.code === '1105') {
        setIsAlreadyRegistered(true)
      }
    })
  }

  const onClickSelect = (organ) => {
    selectOrgan({
      id: organ.id,
      name: organ.name,
      verified: organ.verified,
      address: organ.address
    })
    closeModalOrgan()
  }

  const goBlack = () => {
    setIsAddNewOrgan(false)
  }

  const onOrganInputKeyUp = (e) => {
    if(e.code === 'Enter') {
      searchOrgans()
    }

  }

  // const dropdownProvince = () => {
  //   setIsProvinceDropdownShow(!isProvinceDropdownShow)
  //   setIsCityDropdownShow(false)
  // }
  //
  // const dropdownCity = () => {
  //   if(selectedProvince === Object.keys(cityData)[0]) {
  //     setIsCityDropdownShow(false)
  //   } else {
  //     setIsCityDropdownShow(!isCityDropdownShow)
  //   }
  //
  //   setIsProvinceDropdownShow(false)
  // }

  // const selectProvince = (e, item) => {
  //   setCityList(cityData[item])
  //   setSelectedProvince(item)
  //   setSelectedCity(Object.values(cityData)[0])
  //   setIsProvinceDropdownShow(false)
  //   if(item !== Object.keys(cityData)[0]) {
  //     setIsCityDropdownShow(true)
  //   }
  //
  // }

  const resetOrganName = (e) => {
    e.preventDefault()
    setOrganName('')

  }

  const resetNewOrganName = (e) => {
    e.preventDefault()
    setNewOrganName('')

  }

  // const selectCity = (e, item) => {
  //   setSelectedCity(item)
  //   setIsCityDropdownShow(false)
  // }


  const handleComplete = (data) => {
    setAddressData({
      address: data.address,
      roadAddress: data.roadAddress,
      zipCode: data.zonecode
    })
  };


  useEffect(() => {
    if(isModalOrganOpen) {
      document.documentElement.style.overflow = 'hidden'
    }



    return () => {
      document.documentElement.style.overflow = 'auto'
    }


  }, [isModalOrganOpen])

  // useEffect(() => {
  //   const checkIfClickedOutsideProvince = e => {
  //     if (isProvinceDropdownShow && provinceRef.current && !provinceRef.current.contains(e.target) && !e.target.className.includes('address')) {
  //       setIsProvinceDropdownShow(false)
  //     }
  //   }
  //
  //   document.addEventListener("mousedown", checkIfClickedOutsideProvince)
  //
  //   return () => {
  //     document.removeEventListener("mousedown", checkIfClickedOutsideProvince)
  //   }
  // }, [isProvinceDropdownShow]);

  // useEffect(() => {
  //   const checkIfClickedOutsideCity = e => {
  //     if (isCityDropdownShow && cityRef.current && !cityRef.current.contains(e.target) && !e.target.className.includes('address')) {
  //       setIsCityDropdownShow(false)
  //     }
  //   }
  //
  //   document.addEventListener("mousedown", checkIfClickedOutsideCity)
  //
  //   return () => {
  //     document.removeEventListener("mousedown", checkIfClickedOutsideCity)
  //   }
  // }, [isCityDropdownShow]);

  useEffect(() => {
    // if(!isAddNewOrgan && organNameRef.current) {
    //   organNameRef.current.focus()
    // } else if(isAddNewOrgan && newOrganNameRef.current) {
    //   newOrganNameRef.current.focus()
    // }
  });

  useEffect(() => {
    // setIsProvinceDropdownShow(false)
    // setIsCityDropdownShow(false)
  }, []);

  return (
    <div className={cx('modalOrgan')} onClick={closeModal}>
      <div onClick={(e) => e.stopPropagation()}>
        <div className={cx('header')}>
          {isAddNewOrgan && (
            <img className={cx('back')} onClick={goBlack} src={GArrowBack} alt='GArrowBack'/>
          )}
          <p>{isAddNewOrgan ? '신규 기관 등록' : '소속기관 찾기'}</p>
          <img className={cx('close')} onClick={closeModal} src={BCloseSharp} alt='BCloseSharp'/>
        </div>
        {!isAddNewOrgan ? (
          <div className={cx('bodyFindOrgan')}>
            <div className={cx('inputWrapper')}>
              <input
                className={cx(isOrganNameInputFocused && 'active')}
                type='text'
                value={organName}
                ref={organNameRef}
                onChange={(e) => setOrganName(e.target.value)}
                onFocus={() => setIsOrganNameInputFocused(true)}
                onBlur={() => setIsOrganNameInputFocused(false)}
                onKeyUp={(e) => onOrganInputKeyUp(e)}
                placeholder='소속기관명을 입력해 주세요.'
              />
              {isOrganNameInputFocused && (
                <img onMouseDown={(e) => resetOrganName(e)} src={CloseRound} alt='CloseRound'/>
              )}

              <button className={cx(organName && 'active')} onClick={searchOrgans}>검색</button>
            </div>
            {isSearched ? (
              <>
                <p>아래 검색 결과가 없나요?<span onClick={goToAddNewOrgan}>신규 등록하기</span></p>
                <div className={cx('listBox', organs.length || 'emptyList')}>
                  <>
                    {organs.length > 0 ? (
                      organs.map((organ) => (
                        <div key={organ.id} className={cx('item')}>
                          <div className={cx('itemName')}>
                            <h5>
                              {organ.verified && (
                                <img src={PHospital} alt='PHospital'/>
                              )}
                              <p>{organ.name}</p>
                            </h5>
                            <p>{organ.address}</p>
                          </div>
                          <button onClick={() => onClickSelect(organ)}>선택</button>
                        </div>
                      ))
                    ) : (
                      <p>검색 결과가 없습니다.</p>
                    )}
                    {showMoreBtn && <button onClick={searchOrgans}>더보기</button>}

                  </>

                </div>
              </>
            ) : (
              <p>예) 부민병원, 에버엑스</p>
            )}

          </div>
        ) : (
          <div className={cx('bodyAddOrgan')}>
            <div className={cx('searchContainer')}>
              <input
                className={cx(isNewOrganNameInputFocused && 'active')}
                type='text'
                value={newOrganName}
                ref={newOrganNameRef}
                onChange={(e) => setNewOrganName(e.target.value)}
                onFocus={() => setIsNewOrganNameInputFocused(true)}
                onBlur={() => setIsNewOrganNameInputFocused(false)}
                placeholder='소속기관명을 입력해 주세요.'
              />
              {isNewOrganNameInputFocused && (
                <img onMouseDown={(e) => resetNewOrganName(e)} src={CloseRound} alt='CloseRound'/>
              )}
              <div className={cx('addressBox')}>
                <input
                  readOnly={true}
                  value={addressData.address === null ? '' : addressData.address}
                  placeholder='주소를 검색해 주세요.'
                />
                <button onClick={() => setIsShowAddressSearch(true)}>주소 찾기</button>
              </div>
              { isShowAddressSearch && (
                <div className={cx('addressSearchBox')}>
                  <DaumPostcodeEmbed
                    onComplete={handleComplete}
                    className="addresBody"
                    focusInput
                    autoClose={false}
                    style={{height: '261px'}}
                  />
                </div>
              )}

              { isAlreadyRegistered && (
                <div className={cx('alreadyRegistered')}>
                  <h6>* 이미 존재하는 기관입니다. 소속기관 찾기에서 검색해 주세요.</h6>
                  <p>문의 사항 : support@everex.co.kr</p>
                </div>
              )}


              {/*<div className={cx('dropdownWrapper')}>*/}
              {/*  <div className={cx('address', (isProvinceDropdownShow && selectedProvince === Object.keys(cityData)[0]) && 'activeNavy', (isProvinceDropdownShow && selectedProvince !== Object.keys(cityData)[0]) && 'activeMint')} onClick={dropdownProvince}>*/}
              {/*    <span className={cx('addressProvince')}>{selectedProvince}</span>*/}
              {/*    <img className={cx('addressArrow')} src={BArrowDown} alt='BArrowDown'/>*/}
              {/*  </div>*/}
              {/*  <div className={cx('address', (isCityDropdownShow && selectedCity === Object.values(cityData)[0]) && 'activeNavy', (isCityDropdownShow && selectedCity !== Object.values(cityData)[0]) && 'activeMint')} onClick={dropdownCity}>*/}
              {/*    <span className={cx('addressCity')}>{selectedCity}</span>*/}
              {/*    <img className={cx('addressArrow')} src={BArrowDown} alt='BArrowDown'/>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*{isProvinceDropdownShow && (*/}
              {/*  <div className={cx('dropdownContainer', 'provinceDropdown')} ref={provinceRef}>*/}
              {/*    {Object.keys(cityData).map((item) => (*/}
              {/*      <p key={item} className={cx(item === selectedProvince && 'active')} onClick={(e) => selectProvince(e, item)}>{item}</p>*/}
              {/*    ))}*/}
              {/*  </div>*/}
              {/*)}*/}
              {/*{isCityDropdownShow && (*/}
              {/*  <div className={cx('dropdownContainer', 'cityDropdown')} ref={cityRef}>*/}
              {/*    {cityList.map((item) => (*/}
              {/*      <p key={item} className={cx(item === selectedCity && 'active')} onClick={(e) => selectCity(e, item)}>{item}</p>*/}
              {/*    ))}*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
            <div className={cx('btnWrapper')}>
              <button
                className={cx((newOrganName && addressData.address) && 'active')}
                onClick={registerNewOrgan}
              >
                등록하기
              </button>
            </div>

          </div>
        )}

        {/*<div className={cx('body')}>*/}
        {/*  {  header === '병원(근무지) 찾기' ? getChildren() : children }*/}
        {/*</div>*/}
        {/*<div className={cx('btns')}>*/}
        {/*  <button className={cx('btnFirst', isModalOrganBtnActive && 'active')} onClick={onClickAction}>{ btnFirst }</button>*/}
        {/*  <button className={cx('btnSecond')} onClick={closeModal}>{ btnSecond }</button>*/}

        {/*</div>*/}
      </div>
    </div>
  );
};

export default ModalOrgan;