import React from 'react';
import StatisticsDonutChart from "../../../admin/statistics/statisticsDonutChart/statisticsDonutChart";
import styles from './homeSkeleton.scss'
import classnames from "classnames/bind";

const cx = classnames.bind(styles)
const HomeSkeleton = () => (
  <div className={cx('homeSkeleton')}>
    <div className={cx('top')}>
      <p></p>
      <div></div>
    </div>
    <div className={cx('contentGroup')}>
      <div className={cx('group')}>
        <div className={cx('title')}>
          <p></p>
          <div></div>
        </div>
        <div className={cx('code')}>
          <div>
            <div className={cx('codeTitle')}></div>
            <div className={cx('codeContent')}></div>
          </div>
          <div>
            <div className={cx('codeTitle')}></div>
            <div className={cx('codeContent')}></div>
          </div>
        </div>
        <div className={cx('code2')}></div>

      </div>
      <div className={cx('service')}>
        <p className={cx('subs')}></p>
        <p className={cx('subsName')}></p>
        <p className={cx('subsDesc')}></p>
        <p className={cx('btn')}></p>
      </div>
      <div className={cx('plans')}>
        <p></p>
        <div className={cx('planList')}>
          { [0, 1, 2].map((idx) => (
            <div key={idx}>
              <div className={cx('creator')}>
                <span></span>
              </div>
              <span className={cx('bar')}>|</span>
              <div className={cx('mainPart')}>
                <span></span>
              </div>
              <span className={cx('bar')}>|</span>
              <div className={cx('patient')}>
                <span></span>
              </div>
              <span className={cx('bar')}>|</span>
              <div className={cx('status')}>
                <span></span>
              </div>
            </div>
          )) }

        </div>
      </div>
    </div>
    <div className={cx('contentStatistics')}>

      <div className={cx('contentBoxWrapper')}>
        { [0, 1, 2].map((idx) => (
          <div key={idx}>
            <div className={cx('boxTop')}>
              <div className={cx('top1')}></div>
              <div className={cx('top2')}></div>
            </div>
            <div className={cx('boxBottom')}></div>
          </div>
        )) }
      </div>
      <div className={cx('chartWrapper')}>
        <div className={cx('topTitle')}>
          <p></p>
          <div className={cx('legend')}>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <div></div>
          </div>
        </div>
        <div className={cx('chart')}></div>
      </div>
      <div className={cx('roundChartWrapper')}>
        <div className={cx('roundChartBox')}>
          <div className={cx('title')}>
            <div></div>
            <p></p>
          </div>
          <StatisticsDonutChart number={0}/>
        </div>
        <div className={cx('roundChartBox')}>
          <div className={cx('title')}>
            <div></div>
            <p></p>
          </div>
          <StatisticsDonutChart number={0}/>
        </div>
      </div>
      <div className={cx('barChartWrapper')}>
        <div className={cx('barTitleBox')}>
          <span className={cx('title')}></span>
          <span className={cx('titleRight')}></span>
        </div>
        <div className={cx('barChartBox')}>
          <div className={cx('barTitle')}>
            <span></span>
            <p></p>
          </div>
          <div className={cx('barProgress')}></div>
          <div className={cx('barRange')}>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={cx('barChartBox')}>
          <div className={cx('barTitle')}>
            <span></span>
            <p></p>
          </div>
          <div className={cx('barProgress')}></div>
          <div className={cx('barRange')}>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

    </div>
  </div>
);

export default HomeSkeleton;