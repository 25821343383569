import React, {useEffect, useRef} from 'react';
import styles from './modalSmall.module.scss'
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

const ModalSmall = ({ bodyTitle, bodySub, isOneBtn, btnFirstTxt, btnSecondTxt, isModalSmallOpen, setIsModalSmallOpen, btnFirstEvent, btnSecondEvent, values, isHeaderDimmed = true }) => {
  const ref = useRef()

  useEffect(() => {
    if(isModalSmallOpen) {
      document.documentElement.style.overflow = 'hidden'
    }

    // 버튼 외 클릭 시 모달창 못 닫음 ---------------
    let titleArr = ['이미 가입한 계정이 있어요', '그룹 초대 안내', '그룹 참여 승인 대기', '플랜 배정이 완료되었습니다.']
    if(titleArr.includes(ref.current.querySelectorAll('p')[0].firstChild.nodeValue)) {
      return
    }
    // --------------- 버튼 외 클릭 시 모달창 못 닫음

    const checkIfClickedOutside = e => {
      if (isModalSmallOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalSmallOpen(false)
      }
    }

    const checkIfKeyEsc = e => {
      if (isModalSmallOpen && ref.current && !ref.current.contains(e.target) && e.keyCode === 27) {
        setIsModalSmallOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)
    document.addEventListener("keyup", checkIfKeyEsc)


    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
      document.removeEventListener("keyup", checkIfKeyEsc)
      document.documentElement.style.overflow = 'auto'
    }
  }, [isModalSmallOpen])


  return (
    <div className={cx('modalSmall', isHeaderDimmed || 'headerDimmed')}>
      <div ref={ref}>
        <div className={cx('body')}>
          <p className={cx('title')} dangerouslySetInnerHTML={{__html: bodyTitle}}></p>
          <p className={cx('sub')} dangerouslySetInnerHTML={{__html: bodySub}}></p>
        </div>
        <div className={cx('footer')}>
          <button className={cx('btnFirst')} onClick={() => btnFirstEvent(values)}>{btnFirstTxt}</button>
          { !isOneBtn && (<button className={cx('btnSecond')} onClick={() => btnSecondEvent()}>{btnSecondTxt}</button>) }
        </div>
      </div>
    </div>
  );
};

export default ModalSmall;