import React, {useEffect, useState} from 'react';
import styles from './patientAccess.module.scss'
import classnames from 'classnames/bind';
import {setIsModalBigBtnActive} from "@/store/slice/modalBtnSlice"
import {useDispatch} from "react-redux";
import { setPatientAccessInfo} from "../../../../store/slice/patientSlice";

const GRadioUnCheck = `${process.env.REACT_APP_RESOURCE_URL}/G_radioUnCheck.svg`
const GRadioCheck = `${process.env.REACT_APP_RESOURCE_URL}/G_radioCheck.svg`

const cx = classnames.bind(styles);
const PatientAccess = ({ patientName, patientId, staffName }) => {
  const dispatch = useDispatch()

  const [purpose, setPurpose] = useState('')
  const [inputPurpose, setInputPurpose] = useState('')
  const [activePurposeId, setActivePurposeId] = useState(null)

  const checkRadio = (purpose, id) => {
    setActivePurposeId(id)
    setPurpose(purpose)
    setInputPurpose('')
  }

  const checkETC = (id) => {
    setPurpose(inputPurpose)
    setActivePurposeId(id)
  }

  const onPurposeChange = (e) => {
    setActivePurposeId(4)
    setInputPurpose(e.target.value)
  }

  useEffect(() => {
    if(activePurposeId === 4) {
      setPurpose(inputPurpose)
    }

    if(patientName && staffName && (purpose || (activePurposeId === 4 && inputPurpose))) {
      dispatch(setIsModalBigBtnActive(true))

      let payload = {
        patientId: patientId,
        purpose: purpose,
      }
      dispatch(setPatientAccessInfo(payload))
    } else {
      dispatch(setIsModalBigBtnActive(false))
    }
  }, [patientName, staffName, purpose, activePurposeId, inputPurpose])

  return (
    <div className={cx('patientAccess')}>
      <p>고객명</p>
      <input
        value={patientName}
        className={cx('nameInput')}
        type="text"
        readOnly={true}
      />
      <p>열람자</p>
      <input
        value={staffName}
        className={cx('nameInput')}
        type="text"
        readOnly={true}
      />
      <p>열람 사유</p>
      <div className={cx('options')}>
        <div onClick={() => checkRadio('진료용', 0)}>
          <img src={activePurposeId === 0 ? GRadioCheck : GRadioUnCheck} alt='radioCheck'/>
          <span>진료용</span>
        </div>
        <div onClick={() => checkRadio('고객 관리용', 1)}>
          <img src={activePurposeId === 1 ? GRadioCheck : GRadioUnCheck} alt='radioCheck'/>
          <span>고객 관리용</span>
        </div>
        <div onClick={() => checkRadio('판독용', 2)}>
          <img src={activePurposeId === 2 ? GRadioCheck : GRadioUnCheck} alt='radioCheck'/>
          <span>판독용</span>
        </div>
        <div onClick={() => checkRadio('연구용', 3)}>
          <img src={activePurposeId === 3 ? GRadioCheck : GRadioUnCheck} alt='radioCheck'/>
          <span>연구용</span>
        </div>
        <div onClick={() => checkETC(4)}>
          <img src={activePurposeId === 4 ? GRadioCheck : GRadioUnCheck} alt='radioCheck'/>
          <span>기타</span>
        </div>
      </div>
      <input
        value={inputPurpose}
        className={cx('purposeInput')}
        type="text"
        onChange={(e) => onPurposeChange(e)}
        onFocus={(e) => onPurposeChange(e)}
        placeholder='상세 사유를 입력해 주세요.'
      />
      <p className={cx('warning')}><span>고객 개인정보 보호</span>를 위하여 업무용 외에는 고객 정보 열람이 금지되어 있습니다. <span>열람 이력</span>은 <span>모니터링</span>되며 <span>기록접근 위법</span>이 발견된 경우 <span>처벌</span>을 받을 수 있습니다.</p>
    </div>
  );
};

export default PatientAccess;