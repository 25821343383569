import {createSlice} from "@reduxjs/toolkit";

const modalBtnSlice = createSlice({
  name: 'modalBtnSlice',
  initialState: { value: {
      isModalBigBtnActive: false,
      isModalBigNewBtnActive: false
    } },
  reducers: {
    setIsModalBigBtnActive: (state, action) => {
      state.value.isModalBigBtnActive = action.payload
    },
    setIsModalBigNewBtnActive: (state, action) => {
      state.value.isModalBigNewBtnActive = action.payload
    },
  }
})

export default modalBtnSlice
export const { setIsModalBigBtnActive, setIsModalBigNewBtnActive } = modalBtnSlice.actions