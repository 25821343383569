import React from 'react';
import classnames from "classnames/bind";
import styles from './notiSkeleton.module.scss'

const cx = classnames.bind(styles)

const NotiSkeleton = () => {
  return (
    <div className={cx('notiSkeleton')}>
      { [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map((item) => (
        <div className={cx('item')} key={item}>
          <div>
            <p className={cx('tag')}></p>
            <div className={cx('itemContent')}>
              <p className={cx('notiTitle')}></p>
              <p className={cx('notiTitle2')}></p>
            </div>
          </div>
          <p className={cx('date')}></p>
        </div>
      )) }

    </div>
  );
};

export default NotiSkeleton;