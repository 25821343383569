import React, {useEffect, useState, useRef} from 'react';
import InputSearchBox from "../../common/input/inputSearchBox/inputSearchBox";
import styles from './programExerciseSelect.module.scss'
import classnames from "classnames/bind";
import Pagination from "@/common/pagination/pagination";
import api from "@/api";
import {useDispatch, useSelector} from "react-redux";
import DropdownOrder from "../../common/dropdown/dropdownOrder/dropdownOrder";
import { numberWithCommas, setMinutes} from "../../../helpers/common";
import tagKor from '@/data/tagKor'
import tagEng from '@/data/tagEng'
import ExerciseSelectSkeleton from "@/components/planAssignment/planExerciseSelect/exerciseSelectSkeleton/exerciseSelectSkeleton";
import {setActiveFilterRehabId} from "../../../store/slice/rehabSlice";
import imgSize from "@/data/imgSize"
import ExerciseDragItem from "@/components/planAssignment/planExerciseSelect/exerciseDragItem/exerciseDragItem";
import rehabListOrderParams from '@/data/rehabListOrderParams'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import ProgramCard from "@/components/planAssignment/planExerciseSelect/programCard/programCard";
import ExerciseCard from "@/components/planAssignment/planExerciseSelect/exerciseCard/exerciseCard";
import {setProgramCreationParams, setProgramCreationStep, setAddedProgramExerciseArr} from "@/store/slice/programSlice";
import DropdownMultipleChoice from "../../common/dropdown/dropdownMultipleChoice/dropdownMultipleChoice";
import LeftTabs from "../../common/tabs/leftTabs/leftTabs";
import {setIsMyProgramsUpdate} from "../../../store/slice/programSlice";

const GNewStar = `${process.env.REACT_APP_RESOURCE_URL}/G_newStar.svg`
const YNewStar = `${process.env.REACT_APP_RESOURCE_URL}/Y_newStar.svg`
const GTriangle = `${process.env.REACT_APP_RESOURCE_URL}/G_triangle.svg`
const GExercise = `${process.env.REACT_APP_RESOURCE_URL}/G_exercise.svg`
const GTimer20 = `${process.env.REACT_APP_RESOURCE_URL}/G_timer20*20.svg`
const GTool = `${process.env.REACT_APP_RESOURCE_URL}/G_tool.svg`
const GCheckCircleFull = `${process.env.REACT_APP_RESOURCE_URL}/G_checkCircleFull.svg`
const EmptyResult = `${process.env.REACT_APP_RESOURCE_URL}/Empty_result.svg`
const EmptyBookmark = `${process.env.REACT_APP_RESOURCE_URL}/Empty_bookmark.svg`
const GCheckCircle = `${process.env.REACT_APP_RESOURCE_URL}/G_checkCircle.svg`
const CloseRound = `${process.env.REACT_APP_RESOURCE_URL}/close_round.svg`

const cx = classnames.bind(styles)

const exerciseOrderList = [
  {
    id: 0,
    txt: '등록순 (최근)',
  },
  {
    id: 1,
    txt: '등록순 (오래된)',
  },
  {
    id: 2,
    txt: '이름순 (ㄱ-ㅎ)',
  },
  {
    id: 3,
    txt: '이름순 (ㅎ-ㄱ)',
  },
]


const ProgramExerciseSelect = () => {
  const refs = useRef([])
  const dispatch = useDispatch()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )
  const staff = useSelector(state => {
    return state.staff.value
  })
  const bookmarkProgram = useSelector(state => {
    return state.staff.value.bookmarkProgram
  })
  const bookmarkExercise = useSelector(state => {
    return state.staff.value.bookmarkExercise
  })
  const programCreationParams = useSelector(state => {
    return state.program.value.programCreationParams
  })
  const addedProgramExerciseArr = useSelector(state => {
    return state.program.value.addedProgramExerciseArr
  })
  const activeFilterRehabId = useSelector(state => {
    return state.rehab.value.activeFilterRehabId
  })
  const lvArr = useSelector(state => {
    return state.rehab.value.lvArr
  })
  const isMyProgramsUpdate = useSelector(state => {
    return state.program.value.isMyProgramsUpdate
  })


  const [keyword, setKeyword] = useState('')
  const [isBookmarkActive, setIsBookmarkActive] = useState(false)
  const [payloadObj, setPayloadObj] = useState({
    keyword: '',
    tags: '',
    isBookmark: false,
    page: 1,
    order: '',
  })
  const [isDragging, setIsDragging] = useState(false)
  const [isDropdownExerciseOrderOpen, setIsDropdownExerciseOrderOpen] = useState(false)
  const [isDropdownProgramBodyPartOpen, setIsDropdownProgramBodyPartOpen] = useState(false)
  const [selectedExerciseOrderId, setSelectedExerciseOrderId] = useState(2)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [programBodyPartList, setProgramBodyPartList] = useState([])
  const [filterAllList, setFilterAllList] = useState([])
  const [filterMuList, setFilterMuList] = useState([])
  const [isSearched, setIsSearched] = useState(false)
  const [bodyPartListToggle, setBodyPartListToggle] = useState(true)
  const [apListToggle, setAPListToggle] = useState(false)
  const [etListToggle, setETListToggle] = useState(false)
  const [kcListToggle, setKCListToggle] = useState(false)
  const [lvListToggle, setLVListToggle] = useState(false)
  const [muListToggle, setMUListToggle] = useState(false)
  const [obListToggle, setOBListToggle] = useState(false)
  const [poListToggle, setPOListToggle] = useState(false)
  const [sdListToggle, setSDListToggle] = useState(false)
  const [toListToggle, setTOListToggle] = useState(false)
  const [wbListToggle, setWBListToggle] = useState(false)
  const [selectedFilterItems, setSelectedFilterItems] = useState([])
  const [programList, setProgramList] = useState([])
  const [exerciseList, setExerciseList] = useState([])
  const [filterNumbersObj, setFilterNumbersObj] = useState({})
  const [tagIdArr, setTagIdArr] = useState([])
  const [muKeyword, setMuKeyword] = useState('')
  const [filterRehabList, setFilterRehabList] = useState([
    {
      id: 0,
      tab: '추천 프로그램',
      count: 0,
    },
    {
      id: 1,
      tab: '공유 프로그램',
      count: 0,
    },
    {
      id: 2,
      tab: '나만의 프로그램',
      count: 0,
    },
    {
      id: 3,
      tab: '운동 동작',
      count: 0,
    },
  ])
  const [programExerciseInfo, setProgramExerciseInfo] = useState({
    exciseCount: 0,
    timerCount: 0,
    toolsKor: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isShowAddedExerciseList, setIsShowAddedExerciseList] = useState(false)
  const [isEditActive, setIsEditActive] = useState(false)
  const [isAllItemChecked, setIsAllItemChecked] = useState(false)
  const [isDeleteExercise, setIsDeleteExercise] = useState(false)
  const [isProgramTitleFocus, setIsProgramTitleFocus] = useState(false)
  const [isDiagnosisFocus, setIsDiagnosisFocus] = useState(false)
  const [isPhaseFocus, setIsPhaseFocus] = useState(false)

  const getKeyword = (value) => {
    setKeyword(() => value)
    if(!value) {
      setPayloadObj({
        ...payloadObj,
        keyword: ''
      })
    }
  }

  const onSearch = () => {
    setSelectedFilterItems([])
    setIsBookmarkActive(false)

    setPayloadObj({
      ...payloadObj,
      tags: '',
      isBookmark: false,
      keyword: keyword,
      page: 1
    })
  }

  const onBookmarkClick = () => {
    setIsBookmarkActive(!isBookmarkActive)
    setPayloadObj({
      ...payloadObj,
      isBookmark: !isBookmarkActive,
      page: 1,
    })
  }

  const clearFilter = () => {
    if(isBookmarkActive || selectedFilterItems.length) {
      setSelectedFilterItems([])

      setPayloadObj({
        ...payloadObj,
        tags: '',
        isBookmark: false,
      })

      setIsBookmarkActive(false)
    }
  }

  const getSelectedExerciseOrderId = (id) => {
    if(id === selectedExerciseOrderId) {
      return
    }
    setSelectedExerciseOrderId(id)

    let orderParam = ''

    if(activeFilterRehabId === 0) {
      orderParam = rehabListOrderParams['recommended'][id]
    } else if(activeFilterRehabId === 1){
      orderParam = rehabListOrderParams['shared'][id]
    } else if(activeFilterRehabId === 2){
      orderParam = rehabListOrderParams['my'][id]
    } else {
      orderParam = rehabListOrderParams['ex'][id]
    }

    setPayloadObj({
      ...payloadObj,
      order: orderParam,
      page: 1
    })
  }

  const moveExerciseContainerBottom = () => {
    if(!refs.current.length) {
      return
    }
    let element = null
    if(isEditActive) {
      element = refs.current[1]
    } else {
      element = refs.current[0]
    }

    let scrollBox = element
    if(scrollBox) {
      scrollBox.scrollTo({ top: scrollBox.scrollHeight, behavior: 'smooth'})
    }
  }

  const getProgramExerciseInfo = () => {
    let exerciseArr = []
    let toolArr = []
    let timeCount = 0
    addedProgramExerciseArr.forEach((addedExercise) => {
      if(addedExercise) {
        exerciseArr.push(addedExercise.id)
        if(addedExercise.tag && addedExercise.tag.TO) {
          toolArr = [...toolArr, ...addedExercise.tag.TO]
        } else if(addedExercise.exerciseTags && addedExercise.exerciseTags.TO) {
          toolArr = [...toolArr, ...addedExercise.exerciseTags.TO]
        }
        timeCount += (addedExercise.media[`M${addedExercise.time ? addedExercise.time : addedExercise.count}`].length) * addedExercise.repeat
      }
    })

    let uniqueToolArr = [...new Set(toolArr)]
    let toolKorArr = []

    uniqueToolArr.forEach((to) => {
      toolKorArr.push(tagKor['TO'][to])
    })

    setProgramExerciseInfo({
      exerciseCount: exerciseArr.length,
      timeCount: setMinutes(timeCount),
      toolsKor: toolKorArr.join(', ')
    })
  }

  const checkAddedExercise = () => {
    setIsShowAddedExerciseList(true)
  }

  const goToNextStep = () => {
    if(isNextBtnActive()) {
      dispatch(setProgramCreationStep(1))
    }
  }

  const isNextBtnActive = () => {
    let result = false

    if(programCreationParams.tags.length && addedProgramExerciseArr.length) {
      result = true
    }

    return result
  }

  const onClickFilterItem = (item) => {
    let tempSelectedFilterItems = selectedFilterItems
    let idx = 0
    if(tempSelectedFilterItems.some((selectedItem, i) => {
      if(selectedItem.tag === item.tag) {
        idx = i
        return true
      } else {
        return false
      }
    })) {
      if(idx > -1) {
        tempSelectedFilterItems.splice(idx, 1)
      }
    } else {
      tempSelectedFilterItems.push(item)
    }

    setSelectedFilterItems([...tempSelectedFilterItems])

    let tags = []

    selectedFilterItems.forEach((bodyPart) => {
      tags.push(bodyPart.tag)
    })
    setPayloadObj({
      ...payloadObj,
      tags: tags.join(),
      page: 1,
    })
  }

  const movePage = (page) => {
    setPayloadObj({
      ...payloadObj,
      page: page
    })
  }

  const addFilterNumbers = (filterSections) => {
    let tempExFilterObj = filterSections
    Object.keys(tempExFilterObj).forEach((tempExFilter) => {
      tempExFilterObj[tempExFilter].forEach((exFilter) => {
        Object.keys(filterNumbersObj[tempExFilter]).forEach((filterNumber) => {
          if(exFilter.tag === filterNumber) {
            exFilter.count = filterNumbersObj[tempExFilter][filterNumber]
          }
        })
      })
    })

    setFilterAllList(tempExFilterObj)
  }

  const getTotalElement = (totalElement) => {
    if(totalElement === undefined) {
      return
    }
    let newRehabList = filterRehabList
    newRehabList[activeFilterRehabId].count = totalElement
    setFilterRehabList(newRehabList)
  }

  const selectFilterRehab = (filterRehabId) => {
    if(filterRehabId !== activeFilterRehabId) {
      clearFilter()
      setKeyword('')
      setSelectedExerciseOrderId(2)
      dispatch(setActiveFilterRehabId(filterRehabId))
      if(filterRehabId === 0) {
        setPayloadObj({
          keyword: '',
          tags: '',
          isBookmark: false,
          page: 1,
          creatorId: '',
          shared: false,
          order: rehabListOrderParams['recommended'][2],
          tabId: 0
        })
      } else if(filterRehabId === 1) {
        setPayloadObj({
          keyword: '',
          tags: '',
          isBookmark: false,
          page: 1,
          creatorId: '',
          shared: true,
          order: rehabListOrderParams['shared'][2],
          tabId: 1
        })
      } else if(filterRehabId === 2) {
        setPayloadObj({
          keyword: '',
          tags: '',
          isBookmark: false,
          page: 1,
          creatorId: staff.id,
          shared: false,
          order: rehabListOrderParams['my'][2],
          tabId: 2
        })
      } else {
        setPayloadObj({
          keyword: '',
          tags: '',
          isBookmark: false,
          page: 1,
          creatorId: '',
          shared: false,
          order: rehabListOrderParams['ex'][2],
          tabId: 3
        })
      }
    }
  }

  const getProgramList = () => {
    setIsLoading(true)

    let defaultOrder = ''
    if(!payloadObj.order) {
      if(activeFilterRehabId === 0) {
        defaultOrder = rehabListOrderParams['recommended'][2]
      } else if(activeFilterRehabId === 1) {
        defaultOrder = rehabListOrderParams['shared'][2]
      } else if(activeFilterRehabId === 2) {
        defaultOrder = rehabListOrderParams['my'][2]
      } else {
        defaultOrder = rehabListOrderParams['ex'][2]
      }
    }

    api.getPrograms(payloadObj.keyword, payloadObj.tags, payloadObj.isBookmark, payloadObj.page, payloadObj.order ? payloadObj.order : defaultOrder, payloadObj.creatorId, payloadObj.shared).then((res) => {
      let data = res.data
      const programList = data.list
      if(programList.length) {
        programList.forEach((program) => {
          if(bookmarkProgram.includes(program.pid)) {
            program.isBookmark = true
          } else {
            program.isBookmark = false
          }
        })
      }
      setProgramList(programList)
      setTotalPage(data.totalPage)
      setFilterNumbersObj(data.extra.count)
      setCurrentPage(data.currentPage)
      getTotalElement(data.totalElement)
      setIsLoading(false)
    }).catch((e) => {
      console.log(e)
    })
  }

  const toggleAllItemCheck = () => {
    let newAddedProgramExerciseArr = JSON.stringify(addedProgramExerciseArr)
    newAddedProgramExerciseArr = JSON.parse(newAddedProgramExerciseArr)

    if(isAllItemChecked) {
      newAddedProgramExerciseArr.forEach((addedExercise) => {
        addedExercise.isChecked = false
        setIsAllItemChecked(false)
      })
    } else {
      newAddedProgramExerciseArr.forEach((addedExercise) => {
        addedExercise.isChecked = true
        setIsAllItemChecked(true)
      })
    }

    dispatch(setAddedProgramExerciseArr([...newAddedProgramExerciseArr]))
  }

  const copyExercise = () => {
    let exerciseArr = []
    let exerciseArrToCopy = []
    let newAddedProgramExerciseArr = JSON.stringify(addedProgramExerciseArr)
    newAddedProgramExerciseArr = JSON.parse(newAddedProgramExerciseArr)

    newAddedProgramExerciseArr.forEach((newAddedExercise) => {
      if(newAddedExercise.isChecked) {
        exerciseArr.push(newAddedExercise)
        exerciseArrToCopy.push(newAddedExercise)
        newAddedExercise.isChecked = false
      } else {
        exerciseArr.push(newAddedExercise)
      }
    })

    // deep copy
    let tempExerciseArrToCopy = JSON.stringify(exerciseArrToCopy)
    exerciseArrToCopy = JSON.parse(tempExerciseArrToCopy)
    newAddedProgramExerciseArr = exerciseArr.concat(exerciseArrToCopy)

    newAddedProgramExerciseArr.forEach((addedExercise, idx) => {
      addedExercise.unique = Date.now() + idx
    })

    dispatch(setAddedProgramExerciseArr(newAddedProgramExerciseArr))
    setIsAllItemChecked(false)
  }

  const deleteExercise = () => {
    setIsDeleteExercise(true)
    let newAddedProgramExerciseArr = JSON.stringify(addedProgramExerciseArr)
    newAddedProgramExerciseArr = JSON.parse(newAddedProgramExerciseArr)
    const outcome = newAddedProgramExerciseArr.filter((newAddedExercise) => !newAddedExercise.isChecked)
    newAddedProgramExerciseArr = outcome
    dispatch(setAddedProgramExerciseArr(newAddedProgramExerciseArr))
    setIsAllItemChecked(false)
    if(!newAddedProgramExerciseArr.length) {
      setIsEditActive(false)
    }
  }

  const toggleExCheck = (unique) => {
    let newAddedProgramExerciseArr = JSON.stringify(addedProgramExerciseArr)
    newAddedProgramExerciseArr = JSON.parse(newAddedProgramExerciseArr)
    let selectedEx = newAddedProgramExerciseArr.find((item) => item.unique === unique)

    selectedEx.isChecked = !selectedEx.isChecked

    if(isAllItemChecked && !selectedEx.isChecked) {
      setIsAllItemChecked(false)
    } else if(!isAllItemChecked && selectedEx.isChecked) {
      let isAllChecked = true
      newAddedProgramExerciseArr.forEach((newAddedExercise) => {
        if(!newAddedExercise.isChecked) {
          isAllChecked = false
        }
      })
      setIsAllItemChecked(isAllChecked)
    }
    dispatch(setAddedProgramExerciseArr([...newAddedProgramExerciseArr]))
  }

  const handleDragStart = () => {
    setIsDragging(true)
  }
  const handleDragEnd = (e) => {
    setIsDragging(false)

    const { active, over } = e

    if (active.id !== over.id) {
      let newAddedProgramExerciseArr = JSON.stringify(addedProgramExerciseArr)
      newAddedProgramExerciseArr = JSON.parse(newAddedProgramExerciseArr)

      const oldIndex = newAddedProgramExerciseArr.findIndex((item) => (item.unique === active.id))
      const newIndex = newAddedProgramExerciseArr.findIndex((item) => (item.unique === over.id))

      dispatch(setAddedProgramExerciseArr(arrayMove(newAddedProgramExerciseArr, oldIndex, newIndex)))
    }
  }

  const getExerciseList = () => {
    let allTags = payloadObj.tags
    let objectives = []
    let positions = []
    let levels = []
    let tags = []

    if(allTags) {
      allTags = allTags.split(',')

      allTags.forEach((tag) => {
        if(tag.includes('OB')) {
          objectives.push(tag)
        } else if(tag.includes('PO')) {
          positions.push(tag)
        } else if(tag.includes('LV')) {
          levels.push(tag.replace('LV', ''))
        } else {
          tags.push(tag)
        }
      })
    }

    setIsLoading(true)
    api.getExercises(payloadObj.keyword, objectives.join(','), positions.join(','), levels.join(','), tags.join(','), payloadObj.isBookmark, payloadObj.page, payloadObj.order)
      .then((res) => {
        let data = res.data
        const exerciseList = data.list
        if(exerciseList.length) {
          exerciseList.forEach((exercise) => {
            if(bookmarkExercise.includes(exercise.id)) {
              exercise.isBookmark = true
            } else {
              exercise.isBookmark = false
            }
          })
        }

        setExerciseList([...exerciseList])
        getTotalElement(data.totalElement)
        setTotalPage(data.totalPage)
        setFilterNumbersObj(data.extra.count)
        setCurrentPage(data.currentPage)
        setIsLoading(false)
      }).catch((e) => {
      console.log(e)
    })
  }

  const getCheckedItemCount = () => {
    let result = 0
    addedProgramExerciseArr.forEach((addedExercise) => {
      if(addedExercise.isChecked) {
        result++
      }
    })
    return result
  }

  const onProgramTitleChange = (e) => {
    dispatch(setProgramCreationParams({
      ...programCreationParams,
      name: e.target.value
    }))
  }

  const onProgramTitleBlur = () => {
    setIsProgramTitleFocus(false)
  }

  const onDiagnosisBlur = () => {
    setIsDiagnosisFocus(false)
  }

  const onPhaseBlur = () => {
    setIsPhaseFocus(false)
  }

  const resetProgramTitle = () => {
    dispatch(setProgramCreationParams({
      ...programCreationParams,
      name: ''
    }))
  }

  const resetDiagnosis = () => {
    dispatch(setProgramCreationParams({
      ...programCreationParams,
      diagnosis: ''
    }))
  }

  const resetPhase = () => {
    dispatch(setProgramCreationParams({
      ...programCreationParams,
      phase: ''
    }))
  }

  const toggleBodyPartList = () => {
    if(activeFilterRehabId === 3) {
      setBodyPartListToggle(!bodyPartListToggle)
    }
  }

  const onDiagnosisChange = (e) => {
    dispatch(setProgramCreationParams({
      ...programCreationParams,
      diagnosis: e.target.value
    }))
  }

  const onPhaseChange = (e) => {
    dispatch(setProgramCreationParams({
      ...programCreationParams,
      phase: e.target.value
    }))
  }

  const activeEdit = () => {
    if(!addedProgramExerciseArr.length) {
      return
    }
    setIsEditActive(!isEditActive)

    let newAddedProgramExerciseArr = JSON.stringify(addedProgramExerciseArr)
    newAddedProgramExerciseArr = JSON.parse(newAddedProgramExerciseArr)

    newAddedProgramExerciseArr.forEach((item) => {
      item.isChecked = false
    })
    dispatch(setAddedProgramExerciseArr(newAddedProgramExerciseArr))

    setIsAllItemChecked(false)

    let timeCount = 0
    addedProgramExerciseArr.forEach((ex) => {
      timeCount += (ex.media[`M${ex.time ? ex.time : ex.count}`].length * ex.repeat)
    })

    setProgramExerciseInfo({
      ...programExerciseInfo,
      timeCount: setMinutes(timeCount)
    })
  }

  const getSelectedBodyPartId = (idArr) => {
    let tagArr = []
    let newTagIdArr = []
    idArr.forEach((id) => {
      tagArr.push(programBodyPartList[id].tag)
      newTagIdArr.push(id)
    })
    setTagIdArr(newTagIdArr)
    dispatch(setProgramCreationParams({
      ...programCreationParams,
      tags: tagArr
    }))
  }

  // const getToolCountStr = () => {
  //   let result = '- 개'
  //
  //   if(addedProgramExerciseArr.length && programExerciseInfo.toolCount) {
  //     let firstExIdxHasTool = 0
  //     let toolTag = ''
  //
  //     if(addedProgramExerciseArr[0].tag) {
  //       firstExIdxHasTool = addedProgramExerciseArr.findIndex(i => i.tag['TO'])
  //       toolTag = addedProgramExerciseArr[firstExIdxHasTool].tag.TO[0]
  //     } else if(addedProgramExerciseArr[0].exerciseTags) {
  //       firstExIdxHasTool = addedProgramExerciseArr.findIndex(i => i.exerciseTags['TO'])
  //       toolTag = addedProgramExerciseArr[firstExIdxHasTool].exerciseTags.TO[0]
  //     }
  //
  //     if(programExerciseInfo.toolCount !== 1) {
  //       result = `${tagKor['TO'][toolTag]} 외 ${programExerciseInfo.toolCount - 1}개`
  //     } else {
  //       result = `${tagKor['TO'][toolTag]}`
  //     }
  //   }
  //
  //   return result
  // }


  const getMainPartFirstValue = () => {
    if(!programCreationParams.tags.length) {
      return '운동 부위를 선택해 주세요(필수)'
    }
  }

  const getMuKeyword = (keyword) => {
    setMuKeyword(keyword)
    setFilterMuList(filterAllList.MU)

    if(keyword) {
      setIsSearched(true)
      onMuSearch()
    } else {
      setIsSearched(false)
    }
  }

  const onMuSearch = () => {
    let newFilterMuList = []

    let muKeysArr = Object.keys(tagKor.MU)
    let muNameAllArr = Object.values(tagEng.MU).concat(Object.values(tagKor.MU))
    muNameAllArr.forEach((muName) => {
      if(muName.toLowerCase().split(' ').join('').includes(muKeyword.toLowerCase().split(' ').join(''))) {
        let matchedTag = muKeysArr.find(key => tagKor.MU[key] === muName || tagEng.MU[key] === muName)
        filterAllList.MU.forEach((filterMu) => {
          if(matchedTag === filterMu.tag) {
            newFilterMuList.push(filterMu)
          }
        })
      }
    })

    setFilterMuList(newFilterMuList)
  }

  useEffect(() => {
    if(activeFilterRehabId === 3) {
      getExerciseList()
    } else {
      getProgramList()
    }
  }, [payloadObj])

  useEffect(() => {
    let filterSections = {}
    Object.keys(filterNumbersObj).forEach((filterTitle, idx) => {
      filterSections = {
        ...filterSections,
        [filterTitle]: []
      }
      Object.keys(tagKor[filterTitle]).forEach((tag) => {
        filterSections[filterTitle].push({
          tag: tag,
          tagName: tagKor[filterTitle][tag],
          count: 0
        })
      })
    })
    addFilterNumbers(filterSections)
  }, [programList, exerciseList])

  useEffect(() => {
    if(isMyProgramsUpdate) {
      getProgramList()
      dispatch(setIsMyProgramsUpdate(false))
    }
  }, [isMyProgramsUpdate])

  useEffect(() => {
    if(!isDeleteExercise) {
      if(addedProgramExerciseArr.length > 0) {
        moveExerciseContainerBottom()
      }
    } else {
      setIsDeleteExercise(false)
    }
    getProgramExerciseInfo()
  }, [addedProgramExerciseArr.length])

  useEffect(() => {
    let newAddedProgramExerciseArr = JSON.stringify(addedProgramExerciseArr)
    newAddedProgramExerciseArr = JSON.parse(newAddedProgramExerciseArr)

    if(isAllItemChecked) {
      newAddedProgramExerciseArr.forEach((newAddedExercise) => {
        newAddedExercise.isChecked = true
      })
    }

    dispatch(setAddedProgramExerciseArr([...newAddedProgramExerciseArr]))

  }, [isAllItemChecked])

  useEffect(() => {
    let bodyPartArr = Object.entries(tagKor['MP'])
    let newBodyPartArr = []
    bodyPartArr.forEach((bodyPart, idx) => {
      newBodyPartArr.push({
        id: idx,
        tag: bodyPart[0],
        txt: bodyPart[1],
      })
    })
    setProgramBodyPartList(newBodyPartArr)
    if(programCreationParams.tags.length && newBodyPartArr.length) {
      let newTagIdArr = []
      programCreationParams.tags.forEach((tag) => {
        newBodyPartArr.forEach((bodyPart) => {
          if(tag === bodyPart.tag) {
            newTagIdArr.push(bodyPart.id)
          }
        })
      })

      setTagIdArr(newTagIdArr)
    }
  }, [activeFilterRehabId])

  useEffect(() => {
    // position: sticky 작동하려면 body에 걸려있는 overflow hidden 풀어줘야함
    let body = document.getElementsByTagName('body')[0]
    body.style.overflowX = 'visible'
    return () => body.style.overflowX = 'hidden'
  }, []);

  return (
    <div className={cx('programExerciseSelect')}>
      { isLoading ? (
        <ExerciseSelectSkeleton/>
      ) : (
        <>
          <div className={cx('leftWrapper')}>
            <div className={cx('exerciseBox')}>
              <div className={cx('filterContainer')}>
                <div className={cx('rehabFilter')}>

                  <LeftTabs tabItems={ filterRehabList } activeTabId={activeFilterRehabId} onTabItemClick={selectFilterRehab}/>
                </div>
                <div className={cx('bodyPartFilter')}>
                  <div className={cx('bodyPartTitle')}>
                    { filterRehabList[activeFilterRehabId] && (
                      <p>필터({ numberWithCommas(filterRehabList[activeFilterRehabId].count) }) </p>
                    ) }
                    <div className={cx('reset', (isBookmarkActive || selectedFilterItems.length) && 'active')} onClick={clearFilter}>
                      <div></div>
                      <span>초기화</span>
                    </div>
                  </div>
                  <div className={cx('bookmark')}>
                    <div className={cx('bookmarkTitle')}>
                      <img src={isBookmarkActive ? YNewStar : GNewStar} alt='GNewStar'/>
                      <span>즐겨찾기</span>
                    </div>
                    <div className={cx('toggle')}>
                      <label className={cx('switch')}>
                        <input
                          onChange={onBookmarkClick}
                          type="checkbox"
                          checked={isBookmarkActive}
                        />
                        <span className={cx('slider', 'round')}></span>
                      </label>
                    </div>
                  </div>
                  <div className={cx('filterWrapper')}>
                    <div className={cx('filterItem')} onClick={toggleBodyPartList}>
                      <span>{tagKor['title']['MP']}</span>
                      { activeFilterRehabId === 3 ? (
                        <img className={cx(bodyPartListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                      ) : ''}
                    </div>
                    { bodyPartListToggle && (
                      <div className={cx('bodyList')}>
                        { Object.keys(filterAllList).length ? (
                          filterAllList['MP'].map((item) => (
                            <div
                              className={cx(
                                'filterItemBox',
                                selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                !item.count && 'displayNone'
                              )}
                              key={item.tag}
                            >
                              <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                <div></div>
                                <span>{ item.tagName }</span>
                              </div>
                              <span>{ numberWithCommas(item.count)  }</span>
                            </div>
                          ))
                        ) : ''}
                      </div>
                    ) }
                  </div>
                  { activeFilterRehabId === 3 ? (
                    <>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setAPListToggle(!apListToggle)}>
                          <span>{tagKor['title']['AP']}</span>
                          <img className={cx(apListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { apListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['AP'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.AP[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setETListToggle(!etListToggle)}>
                          <span>{tagKor['title']['ET']}</span>
                          <img className={cx(etListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { etListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['ET'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.ET[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setKCListToggle(!kcListToggle)}>
                          <span>{tagKor['title']['KC']}</span>
                          <img className={cx(kcListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { kcListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['KC'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.KC[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setLVListToggle(!lvListToggle)}>
                          <span>{tagKor['title']['LV']}</span>
                          <img className={cx(lvListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { lvListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['LV'].map((item, idx) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span className={cx('lvTxt', lvArr[idx].color)}>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('muFilterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setMUListToggle(!muListToggle)}>
                          <span>{tagKor['title']['MU']}</span>
                          <img className={cx(muListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        <div className={cx('muFilterList')}>
                          { muListToggle && (
                            <>
                              <div className={cx('muInput')}>
                                <InputSearchBox
                                  placeholder='검색어 입력'
                                  getKeyword={getMuKeyword}
                                  keyword={muKeyword}
                                  onSearch={null}
                                  isSmall={true}
                                />
                              </div>
                              <div className={cx('bodyList')}>
                                { Object.keys(tagKor['MUPartSort']).map((muPart, idx) => (
                                  <div className={cx('muPartWrapper')} key={idx}>
                                    { !isSearched ? (
                                      <>
                                        <p key={muPart} className={cx('muPart')}>{ tagKor['MUPart'][muPart] }</p>
                                        { Object.keys(filterAllList).length ? (
                                          filterAllList['MU'].map((item, idx) => (
                                            tagKor['MUPartSort'][muPart][item.tag] && (
                                              <div
                                                className={cx(
                                                  'filterItemBox',
                                                  selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                                  !item.count && 'displayNone'
                                                )}
                                                key={idx}
                                              >
                                                <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                                  <div className={cx('marginBottom')}></div>
                                                  <span>{tagEng.MU[item.tag]}<br/><span>{ item.tagName }</span></span>
                                                </div>
                                                <span>{ numberWithCommas(item.count)  }</span>
                                              </div>
                                            )
                                          ))
                                        ) : ''}
                                      </>
                                    ) : (
                                      filterMuList.map((item, idx) => (
                                        tagKor['MUPartSort'][muPart][item.tag] && (
                                          <div
                                            className={cx(
                                              'filterItemBox',
                                              selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                              !item.count && 'displayNone'
                                            )}
                                            key={idx}
                                          >
                                            <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                              <div className={cx('marginBottom')}></div>
                                              <span>{tagEng.MU[item.tag]}<br/><span>{ item.tagName }</span></span>
                                            </div>
                                            <span>{ numberWithCommas(item.count)  }</span>
                                          </div>
                                        )
                                      ))
                                    )}

                                  </div>
                                )) }
                              </div>
                            </>
                          ) }
                        </div>

                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setOBListToggle(!obListToggle)}>
                          <span>{tagKor['title']['OB']}</span>
                          <img className={cx(obListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { obListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['OB'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setPOListToggle(!poListToggle)}>
                          <span>{tagKor['title']['PO']}</span>
                          <img className={cx(poListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { poListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['PO'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setSDListToggle(!sdListToggle)}>
                          <span>{tagKor['title']['SD']}</span>
                          <img className={cx(sdListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { sdListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['SD'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.SD[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setTOListToggle(!toListToggle)}>
                          <span>{tagKor['title']['TO']}</span>
                          <img className={cx(toListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { toListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['TO'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setWBListToggle(!wbListToggle)}>
                          <span>{tagKor['title']['WB']}</span>
                          <img className={cx(wbListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { wbListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['WB'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName} <br/><span>{tagEng.WB[item.tag]}</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                    </>
                  ) : '' }
                </div>
              </div>
              <div className={cx('listContainer')}>
                <div className={cx('listContainerTop')}>
                  <div className={cx('inputBox')}>
                    <InputSearchBox
                      placeholder='검색어 입력'
                      getKeyword={getKeyword}
                      keyword={keyword}
                      onSearch={onSearch}
                      isSmall={true}
                    />
                  </div>
                  <div className={cx('dropdownWrapper')}>
                    <DropdownOrder
                      orderList={exerciseOrderList}
                      isDropdownOrderOpen={isDropdownExerciseOrderOpen}
                      setSelectedOrderId={getSelectedExerciseOrderId }
                      selectedOrderId={selectedExerciseOrderId}
                      setIsDropdownOrderOpen={() => setIsDropdownExerciseOrderOpen(!isDropdownExerciseOrderOpen)}
                      listPosition='right'
                    />
                  </div>
                </div>
                <div className={cx('listWrapper')}>
                  <div className={cx('exerciseList')}>
                    { activeFilterRehabId === 3 ? (
                      exerciseList.map((exercise, idx) => (
                        <ExerciseCard exercise={exercise} key={idx}/>
                      ))
                    ) : programList.map((program) => (
                      <ProgramCard key={program.pid} program={program}/>
                    ))}
                  </div>
                  { ((activeFilterRehabId === 3 && !exerciseList.length) || (activeFilterRehabId !== 3 && !programList.length)) && (
                    isBookmarkActive ? (
                      <div className={cx('empty')}>
                        <img src={EmptyBookmark} alt='EmptyBookmark'/>
                        <p>자주 사용하거나 열람하는 컨텐츠를<br/>추가해 빠르게 찾아보세요:)</p>
                      </div>
                    ) : (
                      <div className={cx('empty')}>
                        <img src={EmptyResult} alt='EmptyResult'/>
                        <p>원하시는 결과를 찾지 못했어요 :(<br/>다시 검색해서 찾아보세요.</p>
                      </div>
                    )
                  )}
                  { totalPage > 1 ? (
                    <Pagination key={totalPage} currentPage={currentPage} totalPage={totalPage} getList={movePage}/>
                  ) : ''}
                </div>
              </div>
            </div>
          </div>
          <div className={cx('rightWrapper')} >
            { isShowAddedExerciseList ? (
              <div className={cx('floatContainer', isEditActive && 'blackBorder')}>
                <div className={cx('addedExerciseTop')}>
                  { isEditActive ? (
                    <>
                      <p className={cx('addedExerciseInfo')}>편집</p>
                      <button className={cx(!addedProgramExerciseArr.length && 'inactive')} onClick={() => activeEdit()}>완료</button>
                    </>
                  ) : (
                    <>
                      <div className={cx('arrow')} onClick={() => setIsShowAddedExerciseList(false)}></div>
                      <p className={cx('addedExerciseInfo')}>{programExerciseInfo.timeCount }분<span>|</span>{ programExerciseInfo.exerciseCount ? programExerciseInfo.exerciseCount : '- ' }개 동작</p>
                      <button className={cx(!addedProgramExerciseArr.length && 'inactive')} onClick={() => activeEdit()}>편집</button>
                    </>
                  )}
                </div>
                <div className={cx('addedExerciseList', addedProgramExerciseArr.length && isEditActive && 'scrollHidden', addedProgramExerciseArr.length > 7 && 'scroll')} ref={(ele) => {refs.current[0] = ele}}>
                  { addedProgramExerciseArr.length && !isEditActive ? (
                    <div className={cx('exItemWrapper')}>
                      { addedProgramExerciseArr.map((addedExercise, idx) => (
                        <div className={cx('addedExerciseItem')} key={idx}>
                          <div className={cx('grayBox')}></div>
                          <img src={(addedExercise.urls ? addedExercise.urls.IT : addedExercise.media.IT.url) + imgSize['90']} alt='exImg'/>
                          <div className={cx('addedExerciseContent')}>
                            <p>
                              { addedExercise.name }
                              <span className={cx('tooltiptext', addedExercise.name.length > 25 && 'long')}>{ addedExercise.name }</span>
                            </p>
                            <div className={cx('exCount')}>
                              <span className={cx('unit')}>
                                { addedExercise.time ? addedExercise.time + '초' : addedExercise.count + '회' }
                                <span>X</span>
                                { addedExercise.repeat }세트
                              </span>
                            </div>
                          </div>
                        </div>
                      )) }
                    </div>
                  ) : ''}
                  { !addedProgramExerciseArr.length ? (
                    <div className={cx('emptyAddedExercise')}>
                      <p>프로그램 또는 운동을 선택해 주세요.</p>
                    </div>
                  ) : ''}
                  { addedProgramExerciseArr.length && isEditActive ? (
                    <>
                      <div className={cx('editMenu')}>
                        <div className={cx('itemSelect')}>
                          <img src={isAllItemChecked ? GCheckCircleFull : GCheckCircle} onClick={() => toggleAllItemCheck()} alt='GCheckCircle'/>
                          <span>{ getCheckedItemCount() }개 항목</span>
                        </div>
                        <div className={cx('editBtns', getCheckedItemCount() > 0 && 'active')}>
                          <button className={cx('btnCopy')} onClick={copyExercise}>복사</button>
                          <button className={cx('btnDelete')} onClick={deleteExercise}>삭제</button>
                        </div>
                      </div>
                      <div className={cx('editListContainer', isDragging && 'scrollHidden', addedProgramExerciseArr.length > 7 && 'scroll')} ref={(ele) => {refs.current[1] = ele}}>
                        <DndContext
                          sensors={sensors}
                          collisionDetection={closestCenter}
                          onDragEnd={handleDragEnd}
                          onDragStart={handleDragStart}
                        >
                          <SortableContext
                            items={addedProgramExerciseArr.map(item => item.unique)}
                            strategy={verticalListSortingStrategy}
                          >
                            { addedProgramExerciseArr.map((item) => (
                              <ExerciseDragItem
                                item={item}
                                key={item.unique}
                                id={item.unique}
                                toggleExCheck={toggleExCheck}
                              />
                            )) }
                          </SortableContext>
                        </DndContext>
                      </div>
                    </>
                  ): ''}
                </div>
              </div>
            ) : (
              <div className={cx('floatContainer')}>
                <p className={cx('programInfo')}>프로그램 정보</p>
                <div className={cx('programInfoDetail')}>
                  <DropdownMultipleChoice
                    choiceList={programBodyPartList}
                    isDropdownMultipleChoiceOpen={isDropdownProgramBodyPartOpen}
                    setSelectedChoiceIdArr={getSelectedBodyPartId }
                    selectedChoiceIdArr={tagIdArr}
                    setIsDropdownMultipleChoiceOpen={() => setIsDropdownProgramBodyPartOpen(!isDropdownProgramBodyPartOpen)}
                    firstValue={ getMainPartFirstValue() }
                  />
                  <div className={cx('programInput')}>
                    <input
                      className={cx(isProgramTitleFocus && 'borderPrimary', programCreationParams.name.length && isProgramTitleFocus && 'paddingRight')}
                      placeholder='프로그램명을 입력해 주세요'
                      type='text'
                      value={programCreationParams.name}
                      maxLength={30}
                      onChange={onProgramTitleChange}
                      onFocus={() => setIsProgramTitleFocus(true)}
                      onBlur={() => onProgramTitleBlur()}
                    />
                    { programCreationParams.name.length && isProgramTitleFocus ? (
                      <img src={CloseRound} onMouseDown={() => resetProgramTitle()} alt='CloseRound'/>
                    ) : ''}
                    { isProgramTitleFocus && (
                      <span>{ programCreationParams.name.length }/30</span>
                    ) }

                  </div>
                  <div className={cx('programInput')}>
                    <input
                      className={cx('inputDiagnosis', isDiagnosisFocus && 'borderPrimary', programCreationParams.diagnosis.length && isDiagnosisFocus && 'paddingRight')}
                      placeholder='질환 및 수술명을 입력해 주세요'
                      type='text'
                      value={programCreationParams.diagnosis}
                      onChange={onDiagnosisChange}
                      onFocus={() => setIsDiagnosisFocus(true)}
                      onBlur={() => onDiagnosisBlur()}
                    />
                    { programCreationParams.diagnosis.length && isDiagnosisFocus ? (
                      <img src={CloseRound} onMouseDown={() => resetDiagnosis()} alt='CloseRound'/>
                    ) : ''}
                  </div>
                  <div className={cx('programInput')}>
                    <input
                      className={cx(isPhaseFocus && 'borderPrimary', programCreationParams.phase.length && isPhaseFocus && 'paddingRight')}
                      placeholder='단계를 입력해 주세요'
                      type='text'
                      value={programCreationParams.phase}
                      onChange={onPhaseChange}
                      onFocus={() => setIsPhaseFocus(true)}
                      onBlur={() => onPhaseBlur()}
                    />
                    { programCreationParams.phase.length && isPhaseFocus ? (
                      <img src={CloseRound} onMouseDown={() => resetPhase()} alt='CloseRound'/>
                    ) : ''}
                  </div>

                </div>
                <p className={cx('exerciseInfo')}>
                  운동 정보
                  <button className={cx('detail', programExerciseInfo.exerciseCount && 'active')} onClick={() => checkAddedExercise()}>운동 구성</button>
                </p>
                <div className={cx('count')}>
                  <img src={GTimer20} alt='GTime20'/>
                  <span>{ programExerciseInfo.timeCount ? programExerciseInfo.timeCount : '-' } 분</span>
                </div>
                <div className={cx('count')}>
                  <img src={GExercise} alt='GExercise'/>
                  {/*<span>*/}
                  {/*  { addedProgramExerciseArr.length && programExerciseInfo.exerciseCount ?*/}
                  {/*    addedProgramExerciseArr.length !== 1 ? `${addedProgramExerciseArr[0].name} 외 ${programExerciseInfo.exerciseCount - 1}개` : `${addedProgramExerciseArr[0].name}` :*/}
                  {/*    '- 개'*/}
                  {/*  }*/}
                  {/*</span>*/}
                  <span>{ programExerciseInfo.exerciseCount ? programExerciseInfo.exerciseCount : '- ' }개 동작</span>
                </div>
                <div className={cx('count')}>
                  <img src={GTool} alt='GTool'/>
                  {/*<span>{ getToolCountStr() }</span>*/}
                  <span>{ programExerciseInfo.toolsKor ? programExerciseInfo.toolsKor : '-' }</span>
                </div>
                <div className={cx('btnWrapper')}>
                  <button className={cx('next', isNextBtnActive() && 'active')} onClick={() => goToNextStep()}>다음</button>
                </div>
              </div>
            )}
          </div>
        </>
      ) }
    </div>
  );
};

export default ProgramExerciseSelect;