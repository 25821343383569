import React, {useEffect, useState} from 'react';
import ButtonPrimary from "../../../common/button/buttonPrimary/buttonPrimary";
import styles from './planCard.module.scss'
import classnames from "classnames/bind";
import tagKor from '@/data/tagKor'
import ModalPlanDetail from "../../../common/modal/modalPlanDetail/modalPlanDetail";
import JobIcon from "../../../common/jobIcon/jobIcon";
import {showToast} from "../../../../helpers/toastMessage";
import {planDate} from "../../../../helpers/common";
import {setPlanProgramSelected} from "../../../../store/slice/planSlice";
import {useDispatch, useSelector} from "react-redux";

const GCalendar = `${process.env.REACT_APP_RESOURCE_URL}/G_calendar.svg`

const cx = classnames.bind(styles)
const PlanCard = ({plan, planSelected, setPlanSelected}) => {
  const dispatch = useDispatch()

  const planAssignmentParams = useSelector(state => {
    return state.plan.value.planAssignmentParams
  })

  const planProgramSelected = useSelector(state => {
    return state.plan.value.planProgramSelected
  })

  const [isModalPlanDetailOpen, setIsModalPlanDetailOpen] = useState(false)

  const openPlanDetailModal = (e) => {
    if(e.target.className.includes('buttonPrimary') || e.target.className.includes('primaryBtnIcon') || e.target.className.includes('bookmark')) {
      return
    }
    setIsModalPlanDetailOpen(true)
  }

  const selectPlan = (plan) => {
    if(Object.keys(planSelected).length > 0) {
      return
    }
    setPlanSelected(plan)
    dispatch(setPlanProgramSelected({
      ...plan,
      originalPlanName: plan.planName,
    }))

    showToast('success', '플랜이 선택되었습니다.')
  }

  useEffect(() => {
    if(planAssignmentParams.name) {
      setPlanSelected(planProgramSelected)
    }
  }, [])

  return (
    <div className={cx('planCard')} key={plan.planId} onClick={(e) => openPlanDetailModal(e)}>
      <div className={cx('planContent')}>
        <p className={cx('planTitle')}>
          <span className={cx('title')}>{ plan.planName }</span>
          <span className={cx('date')}>{ planDate(plan.planCreatedDateTime) }</span>
        </p>
        <div className={cx('info')}>
          <div className={cx('detail')}>
            <span className={cx('mp')}>{ tagKor['MP'][plan.mainPart] }</span>
            <div className={cx('patient')}>
              <span>{ plan.patientName }</span>
              { plan.patientGender && <div></div> }
              <span>{ plan.patientGender }</span>
              { plan.patientBirth && <div></div> }
              <span>{ plan.patientBirth }</span>
            </div>
            <div className={cx('staff')}>
              <JobIcon job={plan.medicalStaffJob} isCertified={plan.isCertified}/>
              <span>{ plan.staffName }</span>
            </div>
          </div>
          <div className={cx('dates')}>
            <img src={GCalendar} alt='dates'/>
            <span>총 { plan.planTotalPlanDays }일</span>
          </div>
        </div>
      </div>
      <ButtonPrimary
        text='선택하기'
        onBtnPrimaryClick={() => selectPlan(plan)}
        height={41}
        width={100}
        font='small'
        btnDisabled={Object.keys(planSelected).length !== 0}
      />
      { isModalPlanDetailOpen && (
        <ModalPlanDetail
          planId={plan.planId}
          isModalPlanDetailOpen={isModalPlanDetailOpen}
          setIsModalPlanDetailOpen={setIsModalPlanDetailOpen}
        />
      ) }

    </div>
  );
};

export default PlanCard;