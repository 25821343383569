import React, {useEffect, useState} from 'react';
import Calendar from "react-calendar";
import styles from './calendarSmall.module.scss'
import './calendarSmall.css'
import classnames from "classnames/bind";
import dayjs from 'dayjs';
import {getDayKor} from "../../../../helpers/common";
import {useLocation} from "react-router-dom";
import api from "../../../../utils/api";
import {useDispatch} from "react-redux";
import {setSelectedPlanStartDateTimestamp} from "../../../../store/slice/planSlice";
import {showToast} from "../../../../helpers/toastMessage";

const cx = classnames.bind(styles);
const date = new Date();
const today = {
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  date: date.getDate(),
}

const CalendarSmall = ({ planTotalPlanDays, planDate, setPlanDate, planSelected, isCalendarOpen, setIsCalendarOpen }) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [value, onChange] = useState(new Date());
  const [plansDisabledDates, setPlansDisabledDates] = useState([])
  const [dateThreeMonthLater, setDateThreeMonthLater] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const getDisableDates = (date) => {
    if(!date) {
      return
    }
    let isPlanDisableDay = false // 같은 부위에 이미 배정된 날짜
    let isLaterThanThreeMonth = false // 3개월 이상 지난 날짜

    let yyyy = date.getFullYear().toString()
    let mm = ('0' + (date.getMonth() + 1)).slice(-2).toString()
    let dd = ('0' + date.getDate()).slice(-2).toString()

    let startDate = `${yyyy}${mm}${dd}`

    if(plansDisabledDates.length) {
      if(plansDisabledDates.includes(startDate)) {
        isPlanDisableDay = true
      }
    }

    if(Number(dateThreeMonthLater) < Number(startDate)) {
      isLaterThanThreeMonth = true
    }

    if(isPlanDisableDay) {
      return 'disableCircle'
    } else if(isLaterThanThreeMonth) {
      return 'disable'
    }
  }


  const getPlanList = () => {
    setIsLoading(true)
    let patientId = location.pathname.split('/')[3]
    api.getPlanList(patientId).then((res) => {
      setIsLoading(false)
      let data = res.data

      let disableDates = []
      if(data.length) {
        let planList = Object.values(data)
        planList.forEach((plan) => {
          if(planSelected.mainPart === plan.mainPart) {
            if(plan.status === 'RELEASE' || plan.status === 'IN_PROGRESS') {
              disableDates = disableDates.concat(plan.date)
            }
          }
        })
      }
      disableDates = [...new Set(disableDates)]
      setPlansDisabledDates(disableDates)
    }).catch((e) => {
      console.log(e)
    })
  }

  const getDateThreeMonthLater = () => {
    let todayMilli = new Date(`${today.year}-${today.month.toString().padStart(2, '0')}-${today.date.toString().padStart(2, '0')}`).getTime()
    let threeMonthLater = todayMilli + 7776000000

    let date = new Date(threeMonthLater)
    let year = date.getFullYear()
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let day = ("0" + date.getDate()).slice(-2)

    setDateThreeMonthLater(`${year}${month}${day}`)
  }

  const onClickDay = (value, event) => {
    if(event.target.className.includes('react-calendar__tile') || event.target.tagName === 'ABBR') {
      let copiedDate = new Date(value.getTime())
      let isValidDate = true

      for(let i = 0; planTotalPlanDays > i; i++) {
        if(isValidDate) {
          let planDate = null
          if(i === 0) {
            planDate = new Date(copiedDate.setDate(copiedDate.getDate()))
          } else {
            planDate = new Date(copiedDate.setDate(copiedDate.getDate() + 1))
          }

          let yyyy = planDate.getFullYear().toString()
          let mm = ('0' + (planDate.getMonth() + 1)).slice(-2).toString()
          let dd = ('0' + planDate.getDate()).slice(-2).toString()
          let planDateStr = `${yyyy}${mm}${dd}`

          if(plansDisabledDates.includes(planDateStr)) {
            showToast('error', '이미 배정된 플랜이 있는 날짜가 포함되어 선택이 불가능해요.')
            isValidDate = false
          }
        }
      }

      if(isValidDate) {
        setDateRange(value)
        onChange(value)
        setIsCalendarOpen(false)
      }
    }
  }

  const setDateRange = (value) => {
    dispatch(setSelectedPlanStartDateTimestamp(value.getTime()))

    let tempValue = new Date(value.getTime())
    let yy = tempValue.getFullYear().toString().slice(-2)
    let mm = ('0' + (tempValue.getMonth() + 1)).slice(-2)
    let dd = ('0' + tempValue.getDate()).slice(-2)
    let day = getDayKor(tempValue.getDay())

    let startDate = `${yy}.${mm}.${dd}(${day})`

    let endDateObj = new Date(tempValue.setDate(tempValue.getDate() + (planTotalPlanDays - 1)))

    let endYY = endDateObj.getFullYear().toString().slice(-2)
    let endMM = ('0' + (endDateObj.getMonth() + 1)).slice(-2)
    let endDD = ('0' + endDateObj.getDate()).slice(-2)
    let endDay = getDayKor(endDateObj.getDay())

    let endDate = `${endYY}.${endMM}.${endDD}(${endDay})`

    setPlanDate(startDate + ' - ' + endDate)
  }

  useEffect(() => {
    getPlanList()
    getDateThreeMonthLater()

    if(!planDate) {
      return
    }

    let activeDateStr = planDate.split(' - ')[0]
    let dateyy = activeDateStr.split('.')[0]
    let datemm = activeDateStr.split('.')[1]
    let datedd = activeDateStr.split('.')[2]
    let activePlanStartDate = new Date(`20${dateyy}-${datemm}-${datedd}`)
    onChange(activePlanStartDate)
  }, [])

  return (
    <div className={cx('calendarSmall')}>
      { !isLoading && (
        <Calendar
          onClickDay={(value, event) => onClickDay(value, event)}
          value={value}
          formatDay={(locale, date) => dayjs(date).format('D')}
          minDate={new Date()}
          tileClassName={({ date }) => getDisableDates(date) }
          showNeighboringMonth={false}
        />
      ) }

    </div>

  );
};

export default CalendarSmall;