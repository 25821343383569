import axios from 'axios';

let isTokenRefreshing = false
let refreshSubscribers = []

const onTokenRefreshed = () => {
  refreshSubscribers.map((callback) => callback())
  refreshSubscribers = []
}

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback)
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_NODE === 'production' ? 'https://api.mora-ex.kr' : 'https://dev-api.mora.kr'
})

instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken')
    try {
      if (accessToken && !config.url.includes('/token/refresh')) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
      if(config.url.includes('/api/everex-admin/v1/notices')) {
        config.baseURL = 'https://api.everex.co.kr'
      }
      return config;
    } catch (err) {
      console.error('[_axios.interceptors.request] config : ' + err);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },

  async function (error) {
    const {config} = error
    const originalRequest = config
    if(error.code === 'ECONNABORTED') {
      window.location.href = '/error'
      return Promise.reject(error)
    }
    if(error.response.status === 401) {
      const retryOriReq = new Promise((resolve) => {
        addRefreshSubscriber(() => {
          resolve(instance(originalRequest))
        })
      })

      if(!isTokenRefreshing) {
        isTokenRefreshing = true
        const refreshToken = localStorage.getItem('refreshToken')
        if(refreshToken) {
          instance({
            method: 'post',
            url: '/api/mora-ex/v1/auth/staff/token/refresh',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              refreshToken
            },
          }).then((res) => {
            isTokenRefreshing = false
            const data = res.data
            if(data.accessToken){
              localStorage.setItem('accessToken', data.accessToken)
            } else {
              localStorage.removeItem('accessToken')
            }
            if(data.refreshToken){
              localStorage.setItem('refreshToken', data.refreshToken);
            } else {
              localStorage.removeItem('refreshToken')
            }

            onTokenRefreshed()
          }).catch((e) => {
            if(e.response.status === 400) {
              window.location.href = '/'
            }
            isTokenRefreshing = false
          })
        } else {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          window.location.href = '/'
        }
      }
      return retryOriReq
    } else if(error.response.status === 403) {
      window.location.href = '/'
    } else if(error.response.status === 400) {
      console.log(error.response.data.code)
    }else if(error.response.status === 500) {
      console.log(error.response)
    }

    return Promise.reject(error)
  }
);

export default instance;