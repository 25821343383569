import Signin from "./components/signin/signin";
import Error from './components/error/error'
import Home from "./components/home/home/home";
import GroupInfo from "./components/home/home/groupInfo/groupInfo";
import PatientDetail from "./components/patients/patientDetail/patientDetail";
import SignupIntro from './components/signup/signupIntro/signupIntro'
import SignupLast from './components/signup/signupLast/signupLast'
import ChangeAccount from "./components/changeAccount/changeAccount/changeAccount";
import Authentication from "./components/common/authentication/authentication";
import SetPassword from "./components/changeAccount/setPassword/setPassword";
import Rehab from "./components/rehab/rehab";
import Noti from "./components/noti/noti";
import Subscription from "./components/admin/payment/subscription/subscription";
import SucceedSubscription from "./components/admin/payment/subscription/succeedSubscription/succeedSubscription";
import AdminStaff from "./components/admin/staff/staff";
// import AdminStatistics from "./components/admin/statistics/statistics";
import PlanAssignment from "./components/planAssignment/planAssignment";
import MyPage from "./components/myPage/myPage";
import MoraexService from "./components/terms/moraexService/moraexService";
import Notice from "./components/terms/notice/notice";
import NoticeDetail from "./components/terms/notice/noticeDetail/noticeDetail";
import CreateProgram from "./components/createProgram/createProgram";
import MoraexPrivacyProcess from "./components/terms/moraexPrivacyProcess/moraexPrivacyProcess";
// import GroupSelect from "./components/groupSelect/groupSelect";
import OrganGroupSelect from "./components/organGroupSelect/organGroupSelect";
import Payment from "./components/payment/payment";
import Patients from "./components/patients/patients/patients";
// import ExtEnn from "./components/extEnn/extEnn";
// import ExtEnnAuth from "./components/extEnn/extEnnAuth/extEnnAuth";
// import ExtEnnError from "./components/extEnn/extEnnError/extEnnError";
// import ExtEnnSignup from "./components/extEnn/extEnnSignup/extEnnSignup";

export const pages = [
  { id: 0, path: '/', exact: true, title: '일상 속 재활운동, 모라 엑스', comp: <Signin/>},
  { id: 1, path: '/error', title: 'Error', comp: <Error/>},
  { id: 2, path: '/signup/intro', title: '회원가입', comp: <SignupIntro/>},
  { id: 3, path: '/signup/last', title: '회원가입', comp: <SignupLast/>},
  { id: 4, path: '/change/account', title: '계정 변경', comp: <ChangeAccount/>},
  { id: 5, path: '/authentication', title: '본인 인증', comp: <Authentication/>},
  { id: 6, path: '/set/password', title: '비밀번호 설정', comp: <SetPassword/>},
  { id: 7, path: '/home', title: '홈', comp: <Home/>},
  { id: 8, path: '/patient/:id', title: '고객 상세', comp: <PatientDetail/>},
  { id: 12, path: '/noti', title: '알림', comp: <Noti/>},
  { id: 13, path: '/home/group', title: '홈', comp: <GroupInfo/>},
  { id: 14, path: '/rehab', title: '추천 프로그램', comp: <Rehab/>},

  { id: 15, path: '/admin/staff', title: '사용자 관리', comp: <AdminStaff/>},
  { id: 17, path: '/admin/payment/subscription', title: '서비스 구독', comp: <Subscription/>},
  { id: 18, path: '/admin/payment/subscription/success', title: '구독 완료', comp: <SucceedSubscription/>},
  // { id: 19, path: '/admin/statistics', title: '병원 통계', comp: <AdminStatistics/>},
  { id: 21, path: '/plan/assignment/:id', title: '플랜 배정', comp: <PlanAssignment/>},
  { id: 22, path: '/myPage', title: '마이 페이지', comp: <MyPage/>},
  { id: 23, path: '/terms/service', title: '이용약관', comp: <MoraexService/>},
  { id: 24, path: '/terms/privacy', title: '개인정보 처리방침', comp: <MoraexPrivacyProcess/>},
  { id: 25, path: '/create/program', title: '프로그램 생성', comp: <CreateProgram/>},
  // { id: 26, path: '/group/select', title: '그룹 선택', comp: <GroupSelect/>},
  { id: 26, path: '/organ/group/select', title: '소속기관 및 그룹 선택', comp: <OrganGroupSelect/>},
  { id: 27, path: '/payment', title: '결제 관리', comp: <Payment/>},
  { id: 28, path: '/patients', title: '고객 관리', comp: <Patients/>},
  { id: 29, path: '/terms/notices', title: '공지사항', comp: <Notice/>},
  { id: 30, path: '/terms/notice/:id', title: '공지사항 상세', comp: <NoticeDetail/>},
  // { id: 26, path: '/ext/enn', title: '이지케어텍', comp: <ExtEnn/>},
  // { id: 27, path: '/ext/enn/error', title: '이지케어텍', comp: <ExtEnnError/>},
  // { id: 28, path: '/ext/enn/auth', title: '이지케어텍', comp: <ExtEnnAuth/>},
  // { id: 29, path: '/ext/enn/signup', title: '이지케어텍', comp: <ExtEnnSignup/>},
]

