import {createSlice} from "@reduxjs/toolkit";

const patientSlice = createSlice({
  name: 'patientSlice',
  initialState: { value: {
      isFilteredMyPatients: false,
      patientToAdd: {
        name: '',
        phoneNumber: '',
      },
      patientAccessInfo: {
        patientId: null,
        purpose: '',
      },
      canAccess: null,
      patientChangedProfile: {
        name: '',
        phoneNumber: '',
        height: '',
        weight: '',
      },
    } },
  reducers: {
    setIsFilteredMyPatients: (state, action) => {
      state.value.isFilteredMyPatients = action.payload
    },
    setPatientToAdd: (state, action) => {
      state.value.patientToAdd = action.payload
    },
    setPatientAccessInfo: (state, action) => {
      state.value.patientAccessInfo = action.payload
    },
    setAccess: (state, action) => {
      state.value.canAccess = action.payload
    },
    setPatientChangedProfile: (state, action) => {
      state.value.patientChangedProfile = action.payload
    }


  }
})

export default patientSlice
export const { setIsFilteredMyPatients, setPatientToAdd, setPatientAccessInfo, setAccess, setPatientChangedProfile } = patientSlice.actions