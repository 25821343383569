import React from 'react';
import classnames from "classnames/bind";
import styles from './createProgram.module.scss'
import HeaderNav from "@/common/header/headerNav/headerNav";
import {useSelector} from "react-redux";
import ProgramExerciseSelect from "./programExerciseSelect/programExerciseSelect";
import ProgramInfo from "./programInfo/programInfo";
import FooterGray from "../common/footer/footerGray/footerGray";

const ProgramStep1 = `${process.env.REACT_APP_RESOURCE_URL}/program_step1.svg`
const ProgramStep2 = `${process.env.REACT_APP_RESOURCE_URL}/program_step2.svg`

const cx = classnames.bind(styles)
const CreateProgram = () => {
  const programCreationStep = useSelector(state => {
    return state.program.value.programCreationStep
  })

  return (
    <div className={cx('createProgram')}>
      <HeaderNav/>
      <div className={cx('subHeader')}>
        <div className={cx('subHeaderWrapper')}>
          <span className={cx('title')}>프로그램 생성</span>

          <div className={cx('steps')}>
            <img
              src={
                programCreationStep === 0 ? ProgramStep1 :
                  programCreationStep === 1 ? ProgramStep2 :
                    ''}
              alt='step'
            />
          </div>
        </div>
      </div>
      { programCreationStep === 0 ? <ProgramExerciseSelect/>
        : programCreationStep === 1 ? <ProgramInfo/>
          : ''
      }

      <FooterGray position='relative'/>
    </div>
  );
};

export default CreateProgram;