import React, {useEffect, useState, useRef} from 'react';
import InputSearchBox from "../../common/input/inputSearchBox/inputSearchBox";
import styles from './planExerciseSelect.module.scss'
import classnames from "classnames/bind";
import Pagination from "@/common/pagination/pagination";
import api from "@/api";
import {useDispatch, useSelector} from "react-redux";
import DropdownOrder from "../../common/dropdown/dropdownOrder/dropdownOrder";
import {
  dateObjToStr,
  formatBirthNoDot,
  getDayKor,
  numberWithCommas,
  setMinutes
} from "../../../helpers/common";
import tagKor from '@/data/tagKor'
import tagEng from '@/data/tagEng'
import {
  setAddedPlanExerciseArr, setIsPlanProgram,
  setPlanAssignmentParams,
  setPlanAssignmentStep, setPlanProgramSelected,
  setPlanSchedule
} from "../../../store/slice/planSlice";
import ExerciseSelectSkeleton from "./exerciseSelectSkeleton/exerciseSelectSkeleton";
import {setActiveFilterRehabId} from "../../../store/slice/rehabSlice";
import imgSize from "@/data/imgSize"
import ExerciseDragItem from "./exerciseDragItem/exerciseDragItem";
import rehabListOrderParams from '@/data/rehabListOrderParams'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import ProgramCard from "./programCard/programCard";
import ExerciseCard from "./exerciseCard/exerciseCard";
import {showToast} from "../../../helpers/toastMessage";
import LeftTabs from "../../common/tabs/leftTabs/leftTabs";
import PlanCard from "./planCard/planCard";
import CalendarSmall from "./calendarSmall/calendarSmall";
import JobIcon from "../../common/jobIcon/jobIcon";
import useDidMountEffect from "../../../helpers/useDidMountEffect";
import {setIsMyProgramsUpdate} from "../../../store/slice/programSlice";

const GNewStar = `${process.env.REACT_APP_RESOURCE_URL}/G_newStar.svg`
const YNewStar = `${process.env.REACT_APP_RESOURCE_URL}/Y_newStar.svg`
const GTriangle = `${process.env.REACT_APP_RESOURCE_URL}/G_triangle.svg`
const GExercise = `${process.env.REACT_APP_RESOURCE_URL}/G_exercise.svg`
const GTimer20 = `${process.env.REACT_APP_RESOURCE_URL}/G_timer20*20.svg`
const GTool = `${process.env.REACT_APP_RESOURCE_URL}/G_tool.svg`
const GCheckCircleFull = `${process.env.REACT_APP_RESOURCE_URL}/G_checkCircleFull.svg`
const EmptyResult = `${process.env.REACT_APP_RESOURCE_URL}/Empty_result.svg`
const EmptyBookmark = `${process.env.REACT_APP_RESOURCE_URL}/Empty_bookmark.svg`
const GCheckCircle = `${process.env.REACT_APP_RESOURCE_URL}/G_checkCircle.svg`
const PlanCalendar = `${process.env.REACT_APP_RESOURCE_URL}/plan_calendar.svg`

const cx = classnames.bind(styles)

const exerciseOrderList = [
  {
    id: 0,
    txt: '등록순 (최근)',
  },
  {
    id: 1,
    txt: '등록순 (오래된)',
  },
  {
    id: 2,
    txt: '이름순 (ㄱ-ㅎ)',
  },
  {
    id: 3,
    txt: '이름순 (ㅎ-ㄱ)',
  },
]


const PlanExerciseSelect = () => {
  const refs = useRef([])
  const dispatch = useDispatch()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )
  const staff = useSelector(state => {
    return state.staff.value
  })
  const isMyProgramsUpdate = useSelector(state => {
    return state.program.value.isMyProgramsUpdate
  })
  const bookmarkProgram = useSelector(state => {
    return state.staff.value.bookmarkProgram
  })
  const bookmarkExercise = useSelector(state => {
    return state.staff.value.bookmarkExercise
  })
  const planAssignmentParams = useSelector(state => {
    return state.plan.value.planAssignmentParams
  })
  const addedPlanExerciseArr = useSelector(state => {
    return state.plan.value.addedPlanExerciseArr
  })
  const dateToEdit = useSelector(state => {
    return state.plan.value.dateToEdit
  })
  const planSchedule = useSelector(state => {
    return state.plan.value.planSchedule
  })
  const isAddNewDate = useSelector(state => {
    return state.plan.value.isAddNewDate
  })
  const activeFilterRehabId = useSelector(state => {
    return state.rehab.value.activeFilterRehabId
  })
  const lvArr = useSelector(state => {
    return state.rehab.value.lvArr
  })
  const selectedPlanStartDateTimestamp = useSelector(state => {
    return state.plan.value.selectedPlanStartDateTimestamp
  })
  const planProgramSelected = useSelector(state => {
    return state.plan.value.planProgramSelected
  })

  const [filterMuList, setFilterMuList] = useState([])

  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [recentPlanName, setRecentPlanName] = useState('')
  const [recentPlanDiagnosis, setRecentPlanDiagnosis] = useState('')
  const [planDate, setPlanDate] = useState('')
  const [isSearched, setIsSearched] = useState(false)
  const [muKeyword, setMuKeyword] = useState('')
  const [keyword, setKeyword] = useState('')
  const [isBookmarkActive, setIsBookmarkActive] = useState(false)
  const [isMyPlanActive, setIsMyPlanActive] = useState(false)
  const [payloadObj, setPayloadObj] = useState({
    keyword: '',
    tags: '',
    isBookmark: false,
    page: 1,
    order: '',
  })
  const [isDropdownExerciseOrderOpen, setIsDropdownExerciseOrderOpen] = useState(false)
  const [planList, setPlanList] = useState([])
  const [isDropdownPlanBodyPartOpen, setIsDropdownPlanBodyPartOpen] = useState(false)
  const [selectedExerciseOrderId, setSelectedExerciseOrderId] = useState(2)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [planBodyPartList, setPlanBodyPartList] = useState([])
  const [filterAllList, setFilterAllList] = useState([])
  const [bodyPartListToggle, setBodyPartListToggle] = useState(true)
  const [apListToggle, setAPListToggle] = useState(false)
  const [etListToggle, setETListToggle] = useState(false)
  const [kcListToggle, setKCListToggle] = useState(false)
  const [lvListToggle, setLVListToggle] = useState(false)
  const [muListToggle, setMUListToggle] = useState(false)
  const [obListToggle, setOBListToggle] = useState(false)
  const [poListToggle, setPOListToggle] = useState(false)
  const [sdListToggle, setSDListToggle] = useState(false)
  const [toListToggle, setTOListToggle] = useState(false)
  const [wbListToggle, setWBListToggle] = useState(false)
  const [selectedFilterItems, setSelectedFilterItems] = useState([])
  const [programList, setProgramList] = useState([])
  const [exerciseList, setExerciseList] = useState([])
  const [filterNumbersObj, setFilterNumbersObj] = useState({})
  const [isDragging, setIsDragging] = useState(false)
  const [planSelected, setPlanSelected] = useState({})
  const [filterRehabList, setFilterRehabList] = useState([
    {
      id: 0,
      tab: '추천 프로그램',
      count: 0,
    },
    {
      id: 1,
      tab: '공유 프로그램',
      count: 0,
    },
    {
      id: 2,
      tab: '나만의 프로그램',
      count: 0,
    },
    {
      id: 3,
      tab: '운동 동작',
      count: 0,
    },
    {
      id: 4,
      tab: '최근 배정한 플랜',
      count: 0,
    },
  ])
  const [planExerciseInfo, setPlanExerciseInfo] = useState({
    exciseCount: 0,
    timerCount: 0,
    toolsKor: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isShowAddedExerciseList, setIsShowAddedExerciseList] = useState(false)
  const [isEditActive, setIsEditActive] = useState(false)
  const [isAllItemChecked, setIsAllItemChecked] = useState(false)
  const [isDeleteExercise, setIsDeleteExercise] = useState(false)

  const getKeyword = (value) => {
    setKeyword(() => value)
    if(!value) {
      setPayloadObj({
        ...payloadObj,
        keyword: ''
      })
    }
  }

  const onSearch = () => {
    setSelectedFilterItems([])
    setIsBookmarkActive(false)

    setPayloadObj({
      ...payloadObj,
      tags: '',
      isBookmark: false,
      keyword: keyword,
      page: 1
    })
  }

  const onBookmarkClick = () => {
    setIsBookmarkActive(!isBookmarkActive)
    setPayloadObj({
      ...payloadObj,
      isBookmark: !isBookmarkActive,
      page: 1,
    })
  }

  const onMyPlanClick = () => {
    setIsMyPlanActive(!isMyPlanActive)
  }



  const getMuKeyword = (keyword) => {
    setMuKeyword(keyword)
    setFilterMuList(filterAllList.MU)

    if(keyword) {
      setIsSearched(true)
      onMuSearch()
    } else {
      setIsSearched(false)
    }
  }

  const onMuSearch = () => {
    let newFilterMuList = []

    let muKeysArr = Object.keys(tagKor.MU)
    let muNameAllArr = Object.values(tagEng.MU).concat(Object.values(tagKor.MU))
    muNameAllArr.forEach((muName) => {
      if(muName.toLowerCase().split(' ').join('').includes(muKeyword.toLowerCase().split(' ').join(''))) {
        let matchedTag = muKeysArr.find(key => tagKor.MU[key] === muName || tagEng.MU[key] === muName)
        filterAllList.MU.forEach((filterMu) => {
          if(matchedTag === filterMu.tag) {
            newFilterMuList.push(filterMu)
          }
        })
      }
    })

    setFilterMuList(newFilterMuList)
  }

  const clearFilter = () => {
    if(isBookmarkActive || selectedFilterItems.length) {
      setSelectedFilterItems([])

      setPayloadObj({
        ...payloadObj,
        tags: '',
        isBookmark: false,
      })

      setIsBookmarkActive(false)
    }
  }

  const clearMyRecentPlans = () => {
    setIsMyPlanActive(false)
  }

  const getSelectedExerciseOrderId = (id) => {
    if(id === selectedExerciseOrderId) {
      return
    }
    setSelectedExerciseOrderId(id)

    let orderParam = ''

    if(activeFilterRehabId === 0) {
      orderParam = rehabListOrderParams['recommended'][id]
    } else if(activeFilterRehabId === 1){
      orderParam = rehabListOrderParams['shared'][id]
    } else if(activeFilterRehabId === 2){
      orderParam = rehabListOrderParams['my'][id]
    } else {
      orderParam = rehabListOrderParams['ex'][id]
    }

    setPayloadObj({
      ...payloadObj,
      order: orderParam,
      page: 1
    })
  }



  const getSelectedBodyPartId = (id) => {
    dispatch(setPlanAssignmentParams({
      ...planAssignmentParams,
      mainPart: planBodyPartList[id].tag
    }))
  }

  const moveExerciseContainerBottom = () => {
    if(!refs.current.length) {
      return
    }
    let element = null
    if(isEditActive) {
      element = refs.current[1]
    } else {
      element = refs.current[0]
    }

    let scrollBox = element
    if(scrollBox) {
      scrollBox.scrollTo({ top: scrollBox.scrollHeight, behavior: 'smooth'})
    }
  }

  const getPlanExerciseInfo = () => {
    let exerciseArr = []
    let toolArr = []
    let timeCount = 0
    addedPlanExerciseArr.forEach((addedExercise) => {
      if(addedExercise) {
        exerciseArr.push(addedExercise.id)
        if(addedExercise.tag.TO) {
          toolArr = [...toolArr, ...addedExercise.tag.TO]
        }
        timeCount += (addedExercise.media[`M${addedExercise.time ? addedExercise.time : addedExercise.count}`].length) * addedExercise.repeat
      }
    })

    let uniqueToolArr = [...new Set(toolArr)]
    let toolKorArr = []

    uniqueToolArr.forEach((to) => {
      toolKorArr.push(tagKor['TO'][to])
    })

    setPlanExerciseInfo({
      exerciseCount: exerciseArr.length,
      timeCount: setMinutes(timeCount),
      toolsKor: toolKorArr.join(', ')
    })

    dispatch(setPlanAssignmentParams({
      ...planAssignmentParams,
      tools: uniqueToolArr
    }))
  }

  const checkAddedExercise = () => {
    setIsShowAddedExerciseList(true)
  }

  const goToNextStep = () => {
    if(isNextBtnActive()) {
      dispatch(setIsPlanProgram(false))

      if(isAddNewDate) {
        dispatch(setPlanAssignmentStep(4))
      } else {
        dispatch(setPlanAssignmentStep(1))
      }
    }
  }

  const goToPlanNextStep = () => {
    if(isPlanNextBtnActive()) {
      api.getPlanDetail(planSelected.planId).then((res) => {
        let planDetail = res.data
        let exerciseMap = planDetail.exerciseMap

        let tempPlanDate = planDate
        let startDate = tempPlanDate.split(' - ')[0].split('(')[0]
        let yy = startDate.split('.')[0]
        let mm = startDate.split('.')[1]
        let dd = startDate.split('.')[2]

        let startDateObj = new Date(`20${yy}-${mm}-${dd}`)
        let newRangeDateArr = [`20${yy}${mm}${dd}`]
        let newScheduleObj = planDetail.preScript.schedule
        let datesArr = Object.keys(newScheduleObj)

        datesArr.forEach((dateStr, idx) => {
          if(idx === Object.keys(newScheduleObj).length - 1) {
            return
          }
          let nextDay = new Date(startDateObj)
          nextDay.setDate(startDateObj.getDate() + idx + 1)
          newRangeDateArr.push(dateObjToStr(nextDay))
        })

        let newPlanSchedule = {}
        newRangeDateArr.forEach((date, idx) => {
          let scheduleArrPerDay = []
          newScheduleObj[datesArr[idx]].forEach((rep, repIdx) => {
            let exerciseDesc = exerciseMap[rep.id]
            scheduleArrPerDay[repIdx] = {
              ...rep,
              ...exerciseDesc
            }
          })
          newPlanSchedule[date] = scheduleArrPerDay
        })
        dispatch(setPlanSchedule(newPlanSchedule))

        let toolArr = []

        Object.values(planDetail.exerciseMap).forEach((exDetail) => {
          let tools = exDetail.exerciseTags.TO
          if(tools) {
            toolArr.push(...tools)
          }

        })
        toolArr = [...new Set(toolArr)]

        dispatch(setPlanAssignmentParams({
          ...planAssignmentParams,
          mainPart: planSelected.mainPart,
          name: recentPlanName,
          diagnosis: recentPlanDiagnosis,
          tools: toolArr
        }))
        dispatch(setIsPlanProgram(true))
        dispatch(setPlanAssignmentStep(3))
      }).catch((e) => {
        console.log(e)
      })
    }
  }

  const isPlanNextBtnActive = () => {
    let result = false

    if(planSelected && recentPlanName && planDate) {
      result = true
    }

    return result
  }

  const isNextBtnActive = () => {
    let result = false

    if(planAssignmentParams.mainPart && planAssignmentParams.name && addedPlanExerciseArr.length) {
      result = true
    }

    return result
  }

  const onClickFilterItem = (item) => {
    let tempSelectedFilterItems = selectedFilterItems
    let idx = 0
    if(tempSelectedFilterItems.some((selectedItem, i) => {
      if(selectedItem.tag === item.tag) {
        idx = i
        return true
      } else {
        return false
      }
    })) {
      if(idx > -1) {
        tempSelectedFilterItems.splice(idx, 1)
      }
    } else {
      tempSelectedFilterItems.push(item)
    }

    setSelectedFilterItems([...tempSelectedFilterItems])

    let tags = []

    selectedFilterItems.forEach((bodyPart) => {
      tags.push(bodyPart.tag)
    })
    setPayloadObj({
      ...payloadObj,
      tags: tags.join(),
      page: 1,
    })
  }

  const movePage = (page) => {
    setPayloadObj({
      ...payloadObj,
      page: page
    })
  }

  const moveMyPlanPage = (page) => {
    getRecentAssignedPlans(page)
  }

  const addFilterNumbers = (filterSections) => {
    let tempExFilterObj = filterSections
    Object.keys(tempExFilterObj).forEach((tempExFilter) => {
      tempExFilterObj[tempExFilter].forEach((exFilter) => {
        Object.keys(filterNumbersObj[tempExFilter]).forEach((filterNumber) => {
          if(exFilter.tag === filterNumber) {
            exFilter.count = filterNumbersObj[tempExFilter][filterNumber]
          }
        })
      })
    })

    setFilterAllList(tempExFilterObj)
  }

  const getTotalElement = (totalElement) => {
    if(totalElement === undefined) {
      return
    }
    let newRehabList = filterRehabList
    newRehabList[activeFilterRehabId].count = totalElement
    setFilterRehabList(newRehabList)
  }

  const selectFilterRehab = (filterRehabId) => {
    if(filterRehabId !== activeFilterRehabId) {
      clearFilter()
      setKeyword('')
      setSelectedExerciseOrderId(2)

      // 최근 배정한 플랜 탭에서 다른 탭으로 이동 시, 플랜명, 질환 및 수술명 초기화
      if(activeFilterRehabId === 4) {
        dispatch(setPlanAssignmentParams({
          ...planAssignmentParams,
          name: '',
          diagnosis: '',
        }))
      }

      dispatch(setActiveFilterRehabId(filterRehabId))
      if(filterRehabId === 0) {
        setPayloadObj({
          keyword: '',
          tags: '',
          isBookmark: false,
          page: 1,
          creatorId: '',
          shared: false,
          order: rehabListOrderParams['recommended'][2],
          tabId: 0
        })
      } else if(filterRehabId === 1) {
        setPayloadObj({
          keyword: '',
          tags: '',
          isBookmark: false,
          page: 1,
          creatorId: '',
          shared: true,
          order: rehabListOrderParams['shared'][2],
          tabId: 1
        })
      } else if(filterRehabId === 2) {
        setPayloadObj({
          keyword: '',
          tags: '',
          isBookmark: false,
          page: 1,
          creatorId: staff.id,
          shared: false,
          order: rehabListOrderParams['my'][2],
          tabId: 2
        })
      } else {
        setPayloadObj({
          keyword: '',
          tags: '',
          isBookmark: false,
          page: 1,
          creatorId: '',
          shared: false,
          order: rehabListOrderParams['ex'][2],
          tabId: 3
        })
      }
    }
  }

  const getRecentAssignedPlans = (page = 1) => {
    setIsLoading(true)
    api.getRecentPlans(staff.rehabGroupId, isMyPlanActive, page, 21).then((res) => {
      setIsLoading(false)
      let plans = res.data.list
      let tempPlanList = plans.filter((plan) => {
        plan.patientBirth = formatBirthNoDot(plan.patientBirthday)
        if(plan.medicalStaffPhoneNumber) {
          plan.staffName = `${plan.medicalStaffName}(${plan.medicalStaffPhoneNumber.slice(7, 11)})`
        } else {
          plan.staffName = plan.medicalStaffName
        }

        return plan
      })
      setPlanList(tempPlanList)

      getTotalElement(res.data.totalElement)
      setTotalPage(res.data.totalPage)
      setCurrentPage(res.data.currentPage)
    })
  }

  const getProgramList = () => {
    setIsLoading(true)

    let defaultOrder = ''
    if(!payloadObj.order) {
      if(activeFilterRehabId === 0) {
        defaultOrder = rehabListOrderParams['recommended'][2]
      } else if(activeFilterRehabId === 1) {
        defaultOrder = rehabListOrderParams['shared'][2]
      } else if(activeFilterRehabId === 2) {
        defaultOrder = rehabListOrderParams['my'][2]
      } else {
        defaultOrder = rehabListOrderParams['ex'][2]
      }
    }

    api.getPrograms(payloadObj.keyword, payloadObj.tags, payloadObj.isBookmark, payloadObj.page, payloadObj.order ? payloadObj.order : defaultOrder, payloadObj.creatorId, payloadObj.shared).then((res) => {
      let data = res.data
      const programList = data.list
      if(programList.length) {
        programList.forEach((program) => {
          if(bookmarkProgram.includes(program.pid)) {
            program.isBookmark = true
          } else {
            program.isBookmark = false
          }
        })
      }
      setIsLoading(false)
      setProgramList(programList)
      setTotalPage(data.totalPage)
      setFilterNumbersObj(data.extra.count)
      setCurrentPage(data.currentPage)
      getTotalElement(data.totalElement)
    }).catch((e) => {
      console.log(e)
    })
  }

  const onPlanProgramNameChange = (e) => {
    setRecentPlanName(e.target.value)
    dispatch(setPlanProgramSelected({
      ...planProgramSelected,
      planName: e.target.value
    }))
  }

  const onPlanProgramDiagnosisChange = (e) => {
    setRecentPlanDiagnosis(e.target.value)
    dispatch(setPlanProgramSelected({
      ...planProgramSelected,
      planDiagnosis: e.target.value
    }))
  }

  const toggleAllItemCheck = () => {
    let newAddedPlanExerciseArr = JSON.stringify(addedPlanExerciseArr)
    newAddedPlanExerciseArr = JSON.parse(newAddedPlanExerciseArr)

    if(isAllItemChecked) {
      newAddedPlanExerciseArr.forEach((addedExercise) => {
        addedExercise.isChecked = false
        setIsAllItemChecked(false)
      })
    } else {
      newAddedPlanExerciseArr.forEach((addedExercise) => {
        addedExercise.isChecked = true
        setIsAllItemChecked(true)
      })
    }

    dispatch(setAddedPlanExerciseArr([...newAddedPlanExerciseArr]))
  }

  const copyExercise = () => {
    let exerciseArr = []
    let exerciseArrToCopy = []
    let newAddedPlanExerciseArr = JSON.stringify(addedPlanExerciseArr)
    newAddedPlanExerciseArr = JSON.parse(newAddedPlanExerciseArr)

    newAddedPlanExerciseArr.forEach((newAddedExercise) => {
      if(newAddedExercise.isChecked) {
        exerciseArr.push(newAddedExercise)
        exerciseArrToCopy.push(newAddedExercise)
        newAddedExercise.isChecked = false
      } else {
        exerciseArr.push(newAddedExercise)
      }
    })

    // deep copy
    let tempExerciseArrToCopy = JSON.stringify(exerciseArrToCopy)
    exerciseArrToCopy = JSON.parse(tempExerciseArrToCopy)
    newAddedPlanExerciseArr = exerciseArr.concat(exerciseArrToCopy)

    newAddedPlanExerciseArr.forEach((addedExercise, idx) => {
      addedExercise.unique = Date.now() + idx
    })

    dispatch(setAddedPlanExerciseArr(newAddedPlanExerciseArr))
    setIsAllItemChecked(false)
  }

  const closeCalendarSmall = (e) => {
    if(refs.current[2] && refs.current[2].contains(e.target)) {
      return
    }
    setIsCalendarOpen(false)
  }

  const deleteExercise = () => {
    setIsDeleteExercise(true)
    let newAddedPlanExerciseArr = JSON.stringify(addedPlanExerciseArr)
    newAddedPlanExerciseArr = JSON.parse(newAddedPlanExerciseArr)
    const outcome = newAddedPlanExerciseArr.filter((newAddedExercise) => !newAddedExercise.isChecked)
    newAddedPlanExerciseArr = outcome
    dispatch(setAddedPlanExerciseArr(newAddedPlanExerciseArr))
    setIsAllItemChecked(false)
  }

  const toggleExCheck = (unique) => {
    let newAddedPlanExerciseArr = JSON.stringify(addedPlanExerciseArr)
    newAddedPlanExerciseArr = JSON.parse(newAddedPlanExerciseArr)
    let selectedEx = newAddedPlanExerciseArr.find((item) => item.unique === unique)

    selectedEx.isChecked = !selectedEx.isChecked

    if(isAllItemChecked && !selectedEx.isChecked) {
      setIsAllItemChecked(false)
    } else if(!isAllItemChecked && selectedEx.isChecked) {
      let isAllChecked = true
      newAddedPlanExerciseArr.forEach((newAddedPlanExercise) => {
        if(!newAddedPlanExercise.isChecked) {
          isAllChecked = false
        }
      })
      setIsAllItemChecked(isAllChecked)
    }
    dispatch(setAddedPlanExerciseArr([...newAddedPlanExerciseArr]))
  }

  const handleDragStart = () => {
    setIsDragging(true)
  }


  const handleDragEnd = (e) => {
    setIsDragging(false)

    const { active, over } = e

    if (active.id !== over.id) {
      let newAddedPlanExerciseArr = JSON.stringify(addedPlanExerciseArr)
      newAddedPlanExerciseArr = JSON.parse(newAddedPlanExerciseArr)

      const oldIndex = newAddedPlanExerciseArr.findIndex((item) => (item.unique === active.id))
      const newIndex = newAddedPlanExerciseArr.findIndex((item) => (item.unique === over.id))

      dispatch(setAddedPlanExerciseArr(arrayMove(newAddedPlanExerciseArr, oldIndex, newIndex)))
    }
  }

  const getExerciseList = () => {
    let allTags = payloadObj.tags
    let objectives = []
    let positions = []
    let levels = []
    let tags = []

    if(allTags) {
      allTags = allTags.split(',')

      allTags.forEach((tag) => {
        if(tag.includes('OB')) {
          objectives.push(tag)
        } else if(tag.includes('PO')) {
          positions.push(tag)
        } else if(tag.includes('LV')) {
          levels.push(tag.replace('LV', ''))
        } else {
          tags.push(tag)
        }
      })
    }

    setIsLoading(true)
    api.getExercises(payloadObj.keyword, objectives.join(','), positions.join(','), levels.join(','), tags.join(','), payloadObj.isBookmark, payloadObj.page, payloadObj.order)
      .then((res) => {
        let data = res.data
        const exerciseList = data.list
        if(exerciseList.length) {
          exerciseList.forEach((exercise) => {
            if(bookmarkExercise.includes(exercise.id)) {
              exercise.isBookmark = true
            } else {
              exercise.isBookmark = false
            }
          })
        }
        setIsLoading(false)
        setExerciseList([...exerciseList])
        getTotalElement(data.totalElement)
        setTotalPage(data.totalPage)
        setFilterNumbersObj(data.extra.count)
        setCurrentPage(data.currentPage)
        // window.scrollTo(0,0)

      }).catch((e) => {
      console.log(e)
    })
  }

  const getCheckedItemCount = () => {
    let result = 0
    addedPlanExerciseArr.forEach((addedPlanExercise) => {
      if(addedPlanExercise.isChecked) {
        result++
      }
    })
    return result
  }

  const onPlanTitleChange = (e) => {
    let planName = e.target.value
    dispatch(setPlanAssignmentParams({
      ...planAssignmentParams,
      name: planName
    }))
  }

  const toggleBodyPartList = () => {
    if(activeFilterRehabId === 3) {
      setBodyPartListToggle(!bodyPartListToggle)
    }
  }

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen)
  }

  const onDiagnosisChange = (e) => {
    let diagnosis = e.target.value
    dispatch(setPlanAssignmentParams({
      ...planAssignmentParams,
      diagnosis: diagnosis
    }))
  }

  const activeEdit = () => {
    if(!addedPlanExerciseArr.length) {
      return
    }

    setIsEditActive(!isEditActive)

    let tempAddedPlanExerciseArr = JSON.stringify(addedPlanExerciseArr)
    tempAddedPlanExerciseArr = JSON.parse(tempAddedPlanExerciseArr)

    tempAddedPlanExerciseArr.forEach((item) => {
      item.isChecked = false
    })
    dispatch(setAddedPlanExerciseArr(tempAddedPlanExerciseArr))

    setIsAllItemChecked(false)

    if(dateToEdit) {
      let tempPlanSchedule = {...planSchedule}
      tempPlanSchedule[dateToEdit] = tempAddedPlanExerciseArr

      dispatch((setPlanSchedule(tempPlanSchedule)))
      dispatch((setPlanAssignmentStep(2)))
      showToast('success', '편집이 완료되었습니다.')
    } else {
      let timeCount = 0
      addedPlanExerciseArr.forEach((ex) => {
        timeCount += (ex.media[`M${ex.time ? ex.time : ex.count}`].length * ex.repeat)
      })

      setPlanExerciseInfo({
        ...planExerciseInfo,
        timeCount: setMinutes(timeCount)
      })
    }
  }

  const resetPlanSelect = () => {
    setPlanSelected({})
    setPlanDate('')
  }

  const getMainPartFirstValue = () => {
    if(planAssignmentParams.mainPart) {
      if(planBodyPartList.length) {
        return planBodyPartList[planBodyPartList.findIndex(item => item.tag === planAssignmentParams.mainPart)].txt
      }
    } else {
      return '운동 부위를 선택해 주세요'
    }
  }

  useEffect(() => {
    if(activeFilterRehabId === 3) {
      getExerciseList()
    } else if(activeFilterRehabId === 4){
      getRecentAssignedPlans()
    } else {
      getProgramList()
    }
  }, [payloadObj])

  useEffect(() => {
    let filterSections = {}
    Object.keys(filterNumbersObj).forEach((filterTitle, idx) => {
      filterSections = {
        ...filterSections,
        [filterTitle]: []
      }
      Object.keys(tagKor[filterTitle]).forEach((tag) => {
        filterSections[filterTitle].push({
          tag: tag,
          tagName: tagKor[filterTitle][tag],
          count: 0
        })
      })
    })
    addFilterNumbers(filterSections)
  }, [programList, exerciseList])

  useEffect(() => {
    if(!isDeleteExercise) {
      if(addedPlanExerciseArr.length > 0) {
        moveExerciseContainerBottom()
      }
    } else {
      setIsDeleteExercise(false)
      if(!addedPlanExerciseArr.length && !dateToEdit) {
        setIsEditActive(false)
      }
    }
    getPlanExerciseInfo()
  }, [addedPlanExerciseArr.length])

  useEffect(() => {
    let newAddedPlanExerciseArr = JSON.stringify(addedPlanExerciseArr)
    newAddedPlanExerciseArr = JSON.parse(newAddedPlanExerciseArr)

    if(isAllItemChecked) {
      newAddedPlanExerciseArr.forEach((newAddedExercise) => {
        newAddedExercise.isChecked = true
      })
    }

    dispatch(setAddedPlanExerciseArr([...newAddedPlanExerciseArr]))
  }, [isAllItemChecked])

  useEffect(() => {
    if(planAssignmentParams.mainPart && !planAssignmentParams.name) {
      dispatch(setPlanAssignmentParams({
        ...planAssignmentParams,
        name: `${tagKor['MP'][planAssignmentParams.mainPart]} 재활 운동 플랜`
      }))
    }
  }, [planAssignmentParams.mainPart])

  useEffect(() => {
    setPlanSelected({})

    setRecentPlanName('')
    setRecentPlanDiagnosis('')
    if(!selectedPlanStartDateTimestamp) {
      setPlanDate('')
    }
  }, [activeFilterRehabId])

  useEffect(() => {
    if(Object.keys(planSelected).length) {
      setRecentPlanName(planSelected.planName)
      setRecentPlanDiagnosis(planSelected.planDiagnosis)
    } else {
      setRecentPlanName('')
      setRecentPlanDiagnosis('')
      if(!selectedPlanStartDateTimestamp) {
        setPlanDate('')
      }

      setIsCalendarOpen(false)
    }

  }, [planSelected])

  useEffect(() => {
    if(isMyProgramsUpdate) {
      getProgramList()
      dispatch(setIsMyProgramsUpdate(false))
    }
  }, [isMyProgramsUpdate])

  useEffect(() => {
    let bodyPartArr = Object.entries(tagKor['MP'])
    let newBodyPartArr = []
    bodyPartArr.forEach((bodyPart, idx) => {
      newBodyPartArr.push({
        id: idx,
        tag: bodyPart[0],
        txt: bodyPart[1],
      })
    })
    setPlanBodyPartList(newBodyPartArr)

    if(dateToEdit) {
      setIsShowAddedExerciseList(true)
      setIsEditActive(true)
    }



    if(selectedPlanStartDateTimestamp) {
      let tempValue = new Date(selectedPlanStartDateTimestamp)
      let yy = tempValue.getFullYear().toString().slice(-2)
      let mm = ('0' + (tempValue.getMonth() + 1)).slice(-2)
      let dd = ('0' + tempValue.getDate()).slice(-2)
      let day = getDayKor(tempValue.getDay())

      let startDate = `${yy}.${mm}.${dd}(${day})`

      let endDateObj = new Date(tempValue.setDate(tempValue.getDate() + (planProgramSelected.planTotalPlanDays - 1)))

      let endYY = endDateObj.getFullYear().toString().slice(-2)
      let endMM = ('0' + (endDateObj.getMonth() + 1)).slice(-2)
      let endDD = ('0' + endDateObj.getDate()).slice(-2)
      let endDay = getDayKor(endDateObj.getDay())

      let endDate = `${endYY}.${endMM}.${endDD}(${endDay})`

      setPlanDate(startDate + ' - ' + endDate)
    }

    // position: sticky 작동하려면 body에 걸려있는 overflow hidden 풀어줘야함
    let body = document.getElementsByTagName('body')[0]
    body.style.overflowX = 'visible'
    return () => body.style.overflowX = 'hidden'
  }, [])

  useDidMountEffect(() => {
    if(activeFilterRehabId !== 3) {
      setAPListToggle(false)
      setETListToggle(false)
      setKCListToggle(false)
      setLVListToggle(false)
      setOBListToggle(false)
      setPOListToggle(false)
      setSDListToggle(false)
      setTOListToggle(false)
      setWBListToggle(false)
    }
  }, [activeFilterRehabId]);

  useDidMountEffect(() => {
    getRecentAssignedPlans()
  }, [isMyPlanActive])

  return (
    <div className={cx('planExerciseSelect')} onClick={(e) => closeCalendarSmall(e)}>
      { isLoading ? (
        <ExerciseSelectSkeleton/>
      ) : (
        <>
          <div className={cx('rightWrapper')}>
            <div className={cx('exerciseBox')}>
              <div className={cx('filterContainer')}>
                <div className={cx('rehabFilter')}>
                  <LeftTabs tabItems={ filterRehabList } activeTabId={activeFilterRehabId} onTabItemClick={selectFilterRehab}/>
                </div>
                <div className={cx('bodyPartFilter')}>
                  <div className={cx('bodyPartTitle')}>
                    <p>필터({ numberWithCommas(filterRehabList[activeFilterRehabId].count) }) </p>
                    { activeFilterRehabId === 4 ? (
                      <div className={cx('reset', isMyPlanActive && 'active')} onClick={() => clearMyRecentPlans()}>
                        <div></div>
                        <span>초기화</span>
                      </div>
                    ) : (
                      <div className={cx('reset', (isBookmarkActive || selectedFilterItems.length) && 'active')} onClick={clearFilter}>
                        <div></div>
                        <span>초기화</span>
                      </div>
                    )}

                  </div>
                  { activeFilterRehabId === 4 ? (
                    <div className={cx('bookmark')}>
                      <div className={cx('bookmarkTitle')}>
                        <span>내가 배정한 플랜</span>
                      </div>
                      <div className={cx('toggle')}>
                        <label className={cx('switch')}>
                          <input
                            onChange={() => onMyPlanClick()}
                            type="checkbox"
                            checked={isMyPlanActive}
                          />
                          <span className={cx('slider', 'round')}></span>
                        </label>
                      </div>
                    </div>
                  ): (
                    <>
                      <div className={cx('bookmark')}>
                        <div className={cx('bookmarkTitle')}>
                          <img src={isBookmarkActive ? YNewStar : GNewStar} alt='GNewStar'/>
                          <span>즐겨찾기</span>
                        </div>
                        <div className={cx('toggle')}>
                          <label className={cx('switch')}>
                            <input
                              onChange={onBookmarkClick}
                              type="checkbox"
                              checked={isBookmarkActive}
                            />
                            <span className={cx('slider', 'round')}></span>
                          </label>
                        </div>
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={toggleBodyPartList}>
                          <span>{tagKor['title']['MP']}</span>
                          { activeFilterRehabId === 3 ? (
                            <img className={cx(bodyPartListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                          ) : ''}
                        </div>
                        { bodyPartListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['MP'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                    </>
                  )}
                  { activeFilterRehabId === 3 ? (
                    <>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setAPListToggle(!apListToggle)}>
                          <span>{tagKor['title']['AP']}</span>
                          <img className={cx(apListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { apListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['AP'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.AP[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setETListToggle(!etListToggle)}>
                          <span>{tagKor['title']['ET']}</span>
                          <img className={cx(etListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { etListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['ET'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.ET[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setKCListToggle(!kcListToggle)}>
                          <span>{tagKor['title']['KC']}</span>
                          <img className={cx(kcListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { kcListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['KC'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.KC[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setLVListToggle(!lvListToggle)}>
                          <span>{tagKor['title']['LV']}</span>
                          <img className={cx(lvListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { lvListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['LV'].map((item, idx) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span className={cx('lvTxt', lvArr[idx].color)}>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('muFilterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setMUListToggle(!muListToggle)}>
                          <span>{tagKor['title']['MU']}</span>
                          <img className={cx(muListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        <div className={cx('muFilterList')}>
                          { muListToggle && (
                            <>
                              <div className={cx('muInput')}>
                                <InputSearchBox
                                  placeholder='검색어 입력'
                                  getKeyword={getMuKeyword}
                                  keyword={muKeyword}
                                  onSearch={null}
                                  isSmall={true}
                                />
                              </div>
                              <div className={cx('bodyList')}>
                                { Object.keys(tagKor['MUPartSort']).map((muPart, idx) => (
                                  <div className={cx('muPartWrapper')} key={idx}>
                                    { !isSearched ? (
                                      <>
                                        <p key={muPart} className={cx('muPart')}>{ tagKor['MUPart'][muPart] }</p>
                                        { Object.keys(filterAllList).length ? (
                                          filterAllList['MU'].map((item, idx) => (
                                            tagKor['MUPartSort'][muPart][item.tag] && (
                                              <div
                                                className={cx(
                                                  'filterItemBox',
                                                  selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                                  !item.count && 'displayNone'
                                                )}
                                                key={idx}
                                              >
                                                <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                                  <div className={cx('marginBottom')}></div>
                                                  <span>{tagEng.MU[item.tag]}<br/><span>{ item.tagName }</span></span>
                                                </div>
                                                <span>{ numberWithCommas(item.count)  }</span>
                                              </div>
                                            )
                                          ))
                                        ) : ''}
                                      </>
                                    ) : (
                                      filterMuList.map((item, idx) => (
                                        tagKor['MUPartSort'][muPart][item.tag] && (
                                          <div
                                            className={cx(
                                              'filterItemBox',
                                              selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                              !item.count && 'displayNone'
                                            )}
                                            key={idx}
                                          >
                                            <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                              <div className={cx('marginBottom')}></div>
                                              <span>{tagEng.MU[item.tag]}<br/><span>{ item.tagName }</span></span>
                                            </div>
                                            <span>{ numberWithCommas(item.count)  }</span>
                                          </div>
                                        )
                                      ))
                                    )}

                                  </div>
                                )) }
                              </div>
                            </>
                          ) }
                        </div>

                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setOBListToggle(!obListToggle)}>
                          <span>{tagKor['title']['OB']}</span>
                          <img className={cx(obListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { obListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['OB'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setPOListToggle(!poListToggle)}>
                          <span>{tagKor['title']['PO']}</span>
                          <img className={cx(poListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { poListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['PO'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setSDListToggle(!sdListToggle)}>
                          <span>{tagKor['title']['SD']}</span>
                          <img className={cx(sdListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { sdListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['SD'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.SD[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setTOListToggle(!toListToggle)}>
                          <span>{tagKor['title']['TO']}</span>
                          <img className={cx(toListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { toListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['TO'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setWBListToggle(!wbListToggle)}>
                          <span>{tagKor['title']['WB']}</span>
                          <img className={cx(wbListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { wbListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['WB'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName} <br/><span>{tagEng.WB[item.tag]}</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                    </>
                  ) : '' }
                </div>
              </div>
              { activeFilterRehabId === 4 ? (
                <div className={cx('listContainer')}>
                  <div className={cx('listWrapper')}>
                    { !planList.length ? (
                      <div className={cx('empty')}>
                        <img src={EmptyResult} alt='EmptyResult'/>
                        <p>최근 배정된 플랜이 없습니다.</p>
                      </div>
                    ):(
                      <div className={cx('planListWrapper')}>
                        {
                          planList.map((plan, idx) => (
                            <PlanCard plan={plan} key={idx} planSelected={planSelected} setPlanSelected={setPlanSelected} />
                          ))
                        }
                      </div>

                    )}
                    { totalPage > 1 ? (
                      <Pagination key={totalPage} currentPage={currentPage} totalPage={totalPage} getList={moveMyPlanPage}/>
                    ) : ''}
                  </div>
                </div>
              ) : (
                <div className={cx('listContainer')}>
                  <div className={cx('listContainerTop')}>
                    <div className={cx('inputBox')}>
                      <InputSearchBox
                        placeholder='검색어 입력'
                        getKeyword={getKeyword}
                        keyword={keyword}
                        onSearch={onSearch}
                        isSmall={true}
                      />
                    </div>
                    <div className={cx('dropdownWrapper')}>
                      <DropdownOrder
                        orderList={exerciseOrderList}
                        isDropdownOrderOpen={isDropdownExerciseOrderOpen}
                        setSelectedOrderId={getSelectedExerciseOrderId }
                        selectedOrderId={selectedExerciseOrderId}
                        setIsDropdownOrderOpen={() => setIsDropdownExerciseOrderOpen(!isDropdownExerciseOrderOpen)}
                        listPosition='right'
                      />
                    </div>
                  </div>
                  <div className={cx('listWrapper')}>
                    <div className={cx('exerciseList')}>
                      { activeFilterRehabId === 3 ? (
                        exerciseList.map((exercise, idx) => (
                          <ExerciseCard exercise={exercise} key={idx}/>
                        ))
                      ) : programList.map((program) => (
                        <ProgramCard key={program.pid} program={program}/>
                      ))}
                    </div>
                    { ((activeFilterRehabId === 3 && !exerciseList.length) || (activeFilterRehabId !== 3 && !programList.length)) && (
                      isBookmarkActive ? (
                        <div className={cx('empty')}>
                          <img src={EmptyBookmark} alt='EmptyBookmark'/>
                          <p>자주 사용하거나 열람하는 컨텐츠를<br/>추가해 빠르게 찾아보세요:)</p>
                        </div>
                      ) : (
                        <div className={cx('empty')}>
                          <img src={EmptyResult} alt='EmptyResult'/>
                          <p>원하시는 결과를 찾지 못했어요 :(<br/>다시 검색해서 찾아보세요.</p>
                        </div>
                      )
                    )}
                    { totalPage > 1 ? (
                      <Pagination key={totalPage} currentPage={currentPage} totalPage={totalPage} getList={movePage}/>
                    ) : ''}
                  </div>
                </div>
              )}

            </div>
          </div>
          <div className={cx('leftWrapper')}>
            { (isShowAddedExerciseList && activeFilterRehabId !== 4) ? (
              <div className={cx('floatContainer', isEditActive && 'blackBorder')}>
                <div className={cx('addedExerciseTop')}>
                  { isEditActive ? (
                    <>
                      <p className={cx('addedExerciseInfo')}>편집</p>
                      <button className={cx(!addedPlanExerciseArr.length && 'inactive')} onClick={activeEdit}>완료</button>
                    </>
                  ) : (
                    <>
                      <div className={cx('arrow')} onClick={() => setIsShowAddedExerciseList(false)}></div>
                      <p className={cx('addedExerciseInfo')}>{planExerciseInfo.timeCount }분<span>|</span>{ planExerciseInfo.exerciseCount ? planExerciseInfo.exerciseCount : '- ' }개 동작</p>
                      <button className={cx(!addedPlanExerciseArr.length && 'inactive')} onClick={activeEdit}>편집</button>
                    </>
                  )}
                </div>
                <div className={cx('addedExerciseList', addedPlanExerciseArr.length && isEditActive && 'scrollHidden', addedPlanExerciseArr.length > 7 && 'scroll')} ref={(ele) => {refs.current[0] = ele}}>
                  { addedPlanExerciseArr.length && !isEditActive ? (
                    <div className={cx('exItemWrapper')}>
                      { addedPlanExerciseArr.map((addedExercise, idx) => (
                        <div className={cx('addedExerciseItem')} key={idx}>
                          <div className={cx('grayBox')}></div>
                          <img src={addedExercise.urls.IT + imgSize['90']} alt='exImg'/>
                          <div className={cx('addedExerciseContent')}>
                            <p>
                              { addedExercise.name }
                              <span className={cx('tooltiptext', addedExercise.name.length > 25 && 'long')}>{ addedExercise.name }</span>
                            </p>
                            <div className={cx('exCount')}>
                              <span className={cx('unit')}>
                                { addedExercise.time ? addedExercise.time + '초' : addedExercise.count + '회' }
                                <span>X</span>
                                { addedExercise.repeat }세트
                              </span>
                            </div>
                          </div>
                        </div>
                      )) }
                    </div>
                  ) : ''}
                  { !addedPlanExerciseArr.length ? (
                    <div className={cx('emptyAddedExercise')}>
                      <p>프로그램 또는 운동을 선택해 주세요.</p>
                    </div>
                  ) : ''}
                  { addedPlanExerciseArr.length && isEditActive ? (
                    <>
                      <div className={cx('editMenu')}>
                        <div className={cx('itemSelect')}>
                          <img src={isAllItemChecked ? GCheckCircleFull : GCheckCircle} onClick={() => toggleAllItemCheck()} alt='GCheckCircle'/>
                          <span>{ getCheckedItemCount() }개 항목</span>
                        </div>
                        <div className={cx('editBtns', getCheckedItemCount() > 0 && 'active')}>
                          <button className={cx('btnCopy')} onClick={copyExercise}>복사</button>
                          <button className={cx('btnDelete')} onClick={deleteExercise}>삭제</button>
                        </div>
                      </div>
                      <div className={cx('editListContainer', isDragging && 'scrollHidden', addedPlanExerciseArr.length > 7 && 'scroll')} ref={(ele) => {refs.current[1] = ele}}>
                        <DndContext
                          sensors={sensors}
                          collisionDetection={closestCenter}
                          onDragEnd={handleDragEnd}
                          onDragStart={handleDragStart}
                        >
                          <SortableContext
                            items={addedPlanExerciseArr.map(item => item.unique)}
                            strategy={verticalListSortingStrategy}
                          >
                            { addedPlanExerciseArr.map((item) => (
                              <ExerciseDragItem
                                item={item}
                                key={item.unique}
                                id={item.unique}
                                toggleExCheck={toggleExCheck}
                              />
                            )) }
                          </SortableContext>
                        </DndContext>
                      </div>
                    </>
                  ): ''}
                </div>
              </div>
            ) : (!isShowAddedExerciseList && activeFilterRehabId !== 4) ? (
              <div className={cx('floatContainer')}>
                <p className={cx('planInfo')}>{ isAddNewDate ? '새로운 일정 추가' : '플랜 정보' }</p>
                { !isAddNewDate && (
                  <div className={cx('planInfoDetail')}>
                    <DropdownOrder
                      orderList={planBodyPartList}
                      isDropdownOrderOpen={isDropdownPlanBodyPartOpen}
                      setSelectedOrderId={getSelectedBodyPartId }
                      selectedOrderId={planBodyPartList.findIndex(item => item.tag === planAssignmentParams.mainPart)}
                      setIsDropdownOrderOpen={() => setIsDropdownPlanBodyPartOpen(!isDropdownPlanBodyPartOpen)}
                      fixedValueExist={false}
                      listWidth={248}
                      isScrollList={true}
                      firstValue={ getMainPartFirstValue() }
                    />
                    <input
                      placeholder='플랜명을 입력해 주세요'
                      type='text'
                      value={planAssignmentParams.name}
                      onChange={onPlanTitleChange}
                    />
                    <input
                      className={cx('inputDiagnosis')}
                      placeholder='(선택) 질환 및 수술명을 입력해 주세요'
                      type='text'
                      value={planAssignmentParams.diagnosis}
                      onChange={onDiagnosisChange}
                    />
                  </div>
                )}
                <p className={cx('exerciseInfo', isAddNewDate && 'small')}>
                  운동 정보
                  <button className={cx('detail', planExerciseInfo.exerciseCount && 'active')} onClick={checkAddedExercise}>운동 구성</button>
                </p>
                <div className={cx('count')}>
                  <img src={GTimer20} alt='GTime20'/>
                  <span>{ planExerciseInfo.timeCount ? planExerciseInfo.timeCount : '-' } 분</span>
                </div>
                <div className={cx('count')}>
                  <img src={GExercise} alt='GExercise'/>
                  <span>{ planExerciseInfo.exerciseCount ? planExerciseInfo.exerciseCount : '- ' }개 동작</span>
                </div>
                <div className={cx('count')}>
                  <img src={GTool} alt='GTool'/>
                  <span>{ planExerciseInfo.toolsKor ? planExerciseInfo.toolsKor : '-' }</span>
                </div>
                <div className={cx('btnWrapper')}>
                  <button className={cx('next', isNextBtnActive() && 'active')} onClick={goToNextStep}>다음</button>
                </div>
              </div>
              ): (
              <div className={cx('floatContainer')}>
                <p className={cx('planInfo')}>플랜 정보</p>
                { Object.keys(planSelected).length ? (
                  <div className={cx('planInfoBox', 'planBox')}>
                    <div className={cx('titleBox')}>
                      <span className={cx('title')}>{ planSelected.originalPlanName ? planSelected.originalPlanName : planSelected.planName }</span>
                      <span className={cx('cancel')} onClick={() => resetPlanSelect()}>선택취소</span>
                    </div>
                    <div className={cx('mainPartBox')}>
                      <span className={cx('mainPart')}>{ tagKor['MP'][planSelected.mainPart]}</span>
                      <div className={cx('patientName')}>
                        <span>{ planSelected.patientName }</span>
                        { planSelected.patientGender && <div></div> }
                        <span>{ planSelected.patientGender }</span>
                        { planSelected.patientBirth && <div></div> }
                        <span>{ planSelected.patientBirth }</span>
                      </div>
                    </div>
                    <div className={cx('staffName')}>
                      <JobIcon job={planSelected.medicalStaffJob} isCertified={planSelected.isCertified}/>
                      <span>{ planSelected.staffName }</span>
                    </div>
                  </div>
                ) : (
                  <div className={cx('planInfoBox', 'emptyPlan')}>
                    <p>복사하고 싶은 플랜을 선택해 주세요.</p>
                  </div>
                )}
                <div className={cx('planMainPart')}>
                  <p>
                    { Object.keys(planSelected).length ? tagKor['MP'][planSelected.mainPart] : '운동 부위' }
                  </p>
                </div>
                <input
                  className={cx('planInput', !Object.keys(planSelected).length && 'inactive')}
                  placeholder={ !Object.keys(planSelected).length ? '플랜명' : '플랜명을 입력해 주세요' }
                  value={recentPlanName}
                  onChange={(e) => onPlanProgramNameChange(e)}
                  type='text'
                  readOnly={!Object.keys(planSelected).length}
                />
                <input
                  className={cx('planInput', !Object.keys(planSelected).length && 'inactive')}
                  placeholder={ !Object.keys(planSelected).length ? '질환 및 수술명' : '(선택) 질환 및 수술명을 입력해 주세요' }
                  value={recentPlanDiagnosis}
                  onChange={(e) => onPlanProgramDiagnosisChange(e)}
                  type='text'
                  readOnly={!Object.keys(planSelected).length}
                />
                { Object.keys(planSelected).length ? (
                    <div className={cx('dateSelectBox')}>
                      <div className={cx('dateLengthTitle')}>
                        <img src={PlanCalendar} alt='PlanCalendar'/>
                        <span>총 { planSelected.planTotalPlanDays }일 플랜</span>
                      </div>
                      <div
                        className={cx('dateInput', isCalendarOpen && 'active')}
                        ref={(ele) => {refs.current[2] = ele}}
                      >
                        <p className={cx('dateSelect')}
                           onClick={() => toggleCalendar()}
                        >
                          { planDate ? <span>{ planDate }</span> : '희망하는 시작 날짜를 선택해 주세요' }
                        </p>
                        { isCalendarOpen && (
                          <CalendarSmall
                            planTotalPlanDays={planSelected.planTotalPlanDays}
                            setPlanDate={setPlanDate}
                            planDate={planDate}
                            planSelected={planSelected}
                            isCalendarOpen={isCalendarOpen}
                            setIsCalendarOpen={setIsCalendarOpen}
                          />
                        ) }
                      </div>
                    </div>

                ) : ''}
                <div className={cx('btnWrapper')}>
                  <button className={cx('next', isPlanNextBtnActive() && 'active')} onClick={goToPlanNextStep}>다음</button>
                </div>
              </div>
            )}
          </div>
        </>
      ) }
    </div>
  );
};

export default PlanExerciseSelect;