import React, {useEffect, useState} from 'react';
import HeaderNav from "@/common/header/headerNav/headerNav";
import classnames from "classnames/bind";
import styles from './staff.module.scss'
import api from "@/api";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {setInfo} from "@/store/slice/staffSlice";
import {setIsModalBigBtnActive} from "@/store/slice/modalBtnSlice"
import {showToast} from "@/helpers/toastMessage";
import ModalBig from "../../common/modal/modalBig/modalBig";
import FooterGray from "../../common/footer/footerGray/footerGray";
import ModalSmall from "../../common/modal/modalSmall/modalSmall";
import JobIcon from "../../common/jobIcon/jobIcon";
import InputSearchBox from "../../common/input/inputSearchBox/inputSearchBox";
import Pagination from "../../common/pagination/pagination";
import Refresh from "../../../helpers/refresh";
import {joinDate} from "../../../helpers/common";
import {updateNoti} from "../../../store/slice/notiSlice";
import {setMemberCount} from "@/store/slice/paymentSlice";

const WPlusSharp = `${process.env.REACT_APP_RESOURCE_URL}/W_plus_sharp.svg`
const GTriangle = `${process.env.REACT_APP_RESOURCE_URL}/G_triangle.svg`
const LGTriangle = `${process.env.REACT_APP_RESOURCE_URL}/LG_triangle.svg`

const cx = classnames.bind(styles)
const authOptions = [
  {
    auth: 'ADMIN',
    description: '모든 메뉴의 사용 권한을 갖게 됩니다.'
  },
  {
    auth: 'NORMAL',
    description: '그룹 내에서 제한된 사용 권한을 갖게 됩니다.'
  }
]
const staffStatusObj = {
  INVITED: '초대 완료', // 초대 한 상태, 초대 받은 상태
  REQUESTED: '참여 대기', // 직접 가입 후 승인 기다리는 상태, 가입 승인 안 한 상태
  DONE: '등록 완료', // 가입 완
// REJECTED: '초대 거절',
// DELETED: '삭제'
}

const Staff = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const modal = location.state ? location.state.modal : ''

  const staff = useSelector(state => {
    return state.staff.value
  })
  const memberCount = useSelector(state => {
    return state.payment.value.memberCount
  })
  const subscriptionInfo = useSelector(state => {
    return state.subscription.value.subscriptionInfo
  })
  const [isRefresh, setIsRefresh] = useState(false)
  const [isRefreshNoRedirect, setIsRefreshNoRedirect] = useState(false)
  const [isHideMe, setIsHideMe] = useState(false)
  const [isMyAuthOptionsOpen, setIsMyAuthOptionsOpen] = useState(false)
  const [isMyNoteOpen, setIsMyNoteOpen] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [rerenderKey, setRerenderKey] = useState(0)
  const [staffList, setStaffList] = useState([])
  const [modalSmallContent, setModalSmallContent] = useState({
    bodyTitle: '',
    bodySub: '',
    isOneBtn: false,
    isHalfBtn: false,
    btnLongTxt: '',
    btnLongEvent: null,
    btnShortTxt: '',
    btnShortEvent: null,
    values: {},
  })
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [isModalBigOpen, setIsModalBigOpen] = useState(false)
  const [rehabGroupName, setRehabGroupName] = useState('')
  const [inputValues, setInputValues] = useState({
    name: '',
    phoneNumber: '',
    rehabGroupMemberId: 0,
  })
  const [modalBigContent, setModalBigContent] = useState({
    header: '',
    btnSmall: '',
    btnBig: '',
    closeModalBig: null,
    onAction: null,
    values: {},
  })
  const [timeLimitMilli, setTimeLimitMilli] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [timeLimitStr, setTimeLimitStr] = useState('')
  const [tabItems, setTabItems] = useState([
    {
      id: 0,
      tab: '사용자 관리',
      val: '',
    },
    {
      id: 1,
      tab: '병원 통계',
      val: '',
    },
    {
      id: 2,
      tab: '결제 관리',
      val: '',
    },
    {
      id: 3,
      tab: '병원 정보',
      val: '',
    },
  ])

  const openInputModal = (staffItem) => {
    if (rehabGroupName) {
      setRehabGroupName(staff.rehabGroupName)
    }

    if (staffItem) {
      let newInputValues = {
        name: staffItem.medicalStaffName,
        phoneNumber: staffItem.medicalStaffPhoneNumber,
        rehabGroupMemberId: staffItem.rehabGroupMemberId
      }
      setInputValues(newInputValues)

      setModalBigContent({
        header: '사용자 초대',
        btnSmall: '닫기',
        btnBig: '다시 초대하기',
        closeModalBig: () => setIsModalBigOpen(false),
        onAction: (values) => reinviteStaff(values),
      })
    } else {
      setModalBigContent({
        header: '사용자 초대',
        btnSmall: '닫기',
        btnBig: '초대하기',
        closeModalBig: () => setIsModalBigOpen(false),
        onAction: (values) => inviteStaff(values),
      })
    }

    setIsModalBigOpen(true)
  }

  // const getStaffNumber = (data) => {
    // let newOnListStaff = 0
    // let newDoneStaff = 0
    // let newAdminNumbers = 0
    // let isStop = false

    // data.forEach((staff, idx) => {
      // if (staff.status !== 'REJECTED' && staff.status !== 'DELETED') {
      //   newOnListStaff += 1
      // }
      // if (staff.status === 'DONE') {
      //   newDoneStaff += 1
      // }
      // if (staff.groupRole === 'ADMIN') {
      //   newAdminNumbers += 1
      // }

    //   if (idx + 1 === data.length) {
    //     isStop = true
    //   }
    // })

    // if (isStop) {
      // setAdminNumbers(newAdminNumbers)
      // updateStaffList(data)
      // updateStaffList(data, newDoneStaff, newAdminNumbers)
    // }
  // }

  const updateStaffList = (data, newDoneStaff, newAdminNumbers) => {
    let isStop = false
    if (!data.length) {
      return
    }

    data.forEach((staffItem, idx) => {
      if (staffItem.medicalStaffJoinedDateTime) {
        staffItem.date = joinDate(staffItem.medicalStaffJoinedDateTime)
      }

      staffItem.isAuthOptionsOpen = false
      staffItem.isNoteOpen = false

      // 본인 계정 확인
      // if(staff.rehabGroupMemberId && (staffItem.rehabGroupMemberId === staff.rehabGroupMemberId)) {
      //   staffItem.isMe = true
      //
      //   // 본인 계정 맨위로 올림
      //   if(idx !== 0) {
      //     data.splice(idx, 1)
      //     data.splice(0, 0, staffItem)
      //   }
      // } else {
      //   staffItem.isMe = false
      // }

      // 권한 부여 확인
      if (staffItem.status === 'INVITED' || staffItem.status === 'REQUESTED' || staff.groupRole !== 'ADMIN') {
        staffItem.isAuthEditable = false
      } else {
        staffItem.isAuthEditable = true
      }

      // if((newDoneStaff === 1 && staffItem.isMe) || (newAdminNumbers < 2 && staffItem.hospitalGroupRole === 'ADMIN') || (staff.hospitalGroupRole === 'NORMAL' && !staffItem.isMe)) {
      //   staffItem.isNoteActive = false
      // } else {
      //   staffItem.isNoteActive = true
      // }

      // 사용자 목록 업데이트 후 리렌더링
      if (data.length === idx + 1) {
        isStop = true
      }
    })

    if (isStop) {
      setStaffList(data)
      setRerenderKey((prev) => prev += 1)
    }

  }

  const getStaffList = (page = 1, keyword = '') => {
    setStaffList([])
    setIsLoading(true)
    api.getRehabGroupMembers(staff.rehabGroupId, page, 20, keyword).then((res) => {
      updateStaffList(res.data.list)
      setTotalPage(res.data.totalPage)
      setCurrentPage(res.data.currentPage)
      setIsLoading(false)
      if(!keyword || (keyword && keyword === staff.name)) {
        setIsHideMe(false)
      } else {
        setIsHideMe(true)
      }

      getMembersCount()
    }).catch((err) => {
      if (err.response.data.code === '1001') {
        navigate('/myPage')
      }
    })
  }

  const getMembersCount = () => {
    api.getRehabGroupMembersCount(staff.rehabGroupId).then((res) => {
      dispatch(setMemberCount(res.data.count))
    })
  }

  const checkRole = () => {
    let getStaffRole = staff.groupRole
    if (!getStaffRole) {
      return
    }

    let accessToken = localStorage.getItem('accessToken')
    if (!accessToken) {
      return
    }

    let base64Payload = accessToken.split('.')[1]
    let buffer = window.Buffer || require("buffer").Buffer
    let payload = buffer.from(base64Payload, 'base64')
    let tokenRole = JSON.parse(payload.toString())['group-role']

    if (getStaffRole !== tokenRole) {
      let refreshToken = localStorage.getItem('refreshToken')
      api.postTokenRefresh(refreshToken).then((res) => {
        let data = res.data
        localStorage.setItem('accessToken', data.accessToken)
        localStorage.setItem('refreshToken', data.refreshToken)
        checkRole()
      }).catch((err) => {
        console.log(err)
      })
    } else {
      getStaffList()
      if (timeLimitStr || timeLimitMilli) {
        clearInterval(countdown())
      }
    }
  }

  const checkIfAuthActive = (staffItem) => {
    if (!staff.groupRole) {
      return
    }

    if (!staffItem.isAuthEditable || staff.groupRole === 'NORMAL' || staff.groupRole !== 'ADMIN') {
      // if(!staffItem.isAuthEditable || staff.groupRole === 'NORMAL' || (adminNumbers < 2 && staffItem.isMe)) {
      return false
    } else {
      return true
    }
  }

  const closeAllDropdowns = () => {
    let tempStaffList = staffList
    tempStaffList.forEach((tempStaff) => {
      tempStaff.isNoteOpen = false
    })

    tempStaffList.forEach((tempStaff) => {
      tempStaff.isAuthOptionsOpen = false
    })

    setStaffList(tempStaffList)
    setRerenderKey((prev) => prev += 1)

    setIsMyNoteOpen(false)
    setIsMyAuthOptionsOpen(false)
  }

  const toggleAuthDropdown = (e, staffItem, idx) => {
    e.stopPropagation()
    if (!checkIfAuthActive(staffItem)) {
      return
    }

    if (staffItem.isAuthOptionsOpen) {
      closeAllDropdowns()
    } else {
      closeAllDropdowns()
      let newArr = staffList
      newArr[idx]['isAuthOptionsOpen'] = true
      setStaffList(newArr)
    }

    setRerenderKey((prev) => prev += 1)
  }

  const toggleMyAuthDropdown = (e) => {
    e.stopPropagation()
    if (staff.groupRole !== 'ADMIN') {
      return
    }

    if (isMyAuthOptionsOpen) {
      closeAllDropdowns()
    } else {
      closeAllDropdowns()
      setIsMyAuthOptionsOpen(true)
    }
  }

  const toggleMyNoteDropdown = (e) => {
    e.stopPropagation()

    // if(staffList.length) {
      if (isMyNoteOpen) {
        closeAllDropdowns()
      } else {
        closeAllDropdowns()
        setIsMyNoteOpen(true)
      }
    // }
  }

  const toggleNoteDropdown = (e, staffItem, idx) => {
    e.stopPropagation()

    if (staffItem.isNoteOpen) {
      closeAllDropdowns()
    } else {
      closeAllDropdowns()
      let newArr = staffList
      newArr[idx]['isNoteOpen'] = true
      setStaffList(newArr)
    }
  }

  const getStatusColor = (status) => {
    if (status === 'INVITED') {
      return 'yellow'
    } else if (status === 'REQUESTED') {
      return 'blue'
    } else {
      return 'mint'
    }
  }

  const selectMyAuth = (auth) => {
    if (staff.groupRole === auth) {
      return
    }

    let body = {
      "role": auth
    }

    api.postChangeRole(staff.rehabGroupId, staff.rehabGroupMemberId, body).then(() => {
      setIsRefreshNoRedirect(true)
    }).catch((err) => {
      if (err.response.data.code === '3012') {
        showToast('error', '그룹 내 최소 1명의 관리자가 필요합니다.')
      }
    })
  }

  const selectAuth = (staffItem, auth) => {
    if (staffItem.groupRole === auth) {
      return
    }

    let body = {
      "role": auth
    }

    api.postChangeRole(staff.rehabGroupId, staffItem.rehabGroupMemberId, body).then(() => {
      getStaffList(currentPage)
    }).catch((err) => {
      console.log(err)
    })
  }

  const openModalToCancel = (staffItem) => {
    setModalSmallContent({
      isOneBtn: false,
      bodyTitle: `${staffItem.medicalStaffName}님의 초대를 취소하시겠습니까?`,
      bodySub: '취소 진행 시 복구할 수 없으니 신중히 진행해 주세요.',
      btnSecondTxt: '닫기',
      btnFirstTxt: '취소하기',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      btnFirstEvent: (values) => cancelInvitation(values),
      values: {
        name: staffItem.medicalStaffName,
        id: staffItem.rehabGroupMemberId
      }
    })

    setIsModalSmallOpen(true)
  }

  const cancelInvitation = (values) => {
    api.cancelInvitation(staff.rehabGroupId, values.id).then(() => {
      showToast('error', `${values.name}님의 초대가 취소되었습니다.`)
      getStaffList()
      setIsModalSmallOpen(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  const openModalToExitStaff = (staffItem) => {
    setModalSmallContent({
      isOneBtn: false,
      bodyTitle: `${staffItem.medicalStaffName}님을 삭제하시겠습니까?`,
      bodySub: '삭제 진행 시 복구할 수 없으니 신중히 진행해 주세요.',
      btnSecondTxt: '닫기',
      btnFirstTxt: '삭제하기',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      btnFirstEvent: (values) => exitStaff(values),
      values: {
        name: staffItem.medicalStaffName,
        id: staffItem.rehabGroupMemberId
      }
    })
    setIsModalSmallOpen(true)
  }

  const exitStaff = (values) => {
    api.postHospitalGroupExit(staff.rehabGroupId, values.id).then(() => {
      showToast('error', `${values.name}님이 삭제되었습니다.`)
      getStaffList()
      setIsModalSmallOpen(false)
    }).catch((err) => {
      if (err.response.data.code === '3013') {
        showToast('error', '진행 중인 플랜이 있어, 삭제가 불가능합니다.')
      } else if (err.response.data.code === '3012') {
        showToast('error', '그룹 내 최소 1명의 관리자가 필요합니다.')
      }
    })
  }

  const openModalToApprove = (staffItem) => {
    setModalSmallContent({
      isOneBtn: false,
      bodyTitle: `${staffItem.medicalStaffName}님의 가입을 승인하시겠습니까?`,
      bodySub: '',
      btnSecondTxt: '닫기',
      btnFirstTxt: '승인하기',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      btnFirstEvent: (values) => approveSignup(values),
      values: {
        name: staffItem.medicalStaffName,
        id: staffItem.rehabGroupMemberId
      }
    })
    setIsModalSmallOpen(true)
  }

  const approveSignup = (values) => {
    api.postRehabGroupApproveRequest(staff.rehabGroupId, values.id).then(() => {
      getStaffList()
      setIsModalSmallOpen(false)
      showToast('success', `${values.name}님이 등록되었습니다.`)
    }).catch((err) => {
      console.log(err)
    })
  }

  const openModalToExitMyself = () => {
    setModalSmallContent({
      isOneBtn: false,
      bodyTitle: '나가시겠습니까?',
      bodySub: '나가기 진행 시 복구할 수 없으니 신중히 진행해 주세요.',
      btnSecondTxt: '닫기',
      btnFirstTxt: '나가기',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      btnFirstEvent: (values) => exitMyself(values),
      values: {
        id: staff.rehabGroupMemberId
      }
    })
    setIsModalSmallOpen(true)
  }

  const exitMyself = (values) => {
    api.postHospitalGroupExitMySelf(staff.rehabGroupId, values.id).then(() => {
      setIsRefresh(true)
    }).catch((err) => {
      if (err.response.data.code === '3013') {
        showToast('error', '진행 중인 플랜이 있어, 삭제가 불가능합니다.')
      } else if (err.response.data.code === '3012') {
        showToast('error', '그룹 내 최소 1명의 관리자가 필요합니다.')
      }
    })
  }

  const openModalToReject = (staffItem) => {
    setIsModalSmallOpen(true)
    setModalSmallContent({
      isOneBtn: false,
      bodyTitle: `${staffItem.medicalStaffName}님의 가입을 거절하시겠습니까?`,
      bodySub: '',
      btnSecondTxt: '닫기',
      btnFirstTxt: '거절하기',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      btnFirstEvent: (values) => rejectSignup(values),
      values: {
        name: staffItem.medicalStaffName,
        id: staffItem.rehabGroupMemberId
      }
    })
  }

  const rejectSignup = (values) => {
    api.postRehabGroupRejectRequest(staff.rehabGroupId, values.id).then(() => {
      showToast('error', `${values.name}님을 거절했습니다.`)
      getStaffList()
      setIsModalSmallOpen(false)
    })
  }

  const isInviteBtnActive = () => {
    let result = false

    if (modalBigContent.btnBig === '초대하기') {
      if (inputValues.name && inputValues.phoneNumber.length === 13) {
        result = true
      }
    } else if (!isShowCount()) {
      result = true
    }

    return result
  }

  const countdown = () => {
    let timeSeconds = timeLimitMilli / 1000
    let min = Math.floor(timeSeconds / 60)
    let sec = (timeSeconds % 60).toFixed(0)
    let newTimeStr = min + ':' + (sec < 10 ? '0' : '') + sec
    setTimeLimitStr(newTimeStr)
  }

  const startCountdown = () => {
    setTimeLimitMilli(180000)
    setTimeLimitStr('3:00')
    let intervalFunc = () => {
      setInterval(() => {
        setTimeLimitMilli((prev) => prev - 1000)

        if (timeLimitStr === '0:00' || timeLimitStr === 'NaN:NaN') {
          clearInterval(intervalFunc)
        }
      }, 1000)
    }
    intervalFunc()
  }

  const reinviteStaff = (values) => {
    api.postRehabGroupResend(staff.rehabGroupId, values.rehabGroupMemberId).then(() => {
      showToast('success', `${values.name}님이 초대되었습니다.`)
      setIsModalBigOpen(false)
      setInputValues({
        name: '',
        phoneNumber: '',
        rehabGroupMemberId: 0,
      })
      startCountdown()
    }).catch((err) => {
      console.log(err)
    })
  }

  const inviteStaff = (values) => {
    if (!values.isInviteBtnActive) {
      return
    }
    let body = {
      phoneNumber: values.phoneNumber,
      name: values.name
    }
    api.postInvitation(staff.rehabGroupId, body).then(() => {
      getStaffList()
      showToast('success', `${values.name}님이 초대되었습니다.`)
      setIsModalBigOpen(false)
      setInputValues({
        name: '',
        phoneNumber: '',
        rehabGroupMemberId: 0,
      })
      startCountdown()
    }).catch((err) => {
      if (err.response.data.code === '1002') {
        showToast('error', '이름과 휴대폰 번호를 확인해주세요.')
      } else if (err.response.data.code === '3010') {
        showToast('error', '이미 등록된 사용자입니다.')
        setIsModalBigOpen(false)
        setInputValues({
          name: '',
          phoneNumber: '',
          rehabGroupMemberId: 0
        })
      } else if (err.response.data.code === '1105') {
        showToast('error', '이미 초대된 사용자입니다.')
        setIsModalBigOpen(false)
        setInputValues({
          name: '',
          phoneNumber: '',
          rehabGroupMemberId: 0,
        })
      }
    })
  }

  const isShowCount = () => {
    if (modalBigContent.btnBig === '다시 초대하기' && 0 < timeLimitMilli) {
      return true
    } else {
      return false
    }
  }

  const onNameChange = (e) => {
    setInputValues({
      name: e.target.value.trim(),
      phoneNumber: inputValues.phoneNumber,
      rehabGroupMemberId: inputValues.rehabGroupMemberId
    })
  }

  const onSearch = () => {
    if (keyword) {
      getStaffList(1, keyword)
    }
  }

  const checkMemberCount = () => {
    if(memberCount < subscriptionInfo.maxRehabGroupMemberCount) {
      openInputModal('')
    } else {
      setModalSmallContent({
        isOneBtn: false,
        bodyTitle: '그룹 참여자 수 제한 초과',
        bodySub: '그룹 참여자를 추가하고 싶은 경우,<br/>기등록된 사용자를 삭제하거나<br/>서비스 구독에서 상위 요금제로 변경해 주세요.',
        btnSecondTxt: '닫기',
        btnFirstTxt: '서비스 구독 바로하기',
        btnSecondEvent: () => setIsModalSmallOpen(false),
        btnFirstEvent: () => navigate('/payment'),
      })
      setIsModalSmallOpen(true)
    }
  }

  const onPhoneChange = (e) => {
    setInputValues({
      name: inputValues.name,
      phoneNumber: e.target.value.replace(/[^0-9]/g, ''),
      rehabGroupMemberId: inputValues.rehabGroupMemberId
    })
  }

  const onAllClick = (e) => {
    if (e.target.className.includes('noteOptions')) {
      return
    }
    closeAllDropdowns()
  }

  const getKeyword = (keyword) => {
    setKeyword(keyword)
    if (!keyword) {
      getStaffList()
    }
  }

  useEffect(() => {
    if (!isModalSmallOpen) {
      setInputValues({
        name: '',
        phoneNumber: '',
        rehabGroupMemberId: 0,
      })
    }
  }, [isModalBigOpen])

  useEffect(() => {
    if(localStorage.getItem('accessToken')) {
      api.getStaff().then((res) => {
        const staffInfo = res.data
        if (staffInfo.rehabGroupId !== staff.rehabGroupId || staffInfo.groupRole !== staff.groupRole) {
          dispatch(setInfo(staffInfo))
        }
        if (staffInfo.rehabGroupId === null) {
          navigate('/organ/group/select', { replace: true })
        }
        api.getNotiUnseenCount().then((res) => {
          const isMark = res.data.count > 0
          dispatch(updateNoti(isMark))
        })
      }).catch((e) => {
        console.log(e)
      })
    } else {
      navigate('/')
    }

    if(modal === 'memberLimit') {
      setModalSmallContent({
        isOneBtn: false,
        bodyTitle: '그룹 참여자 수 제한 초과',
        bodySub: '그룹 참여자를 추가하고 싶은 경우,<br/>기등록된 사용자를 삭제하거나<br/>서비스 구독에서 상위 요금제로 변경해 주세요.',
        btnSecondTxt: '닫기',
        btnFirstTxt: '서비스 구독 바로가기',
        btnSecondEvent: () => setIsModalSmallOpen(false),
        btnFirstEvent: () => navigate('/payment'),
        values: {}
      })

      setIsModalSmallOpen(true)
    }

    return () => {
      clearInterval(countdown())
    }
  }, [])

  useEffect(() => {
    setModalBigContent({
      ...modalBigContent,
      values: ({
        ...inputValues,
        isInviteBtnActive: isInviteBtnActive()
      })
    })

    if (inputValues.phoneNumber.length === 11) {
      setInputValues({
        name: inputValues.name,
        phoneNumber: inputValues.phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/g, '$1-$2-$3'),
        rehabGroupMemberId: inputValues.rehabGroupMemberId
      })
    }
  }, [inputValues])

  useEffect(() => {
    dispatch(setIsModalBigBtnActive(isInviteBtnActive()))
  }, [modalBigContent.btnBig, inputValues.name, inputValues.phoneNumber.length, isShowCount()])

  useEffect(() => {
    if (!staff.id) {
      return
    }
    setRehabGroupName(staff.rehabGroupName)
    checkRole()

    if (staff.groupRole && staff.groupRole !== 'ADMIN') {
      setTabItems([tabItems[0]])
    }

    return () => {
      clearInterval(countdown())
    }
  }, [staff])

  useEffect(() => {
    countdown(timeLimitMilli)
  }, [timeLimitMilli])

  return (
    <div className={cx('staff')} onClick={(e) => onAllClick(e)}>
      {isRefresh && <Refresh/>}
      {isRefreshNoRedirect && <Refresh isRedirectHome={false} isUpdateToken={false}/>}
      <HeaderNav/>
      {/*<div className={cx('titleContainer')}>*/}
      {/*  <div>*/}
      {/*    <span>사용자 관리</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className={cx('tabContainer')}>*/}
      {/*  <div>*/}
      {/*    <MainTabs tabItems={ tabItems } activeTabId={activeTabId} onTabItemClick={onTabItemClick}/>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={cx('content')}>
        {/*<div className={cx('contentWrapper')}>*/}
        <div className={cx('staffTop')}>
          {/*<div>*/}
          {/*  <div className={cx('hospital')}>*/}
          {/*    <span>{staff.rehabGroupName}</span>*/}
          {/*  </div>*/}
          {/*  <p><span>{onListStaff}</span> 명의 사용자</p>*/}
          {/*</div>*/}


          <div className={cx('inputWrapper')}>
            <InputSearchBox
              placeholder='사용자 이름 검색'
              getKeyword={getKeyword}
              keyword={keyword}
              onSearch={onSearch}
              isSmall={true}
            />
          </div>
          <div className={cx('btnWrapper')}>
            <p>그룹 참여자 수<span>{ memberCount }명</span></p>
            {staff.groupRole === 'ADMIN' && (
              <button className={cx('inviteBtn')} onClick={() => checkMemberCount()}>
                <img src={WPlusSharp} alt='WPlusSharp'/>
                <span>초대하기</span>
              </button>
            )}
          </div>

        </div>
        {!isLoading ? (
          <div className={cx('listWrapper')}>
            <div className={cx('staffList')} key={rerenderKey}>
              <div className={cx('listHeader')}>
                <p className={cx('name')}>이름</p>
                <p className={cx('auth')}>권한</p>
                <p className={cx('date')}>그룹 등록일</p>
                <p className={cx('status')}>상태</p>
                <p className={cx('note')}>비고</p>
              </div>
              { (currentPage === 1 && !isHideMe) && (
                <>
                  <div className={cx('myContent')}>
                    <div className={cx('name')}>
                      <JobIcon job={staff.job} isCertified={staff.isCertified}/>
                      <span>
                        {staff.name}
                        ({staff.phoneNumber.slice(7, 11)})
                      </span>
                    </div>
                    <div className={cx('auth')}>
                      <div
                        className={cx('authSelected', staff.groupRole !== 'ADMIN' && 'inactive', isMyAuthOptionsOpen && 'active')}
                        onClick={(e) => toggleMyAuthDropdown(e)}
                      >
                        <span>{staff.groupRole === 'ADMIN' ? '관리자' : staff.groupRole === 'NORMAL' ? '일반' : ''}</span>
                        {staff.groupRole === 'ADMIN'}
                        <img src={staff.groupRole === 'ADMIN' ? GTriangle : LGTriangle} alt='triangle'
                             className={cx(isMyAuthOptionsOpen && 'rotate')}/>
                      </div>
                      {isMyAuthOptionsOpen && (
                        <div className={cx('authOptions')}>
                          {authOptions.map((authOption) => (
                            <div key={authOption.auth}
                                 className={cx(authOption.auth === staff.groupRole && 'active')}
                                 onClick={() => selectMyAuth(authOption.auth)}>
                              <p
                                className={cx('option')}>{authOption.auth === 'ADMIN' ? '관리자' : authOption.auth === 'NORMAL' ? '일반' : ''}</p>
                              <p className={cx('description')}>{authOption.description}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className={cx('date')}>
                      { staff.rehabGroupJoinedDateTime && (
                        <span>{joinDate(staff.rehabGroupJoinedDateTime)}</span>
                      ) }
                    </div>
                    <div className={cx('status')}>
                      <span className={cx('done')}>{staffStatusObj['DONE']}</span>
                    </div>
                    <div className={cx('note')}>
                      <div onClick={(e) => toggleMyNoteDropdown(e)}
                           className={cx('noteImg', (isMyNoteOpen) && 'notePrimary' )}></div>
                      {isMyNoteOpen && (
                        <div className={cx('noteOptions')}>
                          <div onClick={() => openModalToExitMyself()}>나가기</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr/>
                </>
              )}
              {staffList.map((staffItem, idx) => (
                <div className={cx('listContentWrapper')} key={idx}>
                  <div
                    className={cx((staffItem.status === 'REJECTED' || staffItem.status === 'DELETED') && 'inactive', 'listContent')}>
                    <div className={cx('name')}>
                      {staffItem.medicalStaffJob && (
                        <JobIcon job={staffItem.medicalStaffJob} isCertified={staffItem.isCertified}/>
                      )}
                      {staffItem.medicalStaffPhoneNumber ? (
                        <span>{staffItem.medicalStaffName}({staffItem.medicalStaffPhoneNumber.slice(7, 11)})</span>
                      ) : (
                        <span>{staffItem.medicalStaffName}</span>
                      )}
                    </div>
                    <div className={cx('auth')}>
                      <div
                        className={cx('authSelected', !checkIfAuthActive(staffItem) && 'inactive', staffItem.isAuthOptionsOpen && 'active')}
                        onClick={(e) => toggleAuthDropdown(e, staffItem, idx)}
                      >
                        <span>{staffItem.groupRole === 'ADMIN' ? '관리자' : staffItem.groupRole === 'NORMAL' ? '일반' : ''}</span>
                        {staffItem.isAuthEditable}
                        <img src={staffItem.isAuthEditable ? GTriangle : LGTriangle} alt='triangle'
                             className={cx(staffItem.isAuthOptionsOpen && 'rotate')}/>
                      </div>
                      {staffItem.isAuthOptionsOpen && (
                        <div className={cx('authOptions')}>
                          {authOptions.map((authOption) => (
                            <div key={authOption.auth}
                                 className={cx(authOption.auth === staffItem.groupRole && 'active')}
                                 onClick={() => selectAuth(staffItem, authOption.auth)}>
                              <p
                                className={cx('option')}>{authOption.auth === 'ADMIN' ? '관리자' : authOption.auth === 'NORMAL' ? '일반' : ''}</p>
                              <p className={cx('description')}>{authOption.description}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className={cx('date')}>
                      <span>{staffItem.date ? staffItem.date : '-'}</span>
                    </div>
                    <div className={cx('status')}>
                      <span className={cx(getStatusColor(staffItem.status))}>{staffStatusObj[staffItem.status]}</span>
                    </div>
                    {staffItem.status !== 'REQUESTED' && (
                      <div className={cx('note')}>
                        <div onClick={(e) => toggleNoteDropdown(e, staffItem, idx)}
                             className={cx('noteImg', staffItem.isNoteOpen && 'notePrimary', staff.groupRole !== 'ADMIN' && 'inactive')}></div>
                        {/*<div onClick={(e) => toggleNoteDropdown(e, staffItem, idx)} className={cx('noteImg', staffItem.isNoteActive && 'active', staffItem.isNoteOpen && 'notePrimary')}></div>*/}
                        {staffItem.isNoteOpen && (
                          <div className={cx('noteOptions')}>
                            {staffItem.status === 'INVITED' && staff.groupRole === 'ADMIN' && (
                              <div onClick={() => openInputModal(staffItem)}>
                                다시 초대하기
                              </div>
                            )}
                            {staffItem.status === 'INVITED' && staff.groupRole === 'ADMIN' && (
                              <div onClick={() => openModalToCancel(staffItem)}>
                                초대 취소하기
                              </div>
                            )}
                            {(staffItem.status === 'DONE' && staff.groupRole === 'ADMIN') && (
                              // {staffItem.status === 'DONE' && staff.groupRole === 'ADMIN' && !staffItem.isMe && (
                              <div onClick={() => openModalToExitStaff(staffItem)}>
                                삭제하기
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {staffItem.status === 'REQUESTED' && staff.groupRole === 'ADMIN' && (
                      <div className={cx('signupAction')}>
                        <button className={cx('green')} onClick={() => openModalToApprove(staffItem)}>승인</button>
                        <button className={cx('red')} onClick={() => openModalToReject(staffItem)}>거절</button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {totalPage > 1 ? (
              <Pagination currentPage={currentPage} totalPage={totalPage} getList={getStaffList}/>
            ) : ''
            }
          </div>
        ) : (
          <div className={cx('listWrapper')}>
            <div className={cx('staffList', 'loading')}>
              <div className={cx('listHeader')}>
                <p className={cx('name')}>이름</p>
                <p className={cx('auth')}>권한</p>
                <p className={cx('date')}>그룹 등록일</p>
                <p className={cx('status')}>상태</p>
                <p className={cx('note')}>비고</p>
              </div>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((staffItem, idx) => (
                <div className={cx('listContentWrapper')} key={idx}>
                  <div
                    className={cx('listContent')}>
                    <div className={cx('name')}></div>
                    <div className={cx('auth')}></div>
                    <div className={cx('date')}></div>
                    <div className={cx('status')}></div>
                    <div className={cx('note')}></div>
                  </div>
                  {!idx ? (
                    <hr/>
                  ) : ''}
                </div>
              ))}
              <div>
              </div>
            </div>
          </div>

        )}

        {/*</div>*/}

      </div>
      <FooterGray position='relative'/>
      <ModalBig
        header={modalBigContent.header}
        btnSmall={modalBigContent.btnSmall}
        btnBig={modalBigContent.btnBig}
        closeModalBig={modalBigContent.closeModalBig}
        isModalBigOpen={isModalBigOpen}
        onAction={modalBigContent.onAction}
        values={modalBigContent.values}
      >
        <div className={cx('addCardInputBox')}>
          <div className={cx('infoBox')}>
            <p>그룹 이름</p>
            <input
              type="text"
              value={rehabGroupName}
              disabled
            />
          </div>
          <div className={cx('infoBox')}>
            <p>이름</p>
            <input
              disabled={modalBigContent.btnBig === '다시 초대하기'}
              type="text"
              placeholder="사용자 이름을 입력하세요."
              value={inputValues.name}
              onChange={onNameChange}
              name='name'
            />
          </div>
          <div className={cx('infoBox')}>
            <p>휴대폰 번호(숫자만 입력)</p>
            <input
              disabled={modalBigContent.btnBig === '다시 초대하기'}
              type="text"
              value={inputValues.phoneNumber}
              onChange={onPhoneChange}
              maxLength={13}
              name='phoneNumber'
            />
          </div>
          {isShowCount() && (
            <p>
              잠시 후 다시 시도해 주세요.<span>{timeLimitStr}</span>
            </p>
          )}
        </div>
      </ModalBig>
      {isModalSmallOpen && (
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          bodySub={modalSmallContent.bodySub}
          isOneBtn={modalSmallContent.isOneBtn}
          btnSecondTxt={modalSmallContent.btnSecondTxt}
          btnFirstTxt={modalSmallContent.btnFirstTxt}
          btnSecondEvent={modalSmallContent.btnSecondEvent}
          btnFirstEvent={modalSmallContent.btnFirstEvent}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}
          values={modalSmallContent.values}
        />
      )}
    </div>
  );
};

export default Staff;