import React, {useEffect, useState} from 'react';
import styles from './programInfo.module.scss'
import classnames from "classnames/bind";
import {useDispatch, useSelector} from "react-redux";
import {getToday, makeCompressedProgram, setMinutes} from "../../../helpers/common";
import {
  setProgramCreationParams,
  setProgramCreationStep
} from "../../../store/slice/programSlice";
import tagKor from '@/data/tagKor'
import ProgramExerciseItemDetail from "./programExerciseItemDetail/programExerciseItemDetail";
import api from "@/api";
import {showToast} from "../../../helpers/toastMessage";
import {useNavigate} from "react-router-dom";
import {setActiveFilterRehabId} from "../../../store/slice/rehabSlice";

const CloseRound = `${process.env.REACT_APP_RESOURCE_URL}/close_round.svg`

const cx = classnames.bind(styles)
const ProgramInfo = () => {
  let dispatch = useDispatch()
  let navigate = useNavigate()
  const addedProgramExerciseArr = useSelector(state => {
    return state.program.value.addedProgramExerciseArr
  })
  const programCreationParams = useSelector(state => {
    return state.program.value.programCreationParams
  })
  const myProgramIdToEdit = useSelector(state => {
    return state.program.value.myProgramIdToEdit
  })

  const [toolTags, setToolTags] = useState([])
  const [isTooltipHide, setIsTooltipHide] = useState(false)
  const [time, setTime] = useState(0)
  const [isShared, setIsShared] = useState(true)
  const [isInputFocus, setIsInputFocus] = useState(false)
  const [isBigInput, setIsBigInput] = useState(false)

  const onPrecautionChange = (e) => {
    dispatch(setProgramCreationParams({
      ...programCreationParams,
      precaution: e.target.value
    }))
  }

  const toggleShareBtn = () => {
    if(isShared) {
      showToast('error', '프로그램 공유 기능이 해제되었습니다.')
    } else {
      showToast('success', '프로그램 공유 기능이 설정되었습니다.')
    }
    setIsShared(!isShared)
  }

  const createProgram = () => {
    let programData = {
      ...programCreationParams,
      compressedExScript: makeCompressedProgram(addedProgramExerciseArr),
      tags: [...programCreationParams.tags, ...toolTags]
    }

    if(myProgramIdToEdit !== null) {
      api.editProgram(myProgramIdToEdit, programData).then(() => {
        showToast('success', '프로그램이 수정되었습니다.')
        navigate('/rehab?tabId=2')
        dispatch(setActiveFilterRehabId(2))
      })
    } else {
      api.createProgram(programData).then(() => {
        showToast('success', '프로그램이 생성되었습니다.')
        navigate('/rehab?tabId=2')
        dispatch(setActiveFilterRehabId(2))
      })
    }
  }

  useEffect(() => {
    dispatch(setProgramCreationParams({
      ...programCreationParams,
      share: isShared
    }))
  }, [isShared])

  useEffect(() => {
    if(programCreationParams.precaution.length > 15) {
      setIsBigInput(true)
    } else {
      setIsBigInput(false)
    }
  }, [programCreationParams.precaution.length])

  useEffect(() => {
    if(!programCreationParams.name) {
      dispatch(setProgramCreationParams({
        ...programCreationParams,
        name: getToday()
      }))
    }

    let toolArr = []
    let timeCount = 0
    addedProgramExerciseArr.forEach((addedExercise) => {
      if(addedExercise) {
        if(addedExercise.tag) {
          if(addedExercise.tag.TO) {
            toolArr = [...toolArr, ...addedExercise.tag.TO]
          }
        } else {
          if(addedExercise.exerciseTags.TO) {
            toolArr = [...toolArr, ...addedExercise.exerciseTags.TO]
          }
        }

        timeCount += (addedExercise.media[`M${addedExercise.time ? addedExercise.time : addedExercise.count}`].length) * addedExercise.repeat
      }
    })

    setTime(setMinutes(timeCount))
    setToolTags([...new Set(toolArr)])
    setIsShared(programCreationParams.share)

    // position: sticky 작동하려면 body에 걸려있는 overflow hidden 풀어줘야함
    let body = document.getElementsByTagName('body')[0]
    body.style.overflowX = 'visible'
    return () => body.style.overflowX = 'hidden'
  }, [])

  return (
    <div className={cx('programInfo')} onClick={() => setIsTooltipHide(true)}>
      <div className={cx('rightWrapper')}>
        <div className={cx('programTitle')}>
          <p className={cx('myProgram')}>나만의 프로그램</p>
          <p className={cx('title')}>{ programCreationParams.name ? programCreationParams.name : getToday() }</p>
        </div>
        <div className={cx('programContent')}>
          <p>
            <span className={cx('contentTitle')}>부위</span>
            <span className={cx('contentDetail')}>
              { programCreationParams.tags.length && (
                programCreationParams.tags.map((tag, idx) => (
                  <span key={idx}>
                    { tagKor['MP'][tag] }
                    { idx !== programCreationParams.tags.length - 1 ? (
                      <span>, &nbsp;</span>
                    ) : ''}
                  </span>
                ))
              )}
            </span>
          </p>
          <p>
            <span className={cx('contentTitle')}>질환 및 수술명</span>
            <span className={cx('contentDetail')}>{ programCreationParams.diagnosis.trim() ? programCreationParams.diagnosis : '-' }</span>
          </p>
          <p>
            <span className={cx('contentTitle')}>단계</span>
            <span className={cx('contentDetail')}>{ programCreationParams.phase.trim() ? programCreationParams.phase : '-' }</span>
          </p>
          <p>
            <span className={cx('contentTitle')}>도구</span>
            <span className={cx('contentDetail')}>
              { toolTags.length && (
                toolTags.map((tag, idx) => (
                  <span key={idx}>
                    { tagKor['TO'][tag] }
                    { idx !== toolTags.length - 1 ? (
                      <span>, &nbsp;</span>
                    ) : ''}
                  </span>
                ))
              )}
            </span>
          </p>
          <p>
            <span className={cx('contentTitle')}>주의사항</span>
            <span className={cx('contentDetail')}>{ programCreationParams.precaution.trim() ? programCreationParams.precaution : '-' }</span>
          </p>
        </div>

        <div className={cx('list')}>
          <div className={cx('listTitle')}>
            <span className={cx('listOrder')}>진행 순서</span>
            <span className={cx('totalTime')}>시간 :<span> { time }분</span></span>
          </div>
          <div className={cx('listContainer')}>
            { addedProgramExerciseArr.map((exercise, idx) => (
              <ProgramExerciseItemDetail exercise={exercise} key={idx}/>
            ))}
          </div>
        </div>

      </div>
      <div className={cx('leftWrapper')}>
        <div className={cx('floatContainer')}>
          <p>프로그램 정보</p>
          <div className={cx('shareBox')}>
            {isShared && (
              <span className={cx('tooltiptext', isTooltipHide && 'hide')}>
                <span>공유 기능이 설정되어 있어요!</span><br/>공유 프로그램이 등록됩니다.
              </span>
            )}
            <span>프로그램 공유하기</span>
            <div className={cx('toggleBtnWrapper')}>
              <p className = {cx('toggleBtn')} onClick={() => toggleShareBtn()}>
                <span className={cx(isShared && 'on')}></span>
              </p>
              <span>{ isShared ? '켜짐' : '꺼짐' }</span>
            </div>
          </div>
          <div className={cx('programInput')}>
            <textarea
              className={cx(isBigInput && 'big', isInputFocus && 'borderPrimary', programCreationParams.precaution.length && isInputFocus && 'paddingRight')}
              placeholder='주의사항을 입력해 주세요'
              type='text'
              maxLength={48}
              value={programCreationParams.precaution}
              onChange={onPrecautionChange}
              onFocus={() => setIsInputFocus(true)}
              onBlur={() => setIsInputFocus(false)}
            />
            { programCreationParams.precaution.length && isInputFocus ? (
              <img src={CloseRound} onMouseDown={() => dispatch(setProgramCreationParams({...programCreationParams, precaution: ''}))} alt='CloseRound'/>
            ) : ''}
            { isInputFocus && (
              <span>{ programCreationParams.precaution.length }/48</span>
            ) }
          </div>
          <div className={cx('btns')}>
            <button className={cx('backBtn')} onClick={() => (dispatch(setProgramCreationStep(0)))}>뒤로</button>
            <button className={cx('saveBtn')} onClick={() => createProgram()}>저장하기</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramInfo;