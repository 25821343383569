import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import styles from './signin.module.scss'
import classnames from 'classnames/bind'
import InputUser from '../common/input/inputUser/inputUser'
import ButtonPrimary from '../common/button/buttonPrimary/buttonPrimary'
import api from '../../utils/api'
import FooterGray from "../common/footer/footerGray/footerGray";
import ModalSmall from "../common/modal/modalSmall/modalSmall";
import Refresh from "../../helpers/refresh";

const Login = `${process.env.REACT_APP_RESOURCE_URL}/login.svg`
const PLogo = `${process.env.REACT_APP_RESOURCE_URL}/P_logo.svg`

const cx = classnames.bind(styles);

const Signin = () => {
  const navigate = useNavigate()

  const [eyeImg, setEyeImg] = useState('open')
  const [id, setId] = useState('')
  const [pw, setPw] = useState('')
  const [isIdEntered, setIsIdEntered] = useState(true)
  const [isPwEntered, setIsPwEntered] = useState(true)
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [modalSmallContent, setModalSmallContent] = useState({})
  const [userDevice, setUserDevice] = useState('m')
  const [isRefresh, setIsRefresh] = useState(false)

  const onEyeClick = () => {
    setEyeImg((eyeImg) => eyeImg === 'open' ? 'close' : 'open')
  }

  const goToSignup = () => {
    navigate('/authentication', { state: { title: 'signup' } })
  }

  const onBtnPrimaryClick = (e) => {
    e.preventDefault()

    if(!id) {
      setIsIdEntered(false)
    } else if(!pw) {
      setIsPwEntered(false)
    } else if(id && pw) {
      api.postAuthToken(id, pw).then((res) => {
        if(res.data.accessToken) {
          localStorage.setItem('accessToken', res.data.accessToken)
        }
        if(res.data.refreshToken) {
          localStorage.setItem('refreshToken', res.data.refreshToken)
        }
        setIsRefresh(true)
      }).catch((error) => {
        if(error.response.status === 400) {
          if(error.response.data.code === '3002') {
            //탈퇴
          } else if(error.response.data.code === '1009' || error.response.data.code === '1001') {
            setModalSmallContent({
              bodyTitle: '아이디 또는 비밀번호가<br/> 잘못 입력되었습니다.',
              bodySub: '아이디와 비밀번호를 정확히 입력해 주세요.',
            })
          }
          setIsModalSmallOpen(true)
        }
      })
    }
  }

  const onIdChange = (e) => {
    let formatNumber = ''

    if(e.target.value.length === 11) {
      formatNumber = e.target.value.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{4})(\d{4})/g, '$1-$2-$3')
    } else if(e.target.value.length > 11){
      formatNumber = e.target.value.toString().slice(0, 13)
    } else {
      formatNumber = e.target.value.replace(/[^0-9]/g, '')
    }

    setId(() => formatNumber)
  }

  const onPwChange = (e) => {
    setPw(() => e.target.value)
  }

  const onResize = () => {
    let width = window.innerWidth
    if(width < 768) {
      setUserDevice('m')
    } else if(width < 1024 && width >= 768) {
      setUserDevice('t')
    } else if(width < 1441 && width >= 1024){
      setUserDevice('d')
    } else {
      setUserDevice('d_big')
    }
  }



  useEffect(() => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')

    if(process.env.REACT_APP_NODE !== 'production') {
      setId('010-0000-1250')
      setPw('everex')
      // setId('010-2869-7886')
      // setPw('test1234!')
    }

    window.addEventListener("resize", onResize)
    onResize()

    return () => {
      document.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <div className={cx('signin')}>
      {isRefresh && <Refresh isUpdateToken={false}/>}
      { userDevice === 'm' ? (
        ''
      ) : (
        <div className={cx('banner')}>
          <p>본 사이트는 구글 크롬 브라우저에 최적화되어 있습니다.</p>
          <a href="https://www.google.com/chrome" target="_blank" rel="noreferrer">[크롬 브라우저 다운로드]</a>
        </div>
      )}

      <div className={cx('content')}>
        { userDevice === 'm' ? (
          ''
        ) : (
          <div className={cx('left')}>
            <img src={Login} alt='Login'/>
          </div>
        )}

        <div className={cx('right')}>
          <div className={cx('rightWrapper')}>
            <img src={PLogo} alt={PLogo}/>
            <form>
              <div className={cx('id')}>
                <label>휴대폰 번호(ID)</label>
                <InputUser placeholder='휴대폰번호를 입력해 주세요.(숫자만 입력)' value={id} onChange={onIdChange}/>
                { isIdEntered || <p className={cx('warning')}>휴대폰 번호(ID)를 입력해 주세요.</p> }
              </div>
              <div className={cx('pw')}>
                <label>비밀번호</label>
                <InputUser
                  placeholder='비밀번호를 입력해 주세요.'
                  type={eyeImg === 'open' ? 'password' : 'text' }
                  eye={true}
                  eyeImg={eyeImg}
                  onEyeClick={onEyeClick}
                  onChange={onPwChange}
                  value={pw}
                />
                { isPwEntered || <p className={cx('warning')}>비밀번호를 입력해 주세요.</p> }
                { userDevice === 'm' ? (
                  ''
                ) : (
                  <p className={cx('accountChange')} onClick={() => navigate('/change/account')}>계정 정보 변경</p>
                )}

              </div>
              <div className={cx('btnWrapper')}>
                <ButtonPrimary text='로그인 하기' onBtnPrimaryClick={onBtnPrimaryClick} height={41} font='small'/>
              </div>
            </form>
            <p className={cx('signup')}>
              MORA가 처음이신가요?<br/><br/>
              { userDevice === 'm' ? (
                <span onClick={ () => goToSignup() }>무료 가입하고 체험하기</span>
              ) : (
                <span onClick={ () => navigate('/signup/intro') }>무료 가입하고 체험하기</span>
              )}

            </p>
          </div>

        </div>
      </div>

      { isModalSmallOpen &&
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          bodySub={modalSmallContent.bodySub}
          isOneBtn={true}
          btnSecondTxt=''
          btnFirstTxt='확인'
          btnFirstEvent={() => setIsModalSmallOpen(false)}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}/>
      }
      { userDevice === 'm' ? (
        ''
      ) : (
        <FooterGray/>
      )}

    </div>
  );
};

export default Signin;