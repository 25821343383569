import {createSlice} from "@reduxjs/toolkit";

const planSlice = createSlice({
  name: 'planSlice',
  initialState: { value: {
      planAssignmentParams: {
        caution: '',
        mainPart: '',
        medicalRecordId: null,
        name: '',
        diagnosis: '',
        tools: [],
        preScript: {
          compressedSchedule: {},
        }
      },
      planStatusData: {
        'CLOSED': {
          id: 0,
          status: '종료',
          txtColor: 'gray'
        },
        'CANCEL': {
          id: 1,
          status: '종료',
          txtColor: 'gray'
        },
        'REJECT': {
          id: 2,
          status: '종료',
          txtColor: 'gray'
        },
        'IN_PROGRESS': {
          id: 3,
          status: '수행중',
          txtColor: 'blue'
        },
        'RELEASE': {
          id: 4,
          status: '시작 대기',
          txtColor: 'lightBlue'
        },

      },
      planStopReason: '',
      addedPlanExerciseArr: [],
      planSchedule: {},
      lastEditedPlanSchedule: {},
      isCopyDateExercise: false,
      // 0: 운동, 프로그램 선택, 1: 날짜 선택, 2: 일자별 플랜 정보 3: 플랜 배정
      planAssignmentStep: 0,
      dateExercisesToCopy: [],
      dateToEdit: '',
      isAddNewDate: false,
      isBackToCalendar: false,
      isPlanProgram: false,
      planProgramSelected: {},
      selectedPlanStartDateTimestamp: 0,
    } },
  reducers: {
    setPlanAssignmentParams: (state, action) => {
      state.value.planAssignmentParams = action.payload
    },
    setAddedPlanExerciseArr: (state, action) => {
      state.value.addedPlanExerciseArr = action.payload
    },
    setPlanSchedule: (state, action) => {
      state.value.planSchedule = action.payload
    },
    setLastEditedPlanSchedule: (state, action) => {
      state.value.lastEditedPlanSchedule = action.payload
    },
    setDateExercisesToCopy: (state, action) => {
      state.value.dateExercisesToCopy = action.payload
    },
    setDateToEdit: (state, action) => {
      state.value.dateToEdit = action.payload
    },
    setIsAddNewDate: (state, action) => {
      state.value.isAddNewDate = action.payload
    },
    setIsBackToCalendar: (state, action) => {
      state.value.isBackToCalendar = action.payload
    },
    setIsPlanProgram: (state, action) => {
      state.value.isPlanProgram = action.payload
    },
    setPlanProgramSelected: (state, action) => {
      state.value.planProgramSelected = action.payload
    },

    setSelectedPlanStartDateTimestamp: (state, action) => {
      state.value.selectedPlanStartDateTimestamp = action.payload
    },


    resetPlanAssignmentParams: (state) => {
      state.value.planAssignmentParams = {
        caution: '',
        mainPart: '',
        medicalRecordId: '',
        name: '',
        diagnosis: '',
        tools: [],
        preScript: {
          compressedSchedule: {},
          testIds: [],
        }
      }
    },
    resetAddedPlanExerciseArr: (state) => {state.value.addedPlanExerciseArr = []},
    resetPlanSchedule: (state) => {state.value.planSchedule = {}},
    resetLastEditedPlanSchedule: (state) => {state.value.lastEditedPlanSchedule = {}},
    resetDateExercisesToCopy: (state) => {state.value.dateExercisesToCopy = []},
    resetDateToEdit: (state) => {state.value.dateToEdit = ''},


    setPlanStopReason: (state, action) => {
      state.value.planStopReason = action.payload
    },
    setPlanAssignmentStep: (state, action) => {
      state.value.planAssignmentStep = action.payload
    },



  }
})

export default planSlice
export const {
  setPlanAssignmentParams,
  setPlanStopReason,
  setAddedPlanExerciseArr,
  setPlanAssignmentStep,
  resetPlanAssignmentParams,
  setPlanSchedule,
  setDateExercisesToCopy,
  resetAddedPlanExerciseArr,
  resetPlanSchedule,
  resetDateExercisesToCopy,
  setDateToEdit,
  resetDateToEdit,
  setIsAddNewDate,
  setIsBackToCalendar,
  setLastEditedPlanSchedule,
  resetLastEditedPlanSchedule,
  setIsPlanProgram,
  setPlanProgramSelected,
  setSelectedPlanStartDateTimestamp}
  = planSlice.actions