import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import styles from './setPassword.module.scss'
import classnames from 'classnames/bind';
import HeaderGray from "@/common/header/headerGray/headerGray";
import ButtonPrimary from "@/common/button/buttonPrimary/buttonPrimary";
import InputUser from "../../common/input/inputUser/inputUser";
import api from "@/api";
import FooterGray from "../../common/footer/footerGray/footerGray";
import ModalSmall from "../../common/modal/modalSmall/modalSmall";

const cx = classnames.bind(styles);

const SetPassword = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const changePwdInfo = location.state.changePwdInfo

  const [newPwd, setNewPwd] = useState('')
  const [pwdConfirm, setPwdConfirm] = useState('')
  const [newPwdEyeImg, setNewPasswordEyeImg] = useState('open')
  const [pwdConfirmEyeImg, setPwdConfirmEyeImg] = useState('open')
  const [isNewPwdErr, setIsNewPwdErr] = useState(false)
  const [isPwdMatchErr, setIsPwdMatchErr] = useState(false)
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)

  const onNewPwdEyeClick = () => {
    setNewPasswordEyeImg((newPwdEyeImg) => newPwdEyeImg === 'open' ? 'close' : 'open')
  }

  const onPwdConfirmEyeClick = () => {
    setPwdConfirmEyeImg((pwdConfirmEyeImg) => pwdConfirmEyeImg === 'open' ? 'close' : 'open')
  }

  const onBtnPrimaryClick = () => {
    const reg = new RegExp(/^(?=.*[A-Za-z])(?=.*[!"#$%&'()*+,\-./:;<=>?@\]^_`{|}~\\])(?=.*[0-9])[0-9A-Za-z!"#$%&'()*+,\-./:;<=>?@\]^_`{|}~\\]{8,16}$/)
    if(reg.test(newPwd) && reg.test(pwdConfirm) && (newPwd === pwdConfirm)) {
      changePassword()
    } else if (!reg.test(newPwd)){
      setIsNewPwdErr(() => true)
      setIsPwdMatchErr(() => false)
    } else if(newPwd !== pwdConfirm) {
      setIsNewPwdErr(() => false)
      setIsPwdMatchErr(() => true)
    } else {
      return
    }
  }

  const changePassword = () => {
    let body = {
      ...changePwdInfo,
      password: newPwd
    }

    api.postPassword(body).then((res) => {
      setIsModalSmallOpen(() => true)
    }).catch((err) => {
      console.log(err)
      navigate('/change/account')
    })

  }

  const onNewPwdChange = (e) => {
    setNewPwd(() => e.target.value)
  }

  const onPwdConfirmChange = (e) => {
    setPwdConfirm(() => e.target.value)
  }

  useEffect(() => {
    if(!Object.keys(changePwdInfo).length) {
      navigate('/change/account')
    }
  }, [changePwdInfo])

  return (
    <div className={cx('setPassword')}>
      <HeaderGray/>
      <div className={cx('container')}>
        <p className={cx('title')}>비밀번호 변경을 도와드릴게요</p>
        <div className={cx('steps')}>
          <div></div>
          <div></div>
        </div>
        <div className={cx('content')}>
          <p className={cx('pwd')}>새 비밀번호</p>
          <InputUser
            placeholder='영문, 숫자, 특수문자의 조합 (8~16자)'
            type={newPwdEyeImg === 'open' ? 'password' : 'text' }
            eye={true}
            eyeImg={newPwdEyeImg}
            onEyeClick={onNewPwdEyeClick}
            onChange={onNewPwdChange}
            value={newPwd}
            style={ isNewPwdErr && 'redBorder' }
          />
          { isNewPwdErr &&
            <p className={cx('err')}>영문, 숫자, 특수문자의 조합 (8~16자)</p>
          }
        </div>
        <div className={cx('content')}>
          <p className={cx('pwd')}>비밀번호 확인</p>
          <InputUser
            placeholder='위의 비밀번호를 다시 입력해 주세요.'
            type={pwdConfirmEyeImg === 'open' ? 'password' : 'text' }
            eye={true}
            eyeImg={pwdConfirmEyeImg}
            onEyeClick={onPwdConfirmEyeClick}
            onChange={onPwdConfirmChange}
            value={pwdConfirm}
            style={ isPwdMatchErr && 'redBorder' }
          />
          { isPwdMatchErr &&
            <p className={cx('err')}>비밀번호가 일치하지 않습니다.</p>
          }
        </div>
        <ButtonPrimary text='비밀번호 변경하기' onBtnPrimaryClick={ onBtnPrimaryClick } btnDisabled={ !newPwd || !pwdConfirm ? true : false } height={41} font='small'/>
      </div>
      { isModalSmallOpen &&
        <ModalSmall
          bodyTitle='비밀번호가 변경되었습니다.'
          bodySub='새로운 비밀번호로 로그인해 주세요'
          isOneBtn={true}
          btnSecondTxt=''
          btnFirstTxt='로그인하기'
          btnFirstEvent={() => navigate('/')}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}/>
      }
      <FooterGray/>
    </div>
  );
};

export default SetPassword;