import React, {useEffect} from 'react';
import api from "@/api";
import {useDispatch, useSelector} from "react-redux";
import {setInfo} from "../store/slice/staffSlice";
import {useNavigate} from "react-router-dom";
import {updateNoti} from "../store/slice/notiSlice";

export default function ToggleBookmark({ type, id }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const staff = useSelector(state => {
    return state.staff.value
  })

  useEffect(() => {
    onBookmarkClick()
  }, [])

  const onBookmarkClick = () => {
    let body = {}
    if(type === 'exercise') {
      body = {
        exerciseId: id
      }
      if(staff.bookmarkExercise.includes(id)) {
        api.deleteStaffBookmark(body).then(() => {
          resetStaff()
        }).catch((e) => {
          console.log(e)
        })
      } else {
        api.postStaffBookmark(body).then(() => {
          resetStaff()
        }).catch((e) => {
          console.log(e)
        })
      }
    } else {
      body = {
        programId: id
      }
      if(staff.bookmarkProgram.includes(id)) {
        api.deleteStaffBookmark(body).then(() => {
          resetStaff()
        }).catch((e) => {
          console.log(e)
        })
      } else {
        api.postStaffBookmark(body).then(() => {
          resetStaff()
        }).catch((e) => {
          console.log(e)
        })
      }
    }
  }

  const resetStaff = () => {
    if(localStorage.getItem('accessToken')) {
      api.getStaff().then((res) => {
        const staffInfo = res.data
        dispatch(setInfo(staffInfo))
        if(staffInfo.rehabGroupId === null) {
          navigate('/organ/group/select', { replace: true })
        }

        api.getNotiUnseenCount().then((res) => {
          const isMark = res.data.count > 0
          dispatch(updateNoti(isMark))
        })
      }).catch((e) => {
        console.log(e)
      })
    } else {
      navigate('/')
    }
  }



  return null
}

