export const formatBirth = (birth) => {
  let birthArr = birth.split('-')
  let year = birthArr[0].slice(2, 4)
  let month = birthArr[1]
  let day = birthArr[2]
  return `${year}.${month}.${day}`
}

export const getToday = () => {
  const date = new Date()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hour = date.getHours()
  let minute = date.getMinutes()
  let second = date.getSeconds()

  month = month >= 10 ? month : '0' + month
  day = day >= 10 ? day : '0' + day
  hour = hour >= 10 ? hour : '0' + hour
  minute = minute >= 10 ? minute : '0' + minute
  second = second >= 10 ? second : '0' + second

  return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
}

export const formatDate = (dateStr, isBracket = true) => {
  let year = dateStr.slice(2, 4)
  let fullYear = dateStr.slice(0, 4)
  let month = dateStr.slice(4, 6)
  let date = dateStr.slice(6, 8)
  let day = new Date(fullYear, month - 1, date).getDay()
  day = getDay(day)

  if(isBracket) {
    return `${year}.${month}.${date}(${day})`
  } else {
    return `${year}.${month}.${date}.${day}`
  }

}

const getDay = (dayNo) => {
  if(dayNo === 0) {
    return '일'
  } else if(dayNo === 1) {
    return '월'
  } else if(dayNo === 2) {
    return '화'
  } else if(dayNo === 3) {
    return '수'
  } else if(dayNo === 4) {
    return '목'
  } else if(dayNo === 5) {
    return '금'
  } else if(dayNo === 6) {
    return '토'
  }
}

export const getDayKor = (dayNo) => {
  if(dayNo === 0) {
    return '일'
  } else if(dayNo === 1) {
    return '월'
  } else if(dayNo === 2) {
    return '화'
  } else if(dayNo === 3) {
    return '수'
  } else if(dayNo === 4) {
    return '목'
  } else if(dayNo === 5) {
    return '금'
  } else if(dayNo === 6) {
    return '토'
  }
}

export const planCreateDate = (planDate, isUnit = false) => {
  let newDate = new Date(planDate)
  let year = newDate.getFullYear()
  let month = newDate.getMonth() + 1
  if(month < 10) {
    month = '0' + month
  }
  let date = newDate.getDate()
  if(date < 10) {
    date = '0' + date
  }
  let day = newDate.getDay()
  day = getDay(day)

  if(isUnit) {
    return `${year}년 ${month}월 ${date}일`
  } else {
    return `${year}.${month}.${date}.${day}`
  }
}

export const joinDate = (date) => {
  return date.split('T')[0].replace('-', '년 ').replace('-', '월 ') + '일'
}

export const planDate = (fullDate) => {
  let date = fullDate.split('T')[0].replace('-', '.').replace('-', '.')
  let time = fullDate.split('T')[1].split(':')[0] + ':' + fullDate.split('T')[1].split(':')[1]

  return date + ' ' + time
}


export const phoneDash = (phone) => {
  let first = phone.slice(0, 3)
  let second = phone.slice(3, 7)
  let third = phone.slice(7, 11)

  return `${first}-${second}-${third}`
}

export const dateDot = (date) => {
  let year = date.slice(0, 4)
  let month = date.slice(4, 6)
  let day = date.slice(6, 8)

  return `${year}.${month}.${day}`
}

export const shortYearDateDot = (date) => {
  if(!date) {
    return
  }
  let year = date.slice(2, 4)
  let month = date.slice(4, 6)
  let day = date.slice(6, 8)

  return `${year}.${month}.${day}`
}

export const formatBirthAge = (birth) => {
  let birthArr = birth.split('-')
  let year = birthArr[0]
  let month = birthArr[1]
  let day = birthArr[2]

  let today = new Date()
  let birthDate = new Date(birth)
  let age = today.getFullYear() - birthDate.getFullYear()
  let m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  return `${year}.${month}.${day}(${age}세)`
}

export const formatBirthNoDot = (birth) => {
  if(birth) {
    let birthArr = birth.split('-')
    let year = birthArr[0].slice(2, 4)
    let month = birthArr[1]
    let day = birthArr[2]
    return `${year}${month}${day}`
  }

}

export const numberWithCommas = (number) => {
  if(!number) {
    return '0'
  }
  if(!(number+'').includes('.')) {
    return (number+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return `${((number+'').split('.')[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${(number+'').split('.')[1]}`
  }
}

export const setMinutes = (milliSeconds) => {
  const minutes = Math.floor(milliSeconds / 1000 / 60)
  if (milliSeconds) {
    if (minutes > 0) {
      return minutes
    } else {
      return '1'
    }
  } else {
    return '-'
  }
}

export const dateObjToStr = (dateObj) => {
  let yy = dateObj.getFullYear().toString()
  let mm = (dateObj.getMonth() + 1).toString()
  let dd = dateObj.getDate().toString()

  if(mm.length === 1) {
    mm = '0' + mm
  }

  if(dd.length === 1) {
    dd = '0' + dd
  }

  return `${yy}${mm}${dd}`
}

export const makeSetEx = (exArr) => {
  let result = []
  let repeat = 1
  exArr.forEach((ex, idx) => {
    if(idx === exArr.length) {
      return
    }
    if(exArr[idx + 1]) {
      if(ex.id === exArr[idx + 1].id && ex.count === exArr[idx + 1].count && ex.time === exArr[idx + 1].time) {
        repeat++
      } else {
        result.push({
          ...ex,
          repeat: repeat
        })
        repeat = 1
      }
    } else {
      result.push({
        ...ex,
        repeat: repeat
      })
    }
  })

  return result
}
export const makeSetCompletedEx = (exArr, exerciseCompleteIdxList) => {
  let result = []
  let repeat = 1
  let complete = exerciseCompleteIdxList.includes(0) ? 1 : 0

  exArr.forEach((ex, idx) => {
    if(idx === exArr.length) {
      return
    }
    if(exArr[idx + 1]) {
      if(ex.id === exArr[idx + 1].id && ex.count === exArr[idx + 1].count && ex.time === exArr[idx + 1].time) {
        repeat++
        if(exerciseCompleteIdxList.includes(idx + 1)) {
          complete++
        }
      } else {
        result.push({
          ...ex,
          repeat: repeat,
          complete: complete,
        })
        repeat = 1
        complete = exerciseCompleteIdxList.includes(idx + 1) ? 1 : 0
      }
    } else {
      result.push({
        ...ex,
        repeat: repeat,
        complete: complete,
      })
    }
  })

  return result
}

export const makeCompressedProgram = (exArr) => {
  let compressedExercises = []
  exArr.forEach((ex) => {
    compressedExercises.push(`i${ex.id}c${ex.count ? ex.count : 0}t${ex.time ? ex.time : 0}r${ex.repeat}`)
  })

  return compressedExercises
}