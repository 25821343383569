import React from 'react';
import styles from './exerciseSelectSkeleton.module.scss'
import classnames from "classnames/bind";
const ExerciseSelectSkeleton = () => {

  const cx = classnames.bind(styles)

  return (
    <div className={cx('exerciseSelectSkeleton')}>
      <div className={cx('rightWrapper')}>
        <div className={cx('contentWrapper')}>
          <div className={cx('content1')}>
            <div className={cx('content1Top')}>
              <div>
                <div></div>
                <div></div>
              </div>
              <div>
                <div></div>
                <div></div>
              </div>
              <div>
                <div></div>
                <div></div>
              </div>
              <hr/>
              <div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className={cx('content1Bottom')}>
              <div className={cx('content1BottomContent1')}>
                <div></div>
                <div></div>
              </div>
              <div className={cx('content1BottomContent2')}>
                <div className={cx('content1BottomContent2_1')}>
                  <div className={cx('content2_1_1')}></div>
                  <div className={cx('content2_1_2')}></div>
                </div>
                <div className={cx('content1BottomContent2_2')}>
                  <div></div>
                  <div></div>
                </div>
                <div className={cx('content1BottomContent2_3')}>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div><div className={cx('content1BottomContent2_3_1')}>
                  <div>
                    <div></div>
                    <div></div>
                  </div>
                  <div></div>
                </div><div className={cx('content1BottomContent2_3_1')}>
                  <div>
                    <div></div>
                    <div></div>
                  </div>
                  <div></div>
                </div><div className={cx('content1BottomContent2_3_1')}>
                  <div>
                    <div></div>
                    <div></div>
                  </div>
                  <div></div>
                </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <div className={cx('content2')}>
            <div className={cx('content2_1')}>
              <div className={cx('content2_1_1')}></div>
              <div className={cx('content2_1_2')}></div>
            </div>
            <div className={cx('content2_2')}>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('content2_2_1')}>
                <div className={cx('content2_2_1_1')}></div>
                <div className={cx('content2_2_1_2')}>
                  <div className={cx('content2_2_1_2_1')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={cx('content2_2_1_2_2')}></div>
                  <div className={cx('content2_2_1_2_3')}></div>
                </div>
                <div className={cx('content2_2_1_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('leftWrapper')}>
        <div className={cx('content1')}></div>
        <div className={cx('content2')}></div>
        <div className={cx('content3')}></div>
        <div className={cx('content4')}></div>
        <div className={cx('content5')}></div>
        <div className={cx('content6')}></div>
        <div className={cx('content7')}></div>
        <div className={cx('content8')}></div>
        <div className={cx('content9')}>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default ExerciseSelectSkeleton;