import React from 'react';
import styles from './rehabListSkeleton.module.scss'
import classnames from "classnames/bind";
const RehabListSkeleton = () => {

  const cx = classnames.bind(styles)

  return (
    <div className={cx('rehabListSkeleton')}>
      <div className={cx('rightWrapper')}>
        <div className={cx('contentWrapper')}>
          <div className={cx('content1')}>
            <div className={cx('content1Top')}>
              <div>
                <div></div>
                <div></div>
              </div>
              <div>
                <div></div>
                <div></div>
              </div>
              <div>
                <div></div>
                <div></div>
              </div>
              <hr/>
              <div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className={cx('content1Bottom')}>
              <div className={cx('content1BottomContent1')}>
                <div></div>
                <div></div>
              </div>
              <div className={cx('content1BottomContent2')}>
                <div className={cx('content1BottomContent2_1')}>
                  <div></div>
                  <div></div>
                </div>
                <div className={cx('content1BottomContent2_2')}>
                  <div></div>
                  <div></div>
                </div>
                <div className={cx('content1BottomContent2_3')}>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div><div className={cx('content1BottomContent2_3_1')}>
                  <div>
                    <div></div>
                    <div></div>
                  </div>
                  <div></div>
                </div><div className={cx('content1BottomContent2_3_1')}>
                  <div>
                    <div></div>
                    <div></div>
                  </div>
                  <div></div>
                </div><div className={cx('content1BottomContent2_3_1')}>
                  <div>
                    <div></div>
                    <div></div>
                  </div>
                  <div></div>
                </div>
                  <div className={cx('content1BottomContent2_3_1')}>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <div className={cx('content2')}>
            <div className={cx('content2_1')}>
              <div className={cx('content2_1_box')}>
                <div className={cx('content2_1_1')}></div>
                <div className={cx('content2_1_1')}></div>
                <div className={cx('content2_1_1')}></div>
              </div>

              <div className={cx('content2_1_2')}></div>
            </div>
            <div className={cx('listWrapper')}>
              { [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map((num) => (
                <div key={num} className={cx('rehabCardExercise')}>
                  <div className={cx('exerciseItemWrapper')}>
                    <div className={cx('iconBox')}></div>
                    <div className={cx('exerciseItem')}>
                      <div className={cx('exImage')}></div>
                      <div className={cx('exerciseTitleBox')}>
                        <div className={cx('icon')}></div>
                        <div className={cx('exNoteBox')}>
                          <p></p>
                          <h6></h6>
                          <div className={cx('workoutSubTitleBox')}></div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              )) }
            </div>
          </div>
        </div>
      </div>
      <div className={cx('leftWrapper')}>
        <div className={cx('content1')}></div>
        <div className={cx('content2')}></div>
        <div className={cx('content3')}></div>
        <div className={cx('content4')}></div>
        <div className={cx('content5')}></div>
        <div className={cx('content6')}></div>
        <div className={cx('content7')}></div>
        <div className={cx('content8')}></div>
        <div className={cx('content9')}>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default RehabListSkeleton;