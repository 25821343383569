import {createSlice} from "@reduxjs/toolkit";

const rehabSlice = createSlice({
  name: 'rehabSlice',
  initialState: { value: {
      activeFilterRehabId: 0,
      lvArr: [
        {
          'lv': '매우 쉬움',
          'color': 'green'
        },
        {
          'lv': '쉬움',
          'color': 'whiteGreen'
        },
        {
          'lv': '보통',
          'color': 'yellow'
        },
        {
          'lv': '어려움',
          'color': 'orange'
        },
        {
          'lv': '매우 어려움',
          'color': 'red'
        },
      ]
    } },
  reducers: {
    setActiveFilterRehabId: (state, action) => {
      state.value.activeFilterRehabId = action.payload
    },
  }
})

export default rehabSlice
export const { setActiveFilterRehabId } = rehabSlice.actions