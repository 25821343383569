import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import { setPatientChangedProfile} from "../../../../store/slice/patientSlice";
import classnames from "classnames/bind";
import styles from "./patientChangeProfile.module.scss";
import {setIsModalBigBtnActive} from "@/store/slice/modalBtnSlice"
import {formatBirthNoDot} from "../../../../helpers/common";

const CloseRound = `${process.env.REACT_APP_RESOURCE_URL}/close_round.svg`

const cx = classnames.bind(styles);
const PatientChangeProfile = ({ patientInfo }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState(patientInfo.userName)
  const [phoneNumber, setPhoneNumber] = useState(patientInfo.userPhoneNumber.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{4})(\d{4})/g, '$1-$2-$3'))
  const [birthday, setBirthday] = useState('')
  const [height, setHeight] = useState(patientInfo.userHeight ? patientInfo.userHeight : '')
  const [weight, setWeight] = useState(patientInfo.userWeight ? patientInfo.userWeight : '')
  const [nameFocus, setNameFocus] = useState(false)
  const [phoneNumberFocus, setPhoneNumberFocus] = useState(false)
  const [birthdayFocus, setBirthdayFocus] = useState(false)
  const [heightFocus, setHeightFocus] = useState(false)
  const [weightFocus, setWeightFocus] = useState(false)

  const parseBirthday = () => {
    let yy = birthday.toString().slice(0, 2)
    let currentYear = new Date().getFullYear().toString().slice(0, 2)

    let yyyy = ''
    if(Number(yy) > Number(currentYear)) {
      yyyy = '19' + yy
    } else {
      yyyy = '20' + yy
    }

    return `${yyyy}-${birthday.toString().slice(2, 4)}-${birthday.toString().slice(4, 6)}`
  }

  const changeName = (e) => {
    setName(e.target.value)
  }
  const changePhoneNumber = (e) => {
    let value = e.target.value
    value = value.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{4})(\d{4})/g, '$1-$2-$3')
    setPhoneNumber(value)
  }

  const changeBirthday = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, '')
    if(value.length <= 6) {
      setBirthday(value)
    }
  }

  const changeHeight = (e) => {
    let value = e.target.value
    value = value.replace(/[^0-9]/g, '')
    setHeight(value)
  }
  const changeWeight = (e) => {
    let value = e.target.value
    value = value.replace(/[^0-9]/g, '')
    setWeight(value)
  }

  const isBirthdayValid = () => {
    if(birthday.length !== 6) {
      return false
    }

    let mm = Number(birthday.toString().slice(2, 4))
    if(mm > 12 || mm < 1) {
      return false
    }

    let dd = Number(birthday.toString().slice(4, 6))
    if(dd > 31 || mm < 1) {
      return false
    }

    return true
  }

  useEffect(() => {
    if(name && phoneNumber.length === 13 && isBirthdayValid()) {
      dispatch(setIsModalBigBtnActive(true))

      let payload = {
        name: name,
        phoneNumber: phoneNumber,
        birthday: parseBirthday(),
        height: height,
        weight: weight,
      }
      dispatch(setPatientChangedProfile(payload))
    } else {
      dispatch(setIsModalBigBtnActive(false))
    }
  }, [name, phoneNumber, birthday, height, weight])

  useEffect(() => {
    if(!patientInfo.userBirthday) {
      return
    }
    setBirthday(formatBirthNoDot(patientInfo.userBirthday))
  }, [])

  return (
    <div className={cx('patientChangeProfile')}>
      <div>
        <p>이름</p>
        <input
          value={name}
          placeholder={patientInfo.userName}
          className={cx(patientInfo.userIsRegistered && 'gray', nameFocus && 'borderPrimary')}
          type="text"
          readOnly={patientInfo.userIsRegistered}
          onChange={changeName}
          onFocus={() => setNameFocus(true)}
          onBlur={() => setNameFocus(false)}
        />
        { nameFocus && name && !patientInfo.userIsRegistered && (
          <img onMouseDown={() => setName('')} src={CloseRound} alt='CloseRound'/>
        ) }

      </div>
      <div>
        <p>휴대폰 번호(숫자만 입력)</p>
        <input
          value={phoneNumber}
          placeholder={patientInfo.userPhoneNumber}
          className={cx(patientInfo.userIsRegistered && 'gray', phoneNumberFocus && 'borderPrimary')}
          type="text"
          readOnly={patientInfo.userIsRegistered}
          onChange={changePhoneNumber}
          maxLength={13}
          onFocus={() => setPhoneNumberFocus(true)}
          onBlur={() => setPhoneNumberFocus(false)}
        />
        { phoneNumberFocus && phoneNumber && !patientInfo.userIsRegistered && (
          <img onMouseDown={() => setPhoneNumber('')} src={CloseRound} alt='CloseRound'/>
        ) }
      </div>

      <div>
        <p>생년월일(6자리)</p>
        <input
          value={birthday}
          placeholder={patientInfo.userBirthday ? formatBirthNoDot(patientInfo.userBirthday) : '예) 930812'}
          className={cx(patientInfo.userIsRegistered && 'gray', birthdayFocus && 'borderPrimary')}
          type="text"
          readOnly={patientInfo.userIsRegistered}
          onChange={changeBirthday}
          onFocus={() => setBirthdayFocus(true)}
          onBlur={() => setBirthdayFocus(false)}
        />
        { birthdayFocus && birthday && !patientInfo.userIsRegistered && (
          <img onMouseDown={() => setBirthday('')} src={CloseRound} alt='CloseRound'/>
        ) }
      </div>

      <div>
        <p>신장</p>
        <input
          value={height}
          placeholder={patientInfo.userHeight ? patientInfo.userHeight : '-'}
          className={cx(heightFocus && 'borderPrimary')}
          type="text"
          onChange={changeHeight}
          onFocus={() => setHeightFocus(true)}
          onBlur={() => setHeightFocus(false)}
          maxLength={3}
        />
        <span>cm</span>
        { heightFocus && height && (
          <img onMouseDown={() => setHeight('')} src={CloseRound} alt='CloseRound'/>
        ) }
      </div>
      <div>
        <p>체중</p>
        <input
          value={weight}
          placeholder={patientInfo.userWeight ? patientInfo.userWeight : '-'}
          className={cx(weightFocus && 'borderPrimary')}
          type="text"
          onChange={changeWeight}
          onFocus={() => setWeightFocus(true)}
          onBlur={() => setWeightFocus(false)}
          maxLength={3}
        />
        <span>kg</span>
        { weightFocus && weight && (
          <img onMouseDown={() => setWeight('')} src={CloseRound} alt='CloseRound'/>
        ) }
      </div>
    </div>
  );
};

export default PatientChangeProfile;