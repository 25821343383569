import React from 'react';
import classnames from "classnames/bind";
import styles from './footerBreadCrumb.module.scss'
import {useLocation} from "react-router-dom";

const cx = classnames.bind(styles)
const FooterBreadCrumb = ({ footerBreadCrumbData }) => {
  const location = useLocation()

  const onClickAction = () => {
    if(footerBreadCrumbData.isPayBtnActive) {
      footerBreadCrumbData.onAction()
    }
  }

  return (
    <div className={cx('footerBreadCrumb')}>
      { location.pathname.includes('subscription') && (
        <div className={cx('forPayment')}>
          <p>총 결제 금액<span>{ footerBreadCrumbData.totalPriceToPay }원</span></p>
          <button
            className={cx('breadNext', footerBreadCrumbData.isPayBtnActive && 'breadActive')}
            onClick={onClickAction}
          >
            <p>결제하기</p>
          </button>
        </div>
      )}

    </div>
  );
};

export default FooterBreadCrumb;