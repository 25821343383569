import React from 'react';
import styles from './footerGray.module.scss'
import classnames from 'classnames/bind';
import {useNavigate} from "react-router-dom";

const GCopyright = `${process.env.REACT_APP_RESOURCE_URL}/G_copyright.svg`

const cx = classnames.bind(styles);

const FooterGray = ({ position }) => {
  const navigate = useNavigate()

  return (
    <div className={cx('footerGray')} style={{ position: position }}>
      <div className={cx('footerWrapper')}>
        <div className={cx('footerLeft')}>
          <p className={cx('everex')}>에버엑스 주식회사</p>
          <p className={cx('info')}>
            대표 : 윤찬
            <span>|</span>
            사업자등록번호 : 751-87-01409
            <span>|</span>
            통신판매업 신고번호 : 2022-서울서초-2641
            <a href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7518701409" target="_blank" rel="noreferrer">[사업자 정보 확인]</a>
          </p>
          <p className={cx('address')}>
            주소 : 서울 서초구 서리풀길 29-7 에버엑스 (06641)
            <span>|</span>
            고객문의 : support@everex.co.kr
            <span>/</span>
            02 - 588 - 0812
            <span>|</span>
            호스팅 서비스: Amazon Web Services
          </p>
          <p className={cx('copyright')}>
            <img src={GCopyright} alt='copyright'/>
            { new Date().getFullYear() } EverEx All rights reserved.
          </p>
        </div>
        <div className={cx('footerRight')}>
          <span onClick={ () => navigate('/terms/service') }>이용약관</span>
          <span onClick={ () => navigate('/terms/privacy') }>개인정보 처리방침</span>
          <span onClick={ () => navigate('/terms/notices') }>공지사항</span>
        </div>
      </div>
    </div>
  );
};

export default FooterGray;