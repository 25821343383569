import React, {useEffect, useRef} from 'react';
import styles from './dropdownOrder.module.scss'
import classnames from 'classnames/bind';

const GTriangle = `${process.env.REACT_APP_RESOURCE_URL}/G_triangle.svg`

const cx = classnames.bind(styles)

const DropdownOrder = ({ orderList, isDropdownOrderOpen, setIsDropdownOrderOpen, setSelectedOrderId, selectedOrderId, fixedValueExist = true, listWidth, listPosition, isScrollList, firstValue, height = 41, font, disable = false, isListOpenUp = false }) => {
  const ref = useRef()

  const onOrderItemClick = (orderItemId) => {
    setIsDropdownOrderOpen()
    setSelectedOrderId(orderItemId)
  }

  const getSelectedItem = () => {
    if(orderList.length) {
      if(orderList[selectedOrderId]) {
        return orderList[selectedOrderId].txt
      } else {
        return firstValue
      }
    } else {
      return firstValue
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isDropdownOrderOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOrderOpen()
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isDropdownOrderOpen])

  return (
    <div className={cx('dropdownOrder')}>
      <div className={cx('filterWrapper')} ref={ref}>
        <div className={cx('dropdownBig')}>
          <div style={{ height: height + 'px' }} className={cx(`listSelected`, isDropdownOrderOpen && 'borderPrimary', font && font, disable && 'disable')} onClick={() => setIsDropdownOrderOpen()}>
            <span className={cx(font && font)}>
              { fixedValueExist ? '정렬' : getSelectedItem()}
            </span>
            <img className={cx(isDropdownOrderOpen && 'rotate')} src={GTriangle} alt='GTriangle'/>
          </div>
          { isDropdownOrderOpen && (
            <div className={cx('filterLists', listPosition === 'right' && 'right', isScrollList && 'scroll', isListOpenUp && 'listOpenUp')} style={{ width: listWidth ? listWidth + 'px' : '' }}>
              { orderList.map((orderItem) =>
                <div key={orderItem.id} style={{ height: height + 'px' }} onClick={() => onOrderItemClick(orderItem.id)} className={cx(orderItem.id === selectedOrderId && 'active', font && font)}>
                  <p className={cx(font && font)}>{ orderItem.txt }</p>
                </div>
              ) }
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default DropdownOrder;