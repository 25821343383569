import React, {useEffect, useState} from 'react';
import styles from './kcbService.module.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles);
const url = 'https://terms.everex.co.kr/projects/kcb/service.html'

const KcbService = () => {
  const [html, setHtml] = useState('')

  useEffect(() => {
    let xmlhttp = new XMLHttpRequest()
    xmlhttp.onreadystatechange=function() {
      if(xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        setHtml(xmlhttp.responseText)
        return xmlhttp.responseText
      }
    }
    xmlhttp.open('GET', url, true)
    xmlhttp.send()
  }, [])

  return (
    <div className={cx("kcbService")}>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </div>
  );
};

export default KcbService;