import React, {useEffect, useState} from 'react';
import InputSearchBox from "@/common/input/inputSearchBox/inputSearchBox";
import styles from './rehab.module.scss'
import classnames from "classnames/bind";
import Pagination from "@/common/pagination/pagination";
import api from "@/api";
import {useDispatch, useSelector} from "react-redux";
import DropdownOrder from "@/common/dropdown/dropdownOrder/dropdownOrder";
import { numberWithCommas} from "@/helpers/common";
import tagKor from '@/data/tagKor'
import tagEng from '@/data/tagEng'
import rehabListOrderParams from '@/data/rehabListOrderParams'


import { setProgramCreationStep} from "@/store/slice/programSlice";
import FooterGray from "../common/footer/footerGray/footerGray";
import HeaderNav from "../common/header/headerNav/headerNav";
import LeftTabs from "../common/tabs/leftTabs/leftTabs";
import {setActiveFilterRehabId} from "../../store/slice/rehabSlice";
import ButtonPrimary from "../common/button/buttonPrimary/buttonPrimary";
import {
  resetAddedProgramExerciseArr,
  resetProgramCreationParams,
  setIsMyProgramsUpdate, setMyProgramIdToEdit
} from "../../store/slice/programSlice";
import {useLocation, useNavigate} from "react-router-dom";
import RehabCardProgram from "../common/rehabCard/rehabCardProgram/rehabCardProgram";
import RehabCardExercise from "../common/rehabCard/rehabCardExercise/rehabCardExercise";
import RehabListSkeleton from "./skeleton/rehabListSkeleton";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import {setMinutes} from "../../helpers/common";

const GNewStar = `${process.env.REACT_APP_RESOURCE_URL}/G_newStar.svg`
const YNewStar = `${process.env.REACT_APP_RESOURCE_URL}/Y_newStar.svg`
const GTriangle = `${process.env.REACT_APP_RESOURCE_URL}/G_triangle.svg`
const EmptyResult = `${process.env.REACT_APP_RESOURCE_URL}/Empty_result.svg`
const EmptyBookmark = `${process.env.REACT_APP_RESOURCE_URL}/Empty_bookmark.svg`

const cx = classnames.bind(styles)

const exerciseOrderList = [
  {
    id: 0,
    txt: '등록순 (최근)',
  },
  {
    id: 1,
    txt: '등록순 (오래된)',
  },
  {
    id: 2,
    txt: '이름순 (ㄱ-ㅎ)'
  },
  {
    id: 3,
    txt: '이름순 (ㅎ-ㄱ)'
  },
]


const Rehab = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const urlParamT = useSelector(state => {
    return state.refresh.value.urlParamT
  })
  const staff = useSelector(state => {
    return state.staff.value
  })
  const bookmarkProgram = useSelector(state => {
    return state.staff.value.bookmarkProgram
  })
  const bookmarkExercise = useSelector(state => {
    return state.staff.value.bookmarkExercise
  })
  const lvArr = useSelector(state => {
    return state.rehab.value.lvArr
  })
  const activeFilterRehabId = useSelector(state => {
    return state.rehab.value.activeFilterRehabId
  })
  const isMyProgramsUpdate = useSelector(state => {
    return state.program.value.isMyProgramsUpdate
  })


  const [keyword, setKeyword] = useState('')
  const [isBookmarkActive, setIsBookmarkActive] = useState(false)
  const [payloadObj, setPayloadObj] = useState({
    keyword: '',
    tags: '',
    isBookmark: false,
    page: 1,
    order: '',
    tabId: 0,
  })

  const [isDropdownExerciseOrderOpen, setIsDropdownExerciseOrderOpen] = useState(false)
  const [selectedExerciseOrderId, setSelectedExerciseOrderId] = useState(2)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [filterAllList, setFilterAllList] = useState([])
  const [filterMuList, setFilterMuList] = useState([])
  const [isSearched, setIsSearched] = useState(false)
  const [bodyPartListToggle, setBodyPartListToggle] = useState(true)
  const [apListToggle, setAPListToggle] = useState(false)
  const [etListToggle, setETListToggle] = useState(false)
  const [kcListToggle, setKCListToggle] = useState(false)
  const [lvListToggle, setLVListToggle] = useState(false)
  const [muListToggle, setMUListToggle] = useState(false)
  const [obListToggle, setOBListToggle] = useState(false)
  const [poListToggle, setPOListToggle] = useState(false)
  const [sdListToggle, setSDListToggle] = useState(false)
  const [toListToggle, setTOListToggle] = useState(false)
  const [wbListToggle, setWBListToggle] = useState(false)
  const [selectedFilterItems, setSelectedFilterItems] = useState([])
  const [programList, setProgramList] = useState([])
  const [exerciseList, setExerciseList] = useState([])
  const [filterNumbersObj, setFilterNumbersObj] = useState({})
  const [muKeyword, setMuKeyword] = useState('')
  const [tabItems, setTabItems] = useState([
    {
      id: 0,
      tab: '추천 프로그램',
      count: 0,
    },
    {
      id: 1,
      tab: '공유 프로그램',
      count: 0,
    },
    {
      id: 2,
      tab: '나만의 프로그램',
      count: 0,
    },
    {
      id: 3,
      tab: '운동 동작',
      count: 0,
    },
  ])
  const [isLoading, setIsLoading] = useState(false)

  const getKeyword = (value) => {
    setKeyword(() => value)
    if(!value) {
      setPayloadObj({
        ...payloadObj,
        keyword: ''
      })
    }
  }

  const onSearch = () => {
    setSelectedFilterItems([])
    setIsBookmarkActive(false)

    setPayloadObj({
      ...payloadObj,
      tags: '',
      isBookmark: false,
      keyword: keyword,
      page: 1
    })
  }

  const onBookmarkClick = () => {
    setIsBookmarkActive(!isBookmarkActive)
    setPayloadObj({
      ...payloadObj,
      isBookmark: !isBookmarkActive,
      page: 1,
    })
  }

  const clearFilter = () => {
    if(isBookmarkActive || selectedFilterItems.length) {
      setSelectedFilterItems([])

      setPayloadObj({
        ...payloadObj,
        tags: '',
        isBookmark: false,
      })

      setIsBookmarkActive(false)
    }
  }

  const getSelectedExerciseOrderId = (id) => {
    if(id === selectedExerciseOrderId) {
      return
    }
    setSelectedExerciseOrderId(id)

    let orderParam = ''

    if(activeFilterRehabId === 0) {
      orderParam = rehabListOrderParams['recommended'][id]
    } else if(activeFilterRehabId === 1){
      orderParam = rehabListOrderParams['shared'][id]
    } else if(activeFilterRehabId === 2){
      orderParam = rehabListOrderParams['my'][id]
    } else {
      orderParam = rehabListOrderParams['ex'][id]
    }

    setPayloadObj({
      ...payloadObj,
      order: orderParam,
      page: 1
    })
  }

  const onClickFilterItem = (item) => {
    let tempSelectedFilterItems = selectedFilterItems
    let idx = 0
    if(tempSelectedFilterItems.some((selectedItem, i) => {
      if(selectedItem.tag === item.tag) {
        idx = i
        return true
      } else {
        return false
      }
    })) {
      if(idx > -1) {
        tempSelectedFilterItems.splice(idx, 1)
      }
    } else {
      tempSelectedFilterItems.push(item)
    }

    setSelectedFilterItems([...tempSelectedFilterItems])

    let tags = []

    selectedFilterItems.forEach((bodyPart) => {
      tags.push(bodyPart.tag)
    })
    setPayloadObj({
      ...payloadObj,
      tags: tags.join(),
      page: 1,
    })
  }

  const movePage = (page) => {
    setPayloadObj({
      ...payloadObj,
      page: page
    })
  }

  const addFilterNumbers = (filterSections) => {
    let tempExFilterObj = filterSections
    Object.keys(tempExFilterObj).forEach((tempExFilter) => {
      tempExFilterObj[tempExFilter].forEach((exFilter) => {
        Object.keys(filterNumbersObj[tempExFilter]).forEach((filterNumber) => {
          if(exFilter.tag === filterNumber) {
            exFilter.count = filterNumbersObj[tempExFilter][filterNumber]
          }
        })
      })
    })

    setFilterAllList(tempExFilterObj)
  }

  const getTotalElement = (totalElement) => {
    if(totalElement === undefined) {
      return
    }
    let newRehabList = tabItems
    newRehabList[activeFilterRehabId].count = totalElement
    setTabItems(newRehabList)
  }

  const onTabItemClick = (filterRehabId) => {
    dispatch(setActiveFilterRehabId(filterRehabId))
    clearFilter()
    setKeyword('')
    setSelectedExerciseOrderId(2)
    if(filterRehabId === 0) {
      setPayloadObj({
        keyword: '',
        tags: '',
        isBookmark: false,
        page: 1,
        creatorId: '',
        shared: false,
        order: rehabListOrderParams['recommended'][2],
        tabId: 0
      })
    } else if(filterRehabId === 1) {
      setPayloadObj({
        keyword: '',
        tags: '',
        isBookmark: false,
        page: 1,
        creatorId: '',
        shared: true,
        order: rehabListOrderParams['shared'][2],
        tabId: 1
      })
    } else if(filterRehabId === 2) {
      setPayloadObj({
        keyword: '',
        tags: '',
        isBookmark: false,
        page: 1,
        creatorId: staff.id,
        shared: false,
        order: rehabListOrderParams['my'][2],
        tabId: 2
      })
    } else {
      setPayloadObj({
        keyword: '',
        tags: '',
        isBookmark: false,
        page: 1,
        creatorId: '',
        shared: false,
        order: rehabListOrderParams['ex'][2],
        tabId: 3
      })
    }
  }

  const getProgramList = () => {
    setIsLoading(true)
    api.getPrograms(payloadObj.keyword, payloadObj.tags, payloadObj.isBookmark, payloadObj.page, payloadObj.order, payloadObj.creatorId, payloadObj.shared).then((res) => {
      let data = res.data
      const programList = data.list
      if(programList.length) {
        programList.forEach((program) => {
          if(bookmarkProgram.includes(program.pid)) {
            program.isBookmark = true
          } else {
            program.isBookmark = false
          }

          let exScript = program.exScript
          let exerciseMap = program.exerciseMap
          let milli = 0

          exScript.forEach((ex) => {
            milli += exerciseMap[ex.id].media[`M${ex.time ? ex.time : ex.count}`].length
          })

          program.time = setMinutes(milli)
        })
      }
      setIsLoading(false)
      setProgramList(programList)
      setTotalPage(data.totalPage)
      setFilterNumbersObj(data.extra.count)
      setCurrentPage(data.currentPage)
      getTotalElement(data.totalElement)
      window.scrollTo(0,0)
      dispatch(setIsMyProgramsUpdate(false))
    }).catch((e) => {
      console.log(e)
    })
  }


  const getExerciseList = () => {
    let allTags = payloadObj.tags
    let objectives = []
    let positions = []
    let levels = []
    let tags = []

    if(allTags) {
      allTags = allTags.split(',')

      allTags.forEach((tag) => {
        if(tag.includes('OB')) {
          objectives.push(tag)
        } else if(tag.includes('PO')) {
          positions.push(tag)
        } else if(tag.includes('LV')) {
          levels.push(tag.replace('LV', ''))
        } else {
          tags.push(tag)
        }
      })
    }
    setIsLoading(true)
    api.getExercises(payloadObj.keyword, objectives.join(','), positions.join(','), levels.join(','), tags.join(','), payloadObj.isBookmark, payloadObj.page, payloadObj.order)
      .then((res) => {
        let data = res.data
        const exerciseList = data.list
        if(exerciseList.length) {
          exerciseList.forEach((exercise) => {
            if(bookmarkExercise.includes(exercise.id)) {
              exercise.isBookmark = true
            } else {
              exercise.isBookmark = false
            }
          })
        }
        setIsLoading(false)
        setExerciseList([...exerciseList])
        getTotalElement(data.totalElement)
        setTotalPage(data.totalPage)
        setFilterNumbersObj(data.extra.count)
        setCurrentPage(data.currentPage)

      }).catch((e) => {
      console.log(e)
    })
  }

  const toggleBodyPartList = () => {
    if(activeFilterRehabId === 3) {
      setBodyPartListToggle(!bodyPartListToggle)
    }
  }

  const getMuKeyword = (keyword) => {
    setMuKeyword(keyword)
    setFilterMuList(filterAllList.MU)

    if(keyword) {
      setIsSearched(true)
      onMuSearch()
    } else {
      setIsSearched(false)
    }
  }

  const makeNewProgram = () => {
    dispatch(setMyProgramIdToEdit(null))
    dispatch(setActiveFilterRehabId(0))
    dispatch(resetAddedProgramExerciseArr())
    dispatch(resetProgramCreationParams())
    dispatch(setProgramCreationStep(0))
    navigate('/create/program')
  }

  const onMuSearch = () => {
    let newFilterMuList = []

    let muKeysArr = Object.keys(tagKor.MU)
    let muNameAllArr = Object.values(tagEng.MU).concat(Object.values(tagKor.MU))
    muNameAllArr.forEach((muName) => {
      if(muName.toLowerCase().split(' ').join('').includes(muKeyword.toLowerCase().split(' ').join(''))) {
        let matchedTag = muKeysArr.find(key => tagKor.MU[key] === muName || tagEng.MU[key] === muName)
        filterAllList.MU.forEach((filterMu) => {
          if(matchedTag === filterMu.tag) {
            newFilterMuList.push(filterMu)
          }
        })
      }
    })

    setFilterMuList(newFilterMuList)
  }

  useEffect(() => {
    let filterSections = {}
    Object.keys(filterNumbersObj).forEach((filterTitle, idx) => {
      filterSections = {
        ...filterSections,
        [filterTitle]: []
      }
      Object.keys(tagKor[filterTitle]).forEach((tag) => {
        filterSections[filterTitle].push({
          tag: tag,
          tagName: tagKor[filterTitle][tag],
          count: 0
        })
      })
    })
    addFilterNumbers(filterSections)
  }, [programList, exerciseList])

  useEffect(() => {
    setIsLoading(true)
    const queryParams = new URLSearchParams(location.search)

    dispatch(setActiveFilterRehabId(queryParams.get('tabId') ? Number(queryParams.get('tabId')) : 0))

    let tempKeyword = queryParams.get('keyword') ? queryParams.get('keyword') : ''
    let tempTags = queryParams.get('tags') ? queryParams.get('tags') : ''
    let tempIsBookmark = (queryParams.get('isBookmark') === 'false' || !queryParams.get('isBookmark')) ? false : true
    let tempPage = queryParams.get('page') ? queryParams.get('page') : 1

    if(staff.id !== null) {
      if(queryParams.get('tabId') === '0' || !queryParams.get('tabId')) {
        setPayloadObj({
          keyword: tempKeyword,
          tags: tempTags,
          isBookmark: tempIsBookmark,
          page: tempPage,
          creatorId: '',
          shared: false,
          order: queryParams.get('order') ? queryParams.get('order') : rehabListOrderParams['recommended'][2],
          tabId: 0
        })
      } else if(queryParams.get('tabId') === '1') {
        setPayloadObj({
          keyword: tempKeyword,
          tags: tempTags,
          isBookmark: tempIsBookmark,
          page: tempPage,
          creatorId: '',
          shared: true,
          order: queryParams.get('order') ? queryParams.get('order') : rehabListOrderParams['shared'][2],
          tabId: 1
        })
      } else if(queryParams.get('tabId') === '2') {
        setPayloadObj({
          keyword: tempKeyword,
          tags: tempTags,
          isBookmark: tempIsBookmark,
          page: tempPage,
          creatorId: staff.id,
          shared: false,
          order: queryParams.get('order') ? queryParams.get('order') : rehabListOrderParams['my'][2],
          tabId: 2
        })
      } else {
        setPayloadObj({
          keyword: tempKeyword,
          tags: tempTags,
          isBookmark: tempIsBookmark,
          page: tempPage,
          creatorId: '',
          shared: false,
          order: queryParams.get('order') ? queryParams.get('order') : rehabListOrderParams['ex'][2],
          tabId: 3
        })
      }
    }

    setKeyword(queryParams.get('keyword') ? queryParams.get('keyword') : '')
    if(queryParams.get('order')) {
      setSelectedExerciseOrderId(queryParams.get('order').includes('CREATED_DATE_TIME_DESC') ? 0 : queryParams.get('order').includes('CREATED_DATE_TIME_ASC') ? 1 : queryParams.get('order').includes('NAME_ASC') ? 2 : 3)
    } else {
      setSelectedExerciseOrderId(2)
    }
    setIsBookmarkActive((queryParams.get('isBookmark') === 'false' || !queryParams.get('isBookmark')) ? false : true)

    if(queryParams.get('tags')) {
      let filterArr = queryParams.get('tags').split(',')
      let selectedQueryFilterArr = []
      filterArr.forEach((filter) => {
        selectedQueryFilterArr.push({ tag: filter })
      })
      setSelectedFilterItems([...selectedQueryFilterArr])
    } else {
      setSelectedFilterItems([])
    }
  }, [staff])

  useEffect(() => {
    if(isMyProgramsUpdate) {
      getProgramList()
    }
  }, [isMyProgramsUpdate])

  useDidMountEffect(() => {
    if(activeFilterRehabId !== 3) {
      setAPListToggle(false)
      setETListToggle(false)
      setKCListToggle(false)
      setLVListToggle(false)
      setOBListToggle(false)
      setPOListToggle(false)
      setSDListToggle(false)
      setTOListToggle(false)
      setWBListToggle(false)
    }
  }, [activeFilterRehabId]);

  useDidMountEffect(() => {
    navigate(`/rehab?keyword=${payloadObj.keyword}&tags=${payloadObj.tags}&isBookmark=${payloadObj.isBookmark}&page=${payloadObj.page}&order=${payloadObj.order}&tabId=${payloadObj.tabId}&t=${urlParamT}`)

    if(activeFilterRehabId === 3) {
      getExerciseList()
    } else {
      getProgramList()
    }
  }, [payloadObj])

  return (
    <div className={cx('rehab')}>
      <HeaderNav/>
      { isLoading ? (
        <RehabListSkeleton/>
      ) : (
        <>
          <div className={cx('rightWrapper')}>
            <div className={cx('exerciseBox')}>
              <div className={cx('filterContainer')}>
                <LeftTabs tabItems={ tabItems } activeTabId={activeFilterRehabId} onTabItemClick={onTabItemClick}/>
                <div className={cx('bodyPartFilter')}>
                  <div className={cx('bodyPartTitle')}>
                    { tabItems[activeFilterRehabId] && (
                      <p>필터({ numberWithCommas(tabItems[activeFilterRehabId].count) })</p>
                    ) }
                    <div className={cx('reset', (isBookmarkActive || selectedFilterItems.length) && 'active')} onClick={clearFilter}>
                      <div></div>
                      <span>초기화</span>
                    </div>
                  </div>
                  <div className={cx('bookmark')}>
                    <div className={cx('bookmarkTitle')}>
                      <img src={isBookmarkActive ? YNewStar : GNewStar} alt='GNewStar'/>
                      <span>즐겨찾기</span>
                    </div>
                    <div className={cx('toggle')}>
                      <label className={cx('switch')}>
                        <input
                          onChange={onBookmarkClick}
                          type="checkbox"
                          checked={isBookmarkActive}
                        />
                        <span className={cx('slider', 'round')}></span>
                      </label>
                    </div>
                  </div>
                  <div className={cx('filterWrapper')}>
                    <div className={cx('filterItem')} onClick={toggleBodyPartList}>
                      <span>{tagKor['title']['MP']}</span>
                      { activeFilterRehabId === 3 ? (
                        <img className={cx(bodyPartListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                      ) : ''}
                    </div>
                    { bodyPartListToggle && (
                      <div className={cx('bodyList')}>
                        { Object.keys(filterAllList).length ? (
                          filterAllList['MP'].map((item) => (
                            <div
                              className={cx(
                                'filterItemBox',
                                selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                !item.count && 'displayNone'
                              )}
                              key={item.tag}
                            >
                              <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                <div></div>
                                <span>{ item.tagName }</span>
                              </div>
                              <span>{ numberWithCommas(item.count)  }</span>
                            </div>
                          ))
                        ) : ''}
                      </div>
                    ) }
                  </div>
                  { activeFilterRehabId === 3 ? (
                    <>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setAPListToggle(!apListToggle)}>
                          <span>{tagKor['title']['AP']}</span>
                          <img className={cx(apListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { apListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['AP'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.AP[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setETListToggle(!etListToggle)}>
                          <span>{tagKor['title']['ET']}</span>
                          <img className={cx(etListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { etListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['ET'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.ET[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setKCListToggle(!kcListToggle)}>
                          <span>{tagKor['title']['KC']}</span>
                          <img className={cx(kcListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { kcListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['KC'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.KC[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setLVListToggle(!lvListToggle)}>
                          <span>{tagKor['title']['LV']}</span>
                          <img className={cx(lvListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { lvListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['LV'].map((item, idx) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span className={cx('lvTxt', lvArr[idx].color)}>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('muFilterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setMUListToggle(!muListToggle)}>
                          <span>{tagKor['title']['MU']}</span>
                          <img className={cx(muListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        <div className={cx('muFilterList')}>
                          { muListToggle && (
                            <>
                              <div className={cx('muInput')}>
                                <InputSearchBox
                                  placeholder='검색어 입력'
                                  getKeyword={getMuKeyword}
                                  keyword={muKeyword}
                                  onSearch={null}
                                  isSmall={true}
                                />
                              </div>
                              <div className={cx('bodyList')}>
                                { Object.keys(tagKor['MUPartSort']).map((muPart, idx) => (
                                  <div className={cx('muPartWrapper')} key={idx}>
                                    { !isSearched ? (
                                      <>
                                        <p key={muPart} className={cx('muPart')}>{ tagKor['MUPart'][muPart] }</p>
                                        { Object.keys(filterAllList).length ? (
                                          filterAllList['MU'].map((item, idx) => (
                                            tagKor['MUPartSort'][muPart][item.tag] && (
                                              <div
                                                className={cx(
                                                  'filterItemBox',
                                                  selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                                  !item.count && 'displayNone'
                                                )}
                                                key={idx}
                                              >
                                                <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                                  <div className={cx('marginBottom')}></div>
                                                  <span>{tagEng.MU[item.tag]}<br/><span>{ item.tagName }</span></span>
                                                </div>
                                                <span>{ numberWithCommas(item.count)  }</span>
                                              </div>
                                            )
                                          ))
                                        ) : ''}
                                      </>
                                    ) : (
                                      filterMuList.map((item, idx) => (
                                        tagKor['MUPartSort'][muPart][item.tag] && (
                                          <div
                                            className={cx(
                                              'filterItemBox',
                                              selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                              !item.count && 'displayNone'
                                            )}
                                            key={idx}
                                          >
                                            <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                              <div className={cx('marginBottom')}></div>
                                              <span>{tagEng.MU[item.tag]}<br/><span>{ item.tagName }</span></span>
                                            </div>
                                            <span>{ numberWithCommas(item.count)  }</span>
                                          </div>
                                        )
                                      ))
                                    )}

                                  </div>
                                )) }
                              </div>
                            </>
                          ) }
                        </div>

                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setOBListToggle(!obListToggle)}>
                          <span>{tagKor['title']['OB']}</span>
                          <img className={cx(obListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { obListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['OB'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setPOListToggle(!poListToggle)}>
                          <span>{tagKor['title']['PO']}</span>
                          <img className={cx(poListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { poListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['PO'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setSDListToggle(!sdListToggle)}>
                          <span>{tagKor['title']['SD']}</span>
                          <img className={cx(sdListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { sdListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['SD'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName }<br/><span>{ tagEng.SD[item.tag] }</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setTOListToggle(!toListToggle)}>
                          <span>{tagKor['title']['TO']}</span>
                          <img className={cx(toListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { toListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['TO'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div></div>
                                    <span>{ item.tagName }</span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                      <div className={cx('filterWrapper')}>
                        <div className={cx('filterItem')} onClick={() => setWBListToggle(!wbListToggle)}>
                          <span>{tagKor['title']['WB']}</span>
                          <img className={cx(wbListToggle && 'rotate')} src={GTriangle} alt={GTriangle}/>
                        </div>
                        { wbListToggle && (
                          <div className={cx('bodyList')}>
                            { Object.keys(filterAllList).length ? (
                              filterAllList['WB'].map((item) => (
                                <div
                                  className={cx(
                                    'filterItemBox',
                                    selectedFilterItems.some((selectedFilter) => selectedFilter.tag === item.tag) && 'active',
                                    !item.count && 'displayNone'
                                  )}
                                  key={item.tag}
                                >
                                  <div className={cx('itemBox')} onClick={() => onClickFilterItem(item)}>
                                    <div className={cx('marginBottom')}></div>
                                    <span>{ item.tagName} <br/><span>{tagEng.WB[item.tag]}</span></span>
                                  </div>
                                  <span>{ numberWithCommas(item.count)  }</span>
                                </div>
                              ))
                            ) : ''}
                          </div>
                        ) }
                      </div>
                    </>
                  ) : '' }
                </div>
              </div>
              <div className={cx('listContainer')}>
                <div className={cx('listContainerTop')}>
                  <div className={cx('topFilter')}>
                    <div className={cx('inputBox')}>
                      <InputSearchBox
                        placeholder='검색어 입력'
                        getKeyword={getKeyword}
                        keyword={keyword}
                        onSearch={onSearch}
                        isSmall={true}
                      />
                    </div>
                    <div className={cx('dropdownWrapper')}>
                      <DropdownOrder
                        orderList={exerciseOrderList}
                        isDropdownOrderOpen={isDropdownExerciseOrderOpen}
                        setSelectedOrderId={getSelectedExerciseOrderId }
                        selectedOrderId={selectedExerciseOrderId}
                        setIsDropdownOrderOpen={() => setIsDropdownExerciseOrderOpen(!isDropdownExerciseOrderOpen)}
                        listPosition='right'
                      />
                    </div>
                  </div>

                  <div className={cx('createBtn')}>
                    <ButtonPrimary
                      text='프로그램 생성'
                      onBtnPrimaryClick={makeNewProgram}
                      icon='WPlusSharp'
                      height={41}
                      font='small'
                    />
                  </div>
                </div>
                <div className={cx('listWrapper')}>
                  <div className={cx('exerciseList')}>
                    { activeFilterRehabId === 3 ? (
                      exerciseList.map((exercise, idx) => (
                      <RehabCardExercise key={exercise.id} exercise={exercise}/>
                      ))
                    ) : programList.map((program) => (
                      <RehabCardProgram key={program.pid} program={program}/>
                    ))}
                  </div>
                  { ((activeFilterRehabId === 3 && !exerciseList.length) || (activeFilterRehabId !== 3 && !programList.length)) && (
                    isBookmarkActive ? (
                      <div className={cx('empty')}>
                        <img src={EmptyBookmark} alt='EmptyBookmark'/>
                        <p>자주 사용하거나 열람하는 컨텐츠를<br/>추가해 빠르게 찾아보세요:)</p>
                      </div>
                    ) : (
                      <div className={cx('empty')}>
                        <img src={EmptyResult} alt='EmptyResult'/>
                        <p>원하시는 결과를 찾지 못했어요 :(<br/>다시 검색해서 찾아보세요.</p>
                      </div>
                    )
                  )}
                  { totalPage > 1 ? (
                    <Pagination key={totalPage} currentPage={currentPage} totalPage={totalPage} getList={movePage}/>
                  ) : ''}
                </div>
              </div>
            </div>
          </div>
        </>
      ) }
      <FooterGray position='relative'/>
    </div>
  );
};

export default Rehab;