import React, {useEffect, useState} from 'react';
import HeaderNav from "@/common/header/headerNav/headerNav";
import classnames from "classnames/bind";
import styles from './payment.module.scss'
import api from "@/api";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import ModalBig from "@/common/modal/modalBig/modalBig";
import {showToast} from "@/helpers/toastMessage";
import Cards from "@/components/admin/payment/cards/cards";
import {setIsModalBigBtnActive} from "@/store/slice/modalBtnSlice";
import {setUnpaidAmount} from "@/store/slice/paymentSlice";
import {numberWithCommas} from "@/helpers/common";
import Pagination from "@/common/pagination/pagination";
import FooterGray from "@/common/footer/footerGray/footerGray";
import ModalSmall from "@/common/modal/modalSmall/modalSmall";
import LeftTabs from "../common/tabs/leftTabs/leftTabs";
import useDidMountEffect from "../../helpers/useDidMountEffect";

const NoPlans = `${process.env.REACT_APP_RESOURCE_URL}/no_plans.svg`
const RWarning = `${process.env.REACT_APP_RESOURCE_URL}/R_warning.svg`
const cx = classnames.bind(styles)
const historyStatus = {
  'IN_PROGRESS': '결제 진행중',
  'DONE': '결제 완료',
  'FAILED': '결제 실패',
}
const tabItems = [
  {
    id: 0,
    tab: '서비스 구독',
    val: 'serviceSubs',
  },
  {
    id: 1,
    tab: '결제 수단',
    val: 'paymentMethod',
  },
  {
    id: 2,
    tab: '결제 내역',
    val: 'paymentHistory',
  },
  {
    id: 3,
    tab: '예치금 내역',
    val: 'depositHistory',
  },
]

const Payment = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const staff = useSelector(state => {
    return state.staff.value
  })
  const unpaidAmount = useSelector(state => {
    return state.payment.value.unpaidAmount
  })
  const memberCount = useSelector(state => {
    return state.payment.value.memberCount
  })
  const subscriptionInfo = useSelector(state => {
    return state.subscription.value.subscriptionInfo
  })
  const [activeTabId, setActiveTabId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [usingServiceId, setUsingServiceId] = useState(null)
  const [remainPlanCount, setRemainPlanCount] = useState(0)
  const [canceledDateTime, setCanceledDateTime] = useState('')
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [deposit, setDeposit] = useState(0)
  const [currentServiceName, setCurrentServiceName] = useState('')
  const [monthlyEndDateTime, setMonthlyEndDateTime] = useState('')
  const [usingServiceDate, setUsingServiceDate] = useState('')
  const [modalSmallContent, setModalSmallContent] = useState({
    bodyTitle: '',
    bodySub: '',
    isOneBtn: false,
    isHalfBtn: false,
    btnLongTxt: '',
    btnLongEvent: null,
    btnShortTxt: '',
    btnShortEvent: null,
  })
  const [modalBigContent, setModalBigContent] = useState({
    header: '',
    btnSmall: '',
    btnBig: '',
    closeModalBig: null,
    onAction: null,
    values: {},
  })
  const [isModalBigOpen, setIsModalBigOpen] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState(null)
  const [patientCount, setPatientCount] = useState(0)
  const [lastDay, setLastDay] = useState('')
  const [nextServicePayday, setNextServicePayday] = useState('')
  const [historyList, setHistoryList] = useState([])
  const [depositList, setDepositList] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [depositTotalPage, setDepositTotalPage] = useState(0)
  const [depositCurrentPage, setDepositCurrentPage] = useState(0)

  const onTabItemClick = (id) => {
    if(id === activeTabId) {
      return
    }
    setActiveTabId(id)
  }

  const goToService = () => {
    if(usingServiceId) {
      if(!canceledDateTime) {
        setModalSmallContent({
          isOneBtn: true,
          bodyTitle: '서비스 변경 안내',
          bodySub: '서비스 변경을 원하실 경우,<br/>고객센터(support@everex.co.kr)로 <br/>문의 부탁드립니다.',
          btnSecondTxt: '',
          btnFirstTxt: '확인',
          btnSecondEvent: null,
          btnFirstEvent: () => setIsModalSmallOpen(false),
        })
      } else {
        setModalSmallContent({
          isOneBtn: true,
          bodyTitle: '서비스 변경이 불가능합니다.',
          bodySub: '서비스 변경을 원하실 경우, 서비스 해지를 취소해 주세요.',
          btnSecondTxt: '',
          btnFirstTxt: '확인',
          btnSecondEvent: null,
          btnFirstEvent: () => setIsModalSmallOpen(false),
        })
      }
      setIsModalSmallOpen(true)
    } else {
      navigate('/admin/payment/subscription', { state: { deposit: deposit } })
    }
  }

  const getSubsInfo = () => {
    setRemainPlanCount(subscriptionInfo.remainPlanCount)
    setPatientCount(subscriptionInfo.patientCount)
    let newServiceId = subscriptionInfo.modelType === 'BASIC' ? 1 : subscriptionInfo.modelType === 'PRO' ? 2 : subscriptionInfo.modelType === 'BUSINESS' ? 3 : null
    setUsingServiceId(newServiceId)
    setCanceledDateTime(subscriptionInfo.canceledDateTime)
    setSubscriptionId(subscriptionInfo.subscriptionId)

    let endDateMilli = Date.parse(subscriptionInfo.endDateTime)
    let nextPayDayMilli = Date.parse(subscriptionInfo.paymentDateTime)

    let newEndDate = new Date(endDateMilli)
    let newPayDay = new Date(nextPayDayMilli)

    let endYear = newEndDate.getFullYear()
    let endMonth = newEndDate.getMonth() + 1
    let endDate = newEndDate.getDate()

    let payYear = newPayDay.getFullYear()
    let payMonth = newPayDay.getMonth() + 1
    if(payMonth < 10) {
      payMonth = '0' + payMonth
    }
    let payDate = newPayDay.getDate()
    if(payDate < 10) {
      payDate = '0' + payDate
    }

    setLastDay(`${endYear}년 ${endMonth}월 ${endDate}일`)
    if(subscriptionInfo.startDateTime) {
      setUsingServiceDate(subscriptionInfo.startDateTime.split('T')[0].replaceAll('-', '.') + ' ~ ' + subscriptionInfo.endDateTime.split('T')[0].replaceAll('-', '.'))
      setMonthlyEndDateTime(subscriptionInfo.monthlyEndDateTime.split('T')[0].replaceAll('-', '.'))
    }

    if(subscriptionInfo.modelType === null) {
      setMonthlyEndDateTime(subscriptionInfo.freeExpiredDateTime.split('T')[0].replaceAll('-', '.'))
    }

    setNextServicePayday(`${payYear}년 ${payMonth}월 ${payDate}일`)
    let modelTypeStr1 = ''
    let modelTypeStr2 = ''
    if(subscriptionInfo.modelType) {
      modelTypeStr1 = subscriptionInfo.modelType.slice(0, 1)
      modelTypeStr2 = subscriptionInfo.modelType.slice(1, subscriptionInfo.modelType.length).toLowerCase()
    }
    setCurrentServiceName(`${ modelTypeStr1 }${ modelTypeStr2 } ${ subscriptionInfo.dateType === 'YEARLY' ? '연' : '월' }구독`)

    setIsLoading(false)
  }

  const getCurrentService = ()  => {
    if(!staff.rehabGroupId) {
      return
    }

    getSubsInfo()
  }

  const terminate = () => {
    if(canceledDateTime) {
      api.postHospitalGroupUnsubscriptionCancel(staff.rehabGroupId, subscriptionId).then(() => {
        setIsModalBigOpen(false)
        showToast('success', '서비스 해지가 취소되었습니다')
        getCurrentService()
      }).catch((err) => {
        console.log(err)
      })
    } else {
      api.postHospitalGroupUnsubscription(staff.rehabGroupId, subscriptionId).then(() => {
        setIsModalBigOpen(false)
        showToast('error', '서비스가 해지되었습니다.')
        getCurrentService()
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const openTerminateModal = () => {
    setModalBigContent({
      header: canceledDateTime ? '서비스 해지 취소' : '서비스 해지',
      btnSmall: '취소',
      btnBig: canceledDateTime ? '해지 취소하기' : '해지하기',
      closeModalBig: () => setIsModalBigOpen(false),
      onAction: () => terminate(),
    })
    dispatch(setIsModalBigBtnActive(true))
    setIsModalBigOpen(true)
  }

  const getUnpaidAmount = () => {
    if(staff.rehabGroupId && staff.groupRole === 'ADMIN') {
      api.getUnpaidAmount(staff.rehabGroupId).then((res) => {
        dispatch(setUnpaidAmount(res.data.unpaidAmount))
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const payUnpaidAmount = () => {
    api.postPayUnpaidSubscription(staff.rehabGroupId).then(async () => {
      setIsModalSmallOpen(false)
      showToast('success', '미결제 금액이 결제되었습니다.')
      await getUnpaidAmount()
      await getHistoryList()
    }).catch((err) => {
      console.log(err)
    })
  }

  const openPayModal = () => {
    setModalSmallContent({
      isOneBtn: false,
      bodyTitle: '결제하시겠습니까?',
      bodySub: `미결제 금액: ${ numberWithCommas(unpaidAmount) }원<br/>결제 수단에 등록된 기본 카드로 <br/>결제가 진행됩니다.`,
      btnSecondTxt: '취소',
      btnFirstTxt: '결제하기',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      btnFirstEvent: () => payUnpaidAmount(),
    })
    setIsModalSmallOpen(true)
  }

  const getHistoryList = (page = 1) => {
    if(!staff.rehabGroupId) {
      return
    }
    api.getHospitalGroupPayment(staff.rehabGroupId, page, 20).then((res) => {
      let newHistoryList = res.data.list

      if(newHistoryList.length) {
        newHistoryList.forEach((history) => {
          let payDate = history.createdDateTime.split('T')[0].replaceAll('-', '')
          history.date = `${payDate.slice(0, 4)}년 ${payDate.slice(4, 6)}월 ${payDate.slice(6, 8)}일`

          let startDate = history.startDateTime.split('T')[0].replaceAll('-', '')
          startDate = `${startDate.slice(0, 4)}년 ${startDate.slice(4, 6)}월 ${startDate.slice(6, 8)}일`

          let endDateMilli = Date.parse(history.endDateTime) - 86400000
          let newEndDate = new Date(endDateMilli)
          let year = newEndDate.getFullYear()
          let month = newEndDate.getMonth() + 1
          let date = newEndDate.getDate()
          let endDate = `${year}년 ${month < 10 ? '0' + month : month}월 ${date}일`

          history.period = `${startDate} 부터<br/>${endDate} 까지`

          let payByCard = history.paidAmount ? `카드 ****-****-****-${history.cardLastNumber}` : ''
          let payByDeposit = history.depositAmount ? '예치금' : ''
          history.payMethod = payByCard + `${payByCard ? '</br>' : '' }` + payByDeposit

          history.totalAmount = numberWithCommas(history.totalAmount) + '원'

          let paidAmtStr = history.paidAmount ? numberWithCommas(history.paidAmount) + '원' : ''
          let depositAmtStr = history.depositAmount ? numberWithCommas(history.depositAmount) + '원' : ''
          history.pricePaid = `${paidAmtStr} ${paidAmtStr ? '<br/>' : ''} ${depositAmtStr}`

          history.subscriptionModelName = `${history.subscriptionModelName} ${history.dateType === 'YEARLY' ? '연' : '월'}구독`
        })
      }
      setHistoryList(newHistoryList)
      setTotalPage(res.data.totalPage)
      setCurrentPage(res.data.currentPage)
    }).catch((err) => {
      console.log(err)
    })
  }

  const getDepositList = (page = 1) => {
    if(!staff.rehabGroupId) {
      return
    }

    api.getHospitalGroupDepositHistory(staff.rehabGroupId, page).then((res) => {
      let newDepositList = res.data.list
      newDepositList.forEach((content) => {
        content.createdDateTime = content.createdDateTime.split('T')[0].replace('-','년 ').replace('-', '월 ') + '일'
        content.status = content.deposit > 0 ? '충전' : content.deposit < 0 ? '사용' : '-'
        content.deposit = content.deposit < 0 ? -(content.deposit) + '원' : content.deposit + '원'
      })
      setDepositList(newDepositList)
      setDepositTotalPage(res.data.totalPage)
      setDepositCurrentPage(res.data.currentPage)
    }).catch((err) => {
      console.log(err)
    })
  }

  const getHospitalGroup = () => {
    if(!staff.rehabGroupId) {
      return
    }

    api.getRehabGroup(staff.rehabGroupId).then((res) => {
      setDeposit(res.data.deposit)
    }).catch((err) => {
      if(err.response.data.code === '1001') {
        navigate('/myPage')
      }
    })
  }

  useEffect(() => {
    getCurrentService()
    getUnpaidAmount()
    getHistoryList()
    getDepositList()
    getHospitalGroup()

    const queryParams = new URLSearchParams(location.search)
    let tabId = queryParams.get('tabId') ? queryParams.get('tabId') : 0
    setActiveTabId(Number(tabId))
  }, [staff])

  useEffect(() => {
    getSubsInfo()
  }, [subscriptionInfo]);

  useEffect(() => {
    let accessToken = localStorage.getItem('accessToken')
    if(accessToken) {
      let base64Payload = accessToken.split('.')[1]
      let buffer = window.Buffer || require("buffer").Buffer
      let payload = buffer.from(base64Payload, 'base64')
      let result = JSON.parse(payload.toString())

      if(result['group-role'] !== 'ADMIN') {
        navigate(-1)
      }
    } else {
      navigate('/')
    }
  }, [])

  useDidMountEffect(() => {
    navigate(`/payment?tabId=${activeTabId}`)
  }, [activeTabId])

  return (
    <div className={cx('payment')}>
      <HeaderNav/>
      <div className={cx('content')}>
        <div className={cx('contentWrapper')}>
          <div className={cx('leftContent')}>
            <LeftTabs tabItems={ tabItems } activeTabId={activeTabId} onTabItemClick={onTabItemClick} unpaidAmount={unpaidAmount} />
          </div>
          <div className={cx(activeTabId !== 0 && 'inactive', 'rightContent')}>
            <div className={cx('title')}>
              <p>서비스 구독</p>
            </div>
            <div className={cx(isLoading && 'inactive', 'service')}>
              <span className={cx('tag')}>구독중인 서비스</span>
              { !usingServiceId ? (
                <div>
                  <p className={cx('mainTitle')}>Free Trial</p>
                  <p className={cx('subInfo')}>
                    남은 배정 건수 :
                    <span className={cx('planCount', remainPlanCount <= 10 && 'red')}>{remainPlanCount}건</span>
                    { remainPlanCount <= 10 ? (
                      <img src={RWarning} alt='chk' className={cx('imgWarning')}/>
                    ) : ''}
                    { remainPlanCount > 0 ? (
                      <span className={cx('freeEndDate')}> (~ { monthlyEndDateTime })</span>
                    ) : ''}
                  </p>
                  <p className={cx('subInfo', 'memberCount')}>그룹 참여자 수 :<span>{ memberCount }명</span></p>
                  <div className={cx('serviceBtns')}>
                    <button onClick={() => goToService()}>서비스 구독하기</button>
                  </div>
                </div>
              ) : (
                <div>
                  <p className={cx('mainTitle')}>{ currentServiceName }</p>
                  <div className={cx('serviceDate')}>
                    <p className={cx('count')}>
                      남은 배정 건수 :
                      <span className={cx(remainPlanCount <= 10 && 'red', 'remainCount')}> { usingServiceId === 3 && subscriptionInfo.monthlyPlanLimitCount >= 10000 ? '무제한' : remainPlanCount + '건' }</span>
                      { remainPlanCount <= 10 ? (
                        <img src={RWarning} alt='chk' className={cx('imgWarning')}/>
                      ) : ''}
                      <span className={cx('endDate')}> (~ { monthlyEndDateTime })</span>
                    </p>
                    <p className={cx('count')}>그룹 참여자 수 :<span className={cx('remainCount')}>{ memberCount }명</span></p>
                    <p className={cx('date')}>
                      <span className={cx('dateTitle')}>구독 기간 &emsp;&emsp;: </span>
                      <span className={cx('dateInfo')}> { usingServiceDate }</span>
                    </p>
                  </div>
                  <div className={cx('serviceBtns')}>
                    <button className={cx('changeBtn')} onClick={() => goToService()}>서비스 변경하기</button>
                    <button className={cx('cancelBtn')} onClick={openTerminateModal}>{ canceledDateTime ? '서비스 해지 취소하기' : '서비스 해지하기' }</button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={cx(activeTabId !== 1 && 'inactive', 'rightContent')}>
            <div className={cx('title')}>
              <p>결제 수단</p>
            </div>
            <div className={cx('payMethodContent')}>
              <div className={cx('cardSection')}>
                <div className={cx('card')}>
                  <span>카드</span>
                </div>
                <div className={cx('cardWrapper')}>
                  <Cards height='180' marginRight='16'/>
                </div>
              </div>
              <div className={cx('depositSection')}>
                <div className={cx('deposit')}>
                  <span>예치금</span>
                  <a href="https://forms.gle/CaXHoWymAXTCkxLWA" target="_blank" rel="noreferrer">예치금 충전</a>
                </div>
                <div className={cx('depositDetail', deposit && 'active')}>
                  <span className={cx('balance')}>잔액:<span>{ numberWithCommas(deposit) }원</span></span>
                  <a href="https://forms.gle/CaXHoWymAXTCkxLWA" target="_blank" rel="noreferrer">예치금 환불하기</a>
                </div>
                <p>*세금계산서 발행을 원하실 경우, 예치금 충전 버튼을 통해 신청해 주세요.</p>
              </div>
            </div>
          </div>
          <div className={cx(activeTabId !== 2 && 'inactive', 'rightContent')}>
            <div className={cx('title')}>
              <p>결제 내역</p>
            </div>
            <div className={cx('historyList')}>
              { unpaidAmount ? (
                <div className={cx('historyToPay')}>
                  <span>미결제 금액: { numberWithCommas(unpaidAmount) }원</span>
                  <button className={cx('payBtn')} onClick={openPayModal}>결제하기</button>
                </div>
              ) : ''}
              <div className={cx('historySummary')}>
                { usingServiceId ? (
                  <p className={cx('historyTitle')}>
                    현재
                    <span className={cx('historyService')}> {currentServiceName} </span>
                    서비스를 구독중이며, 다음 결제 날짜는
                    <span className={cx('nextPayDate')}>{nextServicePayday}</span>
                    입니다.
                  </p>
                ) : (
                  <p className={cx('historyTitle')}>
                    현재
                    <span className={cx('historyService')}> Free Trial </span>
                    서비스를 구독중입니다.
                  </p>
                )}
                <p className={cx('sub')}>*결제 내역은 1년 이내의 정보만 표시됩니다.<br/>
                  *인보이스 발행이 필요한 경우,
                  <a href="mailto:support@everex.co.kr"> support@everex.co.kr</a>로 신청해 주세요.
                </p>
              </div>
              <div className={cx('historyTable')}>
                <div className={cx('tableHeader')}>
                  <p className={cx('date')}>날짜</p>
                  <p className={cx('serviceName')}>서비스명</p>
                  <p className={cx('period')}>서비스 기간</p>
                  <p className={cx('payMethod')}>결제 수단</p>
                  <p className={cx('status')}>상태</p>
                  <p className={cx('priceOrdered')}>주문 금액</p>
                  <p className={cx('pricePaid')}>결제 금액</p>
                </div>
                { historyList.length ? (
                  <div className={cx('tableBody')}>
                    { historyList.map((history) => (
                      <div key={history.createdDateTime}>
                        <span className={cx('date')}>{ history.date }</span>
                        <span className={cx('serviceName')}>{ history.subscriptionModelName }</span>
                        <span className={cx('period')} dangerouslySetInnerHTML={{ __html: history.period }}></span>
                        <span className={cx('payMethod')} dangerouslySetInnerHTML={{ __html: history.payMethod }}></span>
                        <span className={cx('status', history.status === 'FAILED' && 'red')} dangerouslySetInnerHTML={{ __html: historyStatus[history.status] }}></span>
                        <span className={cx('priceOrdered')}>{ history.totalAmount }</span>
                        <span className={cx('pricePaid', history.status === 'FAILED' && 'red')} dangerouslySetInnerHTML={{ __html: history.pricePaid }}></span>
                      </div>
                    )) }
                  </div>
                ) : (
                  <div className={cx('noHistory')}>
                    <img src={NoPlans} alt="NoPlans"/>
                    <p>결제 내역이 없습니다.</p>
                  </div>
                ) }

                {totalPage > 1 ? (
                <Pagination currentPage={currentPage} totalPage={totalPage} getList={getHistoryList}/>
                ) : ''}
              </div>
            </div>
          </div>
          <div className={cx(activeTabId !== 3 && 'inactive', 'rightContent')}>
            <div className={cx('title')}>
              <p>예치금 내역</p>
            </div>
            <div className={cx('historyList')}>
              <div className={cx('historySummary')}>
                { usingServiceId ? (
                  <p className={cx('historyTitle')}>
                    현재
                    <span className={cx('historyService')}> {currentServiceName} </span>
                    서비스를 구독중이며, 다음 결제 날짜는
                    <span className={cx('nextPayDate')}> {nextServicePayday}</span>
                    입니다.
                  </p>
                ) : (
                  <p className={cx('historyTitle')}>
                    현재
                    <span className={cx('historyService')}> Free Trial </span>
                    서비스를 구독중입니다.
                  </p>
                )}
                <p className={cx('sub')}>*예치금 내역은 1년 이내의 정보만 표시됩니다.<br/>
                  *인보이스 발행이 필요한 경우,
                  <a href="mailto:support@everex.co.kr"> support@everex.co.kr</a>로 신청해 주세요.
                </p>
              </div>
              <div className={cx('historyTable')}>
                <div className={cx('depositTableHeader')}>
                  <p className={cx('depositDate')}>날짜</p>
                  <p className={cx('depositStatus')}>상태</p>
                  <p className={cx('price')}>사용/충전 금액</p>
                  <p className={cx('remain')}>잔액</p>
                  <p className={cx('note')}>비고</p>
                </div>
                { depositList.length ? (
                  <div className={cx('depositTableBody')}>
                    { depositList.map ((deposit) => (
                      <div key={deposit.id}>
                        <span className={cx('depositDate')}>{deposit.createdDateTime}</span>
                        <span className={cx('depositStatus')}>{deposit.status}</span>
                        <span className={cx('price')}>{numberWithCommas(deposit.deposit)}</span>
                        <span className={cx('remain')}>{numberWithCommas(deposit.afterDeposit) + '원'}</span>
                        <span className={cx('note')}>{deposit.message ? deposit.message : '-'}</span>
                      </div>
                    )) }
                  </div>
                ) : (
                  <div className={cx('noHistory')}>
                    <img src={NoPlans} alt="NoPlans"/>
                    <p>예치금 내역이 없습니다.</p>
                  </div>
                ) }
              </div>
            </div>
          </div>
          {depositTotalPage > 1 ? (
            <Pagination currentPage={depositCurrentPage} totalPage={depositTotalPage} getList={getDepositList}/>
          ) : ''}
        </div>
      </div>
      <FooterGray position='relative'/>
      { isModalSmallOpen && (
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          bodySub={modalSmallContent.bodySub}
          isOneBtn={modalSmallContent.isOneBtn}
          btnSecondTxt={modalSmallContent.btnSecondTxt}
          btnFirstTxt={modalSmallContent.btnFirstTxt}
          btnSecondEvent={modalSmallContent.btnSecondEvent}
          btnFirstEvent={modalSmallContent.btnFirstEvent}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}
        />
      ) }
      <ModalBig
        header={modalBigContent.header}
        btnSmall={modalBigContent.btnSmall}
        btnBig={modalBigContent.btnBig}
        closeModalBig={modalBigContent.closeModalBig}
        isModalBigOpen={isModalBigOpen}
        onAction={modalBigContent.onAction}
        values={modalBigContent.values}
        width='490'
      >
        <div className={cx('terminateInputBox', !modalBigContent.header.includes('서비스 해지') && 'inactive')}>
          <div className={cx('title')}>
            { !canceledDateTime ? (
              <p>MORA Ex를 이용해 주셔서 감사합니다.</p>
            ) : (
              <p>아직 서비스 해지 취소 신청이 가능합니다.</p>
            )}
          </div>
          <div className={cx('body')}>
            <p>서비스는 현 결제 주기 마지막날인 <span>{lastDay}</span>에 해지됩니다.</p>
            <p>지금까지 MORA Ex를 통해 처방받은 고객의 수는 <span>{patientCount}명</span>입니다.<br/>언제든지 다시 돌아오셔서 MORA Ex를 사용하실 수 있습니다.
            </p>
            <p>서비스 해지 이후에도 고객 관리 메뉴에는 접근할 수 있으며,<br/>중도 환불을 원하실 경우 <a href="https://forms.gle/CaXHoWymAXTCkxLWA" target="_blank" rel="noreferrer">환불 요청서</a>를 작성해 주세요.</p>
          </div>
        </div>
      </ModalBig>
    </div>
  );
};

export default Payment;