import React, {useEffect, useState} from 'react';
import styles from './headerNav.module.scss'
import classnames from 'classnames/bind';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { setUrlParamT} from "@/store/slice/refreshSlice";
import api from "../../../../utils/api";
import {updateNoti} from "../../../../store/slice/notiSlice";
import {setUnpaidAmount} from "../../../../store/slice/paymentSlice";
import {setSubscriptionInfo} from "../../../../store/slice/subscriptionSlice";

let pageIndex = 0
const LogoMT = `${process.env.REACT_APP_RESOURCE_URL}/Logo_MT.svg`
const RWarning = `${process.env.REACT_APP_RESOURCE_URL}/R_warning.svg`
const rBell = `${process.env.REACT_APP_RESOURCE_URL}/icon/R_bell.svg`

const cx = classnames.bind(styles)
const nav = [
  {
    id: 0,
    item: '홈',
  },
  {
    id: 1,
    item: '고객 관리',
  },
  {
    id: 2,
    item: '재활 운동',
  },
  {
    id: 3,
    item: '사용자 관리',
  },
  {
    id: 4,
    item: '결제 관리',
  },
  {
    id: 5,
    item: '알림',
  },
  {
    id: 6,
    item: '마이페이지',
  },
]

const HeaderNav = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const isNotiOn = useSelector(state => {
    return state.noti.value
  })
  const staff = useSelector(state => {
    return state.staff.value
  })
  const unpaidAmount = useSelector(state => {
    return state.payment.value.unpaidAmount
  })

  const [activeNavId, setActiveNavId] = useState(0)
  const [remainPlanCount, setRemainPlanCount] = useState(10000)
  let accessToken = localStorage.getItem('accessToken')
  const isStaffInHospitalGroup = () => {
    // staff 정보에서의 rehabGroupId와 토큰에서의 rehabGroupId 둘 다 있어야함
    // 다른 경우가 있음 ex) 사용자 관리에서 본인이 나가졌을 때 staff 정보에는 rehabGroupId이 없지만 토큰 리프레시를 얀하면 토큰 안에는 rehabGroupId이 그대로 있음
    // 리프레시 안 한 토큰 가지고 고객 관리 등을 들어갔을 때 에러 남
    let result = true

    if(staff.rehabGroupId === null) {
      return false
    }

    let base64Payload = accessToken.split('.')[1]
    let buffer = window.Buffer || require("buffer").Buffer
    let payload = buffer.from(base64Payload, 'base64')
    let tokenRole = JSON.parse(payload.toString())['rg-id']

    if(tokenRole === undefined) {
      return false
    }

    return result
  }

  const changeNav = (id) => {
    setActiveNavId(id)

    if(pathname.includes('/patient/')) {
      setActiveNavId(1)
    } else if(pathname.includes('/admin/payment/subscription')) {
      setActiveNavId(4)
    }
  }

  const getUnpaidAmount = () => {
    if(staff.groupRole === 'ADMIN') {
      api.getUnpaidAmount(staff.rehabGroupId).then((res) => {
        dispatch(setUnpaidAmount(res.data.unpaidAmount))
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const getRemainPlanCount = () => {
    api.getHospitalGroupSubscription(staff.rehabGroupId).then((res) => {
      let data = res.data
      dispatch(setSubscriptionInfo(data))
      setRemainPlanCount(data.remainPlanCount)
    })
  }



  const onNavClick = (id) => {
    const routes = [
      {'path': '/home'},
      {'path': '/patients'},
      {'path': '/rehab'},
      {'path': '/admin/staff'},
      {'path': '/payment'},
      {'path': '/noti'},
      {'path': '/myPage'},
    ]

    changeNav(id)
    if(activeNavId === id) {
      const queryParams = new URLSearchParams(location.search)
      if(pathname === '/rehab') {
        navigate('/rehab?keyword=&tags=&isBookmark=false&page=1&order=&tabId=0&t=' + pageIndex++)
        dispatch(setUrlParamT(pageIndex))
      } else {
        navigate(routes[id].path + '?t=' + pageIndex++)
        dispatch(setUrlParamT(queryParams.get('t')))
      }
    } else {
      navigate(routes[id].path)
      pageIndex = 0
    }

    if(id === 5) {
      api.postNotiSeen().then(() => {
        dispatch(updateNoti(false))
      })
    }
  }

  const logout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    navigate('/')
  }

  useEffect(() => {
    if(location.pathname.includes('/home')) {
      changeNav(0)
    } else if(location.pathname === '/patients'){
      changeNav(1)
    } else if(location.pathname.includes('/rehab') || location.pathname.includes('/create/program')){
      changeNav(2)
    } else if(location.pathname.includes('/admin')){
      changeNav(3)
    } else if(location.pathname === '/payment'){
      changeNav(4)
    }else if(location.pathname === '/noti'){
      changeNav(5)
    } else if(location.pathname === '/myPage'){
      changeNav(6)
    } else {
      changeNav(null)
    }
  }, [])

  useEffect(() => {
    if(accessToken && staff.rehabGroupId) {
      getUnpaidAmount()
      getRemainPlanCount()
    }
  }, [staff])

  return (
    <div className={cx('headerNav')}>
      { !accessToken ? (
        <div>
          <div className={cx('nav')}>
            <img
              src={LogoMT}
              alt='LogoMT'
              onClick={() => navigate('/')}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={cx('nav')}>
            <img src={LogoMT} onClick={() => navigate('/home')} alt='LogoMT'/>
            <div className={cx(activeNavId === 0 && 'active', !isStaffInHospitalGroup() && 'hide')} onClick={() => onNavClick(0)}>
              <span>{ nav[0].item }</span>
            </div>
            <div className={cx(activeNavId === 1 && 'active', !isStaffInHospitalGroup() && 'hide')} onClick={() => onNavClick(1)}>
              <span>{ nav[1].item }</span>
            </div>
            <div className={cx(activeNavId === 2 && 'active', !isStaffInHospitalGroup() && 'hide')} onClick={() => onNavClick(2)}>
              <span>{ nav[2].item }</span>
            </div>
            <div className={cx(activeNavId === 3 && 'active', !isStaffInHospitalGroup() && 'hide')} onClick={() => onNavClick(3)}>
              <span>{ nav[3].item }</span>
            </div>
            { staff.groupRole === 'ADMIN' && (
              <div className={cx(activeNavId === 4 && 'active', !isStaffInHospitalGroup() && 'hide', 'payment')} onClick={() => onNavClick(4)}>
                <span>{ nav[4].item }</span>
                { (unpaidAmount || remainPlanCount <= 10) ? (
                  <img src={RWarning} alt='chk' className={cx('imgWarning')}/>
                ) : ''}
              </div>
            ) }
          </div>
          <div className={cx('btnImg')}>
            <a className={cx('serviceManual')} href="https://everexteam.notion.site/MORA-Ex-0efcd8f9328b41968493c843c5fdce11" target="_blank" rel="noreferrer">
              <span>서비스 매뉴얼</span>
              <div></div>
            </a>
            <div className={cx('noti', activeNavId === 5 && 'active', !isStaffInHospitalGroup() && 'hide')} onClick={() => onNavClick(5)}>
              { isNotiOn && <img src={ rBell } alt='rBell'/> }
            </div>
            <div className={cx('myPage', activeNavId === 6 && 'active')} onClick={() => onNavClick(6)}></div>
            <div className={cx('logout')} onClick={logout}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderNav;