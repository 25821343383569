import React, { useEffect, useRef, useState} from 'react';
import styles from './dropdownStaffToShare.module.scss'
import classnames from 'classnames/bind';
import JobIcon from "../../jobIcon/jobIcon";

const CloseSimple = `${process.env.REACT_APP_RESOURCE_URL}/close_simple.svg`

const cx = classnames.bind(styles)

const DropdownStaffToShare = ({ staffList, originalStaffList, setStaffList, isDropdownStaffToShareOpen, setIsDropdownStaffToShareOpen, setSelectedStaffArr, selectedStaffArr, setKeywordStaffArr, keywordStaffArr, firstValue }) => {
  const ref = useRef()
  const staffBoxRef = useRef()
  const inputRef = useRef()

  const [dropdownCompHeight, setDropdownCompHeight] = useState(41)
  const [staffInputValue, setStaffInputValue] = useState('')

  const onChoiceItemClick = (choiceItem) => {
    if(selectedStaffArr.length) {
      let newSelectedStaffArr = selectedStaffArr
      newSelectedStaffArr.push(choiceItem)
      setSelectedStaffArr(newSelectedStaffArr)
    } else {
      setSelectedStaffArr([choiceItem])
    }

    setStaffInputValue('')
  }

  const onStaffInputValueClick = (e) => {
    e.stopPropagation()
  }

  const onStaffInputValueChange = (e) => {
    let keyword = e.target.value
    setStaffInputValue(keyword)

    let keywordIncludedStaffList = []

    originalStaffList.forEach((staff) => {
      if(staff.txt.includes(keyword)) {
        keywordIncludedStaffList.push(staff)
      }
    })

    setKeywordStaffArr([...keywordIncludedStaffList])
  }

  const removeStaff = (e, staff) => {
    e.stopPropagation()

    let newSelectedStaffArr = selectedStaffArr
    const idx = newSelectedStaffArr.indexOf(staff)

    if(idx > -1) newSelectedStaffArr.splice(idx, 1)
    setSelectedStaffArr(newSelectedStaffArr)

    setStaffList([staff])
  }

  useEffect(() => {
    if(isDropdownStaffToShareOpen && inputRef.current) {
      inputRef.current.focus()
    }

    const checkIfClickedOutside = e => {
      if (isDropdownStaffToShareOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownStaffToShareOpen()
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isDropdownStaffToShareOpen])

  useEffect(() => {
    setDropdownCompHeight(staffBoxRef.current.clientHeight)
  }, [setSelectedStaffArr])

  return (
    <div className={cx('dropdownStaffToShare')} style={{ height: dropdownCompHeight }}>
      <div className={cx('filterWrapper')} ref={ref}>
        <div className={cx('dropdownBig')}>
          <div
            className={cx('listSelected', isDropdownStaffToShareOpen && 'borderPrimary')}
            onClick={() => setIsDropdownStaffToShareOpen()}
            ref={staffBoxRef}
          >
            { selectedStaffArr.length ? (
              <div>
                {
                  selectedStaffArr.map((staff) => (
                    <p className={cx('selectedStaff')} key={Math.random()}>
                      <span>{ staff.txt }</span>
                      <img src={CloseSimple} onClick={(e) => removeStaff(e, staff)} alt='CloseSimple'/>
                    </p>
                  ))
                }
                <input
                  onClick={(e) => onStaffInputValueClick(e)}
                  onChange={(e) => onStaffInputValueChange(e)}
                  type='text'
                  className={cx('inputStaff', !isDropdownStaffToShareOpen && 'disable')}
                  value={staffInputValue}
                  ref={inputRef}
                  autoFocus={true}
                />
              </div>
            ) : (
              <input
                onChange={(e) => onStaffInputValueChange(e)}
                type='text'
                className={cx('inputStaffSingle')}
                placeholder={firstValue}
                value={staffInputValue}
              />
            ) }
          </div>
          { isDropdownStaffToShareOpen && (
            <div className={cx('filterLists')}>
              <div className={cx('listBox', 'scroll')}>
                { staffInputValue ? (
                  keywordStaffArr.map((staff) =>
                    <div key={Math.random()} onClick={() => onChoiceItemClick(staff)} className={cx('items')}>
                      <JobIcon job={staff.medicalStaffJob} isCertified={staff.isCertified} isTooltip={false}/>
                      <p>{ staff.txt }</p>
                    </div>
                  )
                ) : (
                  staffList.map((staff) =>
                    <div key={Math.random()} onClick={() => onChoiceItemClick(staff)} className={cx('items')}>
                      <JobIcon job={staff.medicalStaffJob} isCertified={staff.isCertified} isTooltip={false}/>
                      <p>{ staff.txt }</p>
                    </div>
                  )
                ) }
              </div>
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default DropdownStaffToShare;