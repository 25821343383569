import React, {useEffect, useState} from 'react';
import styles from './signupIntro.module.scss'
import classnames from 'classnames/bind';
import HeaderGray from "@/common/header/headerGray/headerGray";
import ButtonPrimary from "@/common/button/buttonPrimary/buttonPrimary";
import {useNavigate} from "react-router-dom";
import FooterGray from "../../common/footer/footerGray/footerGray";

const welcome = `${process.env.REACT_APP_RESOURCE_URL}/icon/Welcome.svg`
const PCheck = `${process.env.REACT_APP_RESOURCE_URL}/P_check.svg`

const cx = classnames.bind(styles);
const description = [
  '3,000개 이상의 운동 동작 영상을 제공하고 있어요.',
  '학술자료를 바탕으로 만든 다양한 추천 프로그램이 있어요.',
  '고객별 개인 맞춤 재활 운동 배정이 가능해요.',
  '배정한 플랜별 운동 수행률을 모니터링할 수 있어요.'
]

const SignupIntro = () => {
  const navigate = useNavigate()

  const [userDevice, setUserDevice] = useState('m')

  const onBtnPrimaryClick = () => {
    // 테스트하고 지워
    // let signupInfo = {
    //   'txId': '13131312312312',
    //   'phoneNumber': '010-1111-1111',
    //   'termsService': true,
    //   'termsPrivacy': true,
    //   'name': 'Clara'
    // }
    // navigate('/signup/last', { state: { signupInfo: signupInfo }, replace: true})

    // 테스트하고 풀어
    navigate('/authentication', { state: { title: 'signup' } })
  }

  const onResize = () => {
    let width = window.innerWidth
    if(width < 768) {
      setUserDevice('m')
    } else if(width < 1024 && width >= 768) {
      setUserDevice('t')
    } else if(width < 1441 && width >= 1024){
      setUserDevice('d')
    } else {
      setUserDevice('d_big')
    }
  }

  useEffect(() => {
    window.addEventListener("resize", onResize)
    onResize()

    return () => {
      document.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <>
      { userDevice === 'm' ? (
        ''
      ) : (
        <HeaderGray/>
      )}

      <div className={cx('content')}>
        { userDevice === 'm' ? (
          ''
        ) : (
          <div className={cx('signUpImg')}>
            <img src={welcome} alt='welcome'/>
          </div>
        )}

        <div className={cx('introWrapper')}>
          <div>
            <div className={cx('introTitle')}>
              <h1>MORA가 처음이신가요?</h1>
              <p>지금 회원가입하고 고객에게 재활 운동을 배정해 보세요!</p>
            </div>
            <hr/>
            <div className={cx('introDescBox')}>
              { description.map((des) => (
                <div key={des}>
                  <img src={PCheck} alt='PCheck'/>
                  <p>{ des.replace(/(?:\r\n|\r|\n)/g, '<br />') }</p>
                </div>
              )) }
            </div>
            <div className={cx('btnWrapper')}>
              <button className={cx('introBottom')} onClick={() => navigate('/')}>
                로그인하기
              </button>
              <div className={cx('introBtnBox')}>
                <ButtonPrimary text='지금 무료 가입하기' onBtnPrimaryClick={onBtnPrimaryClick} height={41} font='small'/>
              </div>

            </div>

          </div>
        </div>
      </div>
      { userDevice === 'm' ? (
        ''
      ) : (
        <FooterGray/>
      )}

    </>
  );
};

export default SignupIntro;