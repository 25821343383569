import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import store from './store/store'
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";


if(process.env.REACT_APP_NODE === 'production') {
  console.log = () => {}
}

// redirect
// if(process.env.REACT_APP_NODE === 'production') {
//   let newPc = 'https://mora-ex.kr'
//   let newMobile = 'https://m.mora-ex.kr'
//
//   const userAgent = navigator.userAgent
//   let isMobile = (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) || (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent))
//
//   let host = isMobile ? newMobile : newPc
//   if(!window.location.href.includes(host)){
//     window.location.href = host
//   } else{
//     renderRoot()
//   }
//
// } else {
  renderRoot()
// }

function renderRoot () {
  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    <>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </>
  )
}
