import React, {useEffect, useState} from 'react';
import './moraexPrivacyCollect.css'
import {useLocation} from "react-router-dom";
import HeaderNav from "../../common/header/headerNav/headerNav";
import FooterGray from "../../common/footer/footerGray/footerGray";

const url = 'https://terms.everex.co.kr/projects/moraWeb/privateCollect.html'

const MoraexPrivacyCollect = () => {
  const location = useLocation()

  const [html, setHtml] = useState('')

  useEffect(() => {
    let xmlhttp = new XMLHttpRequest()
    xmlhttp.onreadystatechange=function() {
      if(xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        setHtml(xmlhttp.responseText)
        return xmlhttp.responseText
      }
    }
    xmlhttp.open('GET', url, true)
    xmlhttp.send()
  }, [])


  return (
    <div className={location.pathname.includes('terms') ? 'moraexPrivacyCollectFooter' : 'moraexPrivacyCollectAuth'}>
      { location.pathname.includes('terms') && (
        <>
          <HeaderNav/>
          <div className='privacyTitle'>
            <h1>개인정보 처리방침</h1>
          </div>
        </>
      )}
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
      { location.pathname.includes('terms') && (
        <FooterGray position='relative'/>
      )}
    </div>
  );
};

export default MoraexPrivacyCollect;