import styles from './App.module.scss'
import './App.css'
import React, {useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import ScrollToTop from "./helpers/scrollToTop";
import {useSelector} from "react-redux";
import ToastMessage from "./helpers/toastMessage";
import { pages } from './pages'
import classnames from "classnames/bind";
import Refresh from "./helpers/refresh";

const cx = classnames.bind(styles);

function App() {
  const { pathname } = useLocation()

  const urlParamT = useSelector(state => {
    return state.refresh.value.urlParamT
  })

  const preventRightClick = (e) => {
    if(e.target.tagName === 'VIDEO') {
      e.preventDefault()
    }
  }

  useEffect(() => {
    pages.forEach((page) => {

      if(page.path === pathname) {
        document.title = page.title

        // path url 뒤에 :id 붙으면 따로 분기 쳐주기
      } else if (pathname.includes('/patient/')) {
        document.title = '고객 상세'
      } else if (pathname.includes('/plans/approve/')) {
        document.title = '플랜 승인 상세'
      } else if (pathname.includes('/plan/assignment/')) {
        document.title = '플랜 배정'
      } else if (pathname.includes('/terms/notice/')) {
        document.title = '공지사항 상세'
      }
    })
  }, [pathname])

  useEffect(() => {
    document.addEventListener("contextmenu", preventRightClick);
    return () => {
      document.removeEventListener("contextmenu", preventRightClick);
    }
  }, []);

  return (
    <div className={cx('app')}>
      <Refresh isRedirectHome={false} isUpdateToken={false}/>
      <ScrollToTop/>
      <ToastMessage/>
      <Routes key={urlParamT}>
        { pages.map((page) => (
          <Route key={page.id} path={page.path} exact={page.exact ? page.exact : false} element={page.comp}/>
        )) }
      </Routes>
    </div>
  )
}

export default App;
