import React, {useEffect, useRef, useState} from 'react';
import styles from './planDropdown.module.scss'
import classnames from 'classnames/bind';
import {useSelector} from "react-redux";
import {dateDot} from "../../../../helpers/common";

const GTriangle = `${process.env.REACT_APP_RESOURCE_URL}/G_triangle.svg`
const LGArrowDown = `${process.env.REACT_APP_RESOURCE_URL}/LG_arrow_down.svg`

const cx = classnames.bind(styles)
const PlanDropdown = ({ planList, isDropdownPlanOpen, setIsDropdownPlanOpen, setSelectedPlan, selectedPlan }) => {
  const ref = useRef()
  const planStatusData = useSelector(state => {
    return state.plan.value.planStatusData
  })

  const [isSelectedSerialDates, setIsSelectedSerialDates] = useState(true)

  const onPlanItemClick = (planItem) => {
    setIsDropdownPlanOpen(false)
    setSelectedPlan(planItem)
  }

  const getSelectedPlanDateStr = () => {
    if(selectedPlan.date.length === 1) {
      return dateDot(selectedPlan.date[0])
    } else {
      if(isSelectedSerialDates) {
        return `${dateDot(selectedPlan.date[0])}-${dateDot(selectedPlan.date[selectedPlan.date.length - 1])}`
      } else {
        return `${dateDot(selectedPlan.date[0])} 외 ${selectedPlan.date.length - 1}개`
      }
    }
  }

  const isPlanSerialDates = (planItemDate) => {
    let result = true
    let isStop = false
    planItemDate.forEach((date, idx) => {
      if(isStop || idx === planItemDate.length - 1) {
        return
      }
      let timestamp = new Date(`${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`).getTime()
      let nextTimestamp = new Date(`${planItemDate[idx + 1].slice(0, 4)}-${planItemDate[idx + 1].slice(4, 6)}-${planItemDate[idx + 1].slice(6, 8)}`).getTime()
      if(timestamp + 86400000 !== nextTimestamp) {
        isStop = true
        result = false
      }
    })

    return result
  }

  const getDateStr = (planItemDate) => {
    if(planItemDate.length === 1) {
      return dateDot(planItemDate[0])
    } else {
      if(isPlanSerialDates(planItemDate)) {
        return `${dateDot(planItemDate[0])} - ${dateDot(planItemDate[planItemDate.length - 1])}`
      } else {
        return `${dateDot(planItemDate[0])} 외 ${planItemDate.length - 1}개`
      }
    }
  }

  useEffect(() => {
    let isStop = false
    let dateArr = selectedPlan.date
    dateArr.forEach((date, idx) => {
      if(isStop || idx === dateArr.length - 1) {
        return
      }
      let timestamp = new Date(`${date.slice(0, 4)} - ${date.slice(4, 6)}-${date.slice(6, 8)}`).getTime()
      let nextTimestamp = new Date(`${dateArr[idx + 1].slice(0, 4)}-${dateArr[idx + 1].slice(4, 6)}-${dateArr[idx + 1].slice(6, 8)}`).getTime()
      if(timestamp + 86400000 !== nextTimestamp) {
        isStop = true
        setIsSelectedSerialDates(false)
      }
    })
  }, [])

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isDropdownPlanOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownPlanOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isDropdownPlanOpen])

  return (
    <div className={cx('planDropdown')}>
      <div className={cx('filterWrapper')} ref={ref}>
        <div className={cx('dropdownBig')}>
          <div className={cx('listSelected', isDropdownPlanOpen && 'borderPrimary')}>
            { (selectedPlan !== undefined && selectedPlan.id !== null) ? (
              <div className={cx('selected')} onClick={() => setIsDropdownPlanOpen()}>
                <span className={cx('statusTag')}>
                  <span className={cx('status', planStatusData[selectedPlan.status].txtColor)}>{ planStatusData[selectedPlan.status].status }</span>
                </span>
                <span className={cx('name')}>{ selectedPlan.name }</span>
                <span className={cx('bar')}>|</span>
                <span className={cx('rate')}>평균 운동 수행률 :</span>
                <span className={cx('rateNo')}>{ selectedPlan.initPainScale === undefined ? '-' : selectedPlan.avgPerformancePercent }%</span>
                <span className={cx('bar')}>|</span>
                <span className={cx('date')}>{ getSelectedPlanDateStr() }</span>
                <img className={cx(isDropdownPlanOpen && 'rotate')} src={GTriangle} alt='GTriangle'/>
              </div>
            ) : (
              <div className={cx('noPlan')}>
                <span>-</span>
                <img className={cx(isDropdownPlanOpen && 'rotate')} src={LGArrowDown} alt='LGArrowDown'/>
              </div>
            )}
          </div>
          { isDropdownPlanOpen && (
            <div className={cx('filterLists')}>
              { planList.map((planItem) =>
                <div key={planItem.id} onClick={() => onPlanItemClick(planItem)} className={cx(planItem === selectedPlan && 'active')}>
                  <span className={cx('statusTag')}>
                    <span className={cx('status', planStatusData[planItem.status].txtColor)}>{ planStatusData[planItem.status].status }</span>
                  </span>
                  <span className={cx('name')}>{ planItem.name }</span>
                  <span className={cx('bar')}>|</span>
                  <span className={cx('rate')}>평균 운동 수행률 :</span>
                  <span className={cx('rateNo')}>{ planItem.initPainScale === undefined ? '-' : planItem.avgPerformancePercent }%</span>
                  <span className={cx('bar')}>|</span>
                  <span className={cx('date')}>{ getDateStr(planItem.date) }</span>
                </div>
              ) }
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default PlanDropdown;