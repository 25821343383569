import React, {useEffect, useState} from 'react';
import styles from './organGroupSelect.module.scss'
import classnames from "classnames/bind";
import HeaderNav from "../common/header/headerNav/headerNav";
import FooterGray from "../common/footer/footerGray/footerGray";
import ModalSmall from "../common/modal/modalSmall/modalSmall";
import ModalOrgan from "../common/modal/modalOrgan/modalOrgan";
import api from "../../utils/api";
import Refresh from "../../helpers/refresh";
import {showToast} from "../../helpers/toastMessage";
const LGArrowRight = `${process.env.REACT_APP_RESOURCE_URL}/LG_arrow_right.svg`
const GArrowBack = `${process.env.REACT_APP_RESOURCE_URL}/G_arrow_back.svg`
const RWarningUnfilled = `${process.env.REACT_APP_RESOURCE_URL}/R_warning_unfilled.svg`
const CloseRound = `${process.env.REACT_APP_RESOURCE_URL}/close_round.svg`

const cx = classnames.bind(styles);
const GroupSelect = () => {

  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [stepNo,setStepNo] = useState(1) // 1: 소속기관 2: 그룹 3: 신규 그룹 생성
  const [isRefresh, setIsRefresh] = useState(false)
  const [rehabGroupInfo, setRehabGroupInfo] = useState({
    organizationName: '',
    rehabGroupId: null,
    rehabGroupMemberId: null,
  })
  const [invitorInfo, setInvitorInfo] = useState({
    groupName: '',
    invitor: '',
  })
  const [organ, setOrgan] = useState({
    id: 0,
    name: '',
    verified: true,
    address: ''
  })
  const [modalSmallContent, setModalSmallContent] = useState({
    bodyTitle: '',
    bodySub: '',
    isOneBtn: false,
    btnLongTxt: '',
    btnLongEvent: null,
    btnShortTxt: '',
    btnShortEvent: null,
    values: {},
  })
  const [modalOrganContent, setModalOrganContent] = useState({
    closeModalOrgan: null,
    selectOrgan: null,
  })

  const [isModalOrganOpen, setIsModalOrganOpen] = useState(false)

  const [groupList,setGroupList] = useState([])
  const [groupName,setGroupName] = useState('')
  const [isGroupNameInputFocused,setIsGroupNameInputFocused] = useState(false)



  const cancelJoin = () => {
    api.cancelGroupJoin(rehabGroupInfo.rehabGroupId, rehabGroupInfo.rehabGroupMemberId).then(() => {
      setIsModalSmallOpen(false)
      checkMemberStatus()
    })
  }

  const openModalPending = (groupName) => {
    setModalSmallContent({
      isOneBtn: true,
      bodyTitle: '그룹 참여 승인 대기',
      bodySub: `${rehabGroupInfo.organizationName}의 ${invitorInfo.groupName} 참여를 대기 중이에요.<br/>참여를 위해 그룹 관리자의 승인이 필요해요.`,
      btnSecondTxt: '',
      btnFirstTxt: '참여 취소하기',
      btnSecondEvent: null,
      btnFirstEvent: () => cancelJoin(),
    })
    setIsModalSmallOpen(true)
  }


  const acceptInvitation = () => {
    api.postRehabGroupApproveInvitation(rehabGroupInfo.rehabGroupId, rehabGroupInfo.rehabGroupMemberId).then((res) => {
      setIsRefresh(true)
    })
  }

  const declineInvitation = () => {
    api.postRehabGroupRejectInvitation(rehabGroupInfo.rehabGroupId, rehabGroupInfo.rehabGroupMemberId).then((res) => {
      checkMemberStatus()
      setIsModalSmallOpen(false)
    })
  }

  const getGroupList = () => {
    api.getGroups(organ.id).then((res) => {
      setGroupList(res.data)
    })
  }

  const goNext = () => {
    setStepNo(2)
    getGroupList()
  }


  const openModalToAccept = () => {
    setModalSmallContent({
      isOneBtn: false,
      bodyTitle: '그룹 초대 안내',
      bodySub: `${rehabGroupInfo.organizationName}의 ${invitorInfo.groupName}에서 ${invitorInfo.invitor}님이<br/>사용자를 초대했어요.<br/>그룹 참여 여부를 선택해 주세요.`,
      btnSecondTxt: '거절하기',
      btnFirstTxt: '참여하기',
      btnSecondEvent: () => declineInvitation(),
      btnFirstEvent: () => acceptInvitation(),
    })
    setIsModalSmallOpen(true)
  }

  const showModalOrgan = () => {
    setModalOrganContent({
      closeModalOrgan: () => setIsModalOrganOpen(false),
      selectOrgan: (value) => setOrgan(value),
    })

    setIsModalOrganOpen(true)
  }

  const resetGroupName = (e) => {
    e.preventDefault()
    setGroupName('')
  }

  const doJoin = (group) => {
    api.postJoin({ joinCode: group.joinCode }).then((res) => {
      // 그룹의 첫 사용지면 자동적으로 'ADMIN'임.
      // 바로 홈으로 됨.
      if(res.data.groupRole === 'ADMIN') {
        setIsRefresh(true)
      } else {
        checkMemberStatus()
        openModalPending(group.name)
      }
    }).catch((err) => {
      if(err.response.data.code === '1001') {
        //
      } else if(err.response.data.code === '3111') {
        setModalSmallContent({
          isOneBtn: true,
          bodyTitle: '그룹 참여자 수 제한 초과',
          bodySub: `${organ.name}의 ${group.name} 참여자 수 제한을<br/> 초과하여 참여가 불가능해요.<br/>그룹 관리자에게 확인을 요청해 주세요.`,
          btnSecondTxt: '',
          btnFirstTxt: '확인',
          btnSecondEvent: null,
          btnFirstEvent: () => setIsModalSmallOpen(false),
        })
        setIsModalSmallOpen(true)

        // 이미 가입했거나 참여 요청했던 경우의 에러코드.
        // 이미 가입했을 경우 member-status: ? , 이미 그룹 참여 요청했을 경우: member-status: REQUESTED
        // 이미 가입했을 경우엔 해당 페이지 진입 자체를 안할테니 그룹 참여 요청했을 경우(= 승인 대기 상태)에만 처리
      } else if(err.response.data.code === '3007') {
        openModalPending(group.name)
      }
    })
  }

  const checkMemberStatus = () => {
    api.getMemberStatus().then((res) => {
      let data = res.data
      setRehabGroupInfo({
        organizationName: data.organizationName,
        rehabGroupId: data.rehabGroupId,
        rehabGroupMemberId: data.rehabGroupMemberId
      })
      setInvitorInfo({
        groupName: data.rehabGroupName,
        invitor: data.medicalStaffName,
        status: data.status
      })
    })
  }

  const goBack = () => {
    setStepNo(1)
  }

  const goToAddNewGroup = () => {
    setStepNo(3)
  }

  const addGroup = () => {
    let body = {
      name: groupName,
      orgId: organ.id,
    }
    api.postAddRehabGroup(body).then(() => {
      showToast('success', '신규 그룹이 생성되었습니다.')
      setIsRefresh(true)
    })
  }

  useEffect(() => {
    if(invitorInfo.status === 'INVITED') {
      openModalToAccept()
    } else if(invitorInfo.status === 'REQUESTED') {
      openModalPending()
    }
  }, [invitorInfo])

  useEffect(() => {
    checkMemberStatus()
  }, [])

  return (
    <div className={cx('organGroupSelect')}>
      { isRefresh && <Refresh/> }
      <HeaderNav/>
      <div className={cx('wrapper')}>
        { (invitorInfo.status !== 'INVITED' && invitorInfo.status !== 'REQUESTED') && (
          <div className={cx('container')}>
            {stepNo !== 3 && (
              <div className={cx('flow')}>
                <div className={cx(stepNo === 1 && 'active')}><p>1</p>소속기관 선택</div>
                <img src={LGArrowRight} alt='LGArrowRight'/>
                <div className={cx(stepNo === 2 && 'active')}><p>2</p>그룹 선택</div>
              </div>
            )}

            {stepNo === 1 ? (
              <div className={cx('organSelect')}>
                <div className={cx('organContainer')}>
                  <h2>1. 소속기관 선택</h2>
                  <p>현재 재직 중인 소속기관을 검색해 주세요. 의료기관 또는 회사 모두 가입이 가능해요.</p>
                  <div className={cx('organBox')}>
                    <p>소속기관명</p>
                    <div className={cx('organInputWrapper')} onClick={() => showModalOrgan()}>
                      <input
                        placeholder='소속기관명을 검색해주세요.'
                        readOnly={true}
                        value={organ.name ? organ.name : ''}
                      />
                      <button>검색</button>
                    </div>
                  </div>
                </div>
                <button className={cx(organ.name && 'active')} onClick={goNext}>다음</button>
              </div>
            ) : stepNo === 2 ? (
              <div className={cx('groupSelect')}>
                <div className={cx('groupContainer')}>
                  <h2>2. 그룹 선택</h2>
                  <p className={cx('selectedOrgan')}>소속기관명:<span>{ organ.name }</span></p>
                  <p className={cx('groupListTitle')}>기존 그룹 목록</p>
                  <div className={cx('groupListBox')}>
                    {groupList.length > 0 ? (
                      groupList.map((group) => (
                        <div key={group.id} className={cx('groupItem')}>
                          <div>
                            <div className={cx('code')}><span>{group.joinCode}</span></div>
                            <p className={cx('name')}>{group.name}</p>
                          </div>
                          <button onClick={() => doJoin(group)}>참여하기</button>
                        </div>
                      ))
                    ) : (
                      <p className={cx('empty')}>생성된 그룹이 없습니다.</p>
                    )}

                  </div>
                  <div className={cx('btnWrapper')}>
                    <button className={cx('back')} onClick={goBack}>이전</button>
                    <button className={cx('newGroup')} onClick={goToAddNewGroup}>신규 그룹 생성하기</button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={cx('newGroupAdd')}>
                <div className={cx('newGroupContent')}>
                  <img className={cx('backArrow')} src={GArrowBack} onClick={() => setStepNo(2)} alt='GArrowBack'/>
                  <h2>신규 그룹 생성</h2>
                  <p>그룹명</p>
                  <div className={cx('groupInputWrapper')}>
                    <input
                      className={cx(isGroupNameInputFocused && 'active')}
                      placeholder='예) 정형외과, 서울지점'
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                      onFocus={() => setIsGroupNameInputFocused(true)}
                      onBlur={() => setIsGroupNameInputFocused(false)}
                    />
                    {isGroupNameInputFocused && (
                      <img src={CloseRound} onMouseDown={(e) => resetGroupName(e)} className={cx('close')}
                           alt='CloseRound'/>
                    )}

                  </div>

                  <div className={cx('groupGuide')}>
                    <p>그룹명은 생성 이후에도 변경이 가능해요.</p>
                    <p>최초 그룹 생성자가 그룹의 관리자가 돼요.</p>
                    <p>동일한 그룹 내에서만 환자/플랜 리스트가 공유돼요.</p>
                  </div>
                  <div className={cx('warning')}>
                    <img src={RWarningUnfilled} alt='RWarningUnfilled'/>
                    <span>소속기관 내에서 다른 그룹에 속해 있을 경우, 구독 서비스 및 환자 리스트 공유가 불가능해요.</span>
                  </div>
                </div>

                <button className={cx(groupName && 'active')} onClick={groupName && addGroup}>생성하기</button>
              </div>
            )}


          </div>
        )}

      </div>
      <FooterGray/>
      {isModalSmallOpen && (
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          bodySub={modalSmallContent.bodySub}
          isOneBtn={modalSmallContent.isOneBtn}
          btnSecondTxt={modalSmallContent.btnSecondTxt}
          btnFirstTxt={modalSmallContent.btnFirstTxt}
          btnSecondEvent={modalSmallContent.btnSecondEvent}
          btnFirstEvent={modalSmallContent.btnFirstEvent}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}
          values={modalSmallContent.values}
          isHeaderDimmed={false}
        />
      )}

      {isModalOrganOpen && (
        <ModalOrgan
          closeModalOrgan={modalOrganContent.closeModalOrgan}
          selectOrgan={modalOrganContent.selectOrgan}
          isModalOrganOpen={isModalOrganOpen}
        ></ModalOrgan>
      )}

    </div>
  );
};

export default GroupSelect;