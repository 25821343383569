import React, {useState} from 'react';
import styles from './exerciseItemDetail.module.scss'
import classnames from 'classnames/bind';
import imgSize from "@/data/imgSize"
import {useSelector} from "react-redux";
import tagKor from '@/data/tagKor'

const GTriangle = `${process.env.REACT_APP_RESOURCE_URL}/G_triangle.svg`


const cx = classnames.bind(styles);
const ExerciseItemDetail = ({program, exercise, rep = {}}) => {
  const lvArr = useSelector(state => {
    return state.rehab.value.lvArr
  })

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div className={cx('exerciseItemDetail')}>
      <div className={cx('detailWrap')} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <div className={cx('itemLeft')}>
          <img src={program.exerciseMap[exercise.id].media.IT.url + imgSize['90']} alt='thumbnail'/>
          <div className={cx('imgBack')}></div>
          <div className={cx('exItem')}>
            <p className={cx('itemTitle')}>{ program.exerciseMap[exercise.id].name }</p>
            <p className={cx('level', lvArr[program.exerciseMap[exercise.id].level - 1].color)}>
              { lvArr[program.exerciseMap[exercise.id].level - 1].lv }
            </p>
          </div>
        </div>
        <div className={cx('itemRight')}>
          { rep.id ? (
            <p className={cx('unit')}>
              { rep.time ? rep.time + '초 유지' : rep.count + '회 반복' }
              <span>X</span>
              { rep.repeat }세트
            </p>
          ) :(
            <p className={cx('unit')}>
              { exercise.time ? exercise.time + '초 유지' : exercise.count + '회 반복' }
              <span>X</span>
              { exercise.repeat }세트
            </p>
          )}
          <img className={cx(isDropdownOpen && 'rotate')} src={GTriangle} alt='GTriangle'/>
        </div>
      </div>
      { isDropdownOpen && (
        <div className={cx('detailDropdown')}>
          <video
            controls
            controlsList="nodownload"
            src={program.exerciseMap[exercise.id].urls ? program.exerciseMap[exercise.id].urls.MG : exercise.media.MG.url}
            type="video/mp4"
          ></video>
          <div className={cx('exName')}>
            <p>{ program.exerciseMap[exercise.id].name }</p>
          </div>
          <div className={cx('exDetail')}>
            <p>
              <span className={cx('title')}>난이도</span>
              <span className={cx(lvArr[program.exerciseMap[exercise.id].level - 1].color)}>
                { lvArr[program.exerciseMap[exercise.id].level - 1].lv }
              </span>
            </p>
            <p>
              <span className={cx('title')}>부위</span>
              <span>
                { program.tag ? (
                  program.tag.MP ? (
                    program.tag.MP.map((mp, idx) => (
                      <span key={idx}>
                        { tagKor['MP'][mp] }
                        { idx !== program.tag.MP.length - 1 ? (
                          <span> / </span>
                        ) : ''}
                      </span>
                    ))
                  ) : '-'
                ) : (
                  exercise.exerciseTags.MP && (
                    exercise.exerciseTags.MP.map((mp, idx) => (
                      <span key={idx}>
                        { tagKor['MP'][mp] }
                        { idx !== exercise.exerciseTags.MP.length - 1 ? (
                          <span> / </span>
                        ) : '-'}
                      </span>
                    ))
                  )
                )}
              </span>
            </p>
            <p>
              <span className={cx('title')}>도구</span>
              <span>
                { program.tag ? (
                    program.tag.TO ? (
                      program.tag.TO.map((to, idx) => (
                        <span key={idx}>
                          { tagKor['TO'][to] }
                          { idx !== program.tag.TO.length - 1 ? (<span> / </span>) : ''}
                        </span>
                      ))
                    ): '-'
                ): (
                  exercise.exerciseTags.TO ? (
                    exercise.exerciseTags.TO.map((to, idx) => (
                      <span key={idx}>
                        { tagKor['TO'][to] }
                        { idx !== exercise.exerciseTags.TO.length - 1 ? (<span> / </span>) : ''}
                      </span>
                    ))
                  ): '-'
                )}
              </span>
            </p>
            <p>
              <span className={cx('title')}>목적</span>
              <span>{ program.exerciseMap[exercise.id].objective ? tagKor['OB'][program.exerciseMap[exercise.id].objective] : '-' }</span>
            </p>
            <p>
              <span className={cx('title')}>설명</span>
              <span>{ program.exerciseMap[exercise.id].description ? program.exerciseMap[exercise.id].description : '-'}</span>
            </p>
          </div>

        </div>
      ) }

    </div>
  );
};

export default ExerciseItemDetail;