import React, {useEffect, useState} from 'react';
import styles from './assignment.module.scss'
import classnames from "classnames/bind";
import api from "@/api";
import tagKor from '@/data/tagKor'
import {useDispatch, useSelector} from "react-redux";
import {showToast} from "../../../helpers/toastMessage";
import {useLocation, useNavigate} from "react-router-dom";
import {formatBirthNoDot, formatDate, makeCompressedProgram, makeSetEx, planCreateDate} from "../../../helpers/common";
import ModalSmall from "../../common/modal/modalSmall/modalSmall";
import {
  setPlanAssignmentParams,
  setPlanAssignmentStep,
  setPlanProgramSelected,
  setSelectedPlanStartDateTimestamp
} from "../../../store/slice/planSlice";
import DropdownStaffToShare from "../../common/dropdown/dropdownStaffToShare/dropdownStaffToShare";
import JobIcon from "../../common/jobIcon/jobIcon";
import useDidMountEffect from "../../../helpers/useDidMountEffect";

const LogoW = `${process.env.REACT_APP_RESOURCE_URL}/icon/Logo_W.svg`
const BArrowDownSharp = `${process.env.REACT_APP_RESOURCE_URL}/B_arrow_down_sharp.svg`
const GCheckSquare = `${process.env.REACT_APP_RESOURCE_URL}/G_checkSquare.svg`
const PCheckSquare = `${process.env.REACT_APP_RESOURCE_URL}/P_checkSquare.svg`
const closeRound = `${process.env.REACT_APP_RESOURCE_URL}/close_round.svg`
const BTO1 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO1.svg`
const BTO2 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO2.svg`
const BTO3 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO3.svg`
const BTO4 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO4.svg`
const BTO5 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO5.svg`
const BTO6 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO6.svg`
const BTO7 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO7.svg`
const BTO8 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO8.svg`
const BTO9 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO9.svg`
const BTO10 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO10.svg`
const BTO11 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO11.svg`
const BTO12 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO12.svg`
const BTO13 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO13.svg`
const BTO14 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO14.svg`
const BTO15 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO15.svg`
const BTO16 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO16.svg`
const BTO17 = `${process.env.REACT_APP_RESOURCE_URL}/B_TO17.svg`

const cx = classnames.bind(styles)
const cautions = [
  '통증이 심해지거나 관절이 부을 경우 운동을 중단하시고 담당자와 상담해 주세요',
  '약물치료를 시행하시는 경우 약물치료와 운동치료를 병행해 주세요',
  '영상을 참고하여 본인이 가능한 범위 안에서 운동을 해 주세요',
  '가능한 큰 범위까지 움직여주세요',
  '통증이 심한 경우 운동을 중단하세요',
  '어려운 동작은 가능한 만큼만 따라 해 주세요',
  '운동 시 반동이 생기지 않도록 주의하세요',
  '평소에 자주 시행해 주세요',
  '운동 전후 스트레칭을 해 주세요',
  '운동이 끝나면 얼음찜질을 해 주세요',
]
const cautionsLength = cautions.length
const toolObj = {
  'TO1': BTO1,
  'TO2': BTO2,
  'TO3': BTO3,
  'TO4': BTO4,
  'TO5': BTO5,
  'TO6': BTO6,
  'TO7': BTO7,
  'TO8': BTO8,
  'TO9': BTO9,
  'TO10': BTO10,
  'TO11': BTO11,
  'TO12': BTO12,
  'TO13': BTO13,
  'TO14': BTO14,
  'TO15': BTO15,
  'TO16': BTO16,
  'TO17': BTO17,
}

const Assignment = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const staff = useSelector(state => {
    return state.staff.value
  })
  const planAssignmentParams = useSelector(state => {
    return state.plan.value.planAssignmentParams
  })
  const planSchedule = useSelector(state => {
    return state.plan.value.planSchedule
  })
  const isPlanProgram = useSelector(state => {
    return state.plan.value.isPlanProgram
  })

  const [selectedCautions, setSelectedCautions] = useState([])
  const [textareaCaution, setTextareaCaution] = useState('')
  const [isCautionTextareaFocus, setIsCautionTextareaFocus] = useState(false)
  const [isTextareaBig, setIsTextareaBig] = useState(false)
  const [patientInfo, setPatientInfo] = useState({})
  const [exercises, setExercises] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  const [isAssignBtnActive, setIsAssignBtnActive] = useState(false)
  const [isExerciseDetailOpen, setIsExerciseDetailOpen] = useState(false)
  const [isToolDetailOpen, setIsToolDetailOpen] = useState(false)
  const [isDateDetailOpen, setIsDateDetailOpen] = useState(false)
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [staffList, setStaffList] = useState([])
  const [key, setKey] = useState(0)
  const [modalSmallContent, setModalSmallContent] = useState({
    bodyTitle: '',
    bodySub: '',
    isOneBtn: false,
    btnFirstTxt: '',
    btnSecondTxt: '',
    btnFirstEvent: {},
    btnSecondEvent: {},
    isModalSmallOpen: {},
  })
  const [isDropdownStaffOpen, setIsDropdownStaffOpen] = useState(false)
  const [selectedStaffArr, setSelectedStaffArr] = useState([])
  const [originalStaffList, setOriginalStaffList] = useState([])
  const [keywordStaffArr, setKeywordStaffArr] = useState([])

  const onCautionChange = (e) => {
    let cautionTxt = e.target.value
    setTextareaCaution(cautionTxt)
    if(cautionTxt.length > 60) {
      setIsTextareaBig(true)
    } else {
      setIsTextareaBig(false)
    }
  }


  const onCautionFocus = () => {
    setIsCautionTextareaFocus(true)
  }

  const onCautionBlur = () => {
    setIsCautionTextareaFocus(false)
    saveTextareaCaution()
  }

  const saveTextareaCaution = () => {
    if(isCautionTextareaFocus && textareaCaution) {
      if(!selectedCautions.length) {
        if(textareaCaution) {
          setSelectedCautions([textareaCaution])
        }
      } else {
        let newSelectedCautions = [...selectedCautions]
        let idx = null

        selectedCautions.forEach((item, cautionIdx) => {
          if(!cautions.includes(item)) {
            idx = cautionIdx
          }
        })

        if(idx !== null) {
          delete newSelectedCautions[idx]
        }

        if(textareaCaution) {
          newSelectedCautions.push(textareaCaution)
        }

        let cleanArray = newSelectedCautions.filter(i => i !== undefined)
        setSelectedCautions(cleanArray)
      }
    }

  }

  const onAssignBtnClick = () => {
    if(!isAssignBtnActive) {
      return
    }

    let planInfo = {
      ...planAssignmentParams,
      preScript: {
        compressedSchedule: compressExercise()
      }
    }

    api.postPlan(planInfo).then((res) => {
      let data = { medicalStaffIds: [] }
      if(selectedStaffArr.length) {
        let selectedStaffIdArr = []
        selectedStaffArr.forEach((staff) => {
          selectedStaffIdArr.push(staff.medicalStaffId)
        })
        data = {
          medicalStaffIds: [...selectedStaffIdArr, staff.id]
        }
      }

      api.postPlanSharedStaff(res.data.id, data).then(() => {
        dispatch(setPlanProgramSelected({}))
        dispatch(setSelectedPlanStartDateTimestamp(0))

        setModalSmallContent({
          bodyTitle: '플랜 배정이 완료되었습니다.',
          bodySub: `${patientInfo.userName} ${patientInfo.userIsRegistered ? ' / ' + patientInfo.userGender + ' / ' : ''}${patientInfo.userIsRegistered ? formatBirthNoDot(patientInfo.userBirthday) : ''}`,
          isOneBtn: true,
          btnFirstTxt: '확인',
          btnFirstEvent: () => navigate(`/patient/${patientInfo.id}`),
        })

        setIsModalSmallOpen(true)
      })
    }).catch((err) => {
      if(err.response.data.code === '3109') {
        setModalSmallContent({
          bodyTitle: '사용 가능한 플랜 개수가 없습니다.',
          bodySub: '',
          isOneBtn: true,
          btnFirstTxt: '확인',
          btnFirstEvent: closeModalSmall,
        })

        setIsModalSmallOpen(true)
      } else if(err.response.data.code === '3110') {
        setModalSmallContent({
          bodyTitle: '사용 가능한 기간이 지났습니다.',
          bodySub: '',
          isOneBtn: true,
          btnFirstTxt: '확인',
          btnFirstEvent: closeModalSmall,
        })

        setIsModalSmallOpen(true)
      }
    })
  }

  const closeModalSmall = () => {
    setIsModalSmallOpen(false)
  }

  const goBack = () => {
    if(isPlanProgram) {
      dispatch(setPlanAssignmentStep(0))
    } else {
      dispatch(setPlanAssignmentStep(2))
    }
  }

  const compressExercise = () => {
    let compressedExercises = {}
    Object.keys(planSchedule).forEach((date) => {
      let exercisesADay = Object.values(planSchedule[date])
      if(exercisesADay[0].repeat) {
        compressedExercises[date] = makeCompressedProgram(exercisesADay)
      } else {
        compressedExercises[date] = makeCompressedProgram(makeSetEx(exercisesADay))
      }
    })
    return compressedExercises
  }

  const toggleDropdown = () => {
    setIsDropdownStaffOpen(!isDropdownStaffOpen)
  }

  const getKeywordStaffArr = (keywordIncludedStaffList) => {
    setKeywordStaffArr([...keywordIncludedStaffList])
  }

  const deleteTextarea = () => {
    let tempSelectedCautions = selectedCautions
    tempSelectedCautions.forEach((caution, idx) => {
      if(!cautions.includes(caution)) {
        tempSelectedCautions.splice(idx, 1)
        setSelectedCautions(tempSelectedCautions)
      }
    })
    setTextareaCaution('')
  }

  const onTextCautionCheckBoxClick = () => {
    setIsCautionTextareaFocus(!isCautionTextareaFocus)
    if(textareaCaution.length) {
      deleteTextarea()
    }
  }

  const onCautionClick = (caution) => {
    if(!selectedCautions.length) {
      setSelectedCautions([caution])
    } else {
      let newSelectedCautions = [...selectedCautions]
      let idx = null

      selectedCautions.forEach((selectedCaution, cautionIdx) => {
        if(selectedCaution === caution) {
          idx = cautionIdx
        }
      })

      if(idx !== null) {
        delete newSelectedCautions[idx]
      } else {
        newSelectedCautions.push(caution)
      }

      let cleanArray = newSelectedCautions.filter(i => i !== undefined)
      setSelectedCautions(cleanArray)
    }
  }

  const getSelectedStaffArr = (staffArr) => {
    let tempStaffList = staffList
    tempStaffList = tempStaffList.filter(staff => !staffArr.includes(staff))

    setStaffList(tempStaffList)
    setSelectedStaffArr([...staffArr])
  }

  const updateStaffList = (staff) => {
    setStaffList([...staffList, ...staff])
  }

  const getStaffList = () => {
    api.getRehabGroupMembers(staff.rehabGroupId, 1, 50, '').then((res) => {
      let tempStaffList = res.data.list.filter((tempStaff) => {
        if(tempStaff.medicalStaffPhoneNumber) {
          tempStaff.txt = `${tempStaff.medicalStaffName}(${tempStaff.medicalStaffPhoneNumber.slice(7, 11)})`
        }

        return tempStaff.status === 'DONE' && tempStaff.medicalStaffPhoneNumber
      })

      setStaffList(tempStaffList)
      setOriginalStaffList(tempStaffList)
    })
  }

  const checkAllCautions = () => {
    let tempSelectedCautions = selectedCautions
    if(getIncludedCautionsLength() === cautionsLength) {
      tempSelectedCautions = tempSelectedCautions.filter((caution) => {
        if(!cautions.includes(caution)) {
          return caution
        }
      })
    } else {
      tempSelectedCautions = tempSelectedCautions.concat(cautions)
      tempSelectedCautions = [...new Set(tempSelectedCautions)]
    }
    setSelectedCautions(tempSelectedCautions)
    setKey(Math.random)
  }

  const getIncludedCautionsLength = () => {
    let count = 0

    selectedCautions.forEach((caution) => {
      if(cautions.includes(caution)) {
        count++
      }
    })

    return count
  }

  const onkeydown = (e) => {
    if(e.code === 'Enter') {
      e.preventDefault()
      onCautionBlur()
    }
  }

  const isAllChecked = () => {
    let result = false
    if(getIncludedCautionsLength() === cautionsLength) {
      result = true
    }

    return result
  }

  const getCheckboxLength = () => {
    let count = 0
    cautions.forEach((caution) => {
      if(selectedCautions.includes(caution)) {
        count++
      }
    })

    // 직접입력 체크되고 입력값이 없어도 주의사항 카운트 됨
    if(textareaCaution || isCautionTextareaFocus) {
      count++
    }

    return count
  }

  useEffect(() => {
    let tempSelectedCautions = selectedCautions
    let cleanArray = tempSelectedCautions.filter(i => i !== undefined)
    dispatch(setPlanAssignmentParams({
      ...planAssignmentParams,
      caution: cleanArray.join('\n')
    }))
  }, [selectedCautions])

  useEffect(() => {
    if(isChecked) {
      setIsAssignBtnActive(true)
    } else {
      setIsAssignBtnActive(false)
    }
  }, [isChecked])

  useDidMountEffect(() => {
    if(textareaCaution) {
      setIsCautionTextareaFocus(true)
    } else {
      setIsCautionTextareaFocus(false)
    }
  }, [textareaCaution])

  useEffect(() => {
    let medicalRecordId = location.pathname.split('/')[3]
    if(medicalRecordId) {
      api.getPatientInfo(medicalRecordId).then((res) => {
        setPatientInfo(res.data)
      }).catch((e) => {
        if(e.response.data.code === '1001') {
          showToast('error', '고객 정보가 없습니다.')
          navigate('/patients')
        }
      })
    } else {
      navigate('/patients')
    }


    let exerciseAll = Object.values(planSchedule).flat()
    let uniqueExercises = exerciseAll.reduce((accumulator, currentValue) => {
      if (accumulator.filter(s => s.id === currentValue.id).length === 0) {
        let appendItem = { ...currentValue }
        accumulator.push(appendItem)
      }
      return accumulator
    }, [])
    setExercises(uniqueExercises)

    getStaffList()

    // position: sticky 작동하려면 body에 걸려있는 overflow hidden 풀어줘야함
    let body = document.getElementsByTagName('body')[0]
    body.style.overflowX = 'visible'
    return () => body.style.overflowX = 'hidden'
  }, [])

  return (
    <div className={cx('assignment')}>
      <div className={cx('rightWrapper')}>
        <div className={cx('planName')}>
          <span>{ planAssignmentParams.name }</span>
          <img src={LogoW} alt='LogoW'/>
        </div>
        <p className={cx('patientName')}>{ patientInfo.userName }</p>
        <p className={cx('patientInfo')}>
          { patientInfo.userIsRegistered && (
            <>
              <span className={cx('gender')}> { patientInfo.userGender } </span>
              <span className={cx('bar')}> / </span>
              <span className={cx('birth')}> { patientInfo.userBirthday && formatBirthNoDot(patientInfo.userBirthday) }</span>
            </>
          )}
        </p>
        <div className={cx('planDetail')}>
          <span className={cx('title')}>운동 부위</span>
          <p className={cx('content')}><span className={cx('count')}>{ tagKor['MP'][planAssignmentParams.mainPart] }</span></p>
        </div>
        <div className={cx('planDetail')}>
          <span className={cx('title')}>질환 및 수술명</span>
          <p className={cx('content')}><span className={cx('count')}>{ planAssignmentParams.diagnosis.trim() ? planAssignmentParams.diagnosis : '-' }</span></p>
        </div>
        <div className={cx('planDetail', 'pointer')} onClick={() => setIsExerciseDetailOpen(!isExerciseDetailOpen)}>
          <span className={cx('title')}>구성 운동</span>
          { exercises.length ? (
            <p className={cx('content')}><span className={cx('count')}>{ exercises.length }</span><span > ({ exercises[0].name }{ exercises.length - 1 ? ` 외 ${exercises.length - 1}` : '' })</span></p>
          ) : '' }
          <img src={BArrowDownSharp} className={cx(isExerciseDetailOpen && 'rotate')} alt='BArrowDownSharp'/>
        </div>
        { isExerciseDetailOpen && (
          <div className={cx('exerciseDetail')}>
            { exercises.map((exercise, idx) => (
              <div key={exercise.unique ? exercise.unique : idx} className={cx('exerciseItem')}>
                <div className={cx('grayBlack')}></div>
                <img src={exercise.urls ? exercise.urls.IT : exercise.media.IT.url} alt='exerciseImg'/>
                <p>{ exercise.name }</p>
              </div>
            )) }
          </div>
        ) }
        <div className={cx('planDetail', 'pointer')} onClick={() => setIsToolDetailOpen(!isToolDetailOpen)}>
          <span className={cx('title')}>운동 도구</span>
          { planAssignmentParams.tools.length ? (
            <p className={cx('content')}><span className={cx('count')}>{ planAssignmentParams.tools.length }</span><span> ({ tagKor.TO[planAssignmentParams.tools[0]] }{ planAssignmentParams.tools.length - 1 ? ` 외 ${planAssignmentParams.tools.length - 1}` : '' })</span></p>
          ) : '' }
          <img src={BArrowDownSharp} className={cx(isToolDetailOpen && 'rotate')} alt='BArrowDownSharp'/>
        </div>
        { isToolDetailOpen && (
          <div className={cx('toolDetail')}>
            { planAssignmentParams.tools.map((tool) => (
              <div key={tool} className={cx('toolItem')}>
                <img src={toolObj[tool]} alt='tool'/>
                <p>{ tagKor.TO[tool] }</p>
              </div>
            )) }
          </div>
        ) }


        <div className={cx('planDetail', 'pointer')} onClick={() => setIsDateDetailOpen(!isDateDetailOpen)}>
          <span className={cx('title')}>기간 (일)</span>
          <p className={cx('content')}><span className={cx('count')}>{ Object.keys(planSchedule).length }</span><span> ({ formatDate(Object.keys(planSchedule)[0], false)}{ Object.keys(planSchedule).length - 1 ? ` 외 ${Object.keys(planSchedule).length - 1}` : '' })</span></p>
          <img src={BArrowDownSharp} className={cx(isDateDetailOpen && 'rotate')} alt='BArrowDownSharp'/>
        </div>
        { isDateDetailOpen && (
          <div className={cx('dateDetail')}>
            { Object.keys(planSchedule).map((date) => (
              <span key={date}>{ formatDate(date, true) }<span> | </span></span>
            )) }
          </div>
        ) }
        <div className={cx('planDetail', 'caution')}>
          <div className={cx('cautionContent')}>
            <span className={cx('title')}>주의사항</span>
            { getCheckboxLength() ? (
              <p className={cx('content')}>
                <span className={cx('count', 'cautionTxt')}>{ getCheckboxLength() }</span>
                { selectedCautions.length ? (
                  <span className={cx('txt')}> ({ selectedCautions[0] }{ getCheckboxLength() - 1 ? ` 외 ${getCheckboxLength() - 1}` : '' })</span>
                ) : ''}
              </p>
            ) : (
              <p className={cx('noContent')}>- 주의사항을 선택해 주세요(선택)</p>
            )}

          </div>

          <div className={cx('cautionAll')}>
            <div className={cx(isAllChecked() && 'active')} onClick={checkAllCautions}>
              <img src={isAllChecked() ? PCheckSquare : GCheckSquare } alt='checkbox'/>
              <span>전체선택</span>
            </div>
          </div>
          <div className={cx('cautionOptions')} key={key}>
            { cautions.map((caution) => (
              <div key={caution} className={cx(selectedCautions.includes(caution) && 'active')} onClick={() => onCautionClick(caution)}>
                <img src={selectedCautions.includes(caution) ? PCheckSquare : GCheckSquare} alt='checkbox'/>
                <span key={caution}>
                 { caution }
               </span>
              </div>
            )) }
            <div className={cx((isCautionTextareaFocus || textareaCaution) && 'active', isTextareaBig && 'big')}>
              <img onClick={() => onTextCautionCheckBoxClick()} src={(textareaCaution || isCautionTextareaFocus ) ? PCheckSquare : GCheckSquare} alt='checkbox'/>
              <div className={cx('inputBox')}>
                <textarea
                  className={cx(isCautionTextareaFocus && 'focus')}
                  maxLength={150}
                  value={textareaCaution}
                  onChange={(e) => onCautionChange(e)}
                  onFocus={ onCautionFocus }
                  onBlur={ onCautionBlur }
                  onKeyDown={(e) => onkeydown(e)}
                  placeholder='직접 입력(150자 이내)'
                />
                { (isCautionTextareaFocus && textareaCaution) && (
                  <img src={closeRound} onMouseDown={ (e) => deleteTextarea(e) } alt='closeRound'/>
                )}
              </div>
            </div>
          </div>

        </div>
        <p className={cx('assign')}>위와 같이 플랜을 배정합니다.</p>
        <p className={cx('date')}>{ planCreateDate(Date.now(), true) }</p>
        <div className={cx('planStaff')}>
          <div className={cx('staffInfo')}>
            <p className={cx('staff')}>담당자</p>
            <span className={cx('colon')}>:</span>
            <div className={cx('staffName')}>
              <JobIcon job={staff.job} isCertified={staff.isCertified}/>
              <span>{ staff.name }({ staff.phoneNumber.slice(7, 11) })</span>
            </div>
          </div>
        </div>
        <p className={cx('hospital')}>{ staff.rehabGroupName }</p>
      </div>
      <div className={cx('leftWrapper')}>
        <div className={cx('floatContainer')}>
          <p className={cx('leftTitle')}>플랜 배정</p>
          <div className={cx('warning')}>
            <p>고객의 재활기록을 제 3자(에버엑스)에게 제공하는 경우 고객으로부터 진료기록열람요청 동의를 받아야 합니다.<br/>고객의 본인확인 후, 아래 내용을 고지해주세요.</p>
            <p>1) 재활기록의 범위 : 배정된 운동의 상세내용 (기간, 구성운동, 운동부위, 주의사항)<br/>
              2) 열람주체 : 에버엑스 주식회사<br/>
              3) 열람방법 : DB 전송</p>
          </div>
          <div className={cx('checkbox')} onClick={() => setIsChecked(!isChecked)} >
            <img src={ isChecked ? PCheckSquare : GCheckSquare} alt='check'/>
            <span>고객의 재활기록열람요청 의사를 확인했습니다.</span>
          </div>

          <p className={cx('share')}>플랜 공유 사용자 (선택)</p>
          <DropdownStaffToShare
            staffList={staffList}
            originalStaffList={originalStaffList}
            setStaffList={updateStaffList}
            isDropdownStaffToShareOpen={isDropdownStaffOpen}
            setSelectedStaffArr={getSelectedStaffArr }
            selectedStaffArr={selectedStaffArr}
            setKeywordStaffArr={getKeywordStaffArr}
            keywordStaffArr={keywordStaffArr}
            setIsDropdownStaffToShareOpen={toggleDropdown }
            firstValue='플랜을 공유할 사용자를 지정해 주세요.'
          />

          <div className={cx('btns')}>
            <button className={cx('backBtn')} onClick={() => goBack()}>뒤로</button>
            <button className={cx('assignBtn', isAssignBtnActive && 'active')} onClick={() => onAssignBtnClick()}>배정하기</button>
          </div>
        </div>
      </div>
      { isModalSmallOpen &&
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          bodySub={modalSmallContent.bodySub}
          isOneBtn={modalSmallContent.isOneBtn}
          btnSecondTxt={modalSmallContent.btnSecondTxt}
          btnFirstTxt={modalSmallContent.btnFirstTxt}
          btnSecondEvent={modalSmallContent.btnSecondEvent}
          btnFirstEvent={modalSmallContent.btnFirstEvent}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}/>
      }
    </div>
  );
};

export default Assignment;