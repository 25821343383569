import React from 'react';
import styles from './error.module.scss'
import classnames from 'classnames/bind';
import ButtonBigGray from "../common/button/buttonBigGray/buttonBigGray";
import {useNavigate} from "react-router-dom";

const error = `${process.env.REACT_APP_RESOURCE_URL}/error.svg`

const cx = classnames.bind(styles);

const Error = () => {
  const navigate = useNavigate()

  return (
    <div className={cx('error')}>
      <img src={error} alt='error'/>
      <div className={cx('mainTxt')}>
        <p>죄송합니다</p>
        <p>서비스 상태가 원활하지 않습니다.</p>
      </div>
      <div className={cx('subTxt')}>
        <p>이용에 불편을 드려 죄송합니다.</p>
        <p>현재 시스템 오류가 발생하여 페이지를 표시 할 수 없습니다.</p>
        <p>관리자에게 문의하시거나 잠시 후 다시 시도하세요.</p>
      </div>
      <div className={cx('btnWrapper')}>
        <ButtonBigGray text='MORA 홈으로 돌아가기' onBtnBigGrayClick={() => navigate('/')}/>
      </div>

    </div>
  );
};

export default Error;