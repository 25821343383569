import React, {useEffect, useState} from 'react';
import HeaderNav from "@/common/header/headerNav/headerNav";
import classnames from "classnames/bind";
import styles from './noti.module.scss'
import Pagination from "../common/pagination/pagination";
import api from "@/api";
import NotiSkeleton from "./notiSkeleton/notiSkeleton";
import {showToast} from "@/helpers/toastMessage";
import {useNavigate} from "react-router-dom";
import {setAccess} from "../../store/slice/patientSlice";
import {useDispatch, useSelector} from "react-redux";
import FooterGray from "../common/footer/footerGray/footerGray";
import LeftTabs from "../common/tabs/leftTabs/leftTabs";

const emptyBox = `${process.env.REACT_APP_RESOURCE_URL}/Empty_box.svg`

const cx = classnames.bind(styles)

const tabItems= [
  {
    id: 0,
    tab: '전체',
    val: '',
  },
  {
    id: 1,
    tab: '고객',
    val: 'PATIENT',
  },
  {
    id: 2,
    tab: '사용자',
    val: 'MEMBER',
  },
  {
    id: 3,
    tab: '결제',
    val: 'PAYMENT',
  }
]

const notiGroup = {
  PATIENT: {
    ko: '고객',
    color: 'blue'
  },
  MEMBER: {
    ko: '사용자',
    color: 'green'
  },
  PAYMENT: {
    ko: '결제',
    color: 'yellow'
  },
}

const Noti = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const rehabGroupId = useSelector(state => {
    return state.staff.value.rehabGroupId
  })
  const staffId = useSelector(state => {
    return state.staff.value.id
  })
  const staff = useSelector(state => {
    return state.staff.value
  })

  const [activeTabId, setActiveTabId] = useState(0)
  const [notiList, setNotiList] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)

  const onTabItemClick = (tabId) => {
    if(tabId === activeTabId) {
      return
    }
    setActiveTabId(tabId)
  }

  const getNotiList = (page = 1) => {
    setIsLoading(true)
    api.getNotiList(tabItems[activeTabId].val, page).then((res) => {
      setNotiList(res.data.list)
      setTotalPage(res.data.totalPage)
      setCurrentPage(page)
      setIsLoading(false)
      }).catch((e) => {
        console.log(e)
      })
    }
  const onNotiClick = (noti) => {
    api.postNotiRead(noti.id).then(() => {
      if(noti.message.includes('삭제') || noti.message.includes('탈퇴')) {
        showToast('error', '존재하지 않는 고객입니다.')
        getNotiList()
      } else if(noti.rehabGroupId === staff.rehabGroupId) {
        if(noti.group === 'PAYMENT') {
          if(staff.groupRole === 'ADMIN') {
            navigate(noti.link)
          } else {
            showToast('error', '접근할 수 있는 권한이 없어요.')
            getNotiList()
          }
        } else if(noti.group === 'MEMBER'){
          navigate(noti.link)
        } else {
          navigate(noti.link)
          dispatch(setAccess(true))
        }
      } else {
        showToast('error', '접근할 수 있는 권한이 없어요.')
        getNotiList()
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  const readAll = () => {
    setIsLoading(true)
    api.postNotiReadAll(tabItems[activeTabId].val).then(() => {
      getNotiList()
    })
  }

  // const calculateDay = (date) => {
  //   const today = new Date()
  //   const passDate = new Date(date)
  //   const betweenTime = Math.floor((today.getTime() - passDate.getTime()) / 1000 / 60)
  //   const betweenTimeDay = Math.floor(betweenTime / 60 / 24)
  //   if (betweenTime < 1) return '방금 전'
  //   if (betweenTime < 60) {
  //     return `${betweenTime}분전`
  //   }
  //   const betweenTimeHour = Math.floor(betweenTime / 60)
  //   if (betweenTimeHour < 24) {
  //     return `${betweenTimeHour}시간 전`
  //   }
  //
  //   if (betweenTimeDay < 365) {
  //     return `${betweenTimeDay}일 전`
  //   }
  // }

  const dateStr = (date) => {
    let result = ''

    if(new Date().getFullYear() === new Date(date).getFullYear() && new Date().getMonth() === new Date(date).getMonth() && new Date().getDate() === new Date(date).getDate()) {
      let time = date.split('T')[1]
      let hour = Number(time.split(':')[0])
      let ampm = '오전'
      if(hour > 12) {
        ampm = '오후'
        hour = hour - 12
      } else if(hour === 12) {
        ampm = '오후'
      }
      let min = time.split(':')[1]

      result = ampm + ' ' + hour + ':' + min
    } else {
      let time = date.split('T')[0]
      result = time.split('-')[1] + '월 ' + time.split('-')[2] + '일'
    }

    return result
  }

  useEffect(() => {
    if(staffId && rehabGroupId === null) {
      setIsLoading(false)
    } else {
      getNotiList()
    }
  }, [activeTabId, rehabGroupId])

  return (
    <div className={cx('noti')}>
      <HeaderNav/>
      <div className={cx('container')}>
        <div className={cx('wrapper')}>
          <LeftTabs tabItems={ tabItems } activeTabId={activeTabId} onTabItemClick={onTabItemClick}/>
          <div className={cx('content')}>
            <div className={cx('header')}>
              <span className={cx('contentTitle')}>{ tabItems[activeTabId].tab }</span>
              <span className={cx('read')} onClick={readAll}>모두 읽음 표시</span>
            </div>
            <div className={cx('bodyWrapper')}>
              { notiList.length && !isLoading ? (
                <div className={cx('body')}>
                  { notiList.map((noti) => (
                    <div key={noti.id} className={cx('item', noti.readState || 'unread')} onClick={() => onNotiClick(noti)}>
                      <div>
                        <p className={cx('tag', notiGroup[noti.group]['color'])}>
                          <span>{ notiGroup[noti.group]['ko'] }</span>
                        </p>
                        <div className={cx('itemContent')}>
                          <p className={cx('notiTitle', noti.readState && 'thin')}>
                            { noti.message.split('\n')[0] }
                            <span>{ noti.message.split('\n')[1] }</span>
                          </p>
                        </div>
                      </div>
                      <p className={cx('date', noti.readState && 'thin')}>{ dateStr(noti.createdDateTime) }</p>
                    </div>
                  )) }
                </div>
              ) : !notiList.length && !isLoading ? (
                <div className={cx('bodyEmpty')}>
                  <div>
                    <img src={emptyBox} alt='emptyBox'/>
                    <p>모든 알림을 확인하셨어요.</p>
                  </div>
                </div>
              ) : isLoading ? (
                <NotiSkeleton/>
              ) : ''}
              { totalPage > 1 ? (
                <div className={cx('pagination')}>
                  <Pagination currentPage={currentPage} totalPage={totalPage} getList={getNotiList}/>
                </div>
              ) : ''
              }
            </div>

          </div>
        </div>
      </div>
      <FooterGray position='relative'/>
    </div>
  );
};

export default Noti;