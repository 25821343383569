import React from 'react';
import HeaderNav from "@/common/header/headerNav/headerNav";
import classnames from "classnames/bind";
import styles from './succeedSubscription.module.scss'
import {useNavigate} from "react-router-dom";
import FooterGray from "../../../../common/footer/footerGray/footerGray";

const LGArrowRight = `${process.env.REACT_APP_RESOURCE_URL}/LG_arrow_right.svg`
const illust4 = `${process.env.REACT_APP_RESOURCE_URL}/illust_4.svg`

const cx = classnames.bind(styles)

const SucceedSubscription = () => {
  const navigate = useNavigate()

  return (
    <div className={cx('succeedSubscription')}>
      <HeaderNav/>
      <div className={cx('contentWrapper')}>
        <p className={cx('title')}>
          <span className={cx('back')} onClick={() => navigate('/payment')}>결제 관리</span>
          <img src={LGArrowRight} alt='LGArrowRight'/>
          <span className={cx('here')}>서비스 구독</span>
        </p>
        <div className={cx('content')}>
          <img src={illust4} alt="illust4"/>
          <div>
            <p>MORA Ex를 구독해 주셔서 감사합니다.</p>
            <div className={cx('list')}>
              <p>지금부터 MORA Ex 서비스를 자유롭게 이용하실 수 있습니다.</p>
              <p>고객 관리 메뉴에 들어가서 고객에게 재활 플랜을 배정해 보세요.</p>
              <p>예치금 충전 및 세금계산서 발행을 원하실 경우, 결제 수단 메뉴에서 신청 가능합니다.</p>
            </div>
            <div className={cx('btnWrapper')}>
              <button className={cx('btn1')} onClick={() => navigate('/payment')}>결제 수단</button>
              <button className={cx('btn2')} onClick={() => navigate('/patients')}>고객 관리</button>
            </div>
          </div>
        </div>
      </div>
      <FooterGray/>
    </div>
  );
};

export default SucceedSubscription;