import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import styles from './notice.module.scss'
import {useLocation} from "react-router-dom";
import classnames from "classnames/bind";
import HeaderNav from "../../common/header/headerNav/headerNav";
import FooterGray from "../../common/footer/footerGray/footerGray";
import InputSearchBox from "../../common/input/inputSearchBox/inputSearchBox";
import Pagination from "@/common/pagination/pagination";
import NoticeSkeleton from "./noticeSkeleton/noticeSkeleton";
import api from "@/api";
import useDidMountEffect from "../../../helpers/useDidMountEffect";
const NoticeNoList = `${process.env.REACT_APP_RESOURCE_URL}/png/announcement_noList.png`
const NoticeNoKeyword = `${process.env.REACT_APP_RESOURCE_URL}/png/announcement_noKeyword.png`
const cx = classnames.bind(styles)
const Notice = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [keyword, setKeyword] = useState('')
  const [noticeList, setNoticeList] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [noListText, setNoListText] = useState('')
  const getKeyword = (keyword) => {
    setKeyword(keyword)
  }

  const onSearch = () => {
    if(keyword) {
      getNoticeList(1)
    }
  }

  const goToDetail = (id) => {
    navigate(`/terms/notice/${id}?page=${currentPage}`)
  }

  const getNoticeList = (page) => {
    if(page > 1) {
      // 백은 페이지 시작 0부터임
      setCurrentPage(page - 1)
    } else {
      setCurrentPage(1)
    }

    api.getNoticeList(keyword, null, page - 1, 20).then((res) => {
      let data = res.data
      if(data.list.length) {
        let list = data.list
        list.forEach((item) => {
          item.date = item.createdDateTime.split('T')[0].replaceAll('-', '.')
          if(keyword) {
            let position = item.title.toUpperCase().search(keyword.toUpperCase());

            let startText = item.title.substring(0, position)
            let colorText = item.title.substring(position, position + keyword.length)
            let endText = item.title.substring(position + keyword.length, item.title.length)

            item.colorText = `${startText}<span>${colorText}</span>${endText}`
          }
        })
        setNoticeList(list)
      } else {
        setNoticeList([])
        if(keyword) {
          setNoListText('원하시는 결과를 찾지 못했어요 :(<br/>다시 검색해서 찾아보세요.')
        } else {
          setNoListText('등록된 공지사항이 없습니다.')
        }
      }
      setTotalPage(data.totalPage)
      setCurrentPage(data.currentPage + 1)
      setIsLoading(false)

    })
  }

  useDidMountEffect(() => {
    if(!keyword) {
      getNoticeList(1)
    }
  }, [keyword])

  useDidMountEffect(() => {
    navigate(`/terms/notices?page=${currentPage}`)
  }, [currentPage])

  useEffect(() => {
    if(location.search.includes('?page=')) {
      let previousPage = location.search.split('=')[1]
      getNoticeList(previousPage)
    } else {
      getNoticeList(1)
    }

  }, [])

  return (
    <div className={cx('notice')}>
      <HeaderNav/>
      { isLoading ?
        <NoticeSkeleton/> : (
          <div className={cx('noticeContent')}>
            <h1>공지사항</h1>
            <div className={cx('searchContainer')}>
              <InputSearchBox
                placeholder='제목으로 검색'
                getKeyword={getKeyword}
                onSearch={onSearch}
                isSmall={true}
                keyword={keyword}
              />
            </div>
            <div className={cx('table')}>
              <div className={cx('column')}>
                <p>작성일</p>
                <p>제목</p>
              </div>
              <div className={cx('contentWrapper')}>
                { noticeList.length ? (
                  noticeList.map((item) => (
                      <div className={cx('content')} key={item.id} onClick={() => goToDetail(item.id)}>
                        <p className={cx('date')}>{ item.date }</p>
                        <p className={cx('title')} dangerouslySetInnerHTML={{ __html: item.colorText ? item.colorText : item.title }}></p>
                      </div>
                    ))
                ) : (
                  <div className={cx('noContent')}>
                    <img
                      src={noListText === '등록된 공지사항이 없습니다.' ? NoticeNoList : NoticeNoKeyword}
                      className={cx(noListText === '등록된 공지사항이 없습니다.' && 'width240')}
                      alt='NoticeNoList'
                    />
                    <p dangerouslySetInnerHTML={{ __html: noListText }}></p>
                  </div>
                )}

              </div>
              { totalPage > 1 && noticeList.length ? (
                <div className={cx('pagination')} key={totalPage}>
                  <Pagination currentPage={currentPage} totalPage={totalPage} getList={getNoticeList}/>
                </div>
              ) : ''}
            </div>
          </div>
      ) }
      <FooterGray position='relative'/>
    </div>
  );
}

export default Notice