import React, {useEffect, useState} from 'react';
import HeaderNav from "@/common/header/headerNav/headerNav";
import classnames from "classnames/bind";
import styles from './myPage.module.scss'
import api from "@/api";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import FooterGray from "../common/footer/footerGray/footerGray";
import jobList from '@/data/jobList'
import ButtonPrimary from "../common/button/buttonPrimary/buttonPrimary";
import DropdownOrder from "../common/dropdown/dropdownOrder/dropdownOrder";
import {showToast} from "../../helpers/toastMessage";
import useDidMountEffect from "../../helpers/useDidMountEffect";

const Pwd = `${process.env.REACT_APP_RESOURCE_URL}/pwd.svg`

const cx = classnames.bind(styles)

let jobArr = []
Object.values(jobList).forEach((jobKor, idx) => {
  jobArr.push({
    id: idx,
    txt: jobKor
  })
})

const MyPage = () => {
  const navigate = useNavigate()

  const staff = useSelector(state => {
    return state.staff.value
  })

  const [inputType, setInputType] = useState('')
  const [originalJob, setOriginalJob] = useState('')
  const [originalLicense, setOriginalLicense] = useState('')
  const [job, setJob] = useState('')
  const [license, setLicense] = useState('')
  const [showCertified, setShowCertified] = useState(false)
  const [licenseTitle, setLicenseTitle] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDropdownJobOpen, setIsDropdownJobOpen] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState(null)

  const onBtnPrimaryClick = () => {
    if(!isSaveBtnDisabled()) {
      let data = {
        job: Object.keys(jobList).filter((job, idx) => idx === selectedJobId && job)[0],
        licenseNumber: (staff.job === 'DOCTOR' || staff.job === 'KOREAN_DOCTOR' || staff.job === 'PHYSICAL_THERAPIST') ? license : '',
        licenseDetail: (staff.job === 'FITNESS_EXPERT' || staff.job === 'OTHER') ? license : '',
      }

      api.postStaffInfo(data).then(() => {
        showToast('success', '계정 정보가 저장되었습니다.')
      })
    }
  }
  const isDisabled = () => {
    let isDisabled = true

    if((staff.job === 'FITNESS_EXPERT' || staff.job === 'OTHER')) {
      isDisabled = false
    } else {
      if(staff.isCertified) {
        isDisabled = true
      } else {
        isDisabled = false
      }
    }

    return isDisabled
  }

  const isSaveBtnDisabled = () => {
    let result = true

    if(!staff.isCertified) {
      if(job !== originalJob || license !== originalLicense || staff.job !== Object.keys(jobList)[selectedJobId]) {
        result = false
      }
    }

    return result
  }

  useEffect(() => {
    setIsLoading(true)

    if(staff.id === null) {
      return
    }

    setOriginalJob(jobList[staff.job])
    setJob(jobList[staff.job])

    Object.keys(jobList).forEach((job, idx) => {
      if(staff.job === job) {
        setSelectedJobId(idx)
      }
    })

    if(staff.isCertified) {
      setOriginalLicense('인증되었습니다.')
      setShowCertified(true)
    } else {
      setOriginalLicense(staff.licenseNumber ? staff.licenseNumber : staff.licenseDetail)
      setLicense(staff.licenseNumber ? staff.licenseNumber : staff.licenseDetail)
    }

    if(staff.job === 'FITNESS_EXPERT') {
      setLicenseTitle('보유 자격증')
    } else if(staff.job === 'OTHER') {
      setLicenseTitle('상세 정보')
    } else {
      setLicenseTitle('보건의료인 면허번호')
    }

    setIsLoading(false)
  }, [staff])

  useDidMountEffect(() => {
    let originalJobIdx = 0
    Object.keys(jobList).forEach((job, idx) => {
      if(job === staff.job) {
        originalJobIdx = idx
      }
    })

    if(originalJobIdx === selectedJobId) {
      return
    }

    setLicense('')

    if(selectedJobId === 3) {
      setLicenseTitle('보유 자격증')
      setInputType('text')
    } else if(selectedJobId === 4) {
      setLicenseTitle('상세 정보')
      setInputType('text')
    } else {
      setLicenseTitle('보건의료인 면허번호')
      setInputType('number')
    }
  }, [selectedJobId])

  return (
    <div className={cx('myPage')}>
      <HeaderNav/>
      <div className={cx('myPageWrapper')}>
        <div className={cx('mypageContentBox')}>
          <div>
            { isLoading ? (
              <div className={cx('accountBox', 'skeleton')}>
                <p></p>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div className={cx('btn')}></div>
              </div>
            ) : (
              <div className={cx('accountBox')}>
                <p>계정 정보</p>
                <div className={cx('name')}>
                  <p>이름</p>
                  <div>{ staff.name }</div>
                </div>
                <div className={cx('phone')}>
                  <p>휴대폰 번호 (ID)</p>
                  <div>
                    <div className={cx('disableField')}>{ staff.phoneNumber.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{4})(\d{4})/g, '$1-$2-$3') }</div>
                    <span className={cx('change')} onClick={() => navigate('/authentication', { state: { title: 'phoneChange' } })}>변경하기</span>
                  </div>
                </div>
                <div className={cx('password')}>
                  <p>비밀번호</p>
                  <div>
                    <div className={cx('disableField')}><img src={Pwd} alt="password"/></div>
                    <span className={cx('change')} onClick={() => navigate('/authentication', { state: { title: 'passwordChange' } })}>변경하기</span>
                  </div>
                </div>
                <div className={cx('job')}>
                  <p>직업</p>
                  { staff.isCertified ? (
                    <input
                      value={job}
                      className={cx(isDisabled() && 'disable')}
                      onChange={(e) => setJob(e.target.value)}
                    />
                  ) : (
                    <DropdownOrder
                      orderList={jobArr}
                      isDropdownOrderOpen={isDropdownJobOpen}
                      setSelectedOrderId={ setSelectedJobId }
                      selectedOrderId={selectedJobId}
                      setIsDropdownOrderOpen={() => setIsDropdownJobOpen(!isDropdownJobOpen)}
                      fixedValueExist={false}
                      listWidth={360}
                      isScrollList={false}
                      firstValue={ selectedJobId }
                    />
                  )}
                </div>
                <div className={cx('license')}>
                  <p>{ licenseTitle }</p>
                  { showCertified ? (
                    <span>인증되었습니다.</span>
                  ) : (
                    <input
                      value={ license }
                      onChange={(e) => setLicense(e.target.value)}
                      className={cx(isDisabled() && 'disable')}
                      type={ inputType }
                      placeholder={ selectedJobId === 3 ? '예) 건강운동관리사, 선수트레이너' : selectedJobId === 4 ? '예) 간호사, 원무과 직원' : '면허번호를 입력해 주세요.' }
                    />
                  )}
                </div>
                <div className={cx('btnWrapper')}>
                  <ButtonPrimary text='저장하기' onBtnPrimaryClick={onBtnPrimaryClick} height={41} font='small' btnDisabled={isSaveBtnDisabled()}/>
                </div>
              </div>
            )}
          </div>
        </div>
        <FooterGray position='relative'/>
      </div>
    </div>
  );
};

export default MyPage;