import React, {useEffect, useRef, useState} from 'react';
import styles from './authentication.module.scss'
import classnames from 'classnames/bind';
import HeaderGray from "@/common/header/headerGray/headerGray";
import {useLocation, useNavigate} from "react-router-dom";
import ButtonPrimary from "@/common/button/buttonPrimary/buttonPrimary";
import MoraexService from "../../terms/moraexService/moraexService";
import KcbCarrier from "../../terms/kcbCarrier/kcbCarrier";
import KcbService from "../../terms/kcbService/kcbService";
import KcbUnique from "../../terms/kcbUnique/kcbUnique";
import KcbPrivacy from "../../terms/kcbPrivacy/kcbPrivacy";
import api from "@/api";
import authTerms from '@/data/authTerms'
import ButtonBigGray from "../button/buttonBigGray/buttonBigGray";
import ButtonLight from "../button/buttonLight/buttonLight";
import ModalSmall from "../modal/modalSmall/modalSmall";
import FooterGray from "../footer/footerGray/footerGray";
import MoraexPrivacyCollect from "../../terms/moraexPrivacyCollect/moraexPrivacyCollect";

const BCircles = `${process.env.REACT_APP_RESOURCE_URL}/B_circles.svg`
const ArrowDown = `${process.env.REACT_APP_RESOURCE_URL}/arrow_down.svg`
const PCheckSquare = `${process.env.REACT_APP_RESOURCE_URL}/P_checkSquare.svg`
const GCheckSquare = `${process.env.REACT_APP_RESOURCE_URL}/G_checkSquare.svg`
const PCheck = `${process.env.REACT_APP_RESOURCE_URL}/P_check.svg`
const GCheck = `${process.env.REACT_APP_RESOURCE_URL}/G_check.svg`

const cx = classnames.bind(styles);
const carrierFilters = ['SKT', 'KT', 'LG U+', 'SKT 알뜰폰', 'KT 알뜰폰', 'LG U+ 알뜰폰']

const Authentication = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const title = location.state.title
  const timeInterval = useRef()


  const [errMsg, setErrMsg] = useState({
    name: '',
    id: '',
    phoneNumber: '',
    authNumber: '',
  })
  const [name, setName] = useState('')
  const [id, setId] = useState('')
  const [gender, setGender] = useState('')
  const [carrierFilter, setCarrierFilter] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [authNumber, setAuthNumber] = useState('')
  const [isNextBtnActive, setIsNextBtnActive] = useState(false)
  const [isAuthBtnActive, setIsAuthBtnActive] = useState(false)
  const [userDevice, setUserDevice] = useState('m')
  const [termsCheck, setTermsCheck] = useState({
    0: false,
    1: false
  })
  const [subTermsCheck, setSubTermsCheck] = useState({
    0: {
      0: false,
      1: false,
    },
    1: {
      0: false,
      1: false,
      2: false,
      3: false
    }
  })
  const [isTermsOpen, setIsTermsOpen] = useState({
    0: false,
    1: false
  })
  const [isCarrierOptionsOpen, setIsCarrierOptionsOpen] = useState(false)
  const [isMsgSent, setIsMsgSent] = useState(false)
  const [isAuthInputShow, setIsAuthInputShow] = useState(false)
  const [timeLimitMilli, setTimeLimitMilli] = useState(0)
  const [txId, setTxId] = useState('')
  const [modalSmallContent, setModalSmallContent] = useState({})
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [timeResend, setTimeResend] = useState(0)
  const [timeLimitStr, setTimeLimitStr] = useState('')
  const [clickedTermId, setClickedTermId] = useState(null)
  const [isAuthSuccess, setIsAuthSuccess] = useState(false)

  const getTitleStr = () => {
    if(title === 'signup') {
      return <h1>반가워요!<br/> 회원가입을 진행할게요</h1>
    } else if(title === 'phoneChange') {
      return <h1>휴대폰 번호 변경을 도와드릴게요</h1>
    } else if(title === 'passwordChange') {
      return <h1>비밀번호 변경을 도와드릴게요</h1>
    } else {
      navigate('/')
    }
  }

  const checkNextAvailable = () => {
    let result = false

    if(isAuthSuccess) {
      if(title === 'signup') {
        if(Object.values(termsCheck).every(v => v)) {
          result = true
        }
      } else {
        if(termsCheck['1']) {
          result = true
        }
      }
    }

    Object.values(errMsg).forEach((err) => {
      if(err) {
        result = false
      }
    })
    return result
  }

  const checkIsInputForAuth = () => {
    let result = false
    if(name && id && gender && carrierFilter && phoneNumber) {
      result = true
    }

    return result
  }

  const regexName = (value) => {
    setName(value)
  }

  const regexId = (value) => {
    setId(value.replace(/[^0-9]/g, ''))
  }

  const regexGender = (value) => {
    setGender(value.replace(/[^1-8]/g, ''))
  }

  const selectCarrier = (selectedCarrier) => {
    setCarrierFilter(selectedCarrier)
    setIsCarrierOptionsOpen(false)
  }

  const regexPhoneNumber = (value) => {
    setPhoneNumber(value.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{4})(\d{4})/g, '$1-$2-$3'))
  }

  const formatIdToBirth = (id, gender) => {
    let year = id.slice(0, 2)
    let month = id.slice(2, 4)
    let date = id.slice(4, 6)

    if(gender === '1' || gender === '5' || gender === '2' || gender === '6') {
      year = '19' + year
    } else {
      year = '20' + year
    }

    return `${year}-${month}-${date}`
  }

  const getCarrierFieldName = () => {
    if(carrierFilter === 'LG U+') {
      return 'LGU'
    } else if(carrierFilter === 'SKT 알뜰폰') {
      return 'SKT_A'
    } else if (carrierFilter === 'KT 알뜰폰') {
      return 'KT_A'
    } else if (carrierFilter === 'LG U+ 알뜰폰') {
      return 'LGU_A'
    } else {
      return carrierFilter
    }
  }

  const checkError = () => {
    setErrMsg({
      name: '',
      id: '',
      phoneNumber: '',
      authNumber: '',
    })

    if(id.length !== 6) {
      setErrMsg({
        ...errMsg,
        id: '주민등록번호를 다시 확인해주세요'
      })
    }
    if(phoneNumber.length !== 13) {
      setErrMsg({
        ...errMsg,
        phoneNumber: '휴대폰번호를 다시 확인해주세요.'
      })
    }

    sendOtpNumber()
  }

  const sendOtpNumber = () => {
    if(errMsg.id || errMsg.name || errMsg.phoneNumber) {
      return
    }

    let body = {
      "name": name,
      "phoneNumber": phoneNumber,
      "gender": Number(gender) % 2 === 1 ? 'M' : 'F',
      "birthday": formatIdToBirth(id, gender),
      "nationality": gender === '5' || gender === '7' || gender === '6' || gender === '8' ? 'F' : 'L',
      "carrier": getCarrierFieldName()
    }
    api.postSendOtp(body).then((res) => {
      setIsAuthInputShow(true)
      setIsMsgSent(true)
      timeInterval.current = setInterval(() => (
        setTimeLimitMilli((timeLimitMilli) => timeLimitMilli - 1000)
      ), 1000)
      setIsNextBtnActive(checkNextAvailable())
      setTxId(res.data.txId)
    }).catch((err) => {
      if(err.response.data.code === '1100' && err.response.data.status === 429) {
        setModalSmallContent({
          bodyTitle: '본인인증 확인 제한',
          bodySub: '인증 번호 확인 횟수를 초과했습니다. <br/>회원가입 정보를 다시 입력 후 시도해 주세요.',
          isOneBtn: true,
          btnFirstTxt: '확인',
          btnFirstEvent: closeModalSmall,
        })
      } else {
        setModalSmallContent({
          bodyTitle: '작성한 정보가 정확하지 않아요.',
          bodySub: '다시 확인해 주세요.',
          isOneBtn: true,
          btnFirstTxt: '확인',
          btnFirstEvent: closeModalSmall,
        })
      }
      setIsAuthInputShow(false)
      setIsMsgSent(false)
      setIsModalSmallOpen(true)
    })
  }

  const closeModalSmall = () => {
    setIsModalSmallOpen(false)
  }

  const resendAuthNumber = () => {
    clearInterval(timeInterval.current)
    timeInterval.current = null
    initTimeLimit()
    checkError()
  }

  const initTimeLimit = () => {
    // if(process.env.NODE_ENV === 'production') {
      setTimeLimitMilli(180000)
      setTimeResend(120000)
    // } else {
      // setTimeLimitMilli(50000)
      // setTimeResend(40000)
    // }
  }

  const regexAuthNumber = (value) => {
    setAuthNumber(value.replace(/[^0-9]/g, ''))
  }

  const clickAllAgreeCondition = () => {
    if(Object.values(termsCheck).every(v => v)) {
      setTermsCheck({
        0: false,
        1: false
      })
      setSubTermsCheck({
        0: {
          0: false,
          1: false,
        },
        1: {
          0: false,
          1: false,
          2: false,
          3: false,
        },
      })
    } else {
      setTermsCheck({
        0: true,
        1: true
      })
      setSubTermsCheck({
        0: {
          0: true,
          1: true,
        },
        1: {
          0: true,
          1: true,
          2: true,
          3: true,
        },
      })
    }
  }

  const toggleTermsCheck = (termId) => {
    setTermsCheck({
      ...termsCheck,
      [termId]: !termsCheck[termId]
    })

    if(termId === 0) {
      if(termsCheck[0]) {
        Object.keys(subTermsCheck[0]).forEach(v => {
          setSubTermsCheck({
            ...subTermsCheck,
            [termId] : {
              0: false,
              1: false,
            }
          })
        })
      } else {
        Object.keys(subTermsCheck[0]).forEach(v => {
          setSubTermsCheck({
            ...subTermsCheck,
            [termId] : {
              0: true,
              1: true,
            }
          })
        })
      }
    } else {
      if(termsCheck[1]) {
        Object.keys(subTermsCheck[1]).forEach(v => {
          setSubTermsCheck({
            ...subTermsCheck,
            [termId] : {
              0: false,
              1: false,
              2: false,
              3: false,
            }
          })
        })
      } else {
        Object.keys(subTermsCheck[1]).forEach(v => {
          setSubTermsCheck({
            ...subTermsCheck,
            [termId] : {
              0: true,
              1: true,
              2: true,
              3: true,
            }
          })
        })
      }
    }

  }

  const toggleTermsOpen = (termId) => {
    setIsTermsOpen({
      ...isTermsOpen,
      [termId]: !isTermsOpen[termId]
    })
  }

  const toggleSubTermsCheck = (termId, subId) => {
    setClickedTermId(termId)
    setSubTermsCheck({
      ...subTermsCheck,
      [termId] : {
        ...subTermsCheck[termId],
        [subId]: !subTermsCheck[termId][subId]
      }
    })
  }

  const changePhoneNumber = () => {
    let body = {
      "txId": txId,
      "phoneNumber": phoneNumber,
    }

    api.postPhoneNumber(body).then(() => {
      setModalSmallContent({
        bodyTitle: '새로운 휴대폰 번호로 로그인해 주세요',
        bodySub: '휴대폰 번호가 변경되었습니다',
        isOneBtn: true,
        btnFirstTxt: '로그인하기',
        btnFirstEvent: () => navigate('/'),
      })

      setIsModalSmallOpen(true)
    }).catch((err) => {
      if(err.response.data.code === '1001') {
        setModalSmallContent({
          bodyTitle: '가입한 계정이 없습니다',
          bodySub: '회원가입을 진행해 주세요',
          isOneBtn: true,
          btnFirstTxt: '회원가입 하러가기',
          btnFirstEvent: () => navigate('/signup/intro'),
        })

        setIsModalSmallOpen(false)
      }
    })

  }

  const changePassword = () => {
    let changePwdInfo = {
      "txId": txId,
      "phoneNumber": phoneNumber
    }
    navigate('/set/password', { state: { changePwdInfo: changePwdInfo }, replace: true})
  }

  const goToNextStep = () => {
    if(checkNextAvailable()) {
      if(title === 'signup') {
        let signupInfo = {
          'txId': txId,
          'phoneNumber': phoneNumber,
          'termsService': termsCheck[0],
          'termsPrivacy': termsCheck[1],
          'name': name
        }
        navigate('/signup/last', { state: { signupInfo: signupInfo }, replace: true})
      } else if(title === 'phoneChange') {
        changePhoneNumber()
      } else if(title === 'passwordChange') {
        changePassword()
      }
    }

  }

  const checkValidation = () => {
    let body = {
      "txId": txId,
      "phoneNumber": phoneNumber,
    }
    api.postStaffValidation(body).then((res) => {
      if(res.data.exist) {
        if(title === 'signup') {
          setModalSmallContent({
            bodyTitle: '이미 가입한 계정이 있어요',
            bodySub: '',
            isOneBtn: false,
            btnSecondTxt: '로그인하기',
            btnFirstTxt: '비밀번호 찾기',
            btnSecondEvent: () => navigate('/'),
            btnFirstEvent: () => navigate('/change/account'),
          })

          setIsModalSmallOpen(true)
        } else {
            setIsAuthSuccess(true)
            setErrMsg({
              name: '',
              id: '',
              phoneNumber: '',
              authNumber: '',
            })
        }
      } else {
        if(title === 'signup') {
          setIsAuthSuccess(true)
          setErrMsg({
            name: '',
            id: '',
            phoneNumber: '',
            authNumber: '',
          })
        } else {
          setModalSmallContent({
            bodyTitle: '가입한 계정이 없습니다',
            bodySub: '회원가입을 진행해 주세요',
            isOneBtn: true,
            btnFirstTxt: '회원가입 하러가기',
            btnFirstEvent: () => navigate('/signup/intro'),
          })

          setIsModalSmallOpen(true)
        }

      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const checkAuthNumber = () => {
    if(name && id && gender && carrierFilter && phoneNumber && authNumber && timeLimitMilli > 0) {
      let body = {
        "phoneNumber": phoneNumber,
        "txId": txId,
        "otpNumber": authNumber
      }

      api.postCheckOtp(body).then(() => {
        checkValidation()
      }).catch((err) => {
        setIsAuthSuccess(false)
        if(err.response.data.code === '1102' || err.response.data.code === '1001') {
          setErrMsg({
            ...errMsg,
            authNumber: '인증번호를 다시 확인해 주세요.'
          })
        } else if(err.response.data.code === '1104') {
          setErrMsg({
            ...errMsg,
            authNumber: '입력 횟수를 초과했습니다. 인증번호를 다시 받아주세요.'
          })
          setIsMsgSent(false)
        } else {
          console.log(err)
        }
      })

      clearInterval(timeInterval.current)
      timeInterval.current = null
      initTimeLimit()
    }
  }

  const onBtnBigGrayClickGetAuth = () => {
    if(isAuthBtnActive) {
      setIsNextBtnActive(checkNextAvailable())
      checkError()
    }
  }

  const onBtnBigGrayClickGetAuthAgain = () => {
    if(timeLimitMilli < timeResend || errMsg.authNumber) {
      setAuthNumber('')
      setIsNextBtnActive(false)
      resendAuthNumber()
    }
  }

  const onResize = () => {
    let width = window.innerWidth
    if(width < 768) {
      setUserDevice('m')
    } else if(width < 1024 && width >= 768) {
      setUserDevice('t')
    } else if(width < 1441 && width >= 1024){
      setUserDevice('d')
    } else {
      setUserDevice('d_big')
    }
  }

  useEffect(() => {
    setIsNextBtnActive(checkNextAvailable())
  }, [isAuthSuccess, termsCheck])

  useEffect(() => {
    if(timeLimitMilli < 0) {
      clearInterval(timeInterval.current)
      timeInterval.current = null
      setIsNextBtnActive(checkNextAvailable())
      setErrMsg({
        ...errMsg,
        authNumber: '입력 시간이 만료되었습니다. 다시 확인해 주세요.'
      })
      return setTimeLimitStr('0:00')
    } else {
      let minutes = Math.floor(timeLimitMilli / 60000)
      let seconds = ((timeLimitMilli % 60000) / 1000).toFixed(0)
      setTimeLimitStr(minutes + ':' + (seconds < 10 ? '0' : '') + seconds)
    }
  }, [timeLimitMilli])

  useEffect(() => {
    setIsAuthBtnActive(checkIsInputForAuth())
  }, [name, id, gender, carrierFilter, phoneNumber])

  useEffect(() => {
    if(clickedTermId === null) {
      return
    }
    if(Object.values(subTermsCheck[clickedTermId]).every(v => v)) {
      setTermsCheck({
        ...termsCheck,
        [clickedTermId]: true
      })
    } else {
      setTermsCheck({
        ...termsCheck,
        [clickedTermId]: false
      })
    }
  }, [subTermsCheck])

  useEffect(() => {
    if(!title) {
      navigate(-1)
      return
    }
    initTimeLimit()

    window.addEventListener("resize", onResize)
    onResize()

    return () => {
      clearInterval(timeInterval.current)
      document.removeEventListener("resize", onResize)
    }
  }, [])


  return (
    <div className={cx('authentication')}>
      { userDevice === 'm' ? (
        ''
      ) : (
        <HeaderGray/>
      )}

      <div className={cx('agreeConditionWrapper')}>
        <div>
          <div className={cx('agreeConditionTitle')}>
            { getTitleStr() }
          </div>
          { title !== 'phoneChange' &&
            <div className={cx('agreeProgressBar')}>
              <div></div>
              <div></div>
            </div>
          }
          <div className={cx('agreeConditionBox')}>
            <div className={cx('settingUserInfo')}>
              <div className={cx('settingInput')}>
                <p className={cx('inputTitle')}>이름</p>
                <input
                  className={cx(errMsg.name && 'err', isAuthSuccess && 'disable')}
                  autoFocus
                  placeholder="이름을 입력해 주세요."
                  value={name}
                  onInput={(e) => regexName(e.target.value) }
                />
                { errMsg.name &&
                  <p className={cx('errMsg')}>{ errMsg.name }</p>
                }
              </div>
              <div className={cx('settingInput')}>
                <p className={cx('inputTitle')}>주민등록번호</p>
                <div className={cx('idNumber')}>
                  <input
                    className={cx(errMsg.id && 'err', isAuthSuccess && 'disable')}
                    placeholder="주민등록번호"
                    onInput={(e) => regexId(e.target.value)}
                    value={id}
                    maxLength={6}
                  />
                  <span>-</span>
                  <input
                    className={cx(errMsg.id && 'err', isAuthSuccess && 'disable')}
                    placeholder="0"
                    onInput={(e) => regexGender(e.target.value)}
                    value={gender}
                    maxLength={1}
                  />
                  <img src={BCircles} alt="BCircles"/>
                </div>
                { errMsg.id &&
                  <p className={cx('errMsg')}>{ errMsg.id }</p>
                }
              </div>
              <div className={cx('settingInput')}>
                <p className={cx('inputTitle')}>휴대폰번호</p>
                <div className={cx('carrier', isCarrierOptionsOpen && 'active', errMsg.phoneNumber && 'err', isAuthSuccess && 'disable' )}
                     onClick={() => setIsCarrierOptionsOpen(!isCarrierOptionsOpen)}>
                  <span className={cx(carrierFilter && 'active')}>{ carrierFilter ? carrierFilter : '통신사 선택' }</span>
                  <img className={cx(isCarrierOptionsOpen && 'rotate')} src={ArrowDown} alt="arrow_down"/>
                </div>
                { isCarrierOptionsOpen &&
                  <div className={cx('carrierOptions')}>
                    { carrierFilters.map((carrier) => (
                      <p key={carrier} className={cx(carrier === carrierFilter && 'active')} onClick={() => selectCarrier(carrier)}>{ carrier }</p>
                    )) }
                  </div>
                }
                <div className={cx('inputWrapper', 'phoneWrapper')}>
                  <input
                    className={cx(errMsg.phoneNumber && 'err', isAuthSuccess && 'disable')}
                    value={phoneNumber}
                    placeholder="휴대폰 번호 (-없이 숫자만 입력)"
                    maxLength={13}
                    onInput={(e) => regexPhoneNumber(e.target.value)}
                  />
                  { !isMsgSent ? (
                    <div className={cx('btnWrapper')}>
                      <ButtonBigGray text='인증 요청' onBtnBigGrayClick={onBtnBigGrayClickGetAuth} isDisabled={!isAuthBtnActive}/>
                    </div>
                  ) : !isAuthSuccess && (
                    <div className={cx('btnWrapper')}>
                      <ButtonBigGray text='다시 받기' onBtnBigGrayClick={onBtnBigGrayClickGetAuthAgain} isDisabled={timeLimitMilli >= timeResend && !errMsg.authNumber }/>
                    </div>
                      )
                  }
                </div>
                { isAuthInputShow &&
                  <div className={cx('inputWrapper', 'authWrapper')}>
                    <input
                      className={cx(errMsg.authNumber && 'err', isAuthSuccess && 'disable')}
                      value={authNumber}
                      placeholder="6자리 숫자를 입력해 주세요."
                      maxLength={6}
                      onInput={(e) => regexAuthNumber(e.target.value)}
                    />
                    <div className={cx('btnWrapper')}>
                      { isAuthSuccess ? (
                        <button className={cx('disableBtn')}>인증 완료</button>
                      ) : (
                        <ButtonLight text='인증하기' onButtonLightClick={checkAuthNumber}/>
                      )}

                    </div>
                    { !isAuthSuccess && <span>{ timeLimitStr }</span> }
                  </div>
                }
                { errMsg.phoneNumber &&
                  <p className={cx('errMsg')}>{errMsg.phoneNumber}</p>
                }
                { errMsg.authNumber &&
                  <p className={cx('errMsg')}>{errMsg.authNumber}</p>
                }
              </div>
            </div>
            <p className={cx('termsTopTitle')}>서비스 이용약관 동의</p>
            { title === 'signup' &&
              <div onClick={ () => clickAllAgreeCondition() } className={cx('agreeAll')}>
                <img src={Object.values(termsCheck).every(v => v) ? PCheckSquare : GCheckSquare} alt='checkbox'/>
                <p>모두 동의</p>
              </div>
            }
            <div className={cx('agreeEmptyLine')}></div>
            <div className={cx('agreeIndividual')}>
              { authTerms.terms.map((term) => (
                <div className={cx('termsTitle')} key={term.id}>
                  {!(title !== 'signup' && term.id === 0) &&
                    <div className={cx('contentTitle')}>
                      <div onClick={ () => toggleTermsCheck(term.id) }>
                        <img src={termsCheck[term.id] ? PCheck : GCheck} alt='check'/>
                        <p>({ term.required ? '필수' : '선택' }){ term.title }</p>
                      </div>
                      <span>
                        <img src={ArrowDown} alt="arrow_down" onClick={() => toggleTermsOpen(term.id)}/>
                      </span>
                    </div>
                  }
                  { isTermsOpen[term.id] && (
                     term.sub.map((sub) => (
                        <div className={cx('contentBox')} key={sub.subId}>
                          <div className={cx('subTitle')} onClick={() => toggleSubTermsCheck(term.id, sub.subId)}>
                            <img src={subTermsCheck[term.id][sub.subId] ? PCheck : GCheck} alt="check"/>
                            <span>{ sub.subTitle }</span>
                          </div>
                          <div className={cx('subContent')}>
                            { sub.content === 'moraexService' && <MoraexService/>}
                            { sub.content === 'moraexPrivacyCollect' && <MoraexPrivacyCollect/>}
                            { sub.content === 'kcbService' && <KcbService/>}
                            { sub.content === 'kcbPrivacy' && <KcbPrivacy/>}
                            { sub.content === 'kcbUnique' && <KcbUnique/>}
                            { sub.content === 'kcbCarrier' && <KcbCarrier/>}
                          </div>
                        </div>
                      ))

                    ) }

                </div>
              )) }

            </div>

          </div>

          <div className={cx('agreeConditionBtnBox')}>
            <ButtonPrimary text='다음' btnDisabled={!isNextBtnActive ? true : false} onBtnPrimaryClick={goToNextStep} height={41} font='small'/>
          </div>

        </div>
      </div>
      { isModalSmallOpen &&
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          isOneBtn={modalSmallContent.isOneBtn}
          bodySub={modalSmallContent.bodySub}
          btnSecondTxt={modalSmallContent.btnSecondTxt}
          btnFirstTxt={modalSmallContent.btnFirstTxt}
          btnFirstEvent={modalSmallContent.btnFirstEvent}
          btnSecondEvent={modalSmallContent.btnSecondEvent}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}/>

      }

      { userDevice === 'm' ? (
        ''
      ) : (
        <FooterGray position='relative'/>
      )}

    </div>
  );
};

export default Authentication;