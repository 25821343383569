import { useEffect } from "react"
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import api from "../utils/api";
import {setInfo} from "../store/slice/staffSlice";
import {updateNoti} from "../store/slice/notiSlice";
import {setMemberCount} from "../store/slice/paymentSlice";


export default function Refresh({ isRedirectHome = true, isUpdateToken = true }) {
  let navigate = useNavigate()
  let dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    if(isUpdateToken) {
      localStorage.setItem('accessToken', null)
    }

    if(localStorage.getItem('refreshToken')) {
      api.getStaff().then((res) => {
        let staffInfo = res.data
        dispatch(setInfo(staffInfo))

        api.getNotiUnseenCount().then((res) => {
          const isMark = res.data.count > 0
          dispatch(updateNoti(isMark))
        })

        if(staffInfo.rehabGroupId === null) {
          navigate('/organ/group/select', { replace: true })
        } else if(isRedirectHome) {
          navigate('/home', { replace: true })
        }

        if(staffInfo.rehabGroupId !== null) {
          api.getRehabGroupMembersCount(staffInfo.rehabGroupId).then((res) => {
            dispatch(setMemberCount(res.data.count))
          }).catch((err) => {
            console.log(err)
          })
        }

      }).catch((e) => {
        console.log(e)
      })


    } else {
      // 토큰 없어도 접근 가능한 페이지: 이용약관, 개인정보 처리방침, 공지사항
      if(!location.pathname.includes('/terms/')) {
        navigate('/')
      }
    }
  }, [])

  return null
}