import {createSlice} from "@reduxjs/toolkit";

const refreshSlice = createSlice({
  name: 'refreshSlice',
  initialState: { value: {
      urlParamT: 0,
    } },
  reducers: {
    setUrlParamT: (state, action) => {
      state.value.urlParamT = action.payload
    }
  }
})

export default refreshSlice
export const { getRefreshComp, setUrlParamT } = refreshSlice.actions