import {createSlice} from "@reduxjs/toolkit";

const hospitalSlice = createSlice({
  name: 'hospitalSlice',
  initialState: { value: {
    selectedHospital: {
      address: '',
      id: null,
      name: '',
      isSelected: true,
    },
    } },
  reducers: {
    setSelectedHospital: (state, action) => {
      state.value.selectedHospital = action.payload
    },
    resetSelectedHospital: (state) => {
      state.value.selectedHospital = {
        address: '',
        id: null,
        name: '',
        isSelected: true,
      }
    }
  }
})

export default hospitalSlice
export const { setSelectedHospital, resetSelectedHospital } = hospitalSlice.actions