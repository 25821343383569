import {createSlice} from "@reduxjs/toolkit";

const staffSlice = createSlice({
  name: 'staffSlice',
  initialState: { value: {
      autoPlanRelease: false,
      birthday: '',
      bookmarkExercise: [],
      bookmarkProgram: [],
      gender: '',
      rehabGroupId: null,
      rehabGroupMemberId: null,
      groupRole: '',
      rehabGroupName: '',
      id: null,
      isCertified: null,
      job: '',
      licenseNumber: null,
      licenseDetail: '',
      licenseStatus: '',
      name: '',
      phoneNumber: ''
    } },


reducers: {
  setInfo: (state, action) => {
      state.value = action.payload
    }
  },

  resetStaffInfo: (state) => {
    state.value = {
      autoPlanRelease: false,
      birthday: '',
      bookmarkExercise: [],
      bookmarkProgram: [],
      gender: '',
      rehabGroupId: null,
      rehabGroupMemberId: null,
      groupRole: '',
      rehabGroupName: '',
      id: null,
      isCertified: null,
      job: '',
      licenseNumber: null,
      licenseDetail: '',
      licenseStatus: '',
      name: '',
      phoneNumber: ''
    }
  }
})

export default staffSlice
export const { setInfo, resetStaffInfo } = staffSlice.actions