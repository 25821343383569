import React, {useEffect, useState} from 'react';
import styles from './signupLast.module.scss'
import classnames from 'classnames/bind';
import HeaderGray from "@/common/header/headerGray/headerGray";
import ButtonPrimary from "@/common/button/buttonPrimary/buttonPrimary";
import {useLocation, useNavigate} from "react-router-dom";
import api from "../../../utils/api";
import FooterGray from "../../common/footer/footerGray/footerGray";
import ModalSmall from "../../common/modal/modalSmall/modalSmall";
import DropdownOrder from "../../common/dropdown/dropdownOrder/dropdownOrder";
import jobList from '@/data/jobList'

const falseEye = `${process.env.REACT_APP_RESOURCE_URL}/false_eye.svg`
const trueEye = `${process.env.REACT_APP_RESOURCE_URL}/true_eye.svg`

const cx = classnames.bind(styles);
let jobArr = []
Object.values(jobList).forEach((jobKor, idx) => {
  jobArr.push({
    id: idx,
    txt: jobKor
  })
})

const SignupLast = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // const signupInfo = {
  //   'txId': 'txId',
  //   'phoneNumber': '01028697886',
  //   'termsService': true,
  //   'termsPrivacy': true,
  //   'name': '김다영'
  // }
  const signupInfo = location.state.signupInfo
  if(!Object.keys(signupInfo).length) {
    navigate('/authentication', { state: { title: 'signup' } })
  }

  const [pwdEye, setPwdEye] = useState(true)
  const [pwdConfirmEye, setPwdConfirmEye] = useState(true)
  const [pwd, setPwd] = useState('')
  const [pwdConfirm, setPwdConfirm] = useState('')
  const [showRegWarning, setShowRegWarning] = useState(false)
  const [showMatchWarning, setShowMatchWarning] = useState(false)
  const [showRegConfirm, setShowRegConfirm] = useState(false)
  const [showMatchConfirm, setShowMatchConfirm] = useState(false)
  const [license, setLicense] = useState('')
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [isDropdownJobOpen, setIsDropdownJobOpen] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState(null)
  const [userDevice, setUserDevice] = useState('m')

  const onLicenseChange = (e) => {
    setLicense(e.target.value)
  }

  const onPwdChange = (e) => {
    setPwd(e.target.value)
  }

  const onPwdConfirmChange = (e) => {
    setPwdConfirm(e.target.value)
  }

  const goBackHome = () =>{
    setIsModalSmallOpen(false)
    navigate('/')
  }

  const isRegChecked = () => {
    const reg = new RegExp(/^(?=.*[A-Za-z])(?=.*[!"#$%&'()*+,\-./:;<=>?@\]^_`{|}~\\])(?=.*[0-9])[0-9A-Za-z!"#$%&'()*+,\-./:;<=>?@\]^_`{|}~\\]{8,16}$/)
    return pwd.length >= 8 && pwd.length <= 13 && reg.test(pwd)
  }

  const onBtnPrimaryClick = () => {
    if(pwd === pwdConfirm && isRegChecked() && (selectedJobId !== null) && license) {
      let signupData = {
        ...signupInfo,
        password: pwd,
        licenseNumber: (selectedJobId === 0 || selectedJobId === 2) ? license : null,
        licenseDetail: (selectedJobId !== 0 && selectedJobId !== 2) ? license: null,
        job: Object.keys(jobList)[selectedJobId]
      }

      api.postStaff(signupData).then(() => {
        setIsModalSmallOpen(true)
      }).catch((err)=>{
        console.log(err)
      })



    } else if(pwd === pwdConfirm && !isRegChecked()){
      setShowRegWarning(true)
      setShowMatchWarning(false)
      setShowRegConfirm(false)
      setShowMatchConfirm(false)
    } else if(pwd !== pwdConfirm) {
      setShowMatchWarning(true)
      setShowRegWarning(false)
      setShowRegConfirm(false)
      setShowMatchConfirm(false)
    } else {
      return
    }
  }

  const onPwdBlur = () => {
    if(isRegChecked()) {
      setShowRegConfirm(true)
      setShowRegWarning(false)
      setShowMatchWarning(false)
    }
  }

  const onPwdConfirmBlur = () => {
    if(pwd === pwdConfirm && isRegChecked()) {
      setShowMatchConfirm(true)
      setShowRegWarning(false)
      setShowMatchWarning(false)
    }
  }

  const isBtnPrimaryDisabled = () => {
    if(pwd && pwdConfirm && (selectedJobId !== null) && license) {
      return false
    } else {
      return true
    }
  }

  const onResize = () => {
    let width = window.innerWidth
    if(width < 768) {
      setUserDevice('m')
    } else if(width < 1024 && width >= 768) {
      setUserDevice('t')
    } else if(width < 1441 && width >= 1024){
      setUserDevice('d')
    } else {
      setUserDevice('d_big')
    }
  }

  useEffect(() => {
    setLicense('')
  }, [selectedJobId])

  useEffect(() => {
    window.addEventListener("resize", onResize)
    onResize()

    return () => {
      document.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <>
      { userDevice === 'm' ? (
        ''
      ) : (
        <HeaderGray/>
      )}

      <div className={cx('content')}>
        <p className={cx('title')}>반가워요!<br/>회원가입을 진행할게요</p>
        <div className={cx('steps')}>
          <div></div>
          <div></div>
        </div>
        <div className={cx('pwd')}>
          <p>비밀번호</p>
          <input
            placeholder='영문, 숫자, 특수문자의 조합 (8~16자)'
            type={ pwdEye ? 'password' : 'text' }
            onChange={onPwdChange}
            onBlur={onPwdBlur}
            className={cx(showRegWarning ? 'redBorder' : (showRegConfirm && pwd) ? 'greenBorder' : '')}
          />
          <img src={ pwdEye ? falseEye : trueEye } onClick={() => setPwdEye(!pwdEye)} alt='pwdEye'/>
          { showRegWarning && pwd ?
            <div className={cx('warning')}>
              <p>비밀번호는 8자 이상의 영문/숫자/특수문자를 입력해주세요.</p>
            </div> : ''
          }
          { (showRegConfirm && pwd) ?
            <div className={cx('confirmed')}>
              <p>사용 가능한 비밀번호입니다.</p>
            </div> : ''
          }
        </div>
        <div className={cx('pwdConfirm')}>
          <p>비밀번호 확인</p>
          <input
            placeholder='위의 비밀번호를 다시 입력해 주세요.'
            type={ pwdConfirmEye ? 'password' : 'text' }
            onChange={onPwdConfirmChange}
            onBlur={onPwdConfirmBlur}
            className={cx(showMatchWarning ? 'redBorder' : (showMatchConfirm && pwdConfirm) ? 'greenBorder' : '')}
          />
          <img src={ pwdConfirmEye ? falseEye : trueEye } onClick={() => setPwdConfirmEye(!pwdConfirmEye)} alt='pwdConfirmEye'/>
          { showMatchWarning ?
            <div className={cx('warning')}>
              <p>비밀번호가 일치하지 않아요.</p>
            </div> : ''
          }
          { (showMatchConfirm && pwdConfirm) ?
            <div className={cx('confirmed')}>
              <p>비밀번호가 일치합니다.</p>
            </div> : ''
          }
        </div>
        <div className={cx('job')}>
          <p>직업</p>
          <DropdownOrder
            orderList={jobArr}
            isDropdownOrderOpen={isDropdownJobOpen}
            setSelectedOrderId={ setSelectedJobId }
            selectedOrderId={selectedJobId}
            setIsDropdownOrderOpen={() => setIsDropdownJobOpen(!isDropdownJobOpen)}
            fixedValueExist={false}
            listWidth={328}
            isScrollList={false}
            firstValue={ selectedJobId === null && '직업을 선택해 주세요.' }
          />
        </div>
        { selectedJobId !== null ? (
          <div className={cx('license')}>
            <p>{ (selectedJobId === 0 || selectedJobId === 1 || selectedJobId === 2) ? '보건의료인 면허번호' : selectedJobId === 3 ? '보유 자격증' : '상세 정보' }</p>
            <input
              type={ (selectedJobId === 0 || selectedJobId === 2) ? 'number' : 'text' }
              placeholder={ (selectedJobId === 0 || selectedJobId === 1 || selectedJobId === 2) ? '면허번호를 입력해 주세요.' : selectedJobId === 3 ? '예) 건강운동관리사, 선수트레이너' : '예) 간호사, 원무과 직원' }
              value={license}
              onChange={onLicenseChange}
            />
          </div>
        ) : ''}

        <div className={cx('btnWrapper')}>
          <ButtonPrimary text='회원가입하기' btnDisabled={isBtnPrimaryDisabled()} onBtnPrimaryClick={onBtnPrimaryClick} height={41} font='small'/>
        </div>
      </div>
      { isModalSmallOpen ?
        <ModalSmall
          bodyTitle={`${signupInfo.name} 님, 가입을 환영합니다 :) `}
          bodySub=''
          isOneBtn={true}
          btnSecondTxt=''
          btnFirstTxt='홈 바로가기'
          btnFirstEvent={goBackHome}
          btnSecondEvent={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}
        />
        : null
      }
      { userDevice === 'm' ? (
        ''
      ) : (
        <FooterGray position='relative'/>
      )}
    </>
  );
};

export default SignupLast;