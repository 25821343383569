import React, {useEffect, useState} from 'react';
import styles from './patientStatistics.module.scss'
import classnames from 'classnames/bind';
import DropdownOrder from "../../../common/dropdown/dropdownOrder/dropdownOrder";
import tagKor from '@/data/tagKor'
import {useLocation} from "react-router-dom";
import api from "@/api";
import Apexchart from "react-apexcharts";
import {shortYearDateDot} from "../../../../helpers/common";

const noPlansNoOpacity = `${process.env.REACT_APP_RESOURCE_URL}/noPlansNoOpacity.svg`

const cx = classnames.bind(styles)
const chartHeight = 321
const PatientStatistics = ({ statisticsPlanLength, lastPlanMainPart, isOnlyRelease }) => {
  const location = useLocation()

  const [planBodyPartList, setPlanBodyPartList] = useState([])
  const [isChartLoading, setIsChartLoading] = useState(false)
  const [isDropdownPlanBodyPartOpen, setIsDropdownPlanBodyPartOpen] = useState(false)
  const [mainPartTag, setMainPartTag] = useState(lastPlanMainPart)
  const [periodBtnId, setPeriodBtnId] = useState(0)
  const [planCount, setPlanCount] = useState(0)
  const [planAll, setPlanAll] = useState({})
  const [plansByMainPart, setPlansByMainPart] = useState({})
  const [isStatisticsInfoExist, setIsStatisticsInfoExist] = useState(false)
  const [chartKey, setChartKey] = useState(0)
  const [avgPerformance, setAvgPerformance] = useState(0)
  const [painScaleInfo, setPainScaleInfo] = useState({
    initial: 0,
    last: 0,
    changed: 0,
  })
  const [chartOptions, setChartOptions] = useState({
    colors: [],
    grid: {
      show: true,
      borderColor: '#DDDDDD',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      row: {
        colors: undefined,
        opacity: 0
      },
      column: {
        colors: undefined,
        opacity: 0.5
      },
      padding: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
    },
    chart: {
      events: {
        updated: function (chartContext, options) {
          //
        }
      },
      id: 'statistics',
      height: 350,
      type: "line",
      stacked: false,
      zoom: {
        enabled: false,
        type: 'x',
      },
      animations: {
        enabled: false,
      },
      redrawOnParentResize: true,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        autoSelected: ''
      }
    },
    toolbar: {
      show: false,
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      show: true,
      size: 4
    },
    stroke: {
      show: true,
      width: [],
      curve: "smooth",
      colors: []
    },
    plotOptions: {
      bar: {
      //   horizontal: false,
        columnWidth: '10px',
      //   distributed: false,
      //   rangeBarOverlap: false,
      //   rangeBarGroupRows: false,
      //   colors: {
      //     backgroundBarOpacity: 0,
      //     backgroundBarRadius: 0,
      //   },
      },
      area: {
        fillTo: "end",
      },
    },
    fill: {
      opacity: 1
    },

    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0.15,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0.35,
        },
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: -45,
        formatter: (v) => shortYearDateDot(v),
      },
      categories: [],

    },
    yaxis: [
      {
        opposite: true,
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false,
          // color: "#FF1654"
        },
        labels: {
          show: true,
          formatter: (v) => v + '%',
          style: {
            colors: "#545454"
          }
        },
        min: 0,
        max: 100,
        tickAmount: 6,
        forceNiceScale: true,
        title: {
          // text: "",
          // style: {
          //   color: "#FF1654"
          // }
        }
      },
      {
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false,
          // color: "#FF1654"
        },
        labels: {
          show: true,
          formatter: (v) => v + '점',
          style: {
            colors: "#545454"
          }
        },
        min: 0,
        max: 10,
        tickAmount: 6,
        forceNiceScale: true,
        title: {
          // text: "",
          // style: {
          //   color: "#FF1654"
          // }
        }
      },
    ],
    tooltip: {
      followCursor: true,
      shared: true,
      intersect: false,
      x: {
        show: true
      },
      y: [],
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
      fillSeriesColor: false,
      style: {
        fontSize: '12px',
        fontFamily: 'Pretendard'
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: false,
      showForZeroSeries: false,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '12px',
      fontFamily: 'Pretendard',
      fontWeight: 500,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false
      },
      markers: {
        width: 8,
        height: 8,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      },
      itemMargin: {
        horizontal: 24,
        vertical: 10
      },
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: true
      },
    },
    crosshairs: {
      show: true,
      position: "back",
      stroke: {
        color: "#7747FF",
        width: 0,
        dashArray: 10,
      },
    },
  })
  const [chartSeries, setChartSeries] = useState([])

  const getSelectedBodyPartId = (id) => {
    let bodyTag = planBodyPartList[id].tag
    setMainPartTag(bodyTag)
    setPlansByMainPart(planAll[bodyTag])
    setPlanCount(planAll[bodyTag].length)
  }
  const getMainPartFirstValue = () => {
    if(mainPartTag && isStatisticsInfoExist) {
      return tagKor['MP'][mainPartTag]
    } else {
      return '부위'
    }
  }

  const getChartOptionsAndSeries = () => {
    let scheduleArr = []
    let series = []
    let chartColors = []
    let yTooltipFormatter = []
    let strokeWidth = []

    plansByMainPart.forEach((plan, idx) => {
      scheduleArr = [...scheduleArr, ...plan.schedules]

      strokeWidth = [...strokeWidth, 0, 2]

      yTooltipFormatter = [...yTooltipFormatter,
        {
          formatter: function (y) {
            if(y === null) {
              y = '-'
            }
            return y + '%'
          }
        },
        {
          formatter: function (y) {
            if(y === null) {
              y = '-'
            }
            return y + '점'
          }
        }
      ]

      if(idx % 2 === 0) {
        chartColors = [...chartColors, ...["#6AD8D4", "#EB7768"]]
      } else {
        chartColors = [...chartColors, ...['#A66EED', '#FCB448']]
      }

      series.push({
        type: 'column',
        name: `운동 수행률${idx ? idx + 1 : ''}`,
        data: []
      }, {
        type: 'line',
        name: `통증 점수${idx ? idx + 1 : ''}`,
        data: []
      })
    })
    let newOptions = chartOptions
    newOptions.xaxis.categories = scheduleArr
    if(scheduleArr.length > 50) {
      newOptions.xaxis.tickAmount = 50
      newOptions.xaxis.tickPlacement = 'on'
    }
    newOptions.colors = chartColors
    newOptions.stroke.colors = chartColors
    newOptions.stroke.width = strokeWidth
    newOptions.tooltip.y = yTooltipFormatter
    setChartOptions({...newOptions})
    setChartSeries(series)
  }

  const getChartData = () => {
    chartOptions.xaxis.categories.forEach((xdate) => {
      plansByMainPart.forEach((plan, idx) => {
        if(Object.keys(plan.dailyStatistics).includes(xdate)) {
          chartSeries[idx === 0 ? 0 : idx * 2].data.push(plan.dailyStatistics[xdate].performancePercent)
          chartSeries[idx === 0 ? 1 : idx * 2 + 1].data.push(plan.dailyStatistics[xdate].painScale)
        } else {
          chartSeries[idx === 0 ? 0 : idx * 2].data.push(null)
          chartSeries[idx === 0 ? 1 : idx * 2 + 1].data.push(null)
        }
      })
    })
    setChartKey((prev) => prev + 1)
    setIsChartLoading(false)
  }

  const getAllPlans = (startDate) => {
    let date = new Date()
    let endDate = ""+ date.getFullYear()+((date.getMonth()+1)<10?"0"+(date.getMonth()+1):(date.getMonth()+1))+(date.getDate()<10?"0"+date.getDate():date.getDate())

    api.postPlanAllList(location.pathname.split('/')[2], startDate, endDate).then((res) => {
      let data = res.data
      if(Object.keys(data).length) {
        setIsStatisticsInfoExist(true)
        setPlanAll(data)

        let defaultMainPart = mainPartTag
        if(!data[defaultMainPart]) {
          defaultMainPart = Object.keys(data)[0]
          setMainPartTag(defaultMainPart)
        }
        setPlansByMainPart(data[defaultMainPart])
        setPlanCount(data[defaultMainPart].length)

        let availableBodyPartList = Object.keys(res.data)
        let newBodyPartArr = []
        availableBodyPartList.forEach((bodyPart, idx) => {
          newBodyPartArr.push({
            id: idx,
            tag: bodyPart,
            txt: tagKor['MP'][bodyPart],
          })
        })
        setPlanBodyPartList(newBodyPartArr)
      } else {
        setIsStatisticsInfoExist(false)
        setPlanCount(0)
        setPlanBodyPartList([])
        setIsChartLoading(false)
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const onClickPeriod = (id) => {
    if(periodBtnId === id) {
      return
    }
    setIsChartLoading(true)
    if(id !== periodBtnId) {
      setPeriodBtnId(id)
      getStartDate(id)
    }
  }

  const getStartDate = (periodBtnId ) => {
    let today = Date.now()
    let startDateTimestamp = 0

    if(!periodBtnId) {
      // 30 days
      startDateTimestamp = today - 2592000000
    } else if(periodBtnId === 1) {
      // 90 days
      startDateTimestamp = today - 7776000000
    } else if(periodBtnId === 2) {
      // 365 days
      startDateTimestamp = today - 31536000000
    }

    let date = new Date(startDateTimestamp)
    let year = date.getFullYear()
    let month = ('0' + (date.getMonth() + 1)).slice(-2)
    let day = ('0' + date.getDate()).slice(-2)

    getAllPlans(`${year}${month}${day}`)
  }

  const getAvgNumbers = () => {
    let totalPerformancePercent = 0

    let veryFirstPainScale = plansByMainPart[0].initPainScale
    let veryLastPainScale = null

    let isStop = false
    let lastPlanDailyStatistics = plansByMainPart[plansByMainPart.length - 1].dailyStatistics
    Object.keys(lastPlanDailyStatistics).reverse().forEach((date) => {
      if(isStop) {
        return
      }
      if(lastPlanDailyStatistics[date].painScale !== null) {
        veryLastPainScale = lastPlanDailyStatistics[date].painScale
        isStop = true
      }
    })

    plansByMainPart.forEach((plan) => {
      totalPerformancePercent += plan.avgPerformancePercent
    })

    let calculateAvgPerformance = Math.floor(totalPerformancePercent / plansByMainPart.length)
    setAvgPerformance(calculateAvgPerformance)


    let changedPainScale = null
    if(veryFirstPainScale !== null && veryFirstPainScale !== undefined && veryLastPainScale !== null && veryLastPainScale !== undefined) {
      changedPainScale = veryFirstPainScale - veryLastPainScale
    }

    setPainScaleInfo({
      initial: veryFirstPainScale,
      last: veryLastPainScale,
      changed: changedPainScale
    })
  }

  const getPainColor = () => {
    if(painScaleInfo.initial && painScaleInfo.last) {
      let scale = painScaleInfo.last
      if(scale === 1) {
        return 'pain0'
      } else if (scale === 2) {
        return 'pain1'
      } else if (scale === 3) {
        return 'pain2'
      } else if (scale === 4) {
        return 'pain3'
      } else if (scale === 5) {
        return 'pain4'
      } else if (scale === 6) {
        return 'pain5'
      } else if (scale === 7) {
        return 'pain6'
      } else if (scale === 8) {
        return 'pain7'
      } else if (scale === 9) {
        return 'pain8'
      } else if (scale === 10) {
        return 'pain9'
      }
    }

  }

  useEffect(() => {
    if(chartOptions.xaxis.categories.length) {
      getChartData()
    }
  }, [chartOptions.xaxis.categories])

  useEffect(() => {
    if(plansByMainPart.length) {
      getChartOptionsAndSeries()
      getAvgNumbers()
    }
  }, [plansByMainPart])

  useEffect(() => {
    getStartDate()
  }, [])

  return (
    <div className={cx('patientStatistics')}>
      <div className={cx('filters', !statisticsPlanLength && 'inactive')}>
        <div className={cx('dropdownOrderWrapper')}>
          <DropdownOrder
            orderList={planBodyPartList}
            isDropdownOrderOpen={isDropdownPlanBodyPartOpen}
            setSelectedOrderId={ getSelectedBodyPartId }
            selectedOrderId={planBodyPartList.findIndex(item => item.tag === mainPartTag)}
            setIsDropdownOrderOpen={() => setIsDropdownPlanBodyPartOpen(!isDropdownPlanBodyPartOpen)}
            fixedValueExist={false}
            listWidth={328}
            isScrollList={false}
            firstValue={ getMainPartFirstValue() }
          />
        </div>
        <div className={cx('periodBtns')}>
          <button className={cx(periodBtnId === 0 && 'active')} onClick={() => onClickPeriod(0)}>최근 1개월</button>
          <button className={cx(periodBtnId === 1 && 'active')} onClick={() => onClickPeriod(1)}>최근 3개월</button>
          <button className={cx(periodBtnId === 2 && 'active')} onClick={() => onClickPeriod(2)}>최근 1년</button>
        </div>
      </div>
      <p className={cx('planCount')}>총 { planCount }개 플랜</p>

      { isChartLoading ? (
        <div className={cx('noContent')}>
          <img src={noPlansNoOpacity} alt='noPlansNoOpacity'/>
          <p>로딩중입니다.</p>
          <p>잠시만 기다려주세요.</p>
        </div>
      ) : statisticsPlanLength && lastPlanMainPart && isStatisticsInfoExist && !isOnlyRelease ? (
        <div className={cx('content')}>
          <div className={cx('rates')}>
            <div className={cx('performance')}>
              <p className={cx('title')}>평균 운동 수행률</p>
              <p className={cx('number')}>{ avgPerformance } %</p>
              <div>
                <div className={cx('backBar')}></div>
                <div
                  className={cx('coloredBar', avgPerformance > 29 && 'green', avgPerformance < 30 && 'red')}
                  style={{ width: 500 * ( avgPerformance / 100 ) + 'px' }}
                ></div>
              </div>
            </div>
            <div className={cx('pain')}>
              <p className={cx('title')}>통증 점수 변화</p>
              <p className={cx('number')}>
                { painScaleInfo.changed > 0 ? painScaleInfo.changed + ' 감소' : painScaleInfo.changed < 0 ? -(painScaleInfo.changed) + ' 증가' : '변화없음' }
              </p>
              <div>
                <div className={cx('backBar')}></div>
                <div className={cx('currentBar', painScaleInfo.changed && getPainColor(), painScaleInfo.changed > 0 && 'zIndex')} style={{ width: 500 * ( painScaleInfo.last / 10 ) + 'px' }}></div>
                <div className={cx('previousBar', painScaleInfo.changed < 0 && 'flatRight', painScaleInfo.changed < 0 && 'zIndex')} style={{ width: 500 * ( painScaleInfo.initial / 10 ) + 'px' }}></div>
              </div>
            </div>
          </div>
          <div className={cx('chart')}>
            { chartKey ? (
              <Apexchart
                width='100%'
                height={chartHeight}
                options={chartOptions}
                series={chartSeries}
                key={chartKey}
              ></Apexchart>
            ) : ''}

          </div>
        </div>
      ) : (statisticsPlanLength && lastPlanMainPart && !isStatisticsInfoExist && !isOnlyRelease) ? (
        <div className={cx('noContent')}>
          <img src={noPlansNoOpacity} alt='noPlansNoOpacity'/>
          <p>최근 { `${periodBtnId === 0 ? '1개월' : periodBtnId === 1 ? '3개월' : '1년'}` } 내 배정된 플랜이 없습니다.</p>
        </div>
      ) : isOnlyRelease ? (
        <div className={cx('noContent')}>
          <img src={noPlansNoOpacity} alt='noPlansNoOpacity'/>
          <p>고객이 아직 플랜을 시작하지 않았습니다.</p>
          <p>플랜을 시작하면 통계가 표시돼요.</p>
        </div>
      ) : (
        <div className={cx('noContent')}>
          <img src={noPlansNoOpacity} alt='noPlansNoOpacity'/>
          <p>배정된 플랜이 없습니다.</p>
          <p>고객에게 플랜을 배정해 보세요.</p>
        </div>
      )}
    </div>
  );
};

export default PatientStatistics;