import React from 'react';
import styles from './inputUser.module.scss'
import classnames from "classnames/bind";

const falseEye = `${process.env.REACT_APP_RESOURCE_URL}/false_eye.svg`
const trueEye = `${process.env.REACT_APP_RESOURCE_URL}/true_eye.svg`

const cx = classnames.bind(styles);

const InputUser = ({ placeholder, type = 'text', eye = false, eyeImg, onEyeClick, value, onChange, style }) => {
  return (
    <div className={cx('inputUser')}>
      <input
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        value={value}
        className={cx(style)}
      />
      { eye && value?
        eyeImg === 'open' ?
        <img src={falseEye} onClick={onEyeClick} alt='falseEye'/> :
        <img src={trueEye} onClick={onEyeClick} alt='trueEye'/> :
        ''
      }
    </div>

  );
};

export default InputUser;