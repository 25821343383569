import React, {useEffect, useState} from 'react';
import styles from './home.module.scss'
import HeaderNav from "../../common/header/headerNav/headerNav";
import Banner from "./banner/banner";
import classnames from "classnames/bind";
import api from "@/api";
import {useNavigate} from "react-router-dom";
import { useSelector} from "react-redux";
import FooterGray from "../../common/footer/footerGray/footerGray";
import StatisticsCard from "../../admin/statistics/statisticsCard/statisticsCard";
import DropdownBasic from "../../common/dropdown/dropdownBasic/dropdownBasic";
import Apexchart from "react-apexcharts";
import StatisticsDonutChart from "../../admin/statistics/statisticsDonutChart/statisticsDonutChart";
import HomeSkeleton from "./homeSkeleton/homeSkeleton";
import HomeContentGroup from "./homeContentGroup/HomeContentGroup";

const PWorkout = `${process.env.REACT_APP_RESOURCE_URL}/P_workout.svg`
const PChart = `${process.env.REACT_APP_RESOURCE_URL}/P_chart.svg`
const GArrowDown = `${process.env.REACT_APP_RESOURCE_URL}/G_arrow_down.svg`
const RArrowUp = `${process.env.REACT_APP_RESOURCE_URL}/R_arrow_up.svg`

const cx = classnames.bind(styles)
const listFilters = ['이번달', '올해']
const chartWidth = 1080
const chartHeight = 370
const oneBar = 98 // 전체 길이(1080) / 11
const painColor = [
  'pain0',
  'pain1',
  'pain2',
  'pain3',
  'pain4',
  'pain5',
  'pain6',
  'pain7',
  'pain8',
  'pain9',
  'pain10',
]


const Home = () => {
  const navigate = useNavigate()

  const staff = useSelector(state => {
    return state.staff.value
  })

  const [lastPain, setLastPain] = useState(0)
  const [rehabGroupName, setRehabGroupName] = useState('')
  const [joinCode, setJoinCode] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [painChangeRate, setPainChangeRate] = useState(0)
  const [initPain, setInitPain] = useState(0)
  const [patientAvgPerformanceRate, setPatientAvgPerformanceRate] = useState(0)
  const [statisticsDonutChart2, setStatisticsDonutChart2] = useState(1)
  const [patientPlanStartRate, setPatientPlanStartRate] = useState(0)
  const [statisticsDonutChart1, setStatisticsDonutChart1] = useState(0)
  const [patientChartKey, setPatientChartKey] = useState(100)
  const [patientChartSeries, setPatientChartSeries] = useState([
    {
      name: "전체 고객 수",
      data: []
    },
    {
      name: "플랜 배정 고객 수",
      data: []
    },
    {
      name: "신규 고객 수",
      data: []
    }])
  const [patientChartOptions, setPatientChartOptions] = useState({
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: 0,
      },
      categories: [],
    },
    colors: ["#07BEB8", "#F89C47", "#A180FF"],
    chart: {
      id: 'reactChart3',
      height: 350,
      type: "line",
      stacked: false,
      zoom: {
        enabled: false,
        type: 'x',
      },
      animations: {
        enabled: false,
      },
      redrawOnParentResize: true,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        autoSelected: ''
      }
    },
    toolbar: {
      show: false,
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 4,
    },
    stroke: {
      show: true,
      width: [2, 2, 2],
      curve: "smooth",
      colors: ["#07BEB8", "#F89C47", "#A180FF"],
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 10,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0.15,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0.35,
        },
      },
    },

    yaxis: [
      {
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false,
          // color: "#FF1654"
        },
        labels: {
          show: true,
          formatter: (v) => Number(v),
          style: {
            colors: "#545454"
          }
        },
        // min: 0,
        // max: 100,
        // tickAmount: 5,
        forceNiceScale: true,
        title: {
          // text: "Series A",
          // style: {
          //   color: "#FF1654"
          // }
        }
      },
    ],
    tooltip: {
      followCursor: true,
      shared: true,
      intersect: false,
      x: {
        show: false
      },

      y: {
        title: {
          formatter: (seriesName) => seriesName,
        },
        formatter: function (y) {
          if(y === null) {
            y = '-'
          }
          return y + "명"
        }
      },
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
      fillSeriesColor: false,
      style: {
        fontSize: '12px',
        fontFamily: 'Pretendard'
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
    legend: {
      show: false,
      horizontalAlign: "left",
      offsetX: 40
    },
    crosshairs: {
      show: true,
      position: "back",
      stroke: {
        color: "#7747FF",
        width: 1,
        dashArray: 0,
      },
    },
  })
  const [patientSelectedFilter, setPatientSelectedFilter] = useState('이번달')
  const [assignmentCards, setAssignmentCards] = useState([
    {
      id: 0,
      img: 'staff',
      text: '이번달 전체 고객 수',
      number: 0,
    },
    {
      id: 1,
      img: 'square',
      text: '이번달 플랜 배정 고객 수',
      number: 0,
    },
    {
      id: 2,
      img: 'heart',
      text: '이번달 신규 고객 수',
      number: 0,
    },
  ])

  const getStatisticsSummary = () => {
    if(!staff.rehabGroupId) {
      return
    }

    setIsLoading(true)
    api.getHospitalGroupStatistics(staff.rehabGroupId).then((res) => {
      let data = res.data

      let newAssignmentCards = assignmentCards

      newAssignmentCards[0].number = data.patientCountThisMonth
      newAssignmentCards[1].number = data.assignedPlanPatientCountThisMonth
      newAssignmentCards[2].number = data.newPatientCountThisMonth
      setAssignmentCards(newAssignmentCards)


      setPatientPlanStartRate(data.patientPlanStartRate)
      setPatientAvgPerformanceRate(data.patientAvgPerformanceRate)

      setStatisticsDonutChart1((prev) => prev + 1)
      setStatisticsDonutChart2((prev) => prev + 1)

      let newInitPain = data.patientAvgInitPainScale
      let newLastPain = data.patientAvgFinalPainScale

      setInitPain(newInitPain)
      setLastPain(newLastPain)
      setPainChangeRate(Math.round((newInitPain - newLastPain) / newInitPain * 100))

      setIsLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  const fetchPatientGraph = (filter) => {
    setPatientSelectedFilter(filter)
    let type = filter === '올해' ? 'YEAR' : 'MONTH'
    getPatientChart(type)
  }

  const getPatientChart = (type) => {
    if(!staff.rehabGroupId) {
      return
    }

    api.getHospitalGroupPatientChart(staff.rehabGroupId, type).then((res) => {
      let data = res.data
      let newOptions = patientChartOptions
      newOptions.xaxis.categories = data.label
      setPatientChartOptions({...newOptions})

      let newSeries = patientChartSeries
      newSeries[0].data = data.total
      newSeries[1].data = data.assignedPlanPatient
      newSeries[2].data = data.newPatient

      setPatientChartSeries([...newSeries])

      setPatientChartKey((prev) => prev += 1)

    }).catch((err) => {
      console.log(err)
    })
  }

  const getRehabGroupInfo = () => {
    api.getRehabGroup(staff.rehabGroupId).then((res) => {
      let data = res.data
      setJoinCode(data.joinCode)
      setRehabGroupName(data.name)
    })
  }

  useEffect(() => {
    if(staff.id === null) {
      return
    }

    if(staff.rehabGroupId === null) {
      navigate('/organ/group/select', { replace: true })
      return
    }

    getStatisticsSummary()
    getPatientChart('MONTH')
    getRehabGroupInfo()
  }, [staff])

  return (
    <div className={cx('home')}>
      <Banner/>
      <HeaderNav/>
      { isLoading ? (
        <HomeSkeleton/>
      ) : (
        <div className={cx('wrapper')}>
          <div className={cx('top')}>
            <p>{ staff.organizationName }</p>
          </div>
          <HomeContentGroup joinCode={joinCode} rehabGroupName={rehabGroupName}/>
          <div className={cx('contentStatistics')}>

            <div className={cx('contentBoxWrapper')}>
              { assignmentCards.map((card) => (
                <StatisticsCard key={card.id} card={card} cardWidth={366}/>
              )) }
            </div>
            <div className={cx('chartWrapper')}>
              <div className={cx('topTitle')}>
                <p>고객 수</p>
                <div className={cx('legend')}>
                  <p className={cx('mint')}>전체 고객 수</p>
                  <p className={cx('orange')}>플랜 배정 고객 수</p>
                  <p className={cx('purple')}>신규 고객 수</p>
                  <DropdownBasic selectedFilter={patientSelectedFilter} listFilters={listFilters} fetchData={fetchPatientGraph}/>
                </div>
              </div>
              <Apexchart
                width={chartWidth}
                height={chartHeight}
                options={patientChartOptions}
                series={patientChartSeries}
                key={patientChartKey}
              ></Apexchart>
            </div>
            <div className={cx('roundChartWrapper')}>
              <div className={cx('roundChartBox')}>
                <div className={cx('title')}>
                  <img src={PWorkout} alt='PWorkout'/>
                  <p>배정 고객의 <span>플랜 시작률</span></p>
                </div>
                <StatisticsDonutChart key={statisticsDonutChart1} number={patientPlanStartRate}/>
                <span>{ patientPlanStartRate }%</span>
              </div>
              <div className={cx('roundChartBox')}>
                <div className={cx('title')}>
                  <img src={PChart} alt='PChart'/>
                  <p>플랜 시작 고객의 <span>평균 수행률</span></p>
                </div>
                <StatisticsDonutChart key={statisticsDonutChart2} number={patientAvgPerformanceRate}/>
                <span>{ patientAvgPerformanceRate }%</span>
              </div>
            </div>
            <div className={cx('barChartWrapper')}>
              <div className={cx('barTitleBox')}>
                <span className={cx('title')}>배정 고객의 통증 지수 변화</span>
                { initPain !== null && lastPain !== null && initPain > lastPain && (
                  <span className={cx('green')}>
                      통증 지수가 { painChangeRate >= 0 ? painChangeRate : painChangeRate * -1 }% 감소하였습니다.
                    </span>
                ) }
                { initPain !== null && lastPain !== null && initPain < lastPain && (
                  <span className={cx('red')}>
                      통증 지수가 { painChangeRate >= 0 ? painChangeRate : painChangeRate * -1 }% 증가하였습니다.
                    </span>
                ) }
                { ((initPain !== null && lastPain === null) || initPain === lastPain) && (
                  <span className={cx('gray')}>
                      통증 지수 변화가 없습니다.
                    </span>
                ) }
              </div>
              <div className={cx('barChartBox')}>
                <div className={cx('barTitle')}>
                  <span><span>최초</span> 통증 지수</span>
                  <p>{ initPain === null ? '-' : initPain }</p>
                </div>
                <div className={cx('barProgress')}>
                  <div style={{ width: initPain !== null ? `${oneBar * (initPain + 1)}px` : 0 }} className={cx(painColor[initPain])}></div>
                </div>
                <div className={cx('barRange')}>
                  <span>0</span>
                  <span>10</span>
                </div>
              </div>
              <div className={cx('barChartBox')}>
                <div className={cx('barTitle')}>
                  <span><span>마지막</span> 통증 지수</span>
                  <p>{ lastPain === null ? '-' : lastPain }</p>
                  { initPain !== null && lastPain !== null && initPain !== lastPain && (
                    <div className={cx('barChangeRate')}>
                      <img src={ initPain > lastPain ? GArrowDown : initPain < lastPain ? RArrowUp : '' } alt='Arrow'/>
                      <span className={cx(initPain > lastPain && 'green', initPain < lastPain && 'red')}>{ painChangeRate >= 0 ? painChangeRate : painChangeRate * -1 }%</span>
                    </div>
                  ) }
                </div>
                <div className={cx('barProgress')}>
                  <div className={cx('initPainBar')} style={{ width: initPain !== null ? `${oneBar * (initPain + 1)}px` : 0 }}></div>
                  <div style={{width: `${oneBar * (lastPain + 1)}px`}} className={cx(painColor[lastPain])}></div>
                </div>
                <div className={cx('barRange')}>
                  <span>0</span>
                  <span>10</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
      <FooterGray position='relative'/>
    </div>
  );
};

export default Home;