import React, {useState} from 'react';
import styles from './inputSearchBox.module.scss'
import classnames from 'classnames/bind';

const GSearchNew = `${process.env.REACT_APP_RESOURCE_URL}/G_searchNew.svg`

const cx = classnames.bind(styles);

const InputSearchBox = ({ placeholder, getKeyword, keyword, onSearch, isSmall = false }) => {
  const [inputFocus, setInputFocus] = useState(false)

  const onChange = (e) => {
    getKeyword(e.target.value)
  }

  const onRemoveClick = () => {
    getKeyword('')
    setInputFocus(false)
  }

  const onMouseUp = (e) => {
    setInputFocus(true)
  }

  const onBlur = (e) => {
    setInputFocus(false)
  }

  const onInputKeyPress = (e) => {
    if(e.key === 'Enter') {
      onSearch()
    }
  }

  return (
    <div className={cx('inputSearchBox')}>
      <div className={cx('inputWrapper')}>
        <img className={cx('searchImg')} onClick={onSearch} src={GSearchNew} alt='GSearchNew'/>
        <input
          className={cx(inputFocus && 'focus')}
          placeholder={placeholder}
          value={keyword}
          onChange={onChange}
          onMouseUp={onMouseUp}
          onBlur={onBlur}
          onKeyPress={onInputKeyPress}
          style={{ height: isSmall ? '41px' : '52px' }}
        />
        { keyword && <div className={cx('closeImg', isSmall || 'top18')} onClick={onRemoveClick}></div> }
      </div>
    </div>
  );
};

export default InputSearchBox;