import interceptor from "./interceptor";

const api = {
  postAuthToken(phoneNumber, password) {
    return interceptor.post(
      '/api/mora-ex/v1/auth/staff/token',
      {
        phoneNumber: phoneNumber,
        password: password
      }
    )
  },

  postTokenRefresh(refreshToken) {
    return interceptor.post(
      '/api/mora-ex/v1/auth/staff/token/refresh',
      {
        refreshToken: refreshToken
      }
    )
  },

  postChangeRole(rehabGroupId, rehabGroupMemberId, body) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/${rehabGroupMemberId}/change-role`,
      {
        ...body
      }
    )
  },

  getStaff() {
    return interceptor.get(
      '/api/mora-ex/v1/staff'
    )
  },

  postHospitalGroupExit(rehabGroupId, rehabGroupMemberId) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/${rehabGroupMemberId}/exit`,
      {
        rehabGroupMemberId
      }
    )
  },

  postHospitalGroupExitMySelf(rehabGroupId, rehabGroupMemberId) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/${rehabGroupMemberId}/exit-self`,
      {
        rehabGroupMemberId
      }
    )
  },

  postRehabGroupResend(rehabGroupId, rehabGroupMemberId) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/${rehabGroupMemberId}/resend-invitation`,
    )
  },

  postInvitation(rehabGroupId, body) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/invitation`,
      {
        ...body
      }
    )
  },

  postRehabGroupRejectRequest(rehabGroupId, rehabGroupMemberId) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/${rehabGroupMemberId}/reject-request`,
    )
  },

  postRehabGroupRejectInvitation(rehabGroupId, rehabGroupMemberId) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/${rehabGroupMemberId}/reject-invitation`,
    )
  },

  postRehabGroupApproveRequest(rehabGroupId, rehabGroupMemberId) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/${rehabGroupMemberId}/approve-request`,
    )
  },

  postRehabGroupApproveInvitation(rehabGroupId, rehabGroupMemberId) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/${rehabGroupMemberId}/approve-invitation`,
    )
  },

  cancelInvitation(rehabGroupId, rehabGroupMemberId) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/${rehabGroupMemberId}/cancel-invitation`
    )
  },

  getRecord(available, order = 'CREATED_DATE_DESC') {
    return interceptor.get(
      `/api/mora-ex/v2/record?available=${available}&order=${order}`
    )
  },

  getPrograms(keyword, tags, isBookmark, page, order, creatorId, isShared) {
    return interceptor.get (
      `/api/mora-ex/v1/program?${keyword ? `name=${keyword}` : ""}&tags=${tags}${creatorId ? "&creatorId=" + creatorId : ""}&bookmark=${!!isBookmark}&page=${page}&size=21&order=${order}&share=${(!!isShared)}`
    )
  },

  getExercises(keyword, objectives, positions, levels, tags, isBookmark, page, order) {
    return interceptor.get (
      `/api/mora-ex/v1/exercise?${keyword ? `name=${keyword}` : ""}&objectives=${objectives}&positions=${positions}&levels=${levels}&tags=${tags}&bookmark=${!!isBookmark}&page=${page}&size=21&order=${order}`
    )
  },

  getNotiUnseenCount(){
    return interceptor.get(
      `/api/mora-ex/v1/notifications/unseen-count`
    )
  },

  getNotiList(category, page){
    return interceptor.get(
      `/api/mora-ex/v1/notifications?categories=${category}&page=${page}&size=20&sort=id,desc`
    )
  },

  postNotiRead(notiId) {
    return interceptor.post(
      `/api/mora-ex/v1/notifications/${notiId}/read`,
    )
  },

  postNotiReadAll(category) {
    // category 없으면 전체 읽음
    return interceptor.post(
      `/api/mora-ex/v1/notifications/read-all?categories=${category}`,
    )
  },

  postNotiSeen() {
    return interceptor.post(
      `/api/mora-ex/v1/notifications/seen`,
    )
  },


  getPlanDetail(id){
    return interceptor.get(
      `/api/mora-ex/v1/plan/${id}`
    )
  },

  getPlanList(id) {
    return interceptor.get(
      `/api/mora-ex/v1/plan/list?medicalRecordId=${id}`,
    )
  },

  getPatientInfo(id) {
    return interceptor.get(
      `/api/mora-ex/v1/record/${id}`,
    )
  },

  getRecordIdSchedule(id, from, to) {
    return interceptor.get(
      `/api/mora-ex/v1/record/${id}/schedule?from=${from}&to=${to}`,
    )
  },

  postRecordIdSchedule(id, type, content, date) {
    return interceptor.post (
      `/api/mora-ex/v1/record/${id}/schedule`,
      {
        type: type,
        content: content,
        date: date,
      }
    )
  },

  deleteRecordScheduleSid(sid) {
    return interceptor.delete (
      `/api/mora-ex/v1/record/schedule/${sid}`,
    )
  },

  deleteRecord(id) {
    return interceptor.delete (
      `/api/mora-ex/v1/record/${id}`
    )
  },

  patchRecordScheduleSid(sid, content) {
    return interceptor.post (
      `/api/mora-ex/v1/record/schedule/${sid}/patch-to-post`,
      {
        content: content
      }
    )
  },

  postSendOtp(body) {
    return interceptor.post (
      '/api/mora-ex/v2/staff/send-otp',
      {
        ...body
      }
    )
  },

  postCheckOtp(body) {
    return interceptor.post (
      '/api/mora-ex/v2/staff/check-otp',
      {
        ...body
      }
    )
  },

  postStaffValidation(body) {
    return interceptor.post (
      '/api/mora-ex/v2/staff/validation',
      {
        ...body
      }
    )
  },

  postStaff(body) {
    return interceptor.post (
      '/api/mora-ex/v2/staff',
      {
        ...body
      }
    )
  },

  postPhoneNumber(body) {
    return interceptor.post (
      '/api/mora-ex/v2/staff/phone-number',
      {
        ...body
      }
    )
  },

  getHospitalKeywordAndCities(body) {
    return interceptor.get(
      `/api/mora-ex/v1/hospital?${body.keyword ? `hospitalName=${body.keyword}` : ""}&upCityName=${body.upCityName}&downCityName=${body.downCityName}&page=${body.page}&size=${20}`,
    )
  },

  postPassword(body) {
    return interceptor.post (
      '/api/mora-ex/v2/staff/password',
      {
        ...body
      }
    )
  },

  addPatient(body) {
    return interceptor.post (
      '/api/mora-ex/v1/record',
      {
        ...body
      }
    )
  },

  deleteStaffBookmark(body) {
    return interceptor.delete (
      '/api/mora-ex/v1/staff/bookmark',
      { data: body }
    )
  },

  postStaffBookmark(body) {
    return interceptor.post (
      '/api/mora-ex/v1/staff/bookmark',
      {
        ...body
      }
    )
  },

  getHospitalGroupMedicalStaffChart(rehabGroupId, type) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/statistics/medical-staff-chart?type=${type}`,
    )
  },

  getHospitalGroupPlanChart(rehabGroupId, type) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/statistics/plan-chart?type=${type}`,
    )
  },

  getHospitalGroupPatientChart(rehabGroupId, type) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/statistics/patient-chart?type=${type}`,
    )
  },

  getHospitalGroupStatistics(rehabGroupId) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/statistics/summary`,
    )
  },

  postHospitalGroupUnsubscriptionCancel(rehabGroupId, subscriptionId) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/subscription/${subscriptionId}/unsubscription-cancel`,
    )
  },

  getHospitalGroupSubscription(rehabGroupId) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/subscription`,
    )
  },

  postHospitalGroupUnsubscription(rehabGroupId, subscriptionId) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/subscription/${subscriptionId}/unsubscription`,
    )
  },

  getHospitalGroupCard(rehabGroupId) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/card`,
    )
  },

  postHospitalGroupCard(rehabGroupId, body) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/card`,
      {
        ...body
      }
    )
  },

  postHospitalGroupCardPrimary(rehabGroupId, cardId) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/card/${cardId}/primary`,
    )
  },

  deleteHospitalGroupCard(rehabGroupId, cardId) {
    return interceptor.delete (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/card/${cardId}`,
    )
  },

  getUnpaidAmount(rehabGroupId) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/unpaid-amount`,
    )
  },

  postPayUnpaidSubscription(rehabGroupId) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/pay-unpaid-subscription`,
    )
  },

  getHospitalGroupPayment(rehabGroupId, page, size) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/payment?page=${page}&size=${size}`,
    )
  },

  getHospitalGroupDepositHistory(rehabGroupId, page) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/deposit?page=${page}&size=${20}`,
    )
  },

  getSubscriptionModel(type) {
    return interceptor.get(
      `/api/mora-ex/v1/subscription-model?type=${type}`,
    )
  },

  postHospitalGroupSubscription(rehabGroupId, body) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/subscription`,
      {
        ...body
      }
    )
  },

  postJoin(joinCode) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/member/join`,
      {
        ...joinCode
      }
    )
  },

  getRemainPlanCount(rehabGroupId) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/subscription/remain-plan-count`,
    )
  },

  postRecordAccess(payload) {
    return interceptor.post (
      `/api/mora-ex/v1/record/${payload.patientId}/access`,
      {
        purpose: payload.purpose
      }
    )
  },

  getRecordAccess(patientId) {
    return interceptor.get(
      `/api/mora-ex/v1/record/${patientId}/access`,
    )
  },

  postPlanCancel(id, reason) {
    return interceptor.post (
      `/api/mora-ex/v1/plan/${id}/cancel`,
      {
        ...reason
      }
    )
  },

  postPlan(plan) {
    return interceptor.post (
      '/api/mora-ex/v1/plan',
      {
        ...plan
      }
    )
  },

  postResendPlanText(id) {
    return interceptor.post (
      `/api/mora-ex/v1/plan/${id}/resend`
    )
  },

  patchPatientComment(id, payload) {
    return interceptor.post(
      `/api/mora-ex/v1/record/${id}/patch-to-post`,
      {
        ...payload
      }
    )
  },

  postPlanAllList(patientId, startDate, endDate) {
    return interceptor.get(
      `/api/mora-ex/v1/plan/monitoring/${patientId}?startDate=${startDate}&endDate=${endDate}`,
    )
  },

  getHospitalInfo(hospitalId) {
    return interceptor.get(
      `/api/mora-ex/v1/hospital/${hospitalId}`,
    )
  },

  postHospitalInfo(hospitalId, data) {
    return interceptor.post(
      `/api/mora-ex/v1/hospital/${hospitalId}/patch-to-post`,
      {
        ...data
      }
    )
  },

  createProgram(programData) {
    return interceptor.post(
      '/api/mora-ex/v1/program',
      {
        ...programData
      }
    )
  },

  editProgram(id, programData) {
    return interceptor.post(
      `/api/mora-ex/v1/program/${id}/patch-to-post`,
      {
        ...programData
      }
    )
  },

  copyProgram(id, name) {
    return interceptor.post(
      '/api/mora-ex/v1/program/copy',
      {
        copyProgramId: id,
        name: `${name}의 사본`
      }
    )
  },

  deleteProgram(id) {
    return interceptor.delete (
      `/api/mora-ex/v1/program/${id}`
    )
  },

  getStepData(planId) {
    return interceptor.get(
      `/api/mora-ex/v1/plan/${planId}/step`,
    )
  },

  postPlanSharedStaff(planId, data) {
    return interceptor.post(
      `/api/mora-ex/v1/plan/${planId}/attending-medical-staff`,
      {
        ...data
      }
    )
  },

  getStaffList(rehabGroupId) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/medical-staff`,
    )
  },

  getPlanSharedStaffList(planId) {
    return interceptor.get(
      `/api/mora-ex/v1/plan/${planId}/attending-medical-staff`,
    )
  },

  // 이지케어텍
  // loginEnn(data) {
  //   return interceptor.post(
  //     '/api/mora-ex/v1/ext/enn/token',
  //     {
  //       ...data
  //     }
  //   )
  // },
  //
  // signupEnn(data) {
  //   return interceptor.post(
  //     '/api/mora-ex/v1/ext/enn/medical-staff',
  //     {
  //       ...data
  //     }
  //   )
  // },
  //
  // smsEnn(data) {
  //   return interceptor.post(
  //     '/api/mora-ex/v1/ext/enn/medical-staff/send-otp',
  //     {
  //       ...data
  //     }
  //   )
  // },
  //
  // validationEnn(data) {
  //   return interceptor.post(
  //     '/api/mora-ex/v1/ext/enn/medical-staff/check-otp',
  //     {
  //       ...data
  //     }
  //   )
  // },

  // rehab group

  postAddRehabGroup(data) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group`,
      {
        ...data
      }
    )
  },

  getRehabGroup(rehabGroupId) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}`,
    )
  },

  postRehabGroupInfo(rehabGroupId, data) {
    return interceptor.post(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/patch-to-post`,
      {
        ...data
      }
    )
  },

  getRehabGroupCard(rehabGroupId) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/card`,
    )
  },

  postRehabGroupCard(rehabGroupId, body) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/card`,
      {
        ...body
      }
    )
  },

  postRehabGroupCardPrimary(rehabGroupId, cardId) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/card/${cardId}/primary`,
    )
  },

  deleteRehabGroupCard(rehabGroupId, cardId) {
    return interceptor.delete (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/card/${cardId}`,
    )
  },

  getRehabGroupMembers(rehabGroupId, page, size, keyword) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/members?page=${page}&size=${size}&keyword=${keyword}`,
    )
  },

  getRehabGroupMembersCount(rehabGroupId) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/members/count`,
    )
  },

  // imp: isMyPlan 내가 배정한 플랜인지 boolean
  getRecentPlans(rehabGroupId, imp, page, size) {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/recent-plans?imp=${imp}&page=${page}&size=${size}`,
    )
  },

  getMemberStatus() {
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group/member-status`,
    )
  },

  cancelGroupJoin(rehabGroupId, rehabGroupMemberId) {
    return interceptor.post (
      `/api/mora-ex/v1/rehab-group/${rehabGroupId}/member/${rehabGroupMemberId}/cancel-request`,
    )
  },

  postStaffInfo(body) {
    return interceptor.post (
      `/api/mora-ex/v2/staff/patch-to-post`,
      {
        ...body
      }
    )
  },

//  ------------ 공지사항
  getNoticeList(title, isShowBanner, page , size){
    let url = `/api/everex-admin/v1/notices?title=${title}&serviceType=MORA_WEB&page=${page || 0}&size=${size}`
    if(isShowBanner !== null) {
      url = `/api/everex-admin/v1/notices?title=${title}&serviceType=MORA_WEB&lastBanner=${isShowBanner}&page=${page}&size=${size}`
    }
    return interceptor.get(
      url
    )
  },

  getNoticeDetail(noticeId){
    return interceptor.get(
      `/api/everex-admin/v1/notices/${noticeId}`
    )
  },
//   공지사항 ------------


  getOrgans(keyword){
    return interceptor.get(
      `/api/mora-ex/v1/orgs?keyword=${keyword}&page=1&size=10`
    )
  },

  postOrgan(body){
    return interceptor.post(
      `/api/mora-ex/v1/orgs`,
      {
        ...body
      }
    )
  },

  getGroups(orgId){
    return interceptor.get(
      `/api/mora-ex/v1/rehab-group?orgId=${orgId}`
    )
  },

  postGroup(body){
    return interceptor.post(
      `/api/mora-ex/v1/group`,
      {
        ...body
      }
    )
  },
}

export default api

