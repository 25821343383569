import React, {useEffect, useRef, useState} from 'react';
import styles from './exerciseDragItem.module.scss'
import classnames from "classnames/bind";
import DropdownOrder from "../../../common/dropdown/dropdownOrder/dropdownOrder";
import imgSize from "@/data/imgSize"
import {useSortable} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {useDispatch, useSelector} from "react-redux";
import {setAddedPlanExerciseArr} from "../../../../store/slice/planSlice";
import {useLocation} from "react-router-dom";
import {setAddedProgramExerciseArr} from "../../../../store/slice/programSlice";

const GDrag = `${process.env.REACT_APP_RESOURCE_URL}/G_drag.svg`
const GCircle = `${process.env.REACT_APP_RESOURCE_URL}/G_circle.svg`
const GCheckCircleFull = `${process.env.REACT_APP_RESOURCE_URL}/G_checkCircleFull.svg`

const cx = classnames.bind(styles)

const ExerciseDragItem = ({ item, id, toggleExCheck }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: item.unique })
  let dispatch = useDispatch()
  let location = useLocation()
  const addedPlanExerciseArr = useSelector(state => {
    if(location.pathname.includes('create/program')) {
      return state.program.value.addedProgramExerciseArr
    } else {
      return state.plan.value.addedPlanExerciseArr
    }
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const repeatRef = useRef(null)

  const [isDropdownCountOpen, setIsDropdownCountOpen] = useState(false)
  const [isListOpenUp, setIsListOpenUp] = useState(false)
  const [selectedCountId, setSelectedCountId] = useState(null)
  const [repeat, setRepeat] = useState(item.repeat.toString())
  const [isRepeatFocus, setIsRepeatFocus] = useState(false)
  const countList = [
    {
      id: 0,
      no: item.time ? 5 : 5,
      unit: item.time ? '초' : '회',
      unitEng: item.time ? 'time' : 'count',
      txt: item.time ? '5초' : '5회',
    },
    {
      id: 1,
      no: item.time ? 10 : 10,
      unit: item.time ? '초' : '회',
      unitEng: item.time ? 'time' : 'count',
      txt: item.time ? '10초':  '10회',
    },
    {
      id: 2,
      no: item.time ? 15 : 15,
      unit: item.time ? '초' : '회',
      unitEng: item.time ? 'time' : 'count',
      txt: item.time ? '15초' : '15회',
    },
    {
      id: 3,
      no: item.time ? 20 : 20,
      unit: item.time ? '초' : '회',
      unitEng: item.time ? 'time' : 'count',
      txt: item.time ? '20초' : '20회',
    }
  ]

  const onRepeatKeyup = (e) => {
    if(e.code === 'Enter') {
      setIsRepeatFocus(false)
    }
  }

  const onRepeatChange = (e) => {
    let value = e.target.value
    if(value === repeat || value === '0') {
      return
    }

    let repeatNo = e.target.value.replace(/[^0-9]/g, '')
    setRepeat(repeatNo)
  }

  const onRepeatBlur = (e) => {
    let repeatNo = e.target.value.replace(/[^0-9]/g, '')

    let newAddedPlanExerciseArr = JSON.stringify(addedPlanExerciseArr)
    newAddedPlanExerciseArr = JSON.parse(newAddedPlanExerciseArr)

    newAddedPlanExerciseArr.forEach((addedExercise) => {
      if(addedExercise.unique === item.unique) {
        addedExercise.repeat = repeatNo ? repeatNo : item.repeat.toString()
      }
    })

    dispatchNewValue(newAddedPlanExerciseArr)
  }

  const onRepeatFocus = () => {
    setIsRepeatFocus(true)
    repeatRef.current.focus()
  }

  const dispatchNewValue = (arrValue) => {
    if(location.pathname.includes('create/program')) {
      dispatch(setAddedProgramExerciseArr(arrValue))
    } else {
      dispatch(setAddedPlanExerciseArr(arrValue))
    }

    setIsRepeatFocus(false)
  }

  const getSelectedCountId = (id) => {
    if(id === selectedCountId) {
      return
    }

    setSelectedCountId(id)

    let newAddedPlanExerciseArr = JSON.stringify(addedPlanExerciseArr)
    newAddedPlanExerciseArr = JSON.parse(newAddedPlanExerciseArr)

    newAddedPlanExerciseArr.forEach((addedExercise) => {
      if(addedExercise.unique === item.unique) {
        addedExercise[countList[id].unitEng] = countList[id].no
      }
    })

    dispatchNewValue(newAddedPlanExerciseArr)
  }

  const getThumbnail = () => {
    if(Object.keys(item).length) {
      if(item.urls) {
        return item.urls.IT + imgSize['90']
      } else {
        return item.media.IT.url + imgSize['90']
      }
    }
  }

  useEffect(() => {
    let number = item.time ? item.time : item.count

    countList.forEach((countItem) => {
      if(countItem.no === number) {
        setSelectedCountId(countItem.id)
      }
    })

    if(addedPlanExerciseArr.length > 7) {
      if((addedPlanExerciseArr[addedPlanExerciseArr.length - 1].unique === item.unique) || (addedPlanExerciseArr[addedPlanExerciseArr.length - 2].unique === item.unique)) {
        setIsListOpenUp(true)
      }
    }
  }, [])

  return (
      <div className={cx('exerciseDragItem')} style={style} ref={setNodeRef}>
        <img className={cx('circleImg')} src={item.isChecked ? GCheckCircleFull : GCircle} onClick={() => toggleExCheck(id)} alt='GCircle'/>
        <div>
          <div className={cx('imgBack')}></div>
          <img className={cx('thumbnail')} src={ getThumbnail() } alt='exImg'/>
          <div className={cx('addedExerciseContent')}>
            <p>
              { item.name }
              <span className={cx('tooltiptext', item.name.length > 19 && 'long')}>{ item.name }</span>
            </p>
            <div className={cx('editSelections')}>
              <div className={cx('exCount')}>
                <DropdownOrder
                  orderList={countList}
                  isDropdownOrderOpen={isDropdownCountOpen}
                  setSelectedOrderId={getSelectedCountId }
                  selectedOrderId={selectedCountId}
                  setIsDropdownOrderOpen={() => setIsDropdownCountOpen(!isDropdownCountOpen)}
                  fixedValueExist={false}
                  listWidth={60}
                  height={26}
                  font='small'
                  isScrollList={false}
                  firstValue={item.time ? item.time + '초' : item.count + '회'}
                  isListOpenUp={isListOpenUp}
                />
              </div>
              <span>X</span>
              <div className={cx('set', isRepeatFocus && 'active')} onClick={() => onRepeatFocus()}>
                <input
                  maxLength={2}
                  type='text'
                  value={repeat}
                  ref={repeatRef}
                  onChange={(e) => onRepeatChange(e)}
                  onBlur={(e) => onRepeatBlur(e)}
                  onKeyUp={(e) => onRepeatKeyup(e)}
                />
                <span>세트</span>
              </div>
            </div>

          </div>
          <img {...attributes} {...listeners} className={cx('pointer')} src={GDrag} alt='GDrag'/>
        </div>
      </div>
    );
};

export default ExerciseDragItem;