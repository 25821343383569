import React, {useEffect, useRef, useState} from 'react';
import styles from './modalProgramDetail.module.scss'
import classnames from 'classnames/bind';
import ToggleBookmark from "@/helpers/toggleBookmark"
import {useDispatch, useSelector} from "react-redux";
import tagKor from '@/data/tagKor'
import ExerciseItemDetail from "./exerciseItemDetail/exerciseItemDetail";
import {showToast} from "../../../../helpers/toastMessage";
import api from "../../../../utils/api";
import {
  resetProgramCreationParams,
  setAddedProgramExerciseArr, setMyProgramIdToEdit,
  setIsMyProgramsUpdate, setProgramCreationParams, setProgramCreationStep
} from "../../../../store/slice/programSlice";
import {useNavigate} from "react-router-dom";
import {setActiveFilterRehabId} from "../../../../store/slice/rehabSlice";
import ModalSmall from "../modalSmall/modalSmall";
import JobIcon from "../../jobIcon/jobIcon";
import {makeCompressedProgram, makeSetEx} from "../../../../helpers/common";

const BCloseSharp = `${process.env.REACT_APP_RESOURCE_URL}/B_closeSharp.svg`
const YNewStar = `${process.env.REACT_APP_RESOURCE_URL}/Y_newStar.svg`
const GNewStar = `${process.env.REACT_APP_RESOURCE_URL}/G_newStar.svg`
const BNote = `${process.env.REACT_APP_RESOURCE_URL}/B_note.svg`
const GPencil20 = `${process.env.REACT_APP_RESOURCE_URL}/G_pencil20.svg`
const Trash = `${process.env.REACT_APP_RESOURCE_URL}/trash.svg`
const GCopySharp = `${process.env.REACT_APP_RESOURCE_URL}/G_copy_sharp.svg`
const GShareTwoPeople = `${process.env.REACT_APP_RESOURCE_URL}/G_share_two_people.svg`
const GLink = `${process.env.REACT_APP_RESOURCE_URL}/G_link.svg`

const cx = classnames.bind(styles);
const ModalProgramDetail = ({ header, program, isModalProgramDetailOpen, setIsModalProgramDetailOpen }) => {
  const ref = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [bookmarkClickKey, setBookmarkClickKey] = useState(0)
  const bookmarkProgram = useSelector(state => {
    return state.staff.value.bookmarkProgram
  })
  const activeFilterRehabId = useSelector(state => {
    return state.rehab.value.activeFilterRehabId
  })
  const programCreationParams = useSelector(state => {
    return state.program.value.programCreationParams
  })

  const compressedExScript = makeSetEx(program.exScript)

  const [programType, setProgramType] = useState('')
  const [isShowEditOptions, setIsShowEditOptions] = useState(false)
  const [isShared, setIsShared] = useState(false)
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [modalSmallContent, setModalSmallContent] = useState({
    bodyTitle: '',
    bodySub: '',
    isOneBtn: false,
    btnFirstTxt: '',
    btnSecondTxt: '',
    btnFirstEvent: {},
    btnSecondEvent: {},
    isModalSmallOpen: {},
  })

  const getProgramType = () => {
    if(activeFilterRehabId === 0) {
      setProgramType('추천 프로그램')
    } else if(activeFilterRehabId === 1) {
      setProgramType('공유 프로그램')
    } else if(activeFilterRehabId === 2) {
      setProgramType('나만의 프로그램')
    }
  }

  const openWarningModal = () => {
    setModalSmallContent({
      bodyTitle: '해당 프로그램을 삭제하시겠습니까?',
      bodySub: '삭제한 프로그램은 복구할 수 없으니</br>신중히 진행해 주세요.',
      isOneBtn: false,
      btnSecondTxt: '취소',
      btnFirstTxt: '삭제하기',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      btnFirstEvent: () => deleteProgram(),
    })
    setIsModalSmallOpen(true)
  }

  const deleteProgram = () => {
    api.deleteProgram(program.pid).then(() => {
      showToast('error', '프로그램이 삭제되었습니다.')
      dispatch(setIsMyProgramsUpdate(true))
      setIsModalProgramDetailOpen(false)
    })
  }

  const copyProgram = () => {
    api.copyProgram(program.pid, program.name).then(() => {
      showToast('success', '프로그램이 복사되었습니다.')
      dispatch(setIsMyProgramsUpdate(true))
      setIsModalProgramDetailOpen(false)
    })
  }

  const editMyProgram = (e) => {
    closeModal(e)
    dispatch(setMyProgramIdToEdit(program.pid))
    dispatch(resetProgramCreationParams())

    let schedule = program.exScript
    let exerciseMap = program.exerciseMap
    let newSchedule = []

    schedule.forEach((sch, idx) => {
      Object.keys(exerciseMap).forEach((exId) => {
        if(sch.id === Number(exId)) {
          let item = {...Object.assign(exerciseMap[exId], sch)}
          item.unique = Date.now() + idx
          newSchedule.push(item)
        }
      })
    })

    dispatch(setAddedProgramExerciseArr(makeSetEx(newSchedule)))
    dispatch(setProgramCreationParams({
      ...programCreationParams,
      tags: program.tag.MP,
      name: program.name,
      diagnosis: program.diagnosis,
      phase: program.phase,
      precaution: program.precaution,
      share: program.share
    }))

    dispatch(setProgramCreationStep(0))
    dispatch(setActiveFilterRehabId(0))
    navigate('/create/program')
  }

  const closeModal = (e) => {
    if(e) {
      e.stopPropagation()
    }

    setIsModalProgramDetailOpen(false)
  }

  const toggleShareBtn = () => {
    setIsShared(!isShared)

    let programData = {
      compressedExScript: makeCompressedProgram(compressedExScript),
      diagnosis: program.diagnosis,
      name: program.name,
      phase: program.phase,
      precaution: program.precaution,
      share: !isShared,
      tags: program.tag.MP,
    }

    api.editProgram(program.pid, programData).then((res) => {
      if(res.data.share) {
        showToast('success', '프로그램이 공유 기능이 설정되었습니다.')
      } else {
        showToast('error', '프로그램이 공유 기능이 해제되었습니다.')
      }
      dispatch(setIsMyProgramsUpdate(true))
    })
  }

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden'
    const checkIfClickedOutside = e => {
      if (isModalProgramDetailOpen && ref.current && !ref.current.contains(e.target) && !e.target.className.includes('modalSmall')) {
        setIsModalProgramDetailOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
      document.documentElement.style.overflow = 'auto'
    }
  }, [isModalProgramDetailOpen])

  useEffect(() => {
    getProgramType()

    setIsShared(program.share)
  }, [])

  return (
    <>
      { bookmarkClickKey !== 0 ? (
        <ToggleBookmark type='program' id={program.pid} key={bookmarkClickKey}/>
      ) : ''}
      <div className={cx('modalProgramDetail')} onClick={() => closeModal()}>
        <div ref={ref}>
          <div className={cx('header')}>
            <p>{ header }</p>
            <img onClick={(e) => closeModal(e)} src={BCloseSharp} alt='BCloseSharp'/>
          </div>
          <div className={cx('body')}>
            <div className={cx('iconWrapper')}>
              <img onClick={() => setBookmarkClickKey(Math.random())} src={ bookmarkProgram.includes(program.pid) ? YNewStar : GNewStar} alt='GNewStar'/>
              { activeFilterRehabId === 2 ? (
                <>
                  <img src={BNote} className={cx('pencil')} onClick={() => setIsShowEditOptions(!isShowEditOptions)} alt='BNote'/>
                  <div className={cx('editOptions', isShowEditOptions && 'active')}>
                    <p onClick={(e) => editMyProgram(e)}>
                      <img src={GPencil20} alt='GPencil20'/>
                      <span>수정하기</span>
                    </p>
                    <p onClick={() => copyProgram()}>
                      <img src={GCopySharp} alt='GCopySharp'/>
                      <span>복사하기</span>
                    </p>
                    <p onClick={() => openWarningModal()}>
                      <img src={Trash} alt='Trash'/>
                      <span>삭제하기</span>
                    </p>
                    <div>
                      <div className={cx('share')}>
                        <img src={GShareTwoPeople} alt='GShareTwoPeople'/>
                        <span>프로그램 공유하기</span>
                      </div>
                      <div className={cx('toggleBtnWrapper')}>
                        <p className = {cx('toggleBtn')} onClick={() => toggleShareBtn()}>
                          <span className={cx(isShared && 'on')}></span>
                        </p>
                        <span>{ isShared ? '켜짐' : '꺼짐' }</span>
                      </div>
                    </div>
                  </div>
                </>
              ): ''}
            </div>

            <div className={cx('title')}>
              <p className={cx('type')}>{ programType }</p>
              <p className={cx('name')}>{program.name }</p>
            </div>
            <div className={cx('content')}>
              { activeFilterRehabId === 1 ? (
                <div>
                  <span className={cx('contentTitle')}>만든 사람</span>
                  <div className={cx('contents')}>
                    <JobIcon job={program.creator.job} isCertified={program.creator.isCertified}/>
                    <span className={cx('creatorName')}>
                      { program.creator.name }
                    </span>
                    { program.creator.phoneNumber && (
                      <span>({ program.creator.phoneNumber.slice(7, 11) })</span>
                    ) }
                  </div>
                </div>
              ) : ''}
              <div>
                <span className={cx('contentTitle')}>부위</span>
                <span className={cx('contents')}>
                  { program.tag.MP && (
                    program.tag.MP.map((mp, idx) => (
                      <span key={idx}>
                        { tagKor['MP'][mp] }
                        { idx !== program.tag.MP.length - 1 ? (
                          <span> / </span>
                        ) : ''}
                      </span>
                    ))
                  ) }
                </span>
              </div>
              <div>
                <span className={cx('contentTitle')}>질환 및 수술명</span>
                <span className={cx('contents')}>{ program.diagnosis.trim() ? program.diagnosis : '-' }</span>
              </div>
              <div>
                <span className={cx('contentTitle')}>단계</span>
                <span className={cx('contents')}>{ program.phase.trim() ? program.phase : '-'}</span>
              </div>
              <div>
                <span className={cx('contentTitle')}>도구</span>
                <span className={cx('contents')}>
                  { program.tag.TO ? (
                    program.tag.TO.map((to, idx) => (
                      <span key={idx} className={cx(idx && 'marginLeft')}>
                        { tagKor['TO'][to] }
                        { idx !== program.tag.TO.length - 1 ? (
                          <span> / </span>
                        ) : ''}
                      </span>
                    ))
                  ) : '-'}
                </span>
              </div>
              <div>
                <span className={cx('contentTitle')}>주의사항</span>
                <span className={cx('contents')}>{ program.precaution.trim() ? program.precaution : '-'}</span>
              </div>
              { activeFilterRehabId === 0 && (
                <div>
                  <span className={cx('contentTitle', 'academic')}>
                    학술자료
                    <img src={GLink} alt='GLink' />
                  </span>
                  <p className={cx('contents', 'academic')} dangerouslySetInnerHTML={{ __html: program.reference ? program.reference : '-' }}></p>
                </div>
              ) }
            </div>
            <div className={cx('list')}>
              <div className={cx('listTitle')}>
                <span className={cx('listOrder')}>진행 순서</span>
                <span className={cx('totalTime')}>시간 :<span> { program.time }분</span></span>
              </div>
              <div className={cx('listContainer')}>
                { compressedExScript.map((exercise, idx) => (
                  <ExerciseItemDetail program={program} exercise={exercise} key={idx}/>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      { isModalSmallOpen &&
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          bodySub={modalSmallContent.bodySub}
          isOneBtn={modalSmallContent.isOneBtn}
          btnSecondTxt={modalSmallContent.btnSecondTxt}
          btnFirstTxt={modalSmallContent.btnFirstTxt}
          btnSecondEvent={modalSmallContent.btnSecondEvent}
          btnFirstEvent={modalSmallContent.btnFirstEvent}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}/>
      }
    </>
  );
};

export default ModalProgramDetail;