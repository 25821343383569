import React from 'react';
import styles from './buttonBigGray.module.scss'
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

const ButtonBigGray = ({ text, onBtnBigGrayClick, img = '', isDisabled= false, height, font }) => {
  return (
    <button className={cx('buttonBigGray', isDisabled && 'isDisabled')} onClick={onBtnBigGrayClick} style={{ height: height && height + 'px' }}>
      { img &&
        <div className={cx(img === 'Gsearch' && 'Gsearch')}></div>
      }
      <span className={cx(font && font)}>{ text }</span>
    </button>
  );
};

export default ButtonBigGray;