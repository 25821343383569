import { useEffect, useRef } from 'react';

const useDidMountEffect = (func, deps) => {
  // useEffect 첫 렌더링 시 함수 실행 막는 커스텀 훅
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useDidMountEffect;