import React, {useState} from 'react';
import ButtonPrimary from "../../../common/button/buttonPrimary/buttonPrimary";
import styles from './exerciseCard.module.scss'
import classnames from "classnames/bind";
import ToggleBookmark from "@/helpers/toggleBookmark"
import tagKor from '@/data/tagKor'
import {useDispatch, useSelector} from "react-redux";
import ModalExDetail from "../../../common/modal/modalExDetail/modalExDetail";
import {setAddedPlanExerciseArr} from "../../../../store/slice/planSlice";
import {setAddedProgramExerciseArr} from "../../../../store/slice/programSlice";
import {useLocation} from "react-router-dom";
import {showToast} from "../../../../helpers/toastMessage";

const YGrayRoundStar = `${process.env.REACT_APP_RESOURCE_URL}/Y_grayRoundStar.svg`
const GRoundStar = `${process.env.REACT_APP_RESOURCE_URL}/G_roundStar.svg`

const cx = classnames.bind(styles)
const ExerciseCard = ({exercise}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const bookmarkExercise = useSelector(state => {
    return state.staff.value.bookmarkExercise
  })
  const lvArr = useSelector(state => {
    return state.rehab.value.lvArr
  })
  const addedPlanExerciseArr = useSelector(state => {
    if(location.pathname.includes('create/program')) {
      return state.program.value.addedProgramExerciseArr
    } else {
      return state.plan.value.addedPlanExerciseArr
    }

  })

  const [isModalExerciseDetailOpen, setIsModalExerciseDetailOpen] = useState(false)
  const [bookmarkClickKey, setBookmarkClickKey] = useState(0)
  const [modalExerciseDetailContent, setModalExerciseDetailContent] = useState({
    header: '',
    exercise: {},
  })

  const openExerciseDetailModal = (e, exercise) => {
    if(e.target.className.includes('buttonPrimary') || e.target.className.includes('primaryBtnIcon') || e.target.className.includes('bookmark')) {
      return
    }

    setModalExerciseDetailContent({
      header: '미리보기',
      exercise: exercise,
    })

    setIsModalExerciseDetailOpen(true)
  }
  const addExercise = (exercise) => {
    exercise.isChecked = false
    exercise.repeat = 1

    let copiedExStr = JSON.stringify(exercise)
    exercise = JSON.parse(copiedExStr)
    if(exercise.unique === undefined) {
      exercise.unique = Date.now()
    }

    let newAddedPlanExerciseArr = [{...exercise}]
    newAddedPlanExerciseArr = [...addedPlanExerciseArr, ...newAddedPlanExerciseArr]

    if(location.pathname.includes('create/program')) {
      dispatch(setAddedProgramExerciseArr(newAddedPlanExerciseArr))
    } else {
      dispatch(setAddedPlanExerciseArr(newAddedPlanExerciseArr))
    }

    showToast('success', '운동이 추가되었습니다.')
  }

  return (
    <div className={cx('exerciseCard')} key={exercise.id} onClick={(e) => openExerciseDetailModal(e, exercise)}>
      { bookmarkClickKey !== 0 ? (
        <ToggleBookmark type='exercise' id={exercise.id} key={bookmarkClickKey}/>
      ) : ''}
      <div className={cx('imgBox')}>
        <img className={cx('exImage')} src={exercise.urls.IT} alt='exerciseImg'/>
        <img className={cx('bookmark')} onClick={() => setBookmarkClickKey(Math.random())} src={ bookmarkExercise.includes(exercise.id) ? YGrayRoundStar : GRoundStar  } alt='star'/>
      </div>

      <div className={cx('exerciseContent')}>
        <p className={cx('exerciseTag')}>
          { tagKor['OB'][exercise.objective]  } /
          { exercise.tag.KC ? (exercise.tag.KC.map((kc) => (
            <span key={kc}> {tagKor['KC'][kc]} /</span>
          ))) : '' }
          { exercise.tag.AP ? (exercise.tag.AP.map((ap, idx) => (
            <span key={ap}> {tagKor['AP'][ap]} { idx + 1 === exercise.tag.AP.length ? '' : '/' }</span>
          ))) : '' }
        </p>
        <p className={cx('exerciseTitle')}>{ exercise.name }</p>
        <div className={cx('level')}>
          <div className={cx(lvArr[exercise.level - 1].color)}></div>
          <span>{ lvArr[exercise.level - 1].lv }</span>
        </div>
      </div>
      <ButtonPrimary
        text='담기'
        onBtnPrimaryClick={() => addExercise(exercise)}
        icon='WPlusSharp'
        height={41}
        width={100}
        font='small'
      />
      { isModalExerciseDetailOpen && (
        <ModalExDetail
          header={modalExerciseDetailContent.header}
          exercise={modalExerciseDetailContent.exercise}
          isModalExerciseDetailOpen={isModalExerciseDetailOpen}
          setIsModalExerciseDetailOpen={setIsModalExerciseDetailOpen}
        />
      ) }

    </div>
  );
};

export default ExerciseCard;