import React, { useState, useRef} from 'react';
import classnames from "classnames/bind";
import styles from './rehabCardProgram.module.scss'
import {useSelector} from "react-redux";
import api from "@/api";
import imgSize from "@/data/imgSize"
import tagKor from "@/data/tagKor"
import ModalProgramDetail from "../../modal/modalProgramDetail/modalProgramDetail";
import JobIcon from "../../jobIcon/jobIcon";
import Refresh from "../../../../helpers/refresh";

const GRoundStar = `${process.env.REACT_APP_RESOURCE_URL}/LG_roundStar.svg`
const YStarRound = `${process.env.REACT_APP_RESOURCE_URL}/Y_star_round.svg`
const GTimer18 = `${process.env.REACT_APP_RESOURCE_URL}/G_timer18*18.svg`
const InfoNote = `${process.env.REACT_APP_RESOURCE_URL}/info_note.svg`

const cx = classnames.bind(styles)

const RehabCardProgram = ({ program, isImagesLoaded }) => {
  const staff = useSelector(state => {
    return state.staff.value
  })
  const activeFilterRehabId = useSelector(state => {
    return state.rehab.value.activeFilterRehabId
  })

  const wrapperRef = useRef(null)
  const [isRefresh, setIsRefresh] = useState(false)

  const [modalProgramDetailContent, setModalProgramDetailContent] = useState({
    header: '',
    program: {},
  })
  const [isModalProgramDetailOpen, setIsModalProgramDetailOpen] = useState(false)

  const toggleBookmark = (e, pid) => {
    e.stopPropagation()
    let body = {
      programId: pid
    }
    if(staff.bookmarkProgram.includes(pid)) {
      api.deleteStaffBookmark(body).then(() => {
        setIsRefresh(true)
      }).catch((e) => {
        console.log(e)
      })
    } else {
      api.postStaffBookmark(body).then(() => {
        setIsRefresh(true)
      }).catch((e) => {
        console.log(e)
      })
    }
  }

  const openProgramDetailModal = () => {
    setModalProgramDetailContent({
      header: '미리보기',
      program: program,
    })

    setIsModalProgramDetailOpen(true)
  }

  return (
    <div className={cx('rehabCardProgram')} onClick={() => openProgramDetailModal()}>
      {isRefresh && <Refresh isRedirectHome={false} isUpdateToken={false}/>}
      <div className={cx('programItemWrapper')}>
        <div className={cx('iconBox')}>
          <div onClick={(e) => toggleBookmark(e, program.pid)}>
            <img src={staff.bookmarkProgram.includes(program.pid) ? YStarRound : GRoundStar} alt='star'/>
          </div>
        </div>
        <div ref={wrapperRef} className={cx('programItemImgBox')}>
          { Object.keys(program.exerciseMap).slice(0, 4).map((exercise) => (
            <div key={exercise} className={cx('programItem')}>
              <img className={cx('exImage')} src={program.exerciseMap[exercise].urls.IT + imgSize['180']} alt='exercise'/>
            </div>
          )) }
        </div>
        <div className={cx('programTitleBox')}>
          <img src={InfoNote} alt='InfoNote'/>
          <div className={cx('programInfo')}>
            <p>
              { program.tag.MP && (
                program.tag.MP.map((mp, idx) => (
                  <span key={idx}>{ tagKor['MP'][mp] }{ idx !== program.tag.MP.length - 1 ? ' / ' : '' }</span>
                ))
              ) }
            </p>
            <h6>{ program.name }</h6>
            <span className={cx('programReferenceBox')}>
              <img src={GTimer18} alt='Timer'/>
              { program.time }분
              { activeFilterRehabId === 2 && program.share ? ' · 전체공개' : activeFilterRehabId === 2 && !program.share ? ' · 비공개' : ''  }
              { (activeFilterRehabId === 1 && program.creator) && (
                <span>
                  <span className={cx('dot')}> · </span>
                  <JobIcon job={program.creator.job} isCertified={program.creator.isCertified}/>
                  <span className={cx('name')}>
                    {program.creator.name}
                  </span>
                    { program.creator.phoneNumber && (
                      <span className={cx('phone')}>({ program.creator.phoneNumber ? program.creator.phoneNumber.slice(7, 11) : '-' })</span>
                    ) }
                 </span>
              ) }
            </span>
          </div>
        </div>
      </div>
      { isModalProgramDetailOpen && (
        <ModalProgramDetail
          header={modalProgramDetailContent.header}
          program={modalProgramDetailContent.program}
          isModalProgramDetailOpen={isModalProgramDetailOpen}
          setIsModalProgramDetailOpen={setIsModalProgramDetailOpen}
        />
      ) }
    </div>
  );
};

export default RehabCardProgram;