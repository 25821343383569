import {createSlice} from "@reduxjs/toolkit";

const programSlice = createSlice({
  name: 'programSlice',
  initialState: { value: {
      // 0: 운동 선택, 1: 프로그램 정보
      programCreationStep: 0,
      programCreationParams: {
        compressedExScript: [],
        diagnosis: '',
        name: '',
        phase: '',
        precaution: '',
        share: true,
        tags: [],
      },
      addedProgramExerciseArr: [],
      isMyProgramsUpdate: false,
      myProgramIdToEdit: null,
    }
    },
  reducers: {
    setProgramCreationStep: (state, action) => {
      state.value.programCreationStep = action.payload
    },
    setProgramCreationParams: (state, action) => {
      state.value.programCreationParams = action.payload
    },
    setAddedProgramExerciseArr: (state, action) => {
      state.value.addedProgramExerciseArr = action.payload
    },
    setIsMyProgramsUpdate: (state, action) => {
      state.value.isMyProgramsUpdate = action.payload
    },
    setMyProgramIdToEdit: (state, action) => {
      state.value.myProgramIdToEdit = action.payload
    },


    resetAddedProgramExerciseArr: (state) => {
      state.value.addedProgramExerciseArr = []
    },
    resetProgramCreationParams: (state) => {
      state.value.programCreationParams = {
        compressedExScript: [],
        diagnosis: '',
        name: '',
        phase: '',
        precaution: '',
        share: true,
        tags: [],
      }
    },
  }
})

export default programSlice
export const {setProgramCreationStep, setProgramCreationParams, resetProgramCreationParams, setAddedProgramExerciseArr, resetAddedProgramExerciseArr, setIsMyProgramsUpdate, setMyProgramIdToEdit } = programSlice.actions