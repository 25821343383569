import React, {useEffect, useState} from 'react';
import styles from './banner.module.scss'
import classnames from 'classnames/bind';
import { useNavigate} from "react-router-dom";
import api from "@/api";
const cx = classnames.bind(styles)
const close = `${process.env.REACT_APP_RESOURCE_URL}/close.svg`

const Banner = () => {
  const navigate = useNavigate()
  const [noticeId, setNoticeId] = useState(0)
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState('')

  const goToDetail = () => {
    navigate(`/terms/notice/${noticeId}`)
  }

  const closeNotice = (e) => {
    e.stopPropagation()
    let date = new Date()
    let todayMidnight = date.setHours(23,59,59,999)
    let objData = {
      noticeId: noticeId,
      timestamp: todayMidnight
    }
    localStorage.setItem('banner', JSON.stringify(objData))
    setShow(false)
  }

  const showBanner = () => {
    let timestampBanner = localStorage.getItem('banner')
    if(timestampBanner) {
      let objData = JSON.parse(timestampBanner)

      let timestampNow = new Date().getTime()
      if((timestampNow < objData.timestamp) && (objData.noticeId === noticeId)) {
        setShow(false)
      } else {
        setShow(true)
      }
    } else {
      setShow(true)
    }
  }

  const getNotice = () => {
    api.getNoticeList('', true, 0, 1).then((res) => {
      if(res.data.list.length) {
        setTitle(res.data.list[0].title)
        setNoticeId(res.data.list[0].id)
      }
    })
  }

  useEffect(() => {
    if(noticeId) {
      showBanner()
    }
  }, [noticeId]);

  useEffect(() => {
    getNotice()
  }, []);


  return (
    <div className={cx('banner', show && 'show')} onClick={goToDetail}>
      <div>
        <p>📜 {title}</p>
        <img src={close} alt='close' onClick={(e) => closeNotice(e)}/>
      </div>

    </div>
  );
};

export default Banner;