import React, {useEffect, useState} from 'react';
import classnames from "classnames/bind";
import styles from './pagination.module.scss'

const cx = classnames.bind(styles)
const pageCount = 10

const Pagination = ({ currentPage, totalPage, getList }) => {
  const [idx, setIdx] = useState(Math.floor( (currentPage - 1) / pageCount))
  const [pages, setPages] = useState([])

  const onFirstClick = () => {
    setIdx(0)
    getList(1)
  }

  const onPrevClick = () => {
    getList(currentPage - 1)
    if(currentPage % 10 === 1) {
      setIdx(idx - 1)
    }
  }

  const onPageClick = (paging) => {
    getList(paging)
  }

  const onNextClick = () => {
    getList(currentPage + 1)
    if(currentPage % 10 === 0) {
      setIdx(idx + 1)
    }
  }

  const onLastClick = () => {
    setIdx(pages.length - 1)
    getList(totalPage)
  }

  useEffect(() => {
    let numArr = []
    let newPages = []
    for(let i = 1; i <= totalPage; i += 1) {
      numArr.push(i)
    }
    for(let i = 0; i < numArr.length; i += pageCount) {
      newPages.push(numArr.slice(i, i + pageCount))
    }

    setPages(newPages)
  }, [])

  return (
    <div className={cx('pagination')}>
      { idx > 0 && <div className={cx('btn', 'first')} onClick={onFirstClick}></div> }
      { idx > 0 && <div className={cx('btn', 'prev')} onClick={onPrevClick}></div> }
      <div>
        { pages.length &&
          pages[idx].map((paging, i) => (
          <span key={i} className={cx(currentPage === paging && 'focus')} onClick={() => onPageClick(paging)}>{ paging }</span>
        )) }
      </div>
      { !(pages.length === 0 || idx === pages.length - 1) && <div className={cx('btn', 'next')} onClick={onNextClick}></div> }
      { !(pages.length === 0 || idx === pages.length - 1) && <div className={cx('btn', 'last')} onClick={onLastClick}></div> }
    </div>
  );
};

export default Pagination;