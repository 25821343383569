import React from 'react';
import styles from './buttonLight.module.scss'
import classnames from 'classnames/bind';

const cx = classnames.bind(styles)

const ButtonLight = ({ text, onButtonLightClick }) => {

  return (
    <button className={cx('buttonLight')} onClick={() => onButtonLightClick()}>
      <span>{ text }</span>
    </button>
  );
};

export default ButtonLight;