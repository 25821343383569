import React from 'react';
import './statisticsDonutChart.module.scss'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const StatisticsDonutChart = ({number}) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: '',
        data: [number, 100],
        backgroundColor: [
          "#07BEB8",
          '#F2F2F2'
        ],
        hoverBackgroundColor: [
          "#04AFAB",
          '#F2F2F2'
        ],
        borderWidth: 0,
        borderAlign: 'inner',
        borderJoinStyle: 'round',
        weight: 10,
      },
    ],
  }

  const options = {
    plugins: {
      tooltip: {
        enabled: false
      }
    },
    cutout: 43,
    elements: {
      arc: {
        roundedCornersFor: 0
      },
    },
    legend: {
      display: false
    },
    responsive: true,
  }

  return (
    <Doughnut
      data={data}
      options={options}
    />
  );
};

export default StatisticsDonutChart;