import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import api from "@/api";
import classnames from "classnames/bind";
import styles from './cards.module.scss'
import ModalBig from "../../../common/modal/modalBig/modalBig";
import {setIsModalBigBtnActive} from "../../../../store/slice/modalBtnSlice";
import {showToast} from "../../../../helpers/toastMessage";
import {checkIsActiveCardExist, setActiveCardId} from "../../../../store/slice/paymentSlice";
import ModalSmall from "../../../common/modal/modalSmall/modalSmall";

const GPlus = `${process.env.REACT_APP_RESOURCE_URL}/G_plus.svg`
const PCheck = `${process.env.REACT_APP_RESOURCE_URL}/P_check.svg`

const cx = classnames.bind(styles)
const maxCardInfoNumber = 19
const maxCardInfoMonth = 2
const maxCardInfoYear = 2
const maxCardInfoPwd = 2
const maxCardInfoBusinessId = 10
const maxCardInfoPersonalId = 6

const Cards = ({ height, marginRight, getTotalPriceToPay }) => {
  const dispatch = useDispatch()
  const staff = useSelector(state => {
    return state.staff.value
  })

  const [cardListKey, setCardListKey] = useState(0)
  const [cardList, setCardList] = useState([])
  const [isDefaultCardBtnActive, setIsDefaultCardBtnActive] = useState(true)
  const [modalBigContent, setModalBigContent] = useState({
    header: '',
    btnSmall: '',
    btnBig: '',
    closeModalBig: null,
    onAction: null,
    values: {},
  })
  const [isModalBigOpen, setIsModalBigOpen] = useState(false)
  const [modalCardInfo, setModalCardInfo] = useState({
    number: '',
    month: '',
    year: '',
    pwd: '',
    personalId: '',
  })
  const [isCardAddLoading, setIsCardAddLoading] = useState(false)
  const [errorTxt, setErrorTxt] = useState({
    number: false,
    month: false,
    year: false,
    pwd: false,
    id: false,
  })
  const [modalSmallContent, setModalSmallContent] = useState({
    bodyTitle: '',
    bodySub: '',
    isOneBtn: false,
    btnLongTxt: '',
    btnLongEvent: null,
    btnShortTxt: '',
    btnShortEvent: null,
    values: {},
  })
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [emptyCardListLength, setEmptyCardListLength] = useState(0)

  const getCardList = () => {
    if(!staff.rehabGroupId) {
      return
    }
    api.getRehabGroupCard(staff.rehabGroupId).then((res) => {
      let newCardList = res.data
      newCardList.forEach((card) => {
        card.representativeCard = card.representativeCard === 'Y'
        card.active = card.representativeCard
        dispatch(checkIsActiveCardExist(true))
        if(card.active) {
          dispatch(setActiveCardId(card.id))
        }
      })
      setIsDefaultCardBtnActive(true)

      let maxCardListLength = 3
      if(newCardList.length) {
        if(newCardList.length > maxCardListLength) {
          newCardList.splice(maxCardListLength, newCardList.length - maxCardListLength)
        } else if(newCardList.length < maxCardListLength) {
          setEmptyCardListLength(maxCardListLength - newCardList.length)
        } else if(newCardList.length === maxCardListLength) {
          setEmptyCardListLength(0)
        }
      } else {
        dispatch(checkIsActiveCardExist(false))
        dispatch(setActiveCardId(null))
      }
      setCardList(newCardList)
    }).catch((err) => {
      console.log(err)
    })
  }

  const checkIsAllFilled = () => {
    let bool = true

    Object.keys(modalCardInfo).forEach((info) => {
      if(!modalCardInfo[info]) {
        bool = false
        return
      }
    })

    return bool && !isCardAddLoading
  }

  const confirmModal = (values) => {
    let newErrTxt = {
      number: false,
      month: false,
      year: false,
      pwd: false,
      id: false,
    }

    if(values.number.length !== maxCardInfoNumber) {
      newErrTxt.number = true
    } else {
      newErrTxt.number = false
    }
    if(values.month.length !== maxCardInfoMonth) {
      newErrTxt.month = true
    } else {
      newErrTxt.month = false
    }
    if(values.year.length !== maxCardInfoYear) {
      newErrTxt.year = true
    } else {
      newErrTxt.year = false
    }
    if(values.pwd.length !== maxCardInfoPwd) {
      newErrTxt.pwd = true
    } else {
      newErrTxt.pwd = false
    }
    if(values.personalId.length === maxCardInfoBusinessId || values.personalId.length === maxCardInfoPersonalId) {
      newErrTxt.id = false
    } else {
      newErrTxt.id = true
    }

    setErrorTxt(newErrTxt)

    let isAllValid = true
    Object.keys(newErrTxt).forEach((err) => {
      if(newErrTxt[err]) {
        isAllValid = false
      }
    })

    if(isAllValid) {
      addCard(values)
    }
  }

  const addCard = (values) => {
    let body = {
      "rehabGroupId": staff.rehabGroupId,
      "cardNumber": values.number,
      "cardExpirationYear": values.year,
      "cardExpirationMonth": values.month,
      "cardPassword": values.pwd,
      "identityNumber": values.personalId
    }
    setIsCardAddLoading(true)
    api.postRehabGroupCard(staff.rehabGroupId, body).then(() => {
      setCardListKey((prev) => prev += 1)
      getCardList()
      showToast('success', '카드가 추가되었습니다.')
      setIsModalBigOpen(false)
      dispatch(setIsModalBigBtnActive(false))
      setIsCardAddLoading(false)
    }).catch((err) => {
      if(err.response.status === 503) {
        showToast('error', err.response.data.extra.message)
      }
      setIsCardAddLoading(false)
    })
  }

  const openModalBig = () => {
    if(cardList.length < 3) {
      setModalBigContent({
        header: '카드 추가',
        btnSmall: '닫기',
        btnBig: '추가하기',
        closeModalBig: () => setIsModalBigOpen(false),
        onAction: (values) => confirmModal(values),
      })
      setIsModalBigOpen(true)
    }
  }

  const setDefaultCard = (e, cardId) => {
    console.log(e)
    e.stopPropagation()
    if(isDefaultCardBtnActive) {
      setIsDefaultCardBtnActive(false)
      api.postRehabGroupCardPrimary(staff.rehabGroupId, cardId).then(() => {
        getCardList()
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const selectCard = (card) => {
    if(!getTotalPriceToPay) {
      return
    }
    setModalCardInfo({...card})

    let newCardList = cardList
    newCardList.forEach((cardItem) => {
      cardItem.active = false
      if(cardItem === card) {
        cardItem.active = true
        dispatch(checkIsActiveCardExist(true))
        dispatch(setActiveCardId(cardItem.id))
      }
    })

    setCardList([...newCardList])
    setCardListKey((prev) => prev += 1)
  }

  const deleteCard = (values) => {
    api.deleteRehabGroupCard(staff.rehabGroupId, values.cardId).then(() => {
      setCardListKey((prev) => prev += 1)
      getCardList()
      showToast('error', '카드가 삭제되었습니다.')
      setIsModalSmallOpen(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  const openModalSmall = (cardId) => {
    setModalSmallContent({
      isOneBtn: false,
      bodyTitle: '해당 카드를 삭제하시겠습니까?',
      bodySub: '삭제 진행 시 복구할 수 없으니 <br/>신중히 진행해 주세요.',
      btnSecondTxt: '취소',
      btnFirstTxt: '삭제하기',
      btnSecondEvent: () => setIsModalSmallOpen(false),
      btnFirstEvent: (values) => deleteCard(values),
      values: {
        cardId: cardId
      }
    })
    setIsModalSmallOpen(true)
  }

  const onNumberChange = (e) => {
    setModalCardInfo({
      ...modalCardInfo,
      number: e.target.value.replace(/[^0-9]/g, '').replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, '$1-$2-$3-$4')
    })
  }

  const onMonthChange = (e) => {
    setModalCardInfo({
      ...modalCardInfo,
      month: e.target.value.replace(/[^0-9]/g, '').replace(/(\d{2})/g, '$1')
    })
  }

  const onYearChange = (e) => {
    setModalCardInfo({
      ...modalCardInfo,
      year: e.target.value.replace(/[^0-9]/g, '').replace(/(\d{2})/g, '$1')
    })
  }

  const onPwdChange = (e) => {
    setModalCardInfo({
      ...modalCardInfo,
      pwd: e.target.value.replace(/[^0-9]/g, '').replace(/(\d{2})/g, '$1')
    })
  }

  const onPersonalIdChange = (e) => {
    setModalCardInfo({
      ...modalCardInfo,
      personalId: e.target.value.replace(/[^0-9]/g, '').replace(/(\d{10})/g, '$1')
    })
  }

  useEffect(() => {
    dispatch(setIsModalBigBtnActive(checkIsAllFilled()))
    setModalBigContent({
      ...modalBigContent,
      values: ({...modalCardInfo})
    })
  }, [modalCardInfo])

  useEffect(() => {
    getCardList()
  }, [staff])

  useEffect(() => {
    setModalCardInfo({
      number: '',
      month: '',
      year: '',
      pwd: '',
      personalId: '',
    })

    setErrorTxt({
      number: false,
      month: false,
      year: false,
      pwd: false,
      id: false,
    })
  }, [isModalBigOpen])

  return (
    <div className={cx('cards')}>
      <div className={cx('cardEmptyBoxes', cardList.length && 'inactive')} style={{ height: height + 'px' }} onClick={openModalBig} key={cardListKey}>
        { [1, 2, 3].map((card) => (
          <div key={card} style={{ width: `calc((100% - ${marginRight}px) / 3)` }} className={cx('emptyCards')}>
            <img src={GPlus} alt='GPlus'/>
            <p>등록된 카드가 없습니다.</p>
          </div>
        )) }
      </div>
      <div className={cx('cardBoxes', !cardList.length && 'inactive')} style={{ height: height + 'px' }} key={cardListKey + Math.random()}>
        { cardList.map((card) => (
          <div className={cx('cardsWrapper', (card.active && getTotalPriceToPay) && 'active')} onClick={() => selectCard(card)} key={card.id} style={{ width: `calc((100% - ${marginRight}px) / 3)` }}>
            <div className={cx(!card.representativeCard && 'inactive', 'content')}>
              <div className={cx('cardDefault')}>
                <img src={PCheck} alt="P_check"/>
                <span>기본 결제 카드</span>
              </div>
            </div>
            <div className={cx(card.representativeCard && 'inactive', 'content')}>
              <button className={cx('defaultBtn', !isDefaultCardBtnActive && 'inactive')} onClick={(e) => setDefaultCard(e, card.id)}>기본으로 설정</button>
              <p className={cx('cardBtns')}>
                <button className={cx('deleteBtn')} onClick={ () => openModalSmall(card.id) }>삭제</button>
              </p>
            </div>
            <p className={cx('cardName')}>{card.cardName}</p>
            <p className={cx('cardNo')}>
              <span>****</span>
              <span>****</span>
              <span>****</span>
              <span>{card.cardLastNumber}</span>
            </p>
          </div>
        )) }
        { emptyCardListLength ? Array.apply(null, Array(emptyCardListLength)).map((_, idx) => (
          <div key={idx + Math.random()} className={cx('emptyCards')} onClick={openModalBig} style={{ width: `calc((100% - ${marginRight}px) / 3)` }}>
            <img src={GPlus} alt="GPlus"/>
            <p>등록된 카드가 없습니다.</p>
          </div>
        )) : '' }
      </div>
      <ModalBig
        header={modalBigContent.header}
        btnSmall={modalBigContent.btnSmall}
        btnBig={modalBigContent.btnBig}
        closeModalBig={modalBigContent.closeModalBig}
        isModalBigOpen={isModalBigOpen}
        onAction={modalBigContent.onAction}
        values={modalBigContent.values}
      >
        <div className={cx('addCardInputBox')}>
          <div className={cx('cardInfo1')}>
            <p>카드 번호</p>
            <input
              type='text'
              maxLength={maxCardInfoNumber}
              value={modalCardInfo.number || ''}
              onChange={onNumberChange}
            />
            <p className={cx('error', !errorTxt.number && 'inactive')}>카드번호를 확인해주세요.</p>
          </div>
          <div className={cx('cardInfo2')}>
            <div>
              <p>카드 유효 기간</p>
              <input
                className={cx('small', 'margin8')}
                type="text"
                placeholder="월"
                maxLength={maxCardInfoMonth}
                value={modalCardInfo.month || ''}
                onChange={onMonthChange}
              />
              <input
                className={cx('small')}
                type="text"
                placeholder="년"
                maxLength={maxCardInfoYear}
                value={modalCardInfo.year || ''}
                onChange={onYearChange}
              />
              <p className={cx('error', (!errorTxt.month || !errorTxt.year) && 'inactive')}>카드 유효 기간을 확인해주세요.</p>
            </div>
            <div>
              <p>카드 비밀번호 앞 2자리</p>
              <input
                className={cx('medium')}
                type="password"
                maxLength={maxCardInfoPwd}
                value={modalCardInfo.pwd || ''}
                onChange={onPwdChange}
              />
              <p className={cx('error', !errorTxt.pwd && 'inactive')}>카드 비밀번호를 확인해주세요.</p>
            </div>
          </div>
          <div className={cx('cardInfo3')}>
            <p>주민번호 앞 6자리 또는 사업자번호 10자리</p>
            <input
              className={cx('cardId')}
              type="text"
              maxLength={maxCardInfoBusinessId}
              value={modalCardInfo.personalId || ''}
              onChange={onPersonalIdChange}
            />
            <p className={cx('error', !errorTxt.id && 'inactive')}>주민번호 또는 사업자번호를 확인해주세요.</p>
          </div>
        </div>
      </ModalBig>
      { isModalSmallOpen && (
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          bodySub={modalSmallContent.bodySub}
          isOneBtn={modalSmallContent.isOneBtn}
          btnSecondTxt={modalSmallContent.btnSecondTxt}
          btnFirstTxt={modalSmallContent.btnFirstTxt}
          btnSecondEvent={modalSmallContent.btnSecondEvent}
          btnFirstEvent={modalSmallContent.btnFirstEvent}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}
          values={modalSmallContent.values}
        />
      ) }
    </div>
  );
};

export default Cards;