import React from 'react';
import classnames from "classnames/bind";
import styles from './statisticsCard.module.scss'

const PStaff = `${process.env.REACT_APP_RESOURCE_URL}/P_staff.svg`
const OrangeSquare = `${process.env.REACT_APP_RESOURCE_URL}/orange_square.svg`
const PurpleHeart = `${process.env.REACT_APP_RESOURCE_URL}/purple_heart.svg`
const PurpleStaff = `${process.env.REACT_APP_RESOURCE_URL}/purple_staff.svg`

const cx = classnames.bind(styles)
const StatisticsCard = ({card, cardWidth}) => {
  return (
    <div className={cx('statisticsCard')} style={{ width: cardWidth && cardWidth + 'px' }}>
      <div className={cx('cardTitle')}>
        <img src={card.img === 'staff' ? PStaff : card.img === 'square' ? OrangeSquare : card.img === 'heart' ? PurpleHeart : PurpleStaff} alt='icon'/>
        <span>{ card.text }</span>
      </div>
      <p className={cx(card.id === 1 && 'orange', card.id === 2 && 'purple')}>{ card.number }</p>
    </div>
  );
};

export default StatisticsCard;