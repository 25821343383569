import React, {useEffect, useState} from 'react';
import styles from './dateSelect.module.scss'
import classnames from "classnames/bind";
import {useDispatch, useSelector} from "react-redux";
import {
  setLastEditedPlanSchedule,
  setPlanAssignmentStep,
  setPlanSchedule
} from "../../../store/slice/planSlice";
import {formatDate} from "../../../helpers/common";
import api from "@/api";
import {useLocation} from "react-router-dom";
import {showToast} from "../../../helpers/toastMessage";

const PlanCalendar = `${process.env.REACT_APP_RESOURCE_URL}/plan_calendar.svg`

const cx = classnames.bind(styles)
const date = new Date();
const today = {
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  date: date.getDate(),
}
const daysKor = ['일', '월', '화', '수', '목', '금', '토']

const DateSelect = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const addedPlanExerciseArr = useSelector(state => {
    return state.plan.value.addedPlanExerciseArr
  })
  const planSchedule = useSelector(state => {
    return state.plan.value.planSchedule
  })
  const planAssignmentParams = useSelector(state => {
    return state.plan.value.planAssignmentParams
  })
  const isBackToCalendar = useSelector(state => {
    return state.plan.value.isBackToCalendar
  })
  const lastEditedPlanSchedule = useSelector(state => {
    return state.plan.value.lastEditedPlanSchedule
  })

  const [activeTypeId, setActiveTypeId] = useState(0)
  const [thisMonthCalendar, setThisMonthCalendar] = useState({
    year: today.year,
    month: today.month,
    dates: []
  })
  const [nextMonthCalendar, setNextMonthCalendar] = useState({
    year: today.month !== 12 ? today.year : today.year + 1,
    month: today.month !== 12 ? today.month + 1 : 1,
    dates: []
  })
  const [selectedDates, setSelectedDates] = useState([])
  const [planDatesWrap, setPlanDatesWrap] = useState('')
  const [plansDisabledDates, setPlansDisabledDates] = useState([])
  const [dateThreeMonthLater, setDateThreeMonthLater] = useState('')
  const [isNextArrowActive, setIsNextArrowActive] = useState(true)
  const [exDatesWithoutLastEdited, setExDatesWithoutLastEdited] = useState([])
  const [lastExDates, setLastExDates] = useState([])

  const checkIsNextArrowActive = (nextCalendarMonth) => {
    let monthThreeMonthLater = dateThreeMonthLater.slice(4, 6)
    let nextMonth = ('0' + nextCalendarMonth.toString()).slice(0, 2)

    if(monthThreeMonthLater === nextMonth) {
      setIsNextArrowActive(false)
    } else {
      setIsNextArrowActive(true)
    }
  }

  const setCalendars = (isPrevClicked = false, isNextClicked = false) => {
    let thisCalendarYear = thisMonthCalendar.year
    let thisCalendarMonth = thisMonthCalendar.month

    let nextCalendarYear = nextMonthCalendar.year
    let nextCalendarMonth = nextMonthCalendar.month

    if(isPrevClicked) {
      if(thisCalendarYear === today.year && thisCalendarMonth === today.month) {
        return
      }

      thisCalendarMonth = thisCalendarMonth -= 1
      nextCalendarMonth = nextCalendarMonth -= 1

      if(thisCalendarMonth === 0) {
        thisCalendarYear = thisCalendarYear - 1
        thisCalendarMonth = 12
      }

      if(nextCalendarMonth === 0) {
        nextCalendarYear = nextCalendarYear - 1
        nextCalendarMonth = 12
      }
    } else if(isNextClicked) {
      thisCalendarMonth = thisCalendarMonth += 1
      nextCalendarMonth = nextCalendarMonth += 1

      if(thisCalendarMonth === 13) {
        thisCalendarYear = thisCalendarYear + 1
        thisCalendarMonth = 1
      }

      if(nextCalendarMonth === 13) {
        nextCalendarYear = nextCalendarYear + 1
        nextCalendarMonth = 1
      }
    }

    checkIsNextArrowActive(nextCalendarMonth)

    const firstDayOfThisMonth = new Date(thisCalendarYear, thisCalendarMonth - 1, 1).getDay()
    const lastDateOfThisMonth = new Date(thisCalendarYear, thisCalendarMonth,0).getDate()
    const lastDateOfLastMonth = new Date(thisCalendarYear, thisCalendarMonth - 1, 0).getDate()
    const firstDateOfThisCalendar = lastDateOfLastMonth - firstDayOfThisMonth + 1

    const firstDayOfNextMonth = new Date(nextCalendarYear, nextCalendarMonth - 1, 1).getDay()
    const lastDateOfNextMonth = new Date(nextCalendarYear, nextCalendarMonth, 0).getDate()
    const firstDateOfNextCalendar = lastDateOfThisMonth - firstDayOfNextMonth + 1

    makeCalendar(firstDayOfThisMonth, lastDateOfThisMonth, firstDateOfThisCalendar, thisCalendarYear, thisCalendarMonth, 'this')
    makeCalendar(firstDayOfNextMonth, lastDateOfNextMonth, firstDateOfNextCalendar, nextCalendarYear, nextCalendarMonth, 'next')
  }

  const makeCalendar = (firstDayOfTheMonth, lastDateOfTheMonth, firstDateOfTheCalendar, year, month, type) => {
    let week = []
    let weeks = []
    let date = 1
    while (date <= lastDateOfTheMonth) {
      // 첫 주
      if(date === 1) {
        for(let i = 0; i < firstDayOfTheMonth; i++){
          week.push('')
          firstDateOfTheCalendar++
        }

        const length = week.length
        const emptyWeek = 7 - length
        if (length >= 0 && length < 7) {
          for (let i = 1; i <= emptyWeek; i++) {
            week.push(i)
          }
        }
      }

      if(week.length === 7) {
        weeks.push(week)
        date = week[6]
        week = []
      } else if(week.length < 7 && week.indexOf(lastDateOfTheMonth) > -1 ) {
        const length = week.length
        const emptyWeek = 7 - length
        if (length >= 0 && length < 7) {
          for (let i = 1; i <= emptyWeek; i++) {
            week.push('')
          }
        }
        weeks.push(week)
        break
      }
      date++

      if(week.length <= 7) {
        week.push(date)
      }
    }

    if(type === 'this') {
      setThisMonthCalendar({
        year: year,
        month: month,
        dates: weeks
      })
    } else {
      setNextMonthCalendar({
        year: year,
        month: month,
        dates: weeks
      })
    }

  }


  // --------------class
  const todayColor = (date) => {
    if(date === today.date && thisMonthCalendar.year === today.year && thisMonthCalendar.month === today.month) {
      return true
    }
  }

  const todayDate = (date) => {
    if(new Date(thisMonthCalendar.year, thisMonthCalendar.month - 1, date).getTime() === new Date(today.year, today.month - 1, today.date).getTime()) {
      return true
    }
  }

  const sunDayColor = (dateIdx) => {
    if(dateIdx === 0) {
      return true
    }
  }

  const disable = (date, type) => {
    let isPastDay = false // 과거 날짜
    let isLaterThanThreeMonth = false // 3개월 이상 지난 날짜

    let thisMonthDateStr = `${thisMonthCalendar.year}${thisMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`
    let nextMonthDateStr = `${nextMonthCalendar.year}${nextMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`

    if(type === 'this') {
      if(new Date(thisMonthCalendar.year, thisMonthCalendar.month - 1, date).getTime() < new Date(today.year, today.month - 1, today.date).getTime()) {
        isPastDay = true
      }
    }

    if(type === 'this' && Number(dateThreeMonthLater) < Number(thisMonthDateStr)) {
      isLaterThanThreeMonth = true
    }
    if(type === 'next' && Number(dateThreeMonthLater) < Number(nextMonthDateStr)) {
      isLaterThanThreeMonth = true
    }

    return isPastDay || isLaterThanThreeMonth
  }

  const disableCircle = (date, type) => {
    let isPlanDisableDay = false // 같은 부위에 이미 배정된 날짜
    let isAnotherExDate = false // 일자별 플랜 정보 > 뒤로 > 마지막 운동 일정 외의 날짜

    let thisMonthDateStr = `${thisMonthCalendar.year}${thisMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`
    let nextMonthDateStr = `${nextMonthCalendar.year}${nextMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`

    if(type === 'this') {
      if(plansDisabledDates.length) {
        if(plansDisabledDates.includes(thisMonthDateStr)) {
          isPlanDisableDay = true
        }
      }
      if(exDatesWithoutLastEdited.includes(thisMonthDateStr)) {
        isAnotherExDate = true
      }
    } else {
      if(plansDisabledDates.length) {
        if(plansDisabledDates.includes(nextMonthDateStr)) {
          isPlanDisableDay = true
        }
      }
      if(exDatesWithoutLastEdited.includes(nextMonthDateStr)) {
        isAnotherExDate = true
      }
    }

    return isPlanDisableDay || isAnotherExDate
  }

  const nextDate = (datesIdx) => {
    if(datesIdx === thisMonthCalendar.dates.length - 1 && date < thisMonthCalendar.dates[thisMonthCalendar.dates.length - 1][0]) {
      return true
    }
  }

  const selectDate = (date) => {
    if(selectedDates.length) {
      if(selectedDates.find((v) => v === `${thisMonthCalendar.year}${thisMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`)) {
        return true
      }
    }
  }

  const nextSelectDate = (date) => {
    if(selectedDates.length) {
      if(selectedDates.find((v) => v === `${nextMonthCalendar.year}${nextMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`)) {
        return true
      }
    }
  }

  const serialDate = (date, type) => {
    let result = ''

    if (activeTypeId === 0 && selectedDates.length > 2) {
      let ymd = ''
      if (type === 'this') {
        ymd = `${thisMonthCalendar.year}${thisMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`
      } else {
        ymd = `${nextMonthCalendar.year}${nextMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`
      }

      if (selectedDates.includes(ymd) && ymd !== selectedDates[0] && ymd !== selectedDates[selectedDates.length - 1]) {
        result = 'serialMiddle'
      } else if(selectedDates.includes(ymd) && ymd === selectedDates[0]) {
        result = 'serialStart'
      } else if (selectedDates.includes(ymd) && ymd === selectedDates[selectedDates.length - 1]) {
        result = 'serialEnd'
      }
    }

    return result
  }

  // class--------------

  const clickThisMonthSingleDate = (date) => {
    const ymd = `${thisMonthCalendar.year}${thisMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`

    let index = selectedDates.indexOf(ymd)
    if (index >= 0) {
      setSelectedDates((prev) => (
        prev.filter((item, idx) => idx !== index)
      ))
    } else {
      setSelectedDates([
        ...selectedDates,
        ...[ymd]
      ])
    }
  }

  const goToNextStep = () => {
    if(selectedDates.length) {
      dispatch(setPlanAssignmentStep(2))
    }
  }

  const clickNextMonthSingleDate = (date) => {
    const ymd = `${nextMonthCalendar.year}${nextMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`
    let index = selectedDates.indexOf(ymd)
    if (index >= 0) {
      setSelectedDates((prev) => (
        prev.filter((item, idx) => idx !== index)
      ))
    } else {
      setSelectedDates([
        ...selectedDates,
        ...[ymd]
      ])
    }
  }

  const clickSerialDate = (date, type) => {
    let ymd = ''
    if(type === 'this') {
      ymd = `${thisMonthCalendar.year}${thisMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`
    } else {
      ymd = `${nextMonthCalendar.year}${nextMonthCalendar.month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`
    }

    let tempSelectedDates = [
      ...selectedDates,
      ...[ymd]
    ]

    if(tempSelectedDates.length === 2) {
      let serialDates = []
      let firstDateStr = tempSelectedDates[0]
      let lastDateStr = tempSelectedDates[1]
      let firstDateTimestamp = new Date(`${firstDateStr.slice(0, 4)}-${firstDateStr.slice(4, 6)}-${firstDateStr.slice(6, 8)}`).getTime()
      let lastDateTimestamp = new Date(`${lastDateStr.slice(0, 4)}-${lastDateStr.slice(4, 6)}-${lastDateStr.slice(6, 8)}`).getTime()

      if(firstDateTimestamp > lastDateTimestamp) {
        serialDates = [lastDateStr]
      } else {
        for(let i = firstDateTimestamp; i <= Number(lastDateTimestamp); i += 86400000) {
          let newDate = new Date(i)
          let yyyy = newDate.getFullYear()
          let mm = ('0' + (newDate.getMonth() + 1)).slice(-2)
          let dd = ('0' + newDate.getDate()).slice(-2)
          serialDates.push(`${yyyy}${mm}${dd}`)
        }
      }

      let isStop = false
      let allDisabledDates = [...plansDisabledDates, ...exDatesWithoutLastEdited]
      allDisabledDates = [...new Set(allDisabledDates)]
      allDisabledDates.forEach((disabledDate) => {
        serialDates.forEach((serialDate) => {
          if(!isStop) {
            if(disabledDate === serialDate) {
              setSelectedDates([])
              isStop = true
              showToast('error', '이미 배정된 플랜이 있는 날짜는 선택이 불가능해요.')
            }
          }
        })
      })

      if(!isStop) {
        setSelectedDates(serialDates)
      }
    } else {
      setSelectedDates([ymd])
    }
  }

  const clickSelectDate = (date, type) => {
    if(!date) {
      return
    }

    if(activeTypeId === 1) {
      if(type === 'this') {
        clickThisMonthSingleDate(date)
      } else if(type === 'next') {
        clickNextMonthSingleDate(date)
      }
    } else {
      clickSerialDate(date, type)
    }
  }

  const switchTypeId = (id) => {
    setActiveTypeId(id)
    setSelectedDates([])
  }

  const getPlanList = () => {
    let patientId = location.pathname.split('/')[3]
    api.getPlanList(patientId).then((res) => {
      let data = res.data

      let disableDates = []
      if(data.length) {
        let planList = Object.values(data)
        planList.forEach((plan) => {
          if(planAssignmentParams.mainPart === plan.mainPart) {
            if(plan.status === 'RELEASE' || plan.status === 'IN_PROGRESS') {
              disableDates = disableDates.concat(plan.date)
            }
          }
        })
      }
      disableDates = [...new Set(disableDates)]
      setPlansDisabledDates(disableDates)
    }).catch((e) => {
      console.log(e)
    })
  }

  const getDateThreeMonthLater = () => {
    let todayMilli = new Date(`${today.year}-${today.month.toString().padStart(2, '0')}-${today.date.toString().padStart(2, '0')}`).getTime()
    let threeMonthLater = todayMilli + 7776000000

    let date = new Date(threeMonthLater)
    let year = date.getFullYear()
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let day = ("0" + date.getDate()).slice(-2)

    setDateThreeMonthLater(`${year}${month}${day}`)
  }

  const getExDatesWithoutLastEdited = () => {
    if(Object.keys(lastEditedPlanSchedule).length) {
      let newExDatesWithoutLastEdited = Object.keys(planSchedule).filter(date => !Object.keys(lastEditedPlanSchedule).includes(date))
      setExDatesWithoutLastEdited(newExDatesWithoutLastEdited)
      setLastExDates(Object.keys(lastEditedPlanSchedule))
    } else {
      setLastExDates(Object.keys(planSchedule))
    }
  }

  useEffect(() => {
    if(!selectedDates.length) {
      return
    }

    let datesArr = selectedDates.sort((a, b) => a - b)
    setSelectedDates(datesArr)

    if(datesArr.length === 1) {
      let dateFormat = formatDate(datesArr[0])
      setPlanDatesWrap(dateFormat)
    } else {
      if(activeTypeId === 0) {
        let firstDateFormat = formatDate(datesArr[0])
        let lastDateFormat = formatDate(datesArr[datesArr.length - 1])
        setPlanDatesWrap(firstDateFormat + ' - ' + lastDateFormat)
      } else {
        let firstDateFormat = formatDate(datesArr[0])
        setPlanDatesWrap(firstDateFormat + ' 외 ' + (datesArr.length - 1) + '일')
      }
    }

    let tempPlanSchedule = {}
    datesArr.forEach((date) => {
      tempPlanSchedule[date] = addedPlanExerciseArr
    })

    if(!isBackToCalendar) {
      dispatch(setPlanSchedule(tempPlanSchedule))
    } else {
      let tempPlanScheduleWithoutLastEdited = {}
      exDatesWithoutLastEdited.forEach((date) => {
        tempPlanScheduleWithoutLastEdited[date] = planSchedule[date]
      })

      if(Object.keys(tempPlanScheduleWithoutLastEdited).length) {
        dispatch(setLastEditedPlanSchedule(tempPlanSchedule))
      }

      dispatch(setPlanSchedule({
        ...tempPlanScheduleWithoutLastEdited,
        ...tempPlanSchedule
      }))
    }
  }, [selectedDates])

  useEffect(() => {
    setSelectedDates(lastExDates)
    let isStop = false
    lastExDates.forEach((date, idx) => {
      if(isStop || idx === lastExDates.length - 1) {
        return
      }
      let timestamp = new Date(`${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`).getTime()
      let nextTimestamp = new Date(`${lastExDates[idx + 1].slice(0, 4)}-${lastExDates[idx + 1].slice(4, 6)}-${lastExDates[idx + 1].slice(6, 8)}`).getTime()
      if(timestamp + 86400000 !== nextTimestamp) {
        setActiveTypeId(1)
        isStop = true
      }
    })
  }, [lastExDates])


  useEffect(() => {
    setCalendars()
    getPlanList()
    getDateThreeMonthLater()
    if(isBackToCalendar) {
      // 일자별 플랜 정보 > 복사 > 일자별 플랜 정보 > 뒤로 > 이전에 선택한 운동들의 일정은 수정 못하고, 마지막으로 선택한 운동의 일정들만 선택할 수 있게
      getExDatesWithoutLastEdited()
    }

    // position: sticky 작동하려면 body에 걸려있는 overflow hidden 풀어줘야함
    let body = document.getElementsByTagName('body')[0]
    body.style.overflowX = 'visible'
    return () => body.style.overflowX = 'hidden'
  }, [])

  return (
    <div className={cx('dateSelect')}>
      <div className={cx('rightWrapper')}>
        <div className={cx('type')}>
          <div className={cx(activeTypeId === 0 && 'active')} onClick={() => switchTypeId(0)}>
            <p>연속 일정 선택</p>
          </div>
          <div className={cx(activeTypeId === 1 && 'active')} onClick={() => switchTypeId(1)}>
            <p>자유 일정 선택</p>
          </div>
        </div>
        <div className={cx('optionDateWrapper')}>
          <div className={cx('optionCalender')}>

            <div className={cx('dualBox')}>
              <div className={cx('dualCalendarLeftArea')}>
                <div className={cx('dualym')}>
                  <div onClick={() => setCalendars(true)} className={cx('prev')}></div>
                  <p className={cx('year')}>{ thisMonthCalendar.year }년</p>
                  <p>{ thisMonthCalendar.month }월</p>
                </div>
                <div className={cx('dualmonthBox')}>
                  <div className={cx('dualweekBox')}>
                    { daysKor.map((day) => (
                      <div key={day}><p>{ day }</p></div>
                    )) }
                  </div>
                  <div className={cx('dualdateBox')}>
                    { thisMonthCalendar.dates.map((dates, datesIdx) => (
                      <div key={datesIdx} className={cx('dualaWeekBox')}>
                        { dates.map((date, dateIdx) => (
                          <div
                            key={dateIdx}
                            className={cx(
                              todayColor(date) && 'todayColor',
                              sunDayColor(dateIdx) && 'sunDayColor',
                              nextDate(datesIdx) && 'nextDate',
                              selectDate(date) && 'selectDate',
                              disable(date, 'this') && 'disable',
                              disableCircle(date, 'this') && 'disableCircle',
                              serialDate(date, 'this'),
                            )}
                            onClick={() => clickSelectDate(date, 'this')}>
                            <div>
                              <p>{ date }</p>
                            </div>
                          </div>
                        )) }
                      </div>
                    )) }
                  </div>
                </div>
              </div>
              <div className={cx('dualCalendarRightArea')}>
                <div className={cx('dualym')}>
                  <div onClick={() => setCalendars(false, true)} className={cx('next')}></div>
                  <p className={cx('year')}>{ nextMonthCalendar.year }년</p>
                  <p>{ nextMonthCalendar.month }월</p>
                </div>
                <div className={cx('dualmonthBox')}>
                  <div className={cx('dualweekBox')}>
                    { daysKor.map((day) => (
                      <div key={day}>
                        <p>{ day }</p>
                      </div>
                    )) }
                  </div>
                  <div className={cx('dualdateBox')}>
                    { nextMonthCalendar.dates.map((dates, datesIdx) => (
                      <div key={datesIdx} className={cx('dualaWeekBox')}>
                        { dates.map((date, dateIdx) => (
                          <div
                            key={dateIdx}
                            className={cx(
                              sunDayColor(dateIdx) && 'sunDayColor',
                              todayDate(date) && 'today',
                              nextDate(datesIdx) && 'nextDate',
                              nextSelectDate(date) && 'selectDate',
                              serialDate(date, 'next'),
                              disable(date, 'next') && 'disable',
                              disableCircle(date, 'next') && 'disableCircle',
                            )}
                            onClick={() => clickSelectDate(date, 'next')}
                          >
                            <div>
                              <p>{ date }</p>
                            </div>
                          </div>
                        )) }
                      </div>
                    )) }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cx('optionDateReset')}>
            <button className={cx(selectedDates.length && 'resetActive')} onClick={() => setSelectedDates([])}>
              <p>날짜 초기화</p>
            </button>
          </div>
        </div>

      </div>
      <div className={cx('leftWrapper')}>
        <div className={cx('floatContainer')}>
          <p className={cx('planTitle')}>플랜 일정</p>
          <p className={cx('datesToAssign')}>플랜을 배정할 날짜를 선택해 주세요.</p>
          { selectedDates.length ? (
            <p className={cx('dateType')}>{ activeTypeId === 0 ? '연속 일정' : '자유 일정' }</p>
          ) : ''}
          <div className={cx('planDates')}>
            <img src={PlanCalendar} alt='PlanCalendar'/>
            { selectedDates.length ? (
              <span className={cx('dateWrap')}>{ planDatesWrap }</span>
            ) : (
              <span className={cx('noDate')}>-</span>
            )}

          </div>
          <div className={cx('btns')}>
            <button className={cx('backBtn')} onClick={() => dispatch(setPlanAssignmentStep(0))}>뒤로</button>
            <button className={cx('nextBtn', selectedDates.length && 'active')} onClick={() => goToNextStep()}>다음</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateSelect;