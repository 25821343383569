import React, {useEffect, useState} from 'react';
import styles from './patientRegistration.module.scss'
import classnames from 'classnames/bind';
import {setIsModalBigBtnActive} from "@/store/slice/modalBtnSlice"
import {useDispatch} from "react-redux";
import {setPatientToAdd} from "../../../../store/slice/patientSlice";

const PCheckBox = `${process.env.REACT_APP_RESOURCE_URL}/P_checkbox.svg`
const GCheckbox = `${process.env.REACT_APP_RESOURCE_URL}/G_checkbox.svg`

const cx = classnames.bind(styles);

const PatientRegistration = () => {
  const dispatch = useDispatch()

  const [patientName, setPatientName] = useState('')
  const [isSearchBoxFocusName, setIsSearchBoxFocusName] = useState(true)
  const [patientPhoneNumber, setPatientPhoneNumber] = useState('')
  const [patientBirthday, setPatientBirthday] = useState('')
  const [isSearchBoxFocusPhoneNumber, setIsSearchBoxFocusPhoneNumber] = useState(false)
  const [isSearchBoxFocusBirthday, setIsSearchBoxFocusBirthday] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const onPhoneNumberChange = (e) => {
    let phoneNumber = e.target.value.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{4})(\d{4})/g, '$1-$2-$3')
    setPatientPhoneNumber(phoneNumber)
  }

  const onBirthdayChange = (e) => {
    let birthday = e.target.value.replace(/[^0-9]/g, '')
    setPatientBirthday(birthday)
  }

  const onNameChange = (e) => {
    setPatientName(e.target.value.trim())
  }

  const resetName = () => {
    setPatientName('')
  }

  const resetPhoneNumber = () => {
    setPatientPhoneNumber('')
  }

  const resetBirthday = () => {
    setPatientBirthday('')
  }

  const parseBirthday = () => {
    let yy = patientBirthday.toString().slice(0, 2)
    let currentYear = new Date().getFullYear().toString().slice(0, 2)

    let yyyy = ''
    if(Number(yy) > Number(currentYear)) {
      yyyy = '19' + yy
    } else {
      yyyy = '20' + yy
    }

    return `${yyyy}-${patientBirthday.toString().slice(2, 4)}-${patientBirthday.toString().slice(4, 6)}`
  }

  const isBirthdayValid = () => {
    if(patientBirthday.length !== 6) {
      return false
    }

    let mm = Number(patientBirthday.toString().slice(2, 4))
    if(mm > 12 || mm < 1) {
      return false
    }

    let dd = Number(patientBirthday.toString().slice(4, 6))
    if(dd > 31 || mm < 1) {
      return false
    }

    return true
  }

  useEffect(() => {
    if(patientName && patientPhoneNumber.length === 13 && isBirthdayValid() && isChecked) {
      dispatch(setIsModalBigBtnActive(true))

      let payload = {
        name: patientName,
        phoneNumber: patientPhoneNumber,
        birthday: parseBirthday(),
      }
      dispatch(setPatientToAdd(payload))
    } else {
      dispatch(setIsModalBigBtnActive(false))
    }
  }, [patientName, patientPhoneNumber, patientBirthday, isChecked])

  return (
    <div className={cx('patientRegistration')}>
      <p>이름</p>
      <input
        value={patientName}
        className={cx('nameInput', isSearchBoxFocusName && 'activeSearchBox')}
        type="text"
        placeholder="고객 이름을 입력하세요."
        onFocus={() => { setIsSearchBoxFocusName(true) }}
        onBlur={() => { setIsSearchBoxFocusName(false) }}
        onChange={onNameChange}
        autoFocus={true}
      />
      { patientName && isSearchBoxFocusName && (
        <div className={cx('close')} onMouseDown={resetName }></div>
      ) }

      <p>휴대폰 번호(숫자만 입력)</p>
      <input
        value={patientPhoneNumber}
        type="text"
        placeholder="-제외한 숫자만 입력"
        maxLength={13}
        onFocus={() => { setIsSearchBoxFocusPhoneNumber(true) }}
        onBlur={() => { setIsSearchBoxFocusPhoneNumber(false) }}
        onChange={onPhoneNumberChange}
        className={cx('phoneInput', isSearchBoxFocusPhoneNumber && 'activeSearchBox')}
      />
      { patientPhoneNumber && isSearchBoxFocusPhoneNumber && (
        <div className={cx('close', 'closePhone')} onMouseDown={resetPhoneNumber }></div>
      ) }

      <p>생년월일(6자리)</p>
      <input
        value={patientBirthday}
        type="text"
        maxLength={6}
        placeholder="예) 930812"
        onFocus={() => { setIsSearchBoxFocusBirthday(true) }}
        onBlur={() => { setIsSearchBoxFocusBirthday(false) }}
        onChange={onBirthdayChange}
        className={cx(isSearchBoxFocusBirthday && 'activeSearchBox')}
      />
      { patientBirthday && isSearchBoxFocusBirthday && (
        <div className={cx('close', 'closeBirthday')} onMouseDown={resetBirthday }></div>
      ) }

      <div className={cx('privacyBox')}>
        <div className={cx('content')}>
          <p className={cx('main')}>개인정보 제 3자 제공 동의</p>
          <p className={cx('order')}>1) 제공하는 정보: 이름, 휴대폰번호, 생년월일</p>
          <p className={cx('order')}>2) 제공받는 자: 에버엑스 주식회사</p>
          <p className={cx('order')}>3) 제공목적: 고객 등록 및 MORA 앱 다운로드 링크 문자 발송</p>
          <p className={cx('order')}>4) 보유기간: 회원탈퇴 또는 이용자의 삭제요청시까지</p>
          <p className={cx('bottom')}>고객는 개인정보 제3자 제공에 대한 동의를 거부할 권리가 있습니다.</p>
          <p className={cx('bottom')}>단 미동의시 운동 배정이 정상적으로 이루어지지 않을 수 있습니다.</p>
        </div>
        <div className={cx('check')} onClick={() => { setIsChecked(!isChecked) }}>
          <img src={isChecked ? PCheckBox : GCheckbox} alt="checkbox" />
          <span>고객의 개인정보 제3자 제공동의 의사를 확인했습니다.</span>
        </div>
      </div>
    </div>
  )}

export default PatientRegistration;