import React from 'react';
import styles from './patientDetailSkeleton.module.scss'
import classnames from 'classnames/bind';

const cx = classnames.bind(styles)
const PatientDetailSkeleton = () => {
  return (
    <>
      <div className={cx('titleContainer')}>
        <div className={cx('top1')}>
          <div className={cx('top1_1')}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={cx('top1_2')}></div>
        </div>
        <div className={cx('top2')}>
          <div className={cx('top2_1')}>
            <div className={cx('top2_1_1')}>
              <div className={cx('top2_1_1_top')}>
                <div className={cx('top2_1_1_1')}>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className={cx('top2_1_1_2')}>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('top2_1_1_bottom')}>
                <div className={cx('top2_1_1_bottom_1')}>
                  <div></div>
                  <div></div>
                </div>
                <div className={cx('top2_1_1_bottom_2')}>
                  <div></div>
                  <div></div>
                </div>
                <div className={cx('top2_1_1_bottom_3')}>
                  <div></div>
                  <div></div>
                </div>
              </div>

            </div>
            <div className={cx('top2_1_2')}>
              <div>
                <div></div>
                <div></div>
              </div>
              <div></div>
            </div>
          </div>
          <div className={cx('top2_2')}></div>
        </div>
      </div>
      <div className={cx('tabContainer')}>
        <div className={cx('tabWrapper')}>
          <div></div>
          <div></div>
          <div></div>
        </div>

      </div>
      <div className={cx('content')}>
        <div>
          <div className={cx('content1')}>
            <div className={cx('content1_1')}></div>
            <div className={cx('content1_2')}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className={cx('content2')}></div>
          <div className={cx('content3')}>
            <div className={cx('content3_1')}>
              <div></div>
              <div></div>
            </div>
            <div className={cx('content3_2')}></div>
          </div>
        </div>

      </div>
    </>
  );
};

export default PatientDetailSkeleton;