import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import styles from './patientDetail.module.scss'
import classnames from 'classnames/bind';
import HeaderNav from "../../common/header/headerNav/headerNav";
import ButtonPrimary from "../../common/button/buttonPrimary/buttonPrimary";
import api from "@/api";
import dayjs from "dayjs";
import {showToast} from "../../../helpers/toastMessage";
import ButtonPrimarySmall from "../../common/button/buttonPrimarySmall/buttonPrimarySmall";
import {
  formatBirthAge, makeSetCompletedEx,
  makeSetEx,
  numberWithCommas,
  phoneDash,
  planCreateDate,
  shortYearDateDot
} from "../../../helpers/common";
import PlanDropdown from "./planDropdown/planDropdown";
import {setAccess} from "../../../store/slice/patientSlice";
import PatientAccess from "../patients/patientAccess/patientAccess";
import ModalBig from "../../common/modal/modalBig/modalBig";
import ModalSmall from "../../common/modal/modalSmall/modalSmall";
import PatientChangeProfile from "./patientChangeProfile/patientChangeProfile";
import PlanStop from "./planStop/planStop";
import tagKor from "@/data/tagKor"
import Apexchart from "react-apexcharts";
import imgSize from "@/data/imgSize"
import {
  resetAddedPlanExerciseArr, resetDateExercisesToCopy, resetDateToEdit, resetLastEditedPlanSchedule,
  resetPlanAssignmentParams,
  resetPlanSchedule, setIsAddNewDate,
  setPlanAssignmentStep, setPlanProgramSelected, setSelectedPlanStartDateTimestamp
} from "../../../store/slice/planSlice";
import ModalExDetail from "../../common/modal/modalExDetail/modalExDetail";
import PatientDetailSkeleton from "./patientDetailSkeleton/patientDetailSkeleton";
import PatientStatistics from "./patientStatistics/patientStatistics";
import {
  resetProgramCreationParams,
  setAddedProgramExerciseArr, setProgramCreationStep
} from "../../../store/slice/programSlice";
import {setActiveFilterRehabId} from "../../../store/slice/rehabSlice";
import FooterGray from "../../common/footer/footerGray/footerGray";
import JobIcon from "../../common/jobIcon/jobIcon";
import useDidMountEffect from "../../../helpers/useDidMountEffect";

const arrowRightThinGray = `${process.env.REACT_APP_RESOURCE_URL}/arrow_right_thin_gray.svg`
const close = `${process.env.REACT_APP_RESOURCE_URL}/close.svg`
const Trash = `${process.env.REACT_APP_RESOURCE_URL}/trash.svg`
const noPlansNoOpacity = `${process.env.REACT_APP_RESOURCE_URL}/noPlansNoOpacity.svg`
const grayX = `${process.env.REACT_APP_RESOURCE_URL}/icon/gray_x.svg`
const GPencil = `${process.env.REACT_APP_RESOURCE_URL}/G_pencil.svg`
const CloseRound = `${process.env.REACT_APP_RESOURCE_URL}/close_round.svg`
const GCloseRound = `${process.env.REACT_APP_RESOURCE_URL}/G_close_round.svg`
const LGCloseRound = `${process.env.REACT_APP_RESOURCE_URL}/LG_close_round.svg`
const GResend = `${process.env.REACT_APP_RESOURCE_URL}/G_resend.svg`
const GArrowLeftSharp = `${process.env.REACT_APP_RESOURCE_URL}/G_arrow_left_sharp.svg`
const LGArrowLeftSharp = `${process.env.REACT_APP_RESOURCE_URL}/LG_arrow_left_sharp.svg`
const LGArrowRightSharp = `${process.env.REACT_APP_RESOURCE_URL}/LG_arrow_right_sharp.svg`
const GArrowRightSharp = `${process.env.REACT_APP_RESOURCE_URL}/G_arrow_right_sharp.svg`
const GSearchNew16 = `${process.env.REACT_APP_RESOURCE_URL}/G_searchNew16.svg`
const GPain = `${process.env.REACT_APP_RESOURCE_URL}/G_pain.svg`
const YPain = `${process.env.REACT_APP_RESOURCE_URL}/Y_pain.svg`
const OPain = `${process.env.REACT_APP_RESOURCE_URL}/O_pain.svg`
const IntensityLow = `${process.env.REACT_APP_RESOURCE_URL}/intensity_low.svg`
const IntensityMiddle = `${process.env.REACT_APP_RESOURCE_URL}/intensity_middle.svg`
const IntensityHigh = `${process.env.REACT_APP_RESOURCE_URL}/intensity_high.svg`
const NextD = `${process.env.REACT_APP_RESOURCE_URL}/nextD.svg`
const Share = `${process.env.REACT_APP_RESOURCE_URL}/share.svg`

const cx = classnames.bind(styles)
const date = new Date()
const today = {
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  date: date.getDate(),
}

const scheduleState = {
  'PL': {
    name: '플랜',
    color: 'plan'
  },
  'TR': {
    name: '진료일',
    color: 'treatmentColor'
  },
  'OP': {
    name: '수술일',
    color: 'surgeryColor'
  },
  'ETC': {
    name: '기타',
    color: 'etcColor'
  },
}


const PatientDetail = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const patientId = useParams().id
  const dispatch = useDispatch()

  const ref = useRef()
  const timeInterval = useRef()
  const planSharedStaffRef = useRef()

  const canAccess = useSelector(state => {
    return state.patient.value.canAccess
  })
  const patientAccessInfo = useSelector(state => {
    return state.patient.value.patientAccessInfo
  })
  const patientChangedProfile = useSelector(state => {
    return state.patient.value.patientChangedProfile
  })
  const planStopReason = useSelector(state => {
    return state.plan.value.planStopReason
  })
  const planStatusData = useSelector(state => {
    return state.plan.value.planStatusData
  })
  const lvArr = useSelector(state => {
    return state.rehab.value.lvArr
  })

  const [planList, setPlanList] = useState([])
  const [compressedExScript, setCompressedExScript] = useState([])
  const [planSharedStaffList, setPlanSharedStaffList] = useState([])
  const [stepArr, setStepArr] = useState([])
  const [avgStep, setAvgStep] = useState(0)
  const [isOnlyRelease, setIsOnlyRelease] = useState(true)
  const [statisticsPlanLength, setStatisticsPlanLength] = useState(0)
  const [modalExerciseDetailContent, setModalExerciseDetailContent] = useState({
    header: '',
    exercise: {},
  })
  const [isModalExerciseDetailOpen, setIsModalExerciseDetailOpen] = useState(false)
  const [showPlanSharedStaff, setShowPlanSharedStaff] = useState(false)
  const [patientInfo, setPatientInfo] = useState({})
  const [isMemoFocus, setIsMemoFocus] = useState(false)
  const [memo, setMemo] = useState('')
  const [originalMemo, setOriginalMemo] = useState('')
  const [activeLeftTab, setActiveLeftTab] = useState(0)
  const [recordSchedule, setRecordSchedule] = useState([])
  const [nextDate, setNextDate] = useState(0)
  const [pastDate, setPastDate] = useState(0)
  const [showAddSchedule, setShowAddSchedule] = useState(false)
  const [selectState, setSelectState] = useState(Object.keys(scheduleState)[1])
  const [showDetail, setShowDetail] = useState(false)
  const [planDetail, setPlanDetail] = useState({})
  const [painScaleInfo, setPainScaleInfo] = useState({
    initial: 0,
    last: 0,
    changed: 0,
  })
  const [scheduleEditBtnActive, setScheduleEditBtnActive] = useState(false)
  const [calendar, setCalendar] = useState( {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: [],
  })
  const [clickDate, setClickDate] = useState({
    y: today.year,
    m: today.month,
    d: today.date,
    w: 0,
  })
  const [detailDate, setDetailDate] = useState({
    y: 0,
    m: 0,
    d: 0,
    w: 0,
  })
  const [clickDateStr, setClickDateStr] = useState('')
  const [isScheduleInputBoxActive, setIsScheduleInputBoxActive] = useState(false)
  const [scheduleComment, setScheduleComment] = useState('')
  const [generalSchedule, setGeneralSchedule] = useState([])
  const [isModalSmallOpen, setIsModalSmallOpen] = useState(false)
  const [modalSmallContent, setModalSmallContent] = useState({
    bodyTitle: '',
    bodySub: '',
    isOneBtn: false,
    btnFirstTxt: '',
    btnSecondTxt: '',
    btnFirstEvent: {},
    btnSecondEvent: {},
    isModalSmallOpen: {},
  })
  const [isDropdownPlanOpen, setIsDropdownPlanOpen] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState({
    avgPerformancePercent: null,
    completeDate: '',
    date: [],
    id: null,
    name: '',
    status: '',
  })
  const [isModalBigPatientAccess, setIsModalBigPatientAccess] = useState(false)
  const [isModalBigChangeProfileOpen, setIsModalBigChangeProfileOpen] = useState(false)
  const [modalBigPatientDetailsProps, setModalBigPatientDetailsProps] = useState({
    patientName: '',
    patientId: null,
  })
  const [inProgressPlans, setInProgressPlans] = useState([])
  const [isModalBigStopPlanOpen, setIsModalBigStopPlanOpen] = useState(false)
  const [exChartOptions, setExChartOptions] = useState({
    colors: ["#9CE5E3", "#F96354", "#FFEC99"],
    chart: {
      id: 'patientStatistics',
      height: 350,
      type: "bar",
      stacked: false,
      zoom: {
        enabled: false,
        type: 'x',
      },
      animations: {
        enabled: false,
      },
      redrawOnParentResize: true,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
        autoSelected: 'pan'
      }
    },
    toolbar: {
      show: true,
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 4,
    },
    stroke: {
      show: true,
      width: [2, 2, 2],
      curve: "smooth",
      colors: ['transparent', "#EB7768", "transparent"]
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "10px",
        distributed: false,
        rangeBarOverlap: false,
        rangeBarGroupRows: false,
        colors: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: "#CBF3DC",
            },
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 0,
          backgroundBarRadius: 0,
        },
      },
      area: {
        fillTo: "end",
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 10,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0.15,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0.35,
        },
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: 0,
      },
      categories: [],
      tickAmount: 10,
    },
    yaxis: [
      {
        labels: {
          show: true,
          formatter: (v) => v + '%',
        },
        min: 0,
        max: 100,
        tickAmount: 6,
        forceNiceScale: true,
        opposite: true,
      },
      {
        labels: {
          show: true,
          formatter: (v) => v + '점',
        },
        min: 0,
        max: 10,
        tickAmount: 6,
        forceNiceScale: true,
      },
    ],
    tooltip: {
      // custom: function({ series, seriesIndex, dataPointIndex, w }) {
      //   // console.log(opts)
      //   return `<div>
      //             <span><span class="circle"></span>${series[seriesIndex].name}:</span><span>${series[seriesIndex][dataPointIndex]}</span>
      //             <span><span class="circle"></span>${series[seriesIndex].name}:</span><span>${series[seriesIndex][dataPointIndex]}</span>
      //             <span><span class="circle"></span>${series[seriesIndex].name}:</span><span>${series[seriesIndex][dataPointIndex]}</span>
      //           </div>`
      // },
      followCursor: true,
      shared: true,
      intersect: false,
      x: {
        show: true
      },
      y: [
        {
          title: {
            formatter: (seriesName) => seriesName ,
          },
          formatter: function (y) {
            if(y === 0.01) {
              return null
            } else if( isNaN(y) ){
              return '-%'
            } else {
              return y + "%"
            }
          },
        },
        {
          title: {
            formatter: (seriesName) => seriesName,
          },
          formatter: function (y) {
            if (typeof y !== "undefined") {
              if(isNaN(y)) {
                return '-점'
              } else {
                if(y === 0.1) {
                  return '0점'
                } else {
                  return y + "점"
                }
              }
            }
            return y;
          },
        },
        {
          title: {
            formatter: (seriesName) => seriesName,
          },
          formatter: function (y) {
            if (typeof y !== "undefined") {
              if(isNaN(y)) {
                return '-걸음'
              } else {
                return numberWithCommas(y) + "걸음"
              }
            }
            return y;
          },
        }
      ],
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
      fillSeriesColor: false,
      style: {
        fontSize: '12px',
        fontFamily: 'Pretendard',
        display: 'flex'
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: false,
      showForZeroSeries: false,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '12px',
      fontFamily: 'Pretendard',
      fontWeight: 500,
      formatter: undefined,
      inverseOrder: false,
      tooltipHoverFormatter: false,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false
      },
      markers: {
        width: 8,
        height: 8,
        strokeWidth: 4,
        strokeColor: '#EB7768',
        fillColors: '#EB7768',
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      },
      itemMargin: {
        horizontal: 24,
        vertical: 20
      },
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: true
      },
    },
    crosshairs: {
      show: true,
      position: "back",
      stroke: {
        color: '#EB7768',
        width: 2,
        dashArray: 0,
      },
    },
  })
  const [exChartSeries, setExChartSeries] = useState([
      {
        name: "운동 수행률",
        type: "bar",
        data: []
      },
      {
        name: "통증 점수",
        type: "line",
        data: []
      },
      {
        name: "걸음수",
        type: "bar",
        data: []
      }
    ])
  const [exChartKey, setExChartKey] = useState(0)
  const [activePlanDate, setActivePlanDate] = useState('')
  const [planDateArr, setPlanDateArr] = useState([])
  const [planDateArrNo, setPlanDateArrNo] = useState(null)
  const [isRightArrowActive, setIsRightArrowActive] = useState(false)
  const [isLeftArrowActive, setIsLeftArrowActive] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [lastPlanMainPart, setLastPlanMainPart] = useState('')
  const [isTxtSent, setIsTxtSent] = useState(false)
  const [timerCount, setTimerCount] = useState(0)

  const staff = useSelector(state => {
    return state.staff.value
  })

  const onBtnPrimaryClick = () => {
    dispatch(setActiveFilterRehabId(0))
    api.getRemainPlanCount(staff.rehabGroupId).then((res) => {
      let remainPlanCount = res.data.remainPlanCount
      if(remainPlanCount) {
        navigate(`/plan/assignment/${patientId}`)
      } else {
        setModalSmallContent({
          bodyTitle: '사용 가능한 배정 건수를 초과하였습니다.',
          bodySub: '유료서비스 구독 또는 상위 플랜으로 변경해 주세요.',
          isOneBtn: false,
          btnSecondTxt: '닫기',
          btnFirstTxt: '서비스 구독 바로가기',
          btnSecondEvent: () => setIsModalSmallOpen(false),
          btnFirstEvent: () => navigate('/payment'),
        })
        setIsModalSmallOpen(true)
      }
    })
  }

  const closeModalBigPatientDetails = () => {
    setIsModalBigPatientAccess(false)
  }

  const closeModalBigChangeProfile = () => {
    setIsModalBigChangeProfileOpen(false)
  }

  const closeModalBigStopPlan = () => {
    setIsModalBigStopPlanOpen(false)
  }

  const moveToday = () => {
    setClickDate({
      y: today.year,
      m: today.month,
      d: today.date,
      w: new Date(today.year, today.month - 1, today.date).getDay()
    })

    getFirstDayAndLastDay(today.year, today.month)
  }

  const changeMonth = (val) => {
    if (val === 1) {
      setCalendar({...calendar, m: calendar.m += 1})
    } else if (val === -1) {
      setCalendar({...calendar, m: calendar.m -= 1})
    }
    getFirstDayAndLastDay()
  }

  const getFirstDayAndLastDay = (year = calendar.y, month = calendar.m) => {
    const firstDay = new Date(year, month - 1, 1).getDay() // 이번달 시작요일
    const lastDate = new Date(year, month, 0).getDate() // 이번달 마지막일
    const lastYear = year
    const lastMonth = month - 1 // 지난달
    const lastDayOfLastMonth = new Date(lastYear, lastMonth, 0).getDate() // 지난달 마지막일
    const getPrevDate = lastDayOfLastMonth - firstDay + 1 // 직전달의 날짜 시작일

    const datesOfCalendar = makeCalendar(firstDay, lastDate, getPrevDate)

    setCalendar({
      y: year,
      m: month,
      d: datesOfCalendar
    })

    return datesOfCalendar
  }

  const makeCalendar = (firstDay, lastDate, getPrevDate) => {
    let week = []
    const getDates = []
    let day = 1
    while (day <= lastDate) {
      if (day === 1) {
        getPrevDateOfWeek(firstDay, week, getPrevDate)
        getNextDateOfWeek(week)
      }
      if (week.length === 7) {
        getDates.push(week)
        day = week[week.length - 1]
        week = []
      } else if (week.length < 7 && week.indexOf(lastDate) > -1) {
        getNextDateOfWeek(week)
        getDates.push(week)
        break
      }
      day += 1
      if (week.length <= 7) {
        week.push(day)
      }
    }
    setNextDate(getDates[getDates.length-1][0] && getDates[getDates.length-1][0])
    setPastDate(getDates[0][6])
    return getDates
  }

  const getPrevDateOfWeek = (firstDay, week, getPrevDate) => {
    for (let i = 0; i < firstDay; i++) {
      week.push(getPrevDate++)
    }
  }

  const getNextDateOfWeek = (week) => {
    const len = week.length
    const emptyWeek = 7 - len
    if (len >= 0 && len < 7) {
      for (let i = 1; i <= emptyWeek; i += 1) {
        week.push(i)
      }
    }
  }

  const classObj = (i, dd, index) => {
    return {
      sunDayColor: i === 0,
      disable: ((index === 0) && (dd > pastDate)) || ((index === calendar.d.length - 1) && (dd < nextDate)),
      clickDate: new Date(calendar.y, calendar.m - 1, dd).getTime() === new Date(clickDate.y, clickDate.m - 1, clickDate.d).getTime(),
    }
  }

  const getClickDate = (dd) => {
    if(dd) {
      setClickDate({
        y: calendar.y,
        m: calendar.m,
        d: dd,
        w: new Date(calendar.y, calendar.m - 1, dd).getDay()
      })
    } else {
      setClickDate({
        y: today.year,
        m: today.month,
        d: today.date,
        w: new Date(today.year, today.month - 1, today.date).getDay()
      })
    }

    if(showAddSchedule) {
      setShowAddSchedule(!showAddSchedule)
      setSelectState(Object.keys(scheduleState)[1])
    }
  }

  const findSchedule = (dd) => {
    let scheduleTitle = null
    if (recordSchedule.length) {
      scheduleTitle = recordSchedule.find((v) => {
        const ymd = v.date.split('-').map((z) => parseInt(z, 10))
        return ymd[0] === calendar.y && ymd[1] === calendar.m && ymd[2] === dd
      })

    }
    scheduleLength(scheduleTitle && scheduleTitle.schedule)

    return scheduleTitle ? scheduleTitle.schedule : null
  }

  const scheduleLength = (dd) => {
    let scheduleTitle = null
    if (recordSchedule) {
      scheduleTitle = recordSchedule.find((v) => {
        const ymd = v.date.split('-').map((z) => parseInt(z, 10))
        return ymd[0] === calendar.y && ymd[1] === calendar.m && ymd[2] === dd
      })
    }
    return scheduleTitle ? scheduleTitle.schedule.length : null
  }

  const clickDetailDate = (event, dd) => {
    event.stopPropagation()

    setDetailDate({
      y: calendar.y,
      m: calendar.m,
      d: dd,
      w: new Date(calendar.y, calendar.m - 1, dd).getDay()
    })

    setShowDetail(!showDetail)
  }

  const formatDay = (dayNo = null) => {
    const value = {
      0: '일',
      1: '월',
      2: '화',
      3: '수',
      4: '목',
      5: '금',
      6: '토'
    }
    if(dayNo) {
      return value[dayNo]
    } else {
      return value[detailDate.w]
    }
  }

  const getDetailDateStr = () => {
    return `${detailDate.y}년 ${String(detailDate.m).padStart('2', '0')}월 ${String(detailDate.d).padStart('2','0')}일(${formatDay(detailDate.w)})`
  }

  const getScheduleDate = () => {
    return clickDate && `${clickDate.y}년 ${String(clickDate.m).padStart('2', '0')}월 ${String(clickDate.d).padStart('2','0')}일`
  }

  const clickShowAddSchedule = () => {
    setSelectState(Object.keys(scheduleState)[1])

    setShowAddSchedule(!showAddSchedule)
    activeAddSchedule()
  }

  const activeAddSchedule = () => {
    setIsScheduleInputBoxActive(true)
    generalSchedule.forEach((sch) => {
      sch.edit = false
    })
  }

  const postSchedule = () => {
    setScheduleComment(scheduleComment.trim())
    postRecordIdSchedule().then(() => {})
  }

  const postRecordIdSchedule = async () => {
    const today = `${clickDate.y}-${clickDate.m.toString().padStart('2', '0')}-${clickDate.d.toString().padStart('2', '0')}`
    await api.postRecordIdSchedule(patientId, selectState, scheduleComment, clickDateStr ? clickDateStr : today).then(() => {
      setScheduleComment('')
      setShowAddSchedule(false)
    }).catch((err) => {
      console.log(err)
    })
    await getSchedule()
    showToast('success', '일정이 저장되었습니다.')
  }

  const deleteSchedule = async (scheduleId) => {
    await api.deleteRecordScheduleSid(scheduleId).then(() => {}).catch((e) => {
      console.log(e)})
    await getSchedule()
    showToast('error', '일정이 삭제되었습니다.')
  }

  const divideSchedule = () => {
    let scheduleTitle = {
      date: '',
      schedule: [],
    }

    if (recordSchedule.length) {
      scheduleTitle = recordSchedule.find((v) => {
        const ymd = v.date.split('-').map((z) => parseInt(z, 10))
        return ymd[0] === clickDate.y && ymd[1] === clickDate.m && ymd[2] === clickDate.d
      })
    }
    let generalScheduleArr = scheduleTitle ? scheduleTitle.schedule : []
    if(generalScheduleArr.length) {
      generalScheduleArr.forEach(item => {
        item['edit'] = false
      })
    }

    setGeneralSchedule(generalScheduleArr)
    return scheduleTitle ? scheduleTitle.schedule : null
  }

  const clickScheduleToEdit = (idx) => {
    generalSchedule[idx].edit = true
    setGeneralSchedule([...generalSchedule])
    setIsScheduleInputBoxActive(false)
  }

  const onInputScheduleEdit = (value, idx, content) => {
    generalSchedule[idx].content = value
    setGeneralSchedule([...generalSchedule])
    if(value !== content) {
      setScheduleEditBtnActive(true)
    }
  }

  const getSchedule = () => {
    const firstDate = new Date(calendar.y, calendar.m - 1, 1)
    const endDate = new Date(calendar.y, calendar.m, 0)
    const formatFirstDate = dayjs(firstDate).format('YYYYMMDD')
    const formatEndDate = dayjs(endDate).format('YYYYMMDD')
    api.getRecordIdSchedule(patientId, formatFirstDate, formatEndDate).then((res) => {
      setRecordSchedule(res.data)
    }).catch((e) => {
      console.log(e)
    })
  }

  const checkCanDelete = () => {
    if(inProgressPlans.length === 0) {
      setModalSmallContent({
        bodyTitle: `${patientInfo.userName} 고객을 삭제하시겠습니까?`,
        bodySub: '삭제 진행 시 복구할 수 없으니<br/>신중히 진행해 주세요.',
        isOneBtn: false,
        btnSecondTxt: '취소',
        btnFirstTxt: '삭제하기',
        btnSecondEvent: () => setIsModalSmallOpen(false),
        btnFirstEvent: () => deletePatient(),
      })
    } else {
      setModalSmallContent({
        bodyTitle: `${patientInfo.userName} 고객은 수행중인 플랜이 있어<br/> 삭제가 불가능합니다.`,
        bodySub: '삭제를 하시려면 수행중인 플랜을<br/>중단해주셔야 해요',
        isOneBtn: true,
        btnFirstTxt: '확인',
        btnFirstEvent: () => setIsModalSmallOpen(false)
      })
    }
    setIsModalSmallOpen(true)
  }

  const deletePatient = () => {
    api.deleteRecord(patientId).then(() => {
      navigate('/patients')
      showToast('success', `${patientInfo.userName} 고객이 삭제되었습니다.`)
    }).catch((err) => {
      console.log(err)
    })
  }

  const checkAccess = () => {
    if(canAccess === null || canAccess === undefined) {
      api.getRecordAccess(patientId).then((res) => {
        if(!res.data.isAvailable) {
          setIsModalBigPatientAccess(true)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const openPatientDetails = async () => {
    api.postRecordAccess(patientAccessInfo).then(() => {
      closeModalBigPatientDetails()
      dispatch(setAccess(true))
    }).catch(err => {
      console.log(err)
    })
  }

  const changeProfile = async () => {
    setIsModalBigChangeProfileOpen(true)
  }

  const patchSchedule = async (scheduleId, content) => {
    if(!scheduleEditBtnActive) {
      return
    }
    if(content === null) {
      content = ''
    }
    await api.patchRecordScheduleSid(scheduleId, content).then(() => {}).catch((e) => {
      console.log(e)
    })

    await getSchedule()
    showToast('success', '일정이 수정되었습니다.')
  }

  const getChartData = () => {
    let dailyPatientInfo = planDetail.postScript.dailyPatientInfo
    let newOptions = exChartOptions

    let dateArr = Object.keys(dailyPatientInfo)
    dateArr = dateArr.filter((date) => date <= today )
    dateArr = dateArr.map((date) => date.slice(4).replace(/(\d{2})(\d{2})/g, '$1/$2'))
    dateArr.unshift('초기')

    newOptions.xaxis.categories = dateArr
    setExChartOptions({...newOptions})

    let newSeries = exChartSeries

    let performanceArr = Object.values(dailyPatientInfo).map((e) => e.performancePercent ? e.performancePercent : '-')
    performanceArr = ['-', ...performanceArr]
    performanceArr = performanceArr.slice(0, dateArr.length)

    let painArr = Object.values(dailyPatientInfo).map((e) => {
        if(e.painScale) {
          return e.painScale
        } else if(e.painScale === undefined) {
          return '-'
        } else if(e.painScale === 0) {
          return 0.1
        } else {
          return null
        }
      }
    )
    painArr = [planDetail.initPainScale ? planDetail.initPainScale : '-', ...painArr]
    painArr = painArr.slice(0, dateArr.length)

    newSeries[0].data = performanceArr
    newSeries[1].data = painArr

    setExChartSeries([...newSeries])
    setExChartKey((prev) => prev += 1)
  }



  const getStepData = (planId) => {
    if(!planId) {
      planId = selectedPlan.id
    }

    api.getStepData(planId).then((res) => {
      let values = res.data.values
      if(values.length) {
        let stepData = ['-']
        let stepTotal = 0
        values.forEach((value) => {
          if(value === null) {
            stepData.push('-')
          } else {
            stepData.push(value)
            stepTotal += Number(value)
          }
        })

        setStepArr(stepData)
        if(stepTotal) {
          setAvgStep(Math.floor(stepTotal / values.length))
        }
      }
    })
  }

  const calculateActivePlanDate = (planDates) => {
    let today = new Date()
    let isStop = false
    planDates.forEach((planDate, idx) => {
      if(isStop) {
        return
      }
      let newPlanDate = new Date(planDate.slice(0, 4), planDate.slice(4, 6) - 1, planDate.slice(6, 8))

      if(newPlanDate.getFullYear() === today.getFullYear() && newPlanDate.getMonth() === today.getMonth()  && newPlanDate.getDate() === today.getDate()) {
        setActivePlanDate(planDates[idx])
        isStop = true
      } else if(idx === 0 && newPlanDate > today) {
        setActivePlanDate(planDates[0])
        isStop = true
      } else if(idx === planDates.length - 1 && newPlanDate < today) {
        setActivePlanDate(planDates[idx])
        isStop = true
      } else if(newPlanDate > today){
        // 예 : 플랜 날짜중에 6월 1,3,5,6,7,8 이 있고 오늘이 4일이면 3일이 선택
        setActivePlanDate(planDates[idx - 1])
        isStop = true
      }
    })
  }

  const getPlanSharedStaffList = (planId) => {
    if(staff.id !== null && planId !== null) {
      api.getPlanSharedStaffList(planId).then((res) => {
        let tempPlanSharedStaffList = res.data.filter((val) => val.id !== staff.id)
        setPlanSharedStaffList(tempPlanSharedStaffList)
      })
    }
  }

  const getPlanDetail = (planId) => {
    if(!planId) {
      planId = selectedPlan.id
    }
    api.getPlanDetail(planId).then((res) => {
      let data = res.data
      setPlanDetail(data)

      let planDates = Object.keys(data.preScript.schedule)
      setActivePlanDate(planDates[0])
      setCompressedExScript(makeSetCompletedEx(data.preScript.schedule[planDates[0]], data.postScript.dailyPatientInfo[planDates[0]].exerciseCompleteIdxList))

      setPlanDateArrNo(0)

      if(data.initPainScale === undefined) {
        setPainScaleInfo({
          initial: undefined,
          last: 0,
          changed: 0,
        })
      } else {
        if(data.finalPainScale === undefined) {
          setPainScaleInfo({
            initial: data.initPainScale,
            last: undefined,
            changed: 0,
          })
        } else {
          setPainScaleInfo({
            initial: data.initPainScale,
            last: data.finalPainScale,
            changed: data.initPainScale - data.finalPainScale,
          })
        }
      }

      setIsLoading(false)
    }).catch((e) => {
      console.log(e)
    })
  }

  const getPainColor = () => {
    if(painScaleInfo.initial !== undefined && painScaleInfo.last !== undefined) {
      let scale = painScaleInfo.last
      if(scale === 1) {
        return 'pain0'
      } else if (scale === 2) {
        return 'pain1'
      } else if (scale === 3) {
        return 'pain2'
      } else if (scale === 4) {
        return 'pain3'
      } else if (scale === 5) {
        return 'pain4'
      } else if (scale === 6) {
        return 'pain5'
      } else if (scale === 7) {
        return 'pain6'
      } else if (scale === 8) {
        return 'pain7'
      } else if (scale === 9) {
        return 'pain8'
      } else if (scale === 10) {
        return 'pain9'
      }
    }

  }

  const onArrowClick = (direction) => {
    if(direction === 'back') {
      if(isLeftArrowActive && planDateArrNo - 7 >= 0) {
        setPlanDateArrNo(planDateArrNo - 7)
      }
    } else {
      if(isRightArrowActive && planDateArrNo + 7 < Object.keys(planDetail.preScript.schedule).length) {
        setPlanDateArrNo(planDateArrNo + 7)
      }
    }
  }

  const getPlanDateArr = () => {
    let scheduleDates = Object.keys(planDetail.preScript.schedule)
    let sevenDates = scheduleDates.slice(planDateArrNo, planDateArrNo + 7)
    setPlanDateArr(sevenDates)
    calculateActivePlanDate(sevenDates)

    if(!planDateArrNo) {
      if(scheduleDates.length <= 7) {
        setIsRightArrowActive(false)
        setIsLeftArrowActive(false)
      } else {
        setIsRightArrowActive(true)
        setIsLeftArrowActive(false)
      }
    } else if(planDateArrNo > 0) {
      if(scheduleDates.length <= planDateArrNo + 7 ) {
        setIsRightArrowActive(false)
        setIsLeftArrowActive(true)
      } else {
        setIsRightArrowActive(true)
        setIsLeftArrowActive(true)
      }
    }
  }

  const getPlanList = () => {
    api.getPlanList(patientId).then((res) => {
      let data = res.data.reverse()
      setPlanList(data)

      if(data.length) {
        const queryParams = new URLSearchParams(location.search)
        let planId = queryParams.get('planId')
        if(planId) {
          data.forEach((plan) => {
            if(plan.id === Number(planId)) {
              setSelectedPlan(plan)
            }
          })
        } else {
          setSelectedPlan(data[0])
        }

        let today =""+ date.getFullYear()+((date.getMonth()+1)<10?"0"+(date.getMonth()+1):(date.getMonth()+1))+(date.getDate()<10?"0"+date.getDate():date.getDate())

        // const releasePlans = res.data.filter((plan) => plan.completeDate >= today && (plan.status === 'RELEASE'))
        // const inProgressPlans = data.filter((plan) => plan.completeDate >= today && (plan.status === 'IN_PROGRESS'))
        // const pendingPlans = res.data.filter((plan) => plan.status === 'PENDING_PLAN')
        // 종료 const closedPlans = res.data.filter((plan) => (plan.completeDate < today && (plan.status === 'RELEASE' || plan.status === 'IN_PROGRESS')) || plan.status === 'CLOSED' || plan.status === 'CANCEL')

        let inProgressPlans = []

        let isFirstExist = false
        data.forEach((plan) => {
          if(plan.status === 'IN_PROGRESS' || plan.status === 'CLOSED' || plan.status === 'CANCEL') {
            if(!isFirstExist) {
              setLastPlanMainPart(plan.mainPart)
              isFirstExist = true
            }
            setStatisticsPlanLength((prev) => prev + 1)
          }

          if(plan.status !== 'RELEASE') {
            setIsOnlyRelease(false)
          }

          if(plan.completeDate >= today && plan.status === 'IN_PROGRESS') {
            inProgressPlans.push(plan)
          }
        })

        setInProgressPlans(inProgressPlans)
      } else {
        setIsLoading(false)
        setIsOnlyRelease(false)
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  const saveProfile = () => {
    api.patchPatientComment(patientId, patientChangedProfile).then(() => {
      setIsModalBigChangeProfileOpen(false)
      getPatientInfo()
      showToast('success', '고객 정보가 수정되었습니다.')
    }).catch((err) => {
      if(err.response.data.code === '1105' && err.response.data.status === 400) {
        showToast('error', '이미 등록된 고객입니다.')
      }
    })
  }

  const resendTxt = () => {
    if (!isTxtSent) {
      api.postResendPlanText(planDetail.id).then(() => {
        showToast('success', '문자를 다시 보냈습니다.')
        setIsTxtSent(true)
        setTimerCount(0)
      })
    } else {
      showToast('error', `${60 - timerCount}초 후 문자를 보낼 수 있습니다`)
    }
  }

  const chooseStopReason = () => {
    if(selectedPlan.status === 'RELEASE' || selectedPlan.status === 'IN_PROGRESS') {
      setIsModalBigStopPlanOpen(true)
    }
  }

  const stopPlan = () => {
    api.postPlanCancel(selectedPlan.id, planStopReason).then(() => {
      setIsModalBigStopPlanOpen(false)
      showToast('success', '플랜이 중단되었습니다.')
      getPlanList()
    }).catch((err) => {
      console.log(err)
    })
  }

  const getPerformanceInfo = () => {
    let performanceInfo = {
      color: 'black',
      number: '-',
    }

    if(planDetail.id && planDetail.postScript.dailyPatientInfo[activePlanDate].performancePercent !== undefined) {
      let performancePercent = planDetail.postScript.dailyPatientInfo[activePlanDate].performancePercent

      if(performancePercent >= 30) {
        performanceInfo.color = 'green'
      } else if(performancePercent < 30) {
        performanceInfo.color = 'red'
      }

      performanceInfo.number = performancePercent
    }
    return performanceInfo
  }

  const getPainInfo = () => {
    let painInfo = {
      number: '-',
      txt: '',
      img: ''
    }

    if(planDetail.id && planDetail.postScript.dailyPatientInfo[activePlanDate].painScale !== undefined) {
      let painScale = planDetail.postScript.dailyPatientInfo[activePlanDate].painScale
      let painTexts = {
        0: '통증이 전혀 느껴지지 않아요',
        1: '미세한 통증은 있지만 전혀 불편하지 않아요',
        2: '약간의 통증이 느껴져요',
        3: '약간의 통증으로 간헐적으로 신경쓰여요',
        4: '참을 만한 통증이 느껴져요',
        5: '적당한 통증으로 다소 불편함을 느껴요',
        6: '통증이 심해요',
        7: '심한 통증으로 매우 불편함을 느껴요',
        8: '통증이 매우 심해서 일상에 지장이 있어요',
        9: '움직일 수 없을 정도로 통증이 심해요',
        10: '끔찍한 통증으로 괴로워요'
      }

      painInfo.number = painScale
      painInfo.txt = painTexts[painScale]
      if([0, 1, 2, 3].includes(painScale)) {
        painInfo.img = 'green'
      } else if([4, 5, 6].includes(painScale)) {
        painInfo.img = 'yellow'
      } else {
        painInfo.img = 'orange'
      }
    }
    return painInfo
  }

  const getLvInfo = () => {
    let lvInfo = {
      number: '-',
      txt: '',
      img: ''
    }

    if(planDetail.id && planDetail.postScript.dailyPatientInfo[activePlanDate].level !== undefined) {
      let level = planDetail.postScript.dailyPatientInfo[activePlanDate].level
      let levelTexts = {
        0: '운동을 안 한 느낌이에요',
        1: '매우 쉬었어요',
        2: '쉬웠어요',
        3: '괜찮았어요',
        4: '약간 힘들었어요',
        5: '힘들지만 참을 만 했어요',
        6: '좀 힘들었어요',
        7: '꽤 힘들었어요',
        8: '많이 힘들었어요',
        9: '아주 많이 힘들었어요',
        10: '할 수 있는 운동의 끝을 경함한 것 같아요'
      }

      lvInfo.number = level
      lvInfo.txt = levelTexts[level]
      if([0, 1, 2, 3].includes(level)) {
        lvInfo.img = 'low'
      } else if([4, 5, 6].includes(level)) {
        lvInfo.img = 'middle'
      } else {
        lvInfo.img = 'high'
      }
    }
    return lvInfo
  }

  const saveProgram = () => {
    let schedule = planDetail.preScript.schedule[activePlanDate]
    let exerciseMap = planDetail.exerciseMap

    schedule.forEach((sch, idx) => {
      Object.keys(exerciseMap).forEach((exId) => {
        if(sch.id === Number(exId)) {
          sch = Object.assign(sch, exerciseMap[exId])
          sch.unique = Date.now() + idx
        }
      })
    })

    if(schedule.length) {
      dispatch(setActiveFilterRehabId(0))
      dispatch(setAddedProgramExerciseArr(makeSetEx(schedule)))
      dispatch(resetProgramCreationParams())
      dispatch(setProgramCreationStep(0))
      navigate('/create/program')
    }
  }

  const getDifficultExInfo = () => {
    let difficultExInfo = {
      number: '-',
      txt: [],
    }

    let difficultExIdArr = planDetail.postScript.dailyPatientInfo[activePlanDate].difficultExercises

    if(planDetail.id && difficultExIdArr.length) {
      difficultExInfo.number = difficultExIdArr.length
      difficultExIdArr.forEach((exId) => {
        if(planDetail.exerciseMap[exId]) {
          difficultExInfo.txt.push(planDetail.exerciseMap[exId].name)
        }
      })
    }
    return difficultExInfo
  }

  const openExerciseDetailModal = (exercise) => {
    setModalExerciseDetailContent({
      header: '미리보기',
      exercise: exercise,
    })

    setIsModalExerciseDetailOpen(true)
  }

  const onMemoBlur = () => {
    setIsMemoFocus(false)
    setMemo(originalMemo)
  }

  const onMemoChange = (e) => {
    setMemo(e.target.value)
  }

  const onMemoFocus = () => {
    setIsMemoFocus(true)
  }

  const onCloseClick = (e) => {
    e.preventDefault()
    setMemo('')
  }

  const getExCompletePercent = (complete, repeat) => {
    return Math.floor((complete / repeat) * 100)
  }

  const applyComment = (e) => {
    e.preventDefault()

    api.patchPatientComment(patientId, { comment: memo }).then((res) => {
      setMemo(res.data.comment)
      setOriginalMemo(res.data.comment)
      showToast('success', '메모가 저장되었습니다.')
    }).catch((e) => {console.log(e)})
  }

  const getPatientInfo = () => {
    api.getPatientInfo(patientId).then((res) => {
      setPatientInfo(res.data)
      setMemo(res.data.userComment)
      setOriginalMemo(res.data.userComment)
    }).catch((e) => {
      if(e.response.data.code === '1001') {
        showToast('error', '고객 정보가 없습니다.')
      }
      navigate('/patients')
    })
  }

  useEffect(() => {
    getPlanSharedStaffList(selectedPlan.id)
  }, [staff])

  useEffect(() => {
    if(isModalBigPatientAccess) {
      setModalBigPatientDetailsProps({
        patientName: patientInfo.userName,
        patientId: patientInfo.id,
      })
    }
  }, [patientInfo])

  useEffect(() => {
    if(planDetail.id) {
      setCompressedExScript(makeSetCompletedEx(planDetail.preScript.schedule[activePlanDate], planDetail.postScript.dailyPatientInfo[activePlanDate].exerciseCompleteIdxList))
    }
  }, [activePlanDate])

  useEffect(() => {
    if (calendar.m === 13) {
      setCalendar({...calendar, y: calendar.y += 1, m: 1})
    }
    if (calendar.m === 0) {
      setCalendar({...calendar, y: calendar.y -= 1, m: 12})
    }

    if(calendar.d.length) {
      getSchedule()
    }
  }, [calendar])

  useEffect(() => {
    if(selectedPlan.id !== null) {
      getPlanDetail(selectedPlan.id)
      getStepData(selectedPlan.id)
      getPlanSharedStaffList(selectedPlan.id)
    }
  }, [planList, selectedPlan])

  useEffect(() => {
    if(Object.keys(planDetail).length) {
      getChartData()
    }

    if(planDateArrNo !== null) {
      getPlanDateArr()
    }
  }, [planDetail, planDateArrNo])

  useEffect(() => {
    if(!patientId) {
      navigate('/patients')
      return
    }

    window.scrollTo(0,0)

    getPatientInfo()
    getPlanList()
    getFirstDayAndLastDay()
    getClickDate()
    checkAccess()
  }, [patientId])

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showDetail && ref.current && !ref.current.contains(e.target) && e.target.className !== 'detailBtn') {
        setShowDetail(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showDetail])


  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showPlanSharedStaff && planSharedStaffRef.current && !planSharedStaffRef.current.contains(e.target)) {
        setShowPlanSharedStaff(false)
      } else if(!showPlanSharedStaff && e.target.className.includes('share')){
        setShowPlanSharedStaff(true)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showPlanSharedStaff])

  useEffect(() => {
    divideSchedule(clickDate)
    setClickDateStr(`${clickDate.y}-${clickDate.m.toString().padStart('2', '0')}-${clickDate.d.toString().padStart('2', '0')}`)
  }, [recordSchedule, clickDate])

  useEffect(() => {
    if(isTxtSent) {
      timeInterval.current = setInterval(() => (
        setTimerCount((timerCount) => timerCount + 1)
      ), 1000)
    } else {
      clearInterval(timeInterval.current)
      timeInterval.current = null
    }
  }, [isTxtSent])

  useEffect(() => {
    if(timerCount >= 60) {
      clearInterval(timeInterval.current)
      timeInterval.current = null
      setIsTxtSent(false)
      setTimerCount(0)
    }
  }, [timerCount])

  useEffect(() => {
    let newSeries = exChartSeries
    newSeries[2].data = stepArr
    setExChartSeries([...newSeries])
    setExChartKey((prev) => prev += 1)
  }, [stepArr])

  useEffect(() => {
    dispatch(setPlanAssignmentStep(0))
    dispatch(resetPlanAssignmentParams())
    dispatch(resetAddedPlanExerciseArr())
    dispatch(resetPlanSchedule())
    dispatch(resetDateExercisesToCopy())
    dispatch(resetDateToEdit())
    dispatch(setIsAddNewDate(false))
    dispatch(resetLastEditedPlanSchedule())
    dispatch(setPlanProgramSelected({}))
    dispatch(setSelectedPlanStartDateTimestamp(0))

    return () => clearInterval(timeInterval.current)
  }, [])

  useDidMountEffect(() => {
    navigate(`?planId=${selectedPlan.id}`, { replace: true })
  }, [selectedPlan])

  return (
    <div className={cx('patientDetail')}>
      <HeaderNav/>
      { isLoading ? (
        <PatientDetailSkeleton/>
      ) : (
        <>
          <div className={cx('titleContainer')}>
            <div>
              <p className={cx('step')}>
                <span className={cx('step1')} onClick={() => navigate('/patients')}>고객 관리</span>
                <img src={arrowRightThinGray} alt='arrowRightThinGray'/>
                <span className={cx('step2')}>{ patientInfo.userName }</span>
              </p>
              <div className={cx('patientProfile')}>
                <div className={cx('profileContainer')}>
                  <div className={cx('nameContainer')}>
                    <div className={cx('nameWrap')}>
                      <span className={cx('patientName')}>{ patientInfo.userName }</span>
                      { patientInfo.userIsRegistered ? (
                        <span className={cx('gender')}>{ patientInfo.userGender }</span>
                      ) : (
                        <span className={cx('gender')}>-</span>
                      )}
                      { patientInfo.userBirthday && (
                        <>
                          <span className={cx('bar')}>|</span>
                          <span className={cx('birth')}>{ patientInfo.userBirthday && formatBirthAge(patientInfo.userBirthday) }</span>
                        </>
                      ) }
                    </div>
                    <div className={cx('profileBtns')}>
                      <img onClick={() => changeProfile()} src={GPencil} alt='GPencil'/>
                      <img onClick={() => checkCanDelete()} src={Trash} alt='Trash'/>
                    </div>
                  </div>
                  <div className={cx('patientDetailContainer')}>
                    <p>휴대폰 번호 :<span>{ patientInfo.userPhoneNumber ? phoneDash(patientInfo.userPhoneNumber) : '-' }</span></p>
                    <p>신장 :<span>{ patientInfo.userHeight ? patientInfo.userHeight : '-' }cm</span></p>
                    <p>체중 :<span>{ patientInfo.userWeight ? patientInfo.userWeight : '-' }kg</span></p>
                  </div>
                </div>
                <div className={cx('memoContainer')}>
                  <div className={cx('memoTop')}>
                    <span className={cx('memoTitle')}>메모</span>
                    <span className={cx('saveBtn', isMemoFocus && 'dark')} onMouseDown={(e) => applyComment(e)}>저장하기</span>
                  </div>
                  <textarea
                    className={cx(isMemoFocus && 'borderDeepYellow')}
                    placeholder='고객에 대한 메모를 입력해 주세요.'
                    maxLength='150'
                    value={ memo }
                    onChange={ onMemoChange }
                    onFocus={ onMemoFocus }
                    onBlur={ onMemoBlur }
                  ></textarea>
                  { (isMemoFocus && memo) &&
                    <img src={CloseRound} onMouseDown={(e) => onCloseClick(e)} alt='CloseRound'/>
                  }
                  <span className={cx((isMemoFocus && memo) && 'dark')}>{ memo ? memo.length : 0 }/150</span>
                </div>
              </div>
              <div className={cx('planAssignmentBtn')}>
                <ButtonPrimary text='플랜 배정' onBtnPrimaryClick={onBtnPrimaryClick} icon='CalendarArrow' height={52}/>
              </div>

            </div>
          </div>
          <div className={cx('tabContainer')}>
            <div className={cx('tabWrapper')}>
              <div className={cx(activeLeftTab === 0 && 'active')} onClick={() => setActiveLeftTab(0)}><p>플랜 관리</p></div>
              <div className={cx(activeLeftTab === 1 && 'active')} onClick={() => setActiveLeftTab(1)}><p>전체 통계</p></div>
              <div className={cx(activeLeftTab === 2 && 'active')} onClick={() => setActiveLeftTab(2)}><p>일정관리</p></div>
            </div>
          </div>
          { activeLeftTab === 0 && (
            <div className={cx('dropdownWrapper')}>
              <PlanDropdown
                planList={planList}
                isDropdownPlanOpen={isDropdownPlanOpen}
                setIsDropdownPlanOpen={() => setIsDropdownPlanOpen(!isDropdownPlanOpen)}
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
              />
            </div>
          ) }
          <div className={cx('content', activeLeftTab !== 0 ? 'marginTop' : '')}>
            <div>
              { activeLeftTab === 0 &&
                <>
                  { planList.length ? (
                    <div className={cx('planDetail')}>
                      <div className={cx('planName')}>
                        <span>{ selectedPlan.name }</span>
                        <div className={cx('planBtns')}>
                          { selectedPlan.status === 'RELEASE' && (
                            <button onClick={() => resendTxt()}>
                              <img src={GResend} alt='G_resend'/>
                              문자 다시 보내기
                            </button>
                          )}
                          <button
                            onClick={() => chooseStopReason()}
                            className={cx((selectedPlan.status !== 'RELEASE' && selectedPlan.status !== 'IN_PROGRESS') && 'inactive')}
                          >
                            <img src={(selectedPlan.status !== 'RELEASE' && selectedPlan.status !== 'IN_PROGRESS') ? LGCloseRound : GCloseRound} alt='GCloseRound'/>
                            플랜 중단하기
                          </button>
                        </div>
                      </div>
                      <span className={cx('planStatus', planStatusData[selectedPlan.status].txtColor)}>
                        { planStatusData[selectedPlan.status].status }
                      </span>
                      <div className={cx('planWrap')}>
                        <span>통증 부위 :</span>
                        <span className={cx('bold')}>{ tagKor['MP'][planDetail.mainPart] }</span>
                        <span>질환 및 수술명 :</span>
                        <span className={cx('bold')}>{ planDetail.diagnosis ? planDetail.diagnosis.trim() : '-' }</span>
                        <span className={cx('marginRight')}>담당자 :</span>
                        <JobIcon job={planDetail.creatorJob} isCertified={planDetail.creatorIsCertified}/>
                        <span className={cx('bold', 'marginRight')}>{ planDetail.creatorName }({ planDetail.creatorPhoneNumber.slice(7, 11) })</span>

                        <div className={cx('shareWrap')}>
                          <img className={cx('shareIcon')} src={Share} alt='share'/>
                          { planSharedStaffList.length ? (
                            <div className={cx('share')} >
                              <span className={cx('bold', 'mint', 'share')}>{ planSharedStaffList.length }</span>
                              <div className={cx('tooltiptextName', showPlanSharedStaff && 'show')} ref={planSharedStaffRef}>
                                { planSharedStaffList.map((staff) => (
                                  <div className={cx('staffName')} key={staff.id}>
                                    <JobIcon job={staff.job} isCertified={staff.isCertified}/>
                                    <p className={cx('planSharedStaffName')}>{ staff.name }({ staff.phoneNumber.slice(7, 11) })</p>
                                  </div>
                                )) }
                              </div>
                            </div>
                          ) : (
                            <div className={cx('share')}>
                              <span className={cx('bold')}>-</span>
                            </div>
                          )}
                        </div>

                        <span>배정일 :</span>
                        <span className={cx('bold')}>{ planCreateDate(planDetail.createDateTime) }</span>
                      </div>
                      <div className={cx('exData')}>
                        <p>운동 데이터</p>
                        <div className={cx('exDataContainer')}>
                          <div className={cx('exGraph')}>
                            <Apexchart
                              width={748}
                              height={367}
                              options={exChartOptions}
                              series={exChartSeries}
                              key={exChartKey}
                            ></Apexchart>
                          </div>
                          <div className={cx('exNumbers')}>
                            <div>
                              <p className={cx('exNumberTitle')}>평균 운동 수행률</p>
                              <p className={cx('exNumber')}>{ planDetail.initPainScale === undefined ? '- ' : planDetail.avgPerformancePercent } %</p>
                              <div className={cx('exNumberBar')}>
                                <div className={cx('backBar')}></div>
                                <div className={cx('colorBar', planDetail.avgPerformancePercent > 29 && 'green', planDetail.avgPerformancePercent < 30 && 'red')} style={{ width: 280 * ( planDetail.avgPerformancePercent / 100 ) + 'px' }}></div>
                              </div>
                            </div>
                            <div>
                              <p className={cx('exNumberTitle')}>통증 점수 변화</p>
                              <p className={cx('exNumber')}>
                                { painScaleInfo.changed > 0 ? painScaleInfo.changed + ' 감소' : painScaleInfo.changed < 0 ? -(painScaleInfo.changed) + ' 증가' : painScaleInfo.initial === undefined ? '-' : '변화없음' }
                              </p>
                              <div className={cx('exNumberBar')}>
                                <div className={cx('backBar')}></div>
                                <div className={cx('colorBar', painScaleInfo.changed && getPainColor(), painScaleInfo.changed > 0 && 'zIndex')} style={{ width: 280 * ( painScaleInfo.last / 10 ) + 'px' }}></div>
                                <div className={cx('previousBar', painScaleInfo.changed < 0 && 'flatRight', painScaleInfo.changed < 0 && 'zIndex')} style={{ width: 280 * ( painScaleInfo.initial / 10 ) + 'px' }}></div>
                              </div>
                            </div>
                            <div>
                              <p className={cx('exNumberTitle')}>평균 걸음수</p>
                              <p className={cx('exNumber')}>{ avgStep ? numberWithCommas(avgStep) : '-' } 걸음</p>
                            </div>
                          </div>
                        </div>
                        <p>재활 운동</p>
                        <div className={cx('rehabContainer')}>
                          <div className={cx('rehabDate')}>
                            <img className={cx(isLeftArrowActive && 'pointer')}
                                 src={isLeftArrowActive ? GArrowLeftSharp : LGArrowLeftSharp}
                                 onClick={() => onArrowClick('back')}
                                 alt='arrow'
                            />
                            { planDateArr.map((planDate) => (
                              <span key={planDate} className={cx(planDate === activePlanDate && 'active')} onClick={() => setActivePlanDate(planDate)}>
                                { shortYearDateDot(planDate) }
                              </span>
                            )) }
                            <img
                              className={cx('arrowRight', isRightArrowActive && 'pointer')}
                              src={isRightArrowActive ? GArrowRightSharp : LGArrowRightSharp}
                              onClick={() => onArrowClick('next')}
                              alt='arrow'
                            />
                          </div>
                          <div className={cx('rehabDetail')}>
                            <div className={cx('rehabList')}>
                              { Object.keys(planDetail).length && compressedExScript.map((planEx, idx) => (
                                <div key={idx} className={cx('rehabItem')}>
                                  <div className={cx('rehabThumbnail')}>
                                    <img src={planDetail.exerciseMap[planEx.id].media.IT.url + imgSize['90']} alt='thumbnail'/>
                                    <div className={cx('grayBack')}></div>
                                    <div className={cx('rehabItemTitle')}>
                                      <span className={cx('rehabItemName')}>{ planDetail.exerciseMap[planEx.id].name }</span>
                                      <span className={cx('rehabItemLv', lvArr[planDetail.exerciseMap[planEx.id].level - 1].color)}>
                                  { lvArr[planDetail.exerciseMap[planEx.id].level - 1].lv }
                                </span>
                                    </div>
                                  </div>
                                  <div className={cx('rehabCount')}>
                                    <img className={cx('exSearch')} onClick={() => openExerciseDetailModal(planDetail.exerciseMap[planEx.id])} src={GSearchNew16} alt='GSearchNew'/>
                                    <p className={cx('unit')}>
                                      <span>{ planEx.count ? planEx.count + '회 반복' : planEx.time + '초 유지' }</span>
                                      <span className={cx('multiply')}>X</span>
                                      <span>{ planEx.repeat }세트</span>
                                    </p>
                                    <p className={cx('percent', getExCompletePercent(planEx.complete, planEx.repeat) === 100 ? 'primary' : getExCompletePercent(planEx.complete, planEx.repeat) === 0 ? 'gray' : '')}>
                                      { getExCompletePercent(planEx.complete, planEx.repeat) }%
                                    </p>
                                  </div>
                                </div>
                              ))}

                            </div>
                            <div className={cx('rehabWrap')}>
                              <div>
                                <p className={cx('rehabWrapTitle')}>운동 수행률</p>
                                <p className={cx('rehabWrapNumber', getPerformanceInfo().color)}>{ getPerformanceInfo().number } %</p>
                                { getPerformanceInfo().number < 30 ? (
                                  <p className={cx('rehabWrapTxt', 'red')}>미달성</p>
                                ) : ''}

                              </div>
                              <div>
                                <p className={cx('rehabWrapTitle')}>통증 점수</p>
                                <p className={cx('rehabWrapNumber')}>{ getPainInfo().number }</p>
                                <p className={cx('rehabWrapTxt')}>{ getPainInfo().txt }</p>
                                { getPainInfo().img && (
                                  <img src={getPainInfo().img === 'green' ? GPain : getPainInfo().img === 'yellow' ? YPain : OPain} alt='painImg'/>
                                ) }
                              </div>
                              <div>
                                <p className={cx('rehabWrapTitle')}>운동 강도</p>
                                <p className={cx('rehabWrapNumber')}>{ getLvInfo().number }</p>
                                <p className={cx('rehabWrapTxt')}>{ getLvInfo().txt }</p>
                                { getLvInfo().img && (
                                  <img src={getLvInfo().img === 'low' ? IntensityLow : getLvInfo().img === 'middle' ? IntensityMiddle : IntensityHigh} alt='lvImg'/>
                                ) }
                              </div>
                              <div>
                                <p className={cx('rehabWrapTitle')}>불편한 동작</p>
                                <p className={cx('rehabWrapNumber')}>{ getDifficultExInfo().number }</p>
                                { getDifficultExInfo().txt.map((exName) => (
                                  <p className={cx('rehabWrapTxt', getDifficultExInfo().number > 1 && 'difficultEx')} key={exName}>{ exName }</p>
                                )) }
                              </div>
                            </div>
                          </div>
                          <button onClick={() => saveProgram()}>나만의 프로그램 저장</button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={cx('noContent')}>
                      <img src={noPlansNoOpacity} alt='noPlansNoOpacity'/>
                      <p>배정된 플랜이 없습니다.</p>
                    </div>
                  )
                  }
                </>
              }
              { activeLeftTab === 1 &&
                <PatientStatistics statisticsPlanLength={statisticsPlanLength} lastPlanMainPart={lastPlanMainPart} isOnlyRelease={isOnlyRelease}/>
              }
              { activeLeftTab === 2 &&
                <div className={cx('scheduleContent')}>
                  <div className={cx('calendar')}>
                    <div className={cx('header')}>
                      <div className={cx('todayMoveBtn')} onClick={() => moveToday()}>
                        <button>
                          <p>오늘</p>
                        </button>
                      </div>
                      <div className={cx('date')}>
                        <p>{ calendar.y }년 &nbsp;</p>
                        <p>{ calendar.m }월</p>
                      </div>
                      <div className={cx('btns')}>
                        <button onClick={() => changeMonth(-1)}><img src={NextD} alt='NextD'/></button>
                        <button onClick={() => changeMonth(1)}><img src={NextD} alt='NextD'/></button>
                      </div>
                    </div>
                    <div className={cx('body')}>
                      <div className={cx('week')}>
                        { ['일', '월', '화', '수', '목', '금', '토'].map((day) => (
                          <div key={day}>
                            <p>{ day }</p>
                          </div>
                        )) }
                      </div>
                      <div className={cx('calendarDate')}>
                        { calendar.d.map((day, index) => (
                          <div className={cx('dateBox')} key={ index }>
                            { day.map((dd, i) => (
                              <div key={ i } className={cx(classObj(i, dd, index))} onClick={() => getClickDate(dd)}>
                                <div
                                  className={cx('eachDate',
                                    (new Date(calendar.y, calendar.m - 1, dd).getTime() === new Date(today.year, today.month - 1, today.date).getTime())
                                    && !(index === 0 && dd > pastDate)
                                    && !(index === calendar.d.length - 1 && dd < nextDate) && 'today',
                                    index === 0 && dd > pastDate || index === calendar.d.length - 1 && dd < nextDate && 'disableDate' )
                                  }
                                >
                                  <p>{dd}</p>
                                </div>
                                <div className={cx('scheduleBox')}>
                                  { findSchedule(dd) && findSchedule(dd).map((list, i) => (
                                    <div key={ i } className = {cx('list', list.type === 'PL' && 'plan')}>
                                      { list.type === 'PL' ?
                                        <p>{ list.content }</p> :
                                        <>
                                          <div className={cx(scheduleState[list.type] ? scheduleState[list.type].color : '')}>
                                            <div></div>
                                          </div>
                                          <p>{ scheduleState[list.type] ? scheduleState[list.type].name : '' }</p>
                                        </>
                                      }
                                    </div>
                                  ))
                                  }
                                </div>
                                { scheduleLength(dd) > 3 &&
                                  <div className={cx('rest')}>
                                    <p className={cx('detailBtn')} onClick={(event) => clickDetailDate(event, dd)}>더보기</p>
                                  </div>
                                }

                                { showDetail && (dd === detailDate.d) && (
                                  <div className={cx('modal')}>
                                    <div className={cx('box')} ref={ref}>
                                      <div className={cx('dateWeek')}>
                                        <h6>{ getDetailDateStr() }</h6>
                                        <img onClick={() => setShowDetail(!showDetail) } src={grayX} alt="grayX"/>
                                      </div>
                                      <div className={cx('dateSchedule')}>
                                        { findSchedule(dd) && findSchedule(dd).map((item) => (
                                          <div key={item.id}>
                                            <div className={cx(item.type === 'PL' && 'detail')}>
                                              { item.type === 'PL' ?
                                                <p>{ item.content }</p> :
                                                <div className={cx('noPl')}>
                                                  <div className={cx(scheduleState[item.type] ? scheduleState[item.type].color : '')}></div>
                                                  <p>{ scheduleState[item.type] ? scheduleState[item.type].name : '' }</p>
                                                </div>
                                              }
                                            </div>
                                          </div>
                                        )) }
                                      </div>
                                    </div>
                                  </div>
                                ) }

                              </div>
                            )) }
                          </div>
                        )) }
                      </div>
                    </div></div>
                  <div className={cx('scheduleEdit')}>
                    <div className={cx('dateTop')}>
                      <span className={cx('generalTextBox')}>{ getScheduleDate() }({ clickDate && formatDay(clickDate.w) })</span>
                      <button onClick={() => clickShowAddSchedule()} className={cx('addScheduleBtn')}>새 일정 등록</button>
                    </div>
                    { showAddSchedule &&
                      <div className={cx('addInputScheduleBox')} onClick={() => activeAddSchedule()}>
                        <div className={cx('scheduleState')}>
                          <div className={cx('stateList')}>
                            { Object.keys(scheduleState).map((state, idx) => (
                              state !== 'PL' && (
                                <div key={idx} onClick={() => setSelectState(state)} className={cx( selectState === state && 'selectedState' )}>
                                  <div className={cx(scheduleState[state] ? scheduleState[state].color : '')}>
                                    <div></div>
                                  </div>
                                  <p>{ scheduleState[state] ? scheduleState[state].name : '' }</p>
                                </div>
                              )
                            )) }
                          </div>
                          <img onClick={() => setShowAddSchedule(false)} src={close} alt={close}/>
                        </div>
                        <div className={cx('scheduleInput')}>
                          <input
                            className={cx(isScheduleInputBoxActive && 'borderPrimary')}
                            value={scheduleComment}
                            onInput={(e) => setScheduleComment(e.target.value)}
                            onFocus={() => setIsScheduleInputBoxActive(true)}
                            type="text"
                            placeholder="세부내용을 적어 주세요(20자 내)"
                            maxLength={20}
                          />
                          { scheduleComment && (
                            <img src={CloseRound} onClick={() => setScheduleComment('')} alt='close'/>
                          )}
                          <div className={cx('btnWrapper')}>
                            <ButtonPrimarySmall text='등록' onBtnPrimarySmallClick={() => postSchedule()}/>
                          </div>
                        </div>
                      </div>
                    }
                    { !generalSchedule.length && !showAddSchedule && (
                      <div className={cx('addInputSchedule')}>
                        <p>등록된 일정이 없습니다</p>
                      </div>
                    )}
                    { generalSchedule.map((schedule, idx) => (
                      schedule.type === 'PL' ? (
                        <div key={idx} className={cx('planItem', idx === generalSchedule.length - 1 && 'noBottomMargin')}>
                          <div className={cx('generalCategory', scheduleState[schedule.type] ? scheduleState[schedule.type].color : '')}></div>
                          { schedule.content &&
                            <p className={cx('planContent')}>{ schedule.content }</p>
                          }
                        </div>
                      ) : (
                        <div key={idx} className={cx('generalItem', schedule.edit && 'borderPrimary', idx === generalSchedule.length - 1 && 'noBottomMargin' )} onClick={() => clickScheduleToEdit(idx)}>
                          <div className={cx('generalCategory')}>
                            <div>
                              <div className={cx(scheduleState[schedule.type] ? scheduleState[schedule.type].color : '')}>
                                <div></div>
                              </div>
                              <p>{ scheduleState[schedule.type] ? scheduleState[schedule.type].name : ''}</p>
                            </div>
                            <img
                              src={Trash}
                              alt='Trash'
                              onClick={() => deleteSchedule(schedule.id)}
                            />
                          </div>
                          { (schedule.edit || (schedule.content && !schedule.edit)) &&
                            <div className={cx('generalAdd')}>
                              { schedule.edit ?
                                <>
                                  <input
                                    maxLength={21}
                                    value={schedule.content}
                                    type='text'
                                    onInput={(e) => onInputScheduleEdit(e.target.value, idx, schedule.content)}
                                  />
                                  <div className={cx('btnWrapper')}>
                                    <ButtonPrimarySmall text='등록' onBtnPrimarySmallClick={() => patchSchedule(schedule.id, schedule.content)} btnDisabled={!scheduleEditBtnActive}/>
                                  </div>
                                </> :
                                <p>{ schedule.content }</p>
                              }
                            </div>
                          }
                        </div>
                      )
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </>
      )}
      <FooterGray position='relative'/>
      <ModalBig
        header='고객 상세 정보 열람'
        btnSmall='닫기'
        btnBig='열람'
        closeModalBig={closeModalBigPatientDetails}
        isModalBigOpen={isModalBigPatientAccess}
        onAction={openPatientDetails}
      >
        <PatientAccess
          patientName={modalBigPatientDetailsProps.patientName}
          patientId={modalBigPatientDetailsProps.patientId}
          staffName={staff.name}
        />
      </ModalBig>

      <ModalBig
        header='정보 수정'
        btnSmall='취소'
        btnBig='저장하기'
        closeModalBig={closeModalBigChangeProfile}
        isModalBigOpen={isModalBigChangeProfileOpen}
        onAction={saveProfile}
      >
        <PatientChangeProfile
          patientInfo={patientInfo}
        />
      </ModalBig>

      <ModalBig
        header='플랜 중단하기'
        btnSmall='닫기'
        btnBig='중단하기'
        closeModalBig={closeModalBigStopPlan}
        isModalBigOpen={isModalBigStopPlanOpen}
        onAction={stopPlan}
      >
        <PlanStop/>
      </ModalBig>

      { isModalSmallOpen &&
        <ModalSmall
          bodyTitle={modalSmallContent.bodyTitle}
          bodySub={modalSmallContent.bodySub}
          isOneBtn={modalSmallContent.isOneBtn}
          btnSecondTxt={modalSmallContent.btnSecondTxt}
          btnFirstTxt={modalSmallContent.btnFirstTxt}
          btnSecondEvent={modalSmallContent.btnSecondEvent}
          btnFirstEvent={modalSmallContent.btnFirstEvent}
          isModalSmallOpen={isModalSmallOpen}
          setIsModalSmallOpen={setIsModalSmallOpen}/>
      }
      { isModalExerciseDetailOpen && (
        <ModalExDetail
          header={modalExerciseDetailContent.header}
          exercise={modalExerciseDetailContent.exercise}
          isModalExerciseDetailOpen={isModalExerciseDetailOpen}
          setIsModalExerciseDetailOpen={setIsModalExerciseDetailOpen}
        />
      ) }
    </div>
  );
};

export default PatientDetail;