import React from 'react';
import styles from './jobIcon.module.scss'
import classnames from "classnames/bind";
import jobList from '@/data/jobList'

const cx = classnames.bind(styles)

const JobIcon = ({ job, isCertified, isTooltip = true }) => {

  const getJobColor = () => {
    if(job === 'DOCTOR') {
      if(isCertified) {
        return 'doctorCertified'
      } else {
        return 'doctor'
      }
    } else if(job === 'PHYSICAL_THERAPIST') {
      if(isCertified) {
        return 'physicalTherapistCertified'
      } else {
        return 'physicalTherapist'
      }
    } else if(job === 'FITNESS_EXPERT') {
      return 'fitnessExpert'
    } else if(job === 'KOREAN_DOCTOR') {
      if(isCertified) {
        return 'orientalDoctorCertified'
      } else {
        return 'orientalDoctor'
      }
    } else {
      return 'other'
    }
  }

  return (
    <div className={cx('jobIcon', getJobColor())}>
      { isTooltip && (
        <span className={cx('tooltiptext')}>{ jobList[job] }</span>
      ) }
    </div>
  );
};

export default JobIcon;