import React, {useEffect, useState} from 'react';
import styles from './kcbCarrier.module.scss'
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);
const url = 'https://terms.everex.co.kr/projects/kcb/operator.html'

const KcbCarrier = () => {
  const [html, setHtml] = useState('')

  useEffect(() => {
    let xmlhttp = new XMLHttpRequest()
    xmlhttp.onreadystatechange=function() {
      if(xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        setHtml(xmlhttp.responseText)
        return xmlhttp.responseText
      }
    }
    xmlhttp.open('GET', url, true)
    xmlhttp.send()
  }, [])

  return (
    <div className={cx('kcbCarrier')}>
      <div>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>
    </div>
  );
};

export default KcbCarrier;