import React from 'react';
import styles from './headerGray.module.scss'
import classnames from 'classnames/bind';
import {useNavigate} from "react-router-dom";

const unionSmall = `${process.env.REACT_APP_RESOURCE_URL}/UnionSmall.svg`

const cx = classnames.bind(styles)

const HeaderGray = () => {
  const navigate = useNavigate()

  return (
    <div className={cx('headerGray')}>
      <img src={unionSmall} onClick={() => navigate('/')} alt='unionSmall'/>
    </div>
  );
};

export default HeaderGray;