import React, {useState} from 'react';
import ButtonPrimary from "../../../common/button/buttonPrimary/buttonPrimary";
import styles from './programCard.module.scss'
import classnames from "classnames/bind";
import ModalProgramDetail from "../../../common/modal/modalProgramDetail/modalProgramDetail";
import ToggleBookmark from "@/helpers/toggleBookmark"
import {useDispatch, useSelector} from "react-redux";
import tagKor from '@/data/tagKor'
import {makeSetEx, setMinutes} from "../../../../helpers/common";
import {setAddedPlanExerciseArr} from "../../../../store/slice/planSlice";
import {setAddedProgramExerciseArr} from "../../../../store/slice/programSlice";
import {useLocation} from "react-router-dom";
import {showToast} from "../../../../helpers/toastMessage";

const GTimer = `${process.env.REACT_APP_RESOURCE_URL}/G_timer.svg`
const YGrayRoundStar = `${process.env.REACT_APP_RESOURCE_URL}/Y_grayRoundStar.svg`
const GRoundStar = `${process.env.REACT_APP_RESOURCE_URL}/G_roundStar.svg`

const cx = classnames.bind(styles)

const ProgramCard = ({program}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const bookmarkProgram = useSelector(state => {
    return state.staff.value.bookmarkProgram
  })
  const addedPlanExerciseArr = useSelector(state => {
    if(location.pathname.includes('create/program')) {
      return state.program.value.addedProgramExerciseArr
    } else {
      return state.plan.value.addedPlanExerciseArr
    }

  })


  const [modalProgramDetailContent, setModalProgramDetailContent] = useState({
    header: '',
    program: {},
  })
  const [isModalProgramDetailOpen, setIsModalProgramDetailOpen] = useState(false)
  const [bookmarkClickKey, setBookmarkClickKey] = useState(0)

  const openProgramDetailModal = (e, program) => {
    if(e.target.className.includes('buttonPrimary') || e.target.className.includes('primaryBtnIcon') || e.target.className.includes('bookmark')) {
      return
    }

    setModalProgramDetailContent({
      header: '미리보기',
      program: program,
    })

    setIsModalProgramDetailOpen(true)
  }

  const calculateMinutes = (program) => {
    let exScript = program.exScript
    let exerciseMap = program.exerciseMap
    let milli = 0

    exScript.forEach((ex) => {
      milli += exerciseMap[ex.id].media[`M${ex.time ? ex.time : ex.count}`].length
    })

    program.time = setMinutes(milli)

    return setMinutes(milli)
  }

  const addExercise = (program) => {
    let newAddedPlanExerciseArr = []
    let exScript = program.exScript
    exScript.forEach((exScriptItem) => {
      Object.values(program.exerciseMap).forEach((exMap) => {
        if(exMap.id === exScriptItem.id) {
          exScriptItem.isChecked = false
          let newItem = {
            ...exMap,
            ...exScriptItem,
          }
          newAddedPlanExerciseArr.push(newItem)
        }
      })
    })

    newAddedPlanExerciseArr.forEach((addedExercise, idx) => {
      if (addedExercise.unique === undefined) {
        addedExercise.unique = Date.now() + idx
      }
    })

    newAddedPlanExerciseArr = [...addedPlanExerciseArr, ...makeSetEx(newAddedPlanExerciseArr)]

    if(location.pathname.includes('create/program')) {
      dispatch(setAddedProgramExerciseArr(newAddedPlanExerciseArr))
    } else {
      dispatch(setAddedPlanExerciseArr(newAddedPlanExerciseArr))
    }

    showToast('success', '프로그램이 추가되었습니다.')
  }

  return (
    <div className={cx('programCard')} key={program.pid} onClick={(e) => openProgramDetailModal(e, program)}>
      { bookmarkClickKey !== 0 ? (
        <ToggleBookmark type='program' id={program.pid} key={bookmarkClickKey}/>
      ) : ''}
      <div className={cx('imageBox')}>
        { Object.keys(program.exerciseMap).slice(0, 4).map((exercise) => (
          <div key={exercise} className={cx('programItem')}>
            <img className={cx('exImage')} src={program.exerciseMap[exercise].urls.IT} alt='exercise'/>
          </div>
        )) }
        <img className={cx('bookmark')} onClick={() => setBookmarkClickKey(Math.random())} src={ bookmarkProgram.includes(program.pid) ? YGrayRoundStar : GRoundStar } alt='star'/>
      </div>
      <div className={cx('exerciseContent')}>
        <p>
          { program.tag.MP && (
            program.tag.MP.map((mp, idx) => (
              <span className={cx('tags')} key={idx}>
              { tagKor['MP'][mp] }
                { idx !== program.tag.MP.length - 1 ? (
                  <span> / </span>
                ) : ''}
            </span>
            ))
          ) }
        </p>
        <p className={cx('exerciseTitle')}>{ program.name }</p>
        <div className={cx('time')}>
          <img src={GTimer} alt='GTimer'/>
          <span>{ calculateMinutes(program) }분</span>
        </div>
      </div>
      <ButtonPrimary
        text='담기'
        onBtnPrimaryClick={() => addExercise(program)}
        icon='WPlusSharp'
        height={41}
        width={100}
        font='small'
      />
      { isModalProgramDetailOpen && (
        <ModalProgramDetail
          header={modalProgramDetailContent.header}
          program={modalProgramDetailContent.program}
          isModalProgramDetailOpen={isModalProgramDetailOpen}
          setIsModalProgramDetailOpen={setIsModalProgramDetailOpen}
        />
      ) }
    </div>
  );
};

export default ProgramCard;