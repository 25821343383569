import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import styles from './planStop.module.scss'
import classnames from 'classnames/bind';
import {setIsModalBigBtnActive} from "@/store/slice/modalBtnSlice"
import { setPlanStopReason} from "../../../../store/slice/planSlice"

const cx = classnames.bind(styles);
const reason = ['운동 수행률 저조', '통증 점수 악화', '플랜 재 처방', '기타']
const PlanStop = () => {
  const dispatch = useDispatch()

  const [selectedReason, setSelectedReason] = useState(null)

  useEffect(() => {
    if(selectedReason !== null) {
      dispatch(setIsModalBigBtnActive(true))

      let payload = {
        planStopReason: reason[selectedReason]
      }
      dispatch(setPlanStopReason(payload))
    } else {
      dispatch(setIsModalBigBtnActive(false))
    }

  }, [selectedReason])

  return (
    <div className={cx('planStop')}>
      <p className={cx('title')}>중단 사유를 선택해 주세요.</p>
      <p className={cx('sub')}>고객에게 해당 사유가 앱에서 표시 됩니다.</p>
      <div>
        { reason.map((item, idx) => (
          <p key={idx} onClick={() => setSelectedReason(idx)} className={cx(selectedReason === idx && 'active')}>{ item }</p>
        )) }
      </div>
      <p className={cx('warning')}>* 플랜 중단 후 실행 취소 할 수 없으므로, 신중하게 결정해 주세요.</p>
    </div>
  );
};

export default PlanStop;