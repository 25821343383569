import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastOptions = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  closeButton: false,
};

export function showToast (type, text ) {
  if(type === 'success') {
    toast.success(text, {
      ...toastOptions,
    })
  } else {
    toast.error(text, {
      ...toastOptions,
    })
  }
}

export default function ToastMessage() {
  return <ToastContainer/>
}