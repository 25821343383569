import React, {useEffect, useRef} from 'react';
import styles from './dropdownMutipleChoice.module.scss'
import classnames from 'classnames/bind';

const GTriangle = `${process.env.REACT_APP_RESOURCE_URL}/G_triangle.svg`
const GCheckSquare = `${process.env.REACT_APP_RESOURCE_URL}/G_checkSquare.svg`
const PCheckSquare = `${process.env.REACT_APP_RESOURCE_URL}/P_checkSquare.svg`

const cx = classnames.bind(styles)

const DropdownMultipleChoice = ({ choiceList, isDropdownMultipleChoiceOpen, setIsDropdownMultipleChoiceOpen, setSelectedChoiceIdArr, selectedChoiceIdArr, firstValue }) => {
  const ref = useRef()
  const onChoiceItemClick = (choiceItemId) => {
    if(selectedChoiceIdArr.length) {
      let newSelectedChoiceIdArr = selectedChoiceIdArr
      if(newSelectedChoiceIdArr.includes(choiceItemId)) {
        const idx = newSelectedChoiceIdArr.indexOf(choiceItemId)
        if(idx > -1) newSelectedChoiceIdArr.splice(idx, 1)
      } else {
        newSelectedChoiceIdArr.push(choiceItemId)
      }
      setSelectedChoiceIdArr(newSelectedChoiceIdArr)
    } else {
      setSelectedChoiceIdArr([choiceItemId])
    }

  }

  const getSelectedItem = () => {
    if(choiceList.length) {
      let selectedStr = ''
      selectedChoiceIdArr.forEach((id, idx) => {
        selectedStr += choiceList[id].txt
        if(idx !== selectedChoiceIdArr.length - 1) {
          selectedStr += ', '
        }
      })
      return selectedStr
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isDropdownMultipleChoiceOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownMultipleChoiceOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isDropdownMultipleChoiceOpen])

  return (
    <div className={cx('dropdownMultipleChoice')}>
      <div className={cx('filterWrapper')} ref={ref}>
        <div className={cx('dropdownBig')}>
          <div className={cx(`listSelected`, isDropdownMultipleChoiceOpen && 'borderPrimary')} onClick={() => setIsDropdownMultipleChoiceOpen()}>
            <span>
              { selectedChoiceIdArr.length ? getSelectedItem() : firstValue}
            </span>
            <img className={cx(isDropdownMultipleChoiceOpen && 'rotate')} src={GTriangle} alt='GTriangle'/>
          </div>
          { isDropdownMultipleChoiceOpen && (
            <div className={cx('filterLists')}>
              <div className={cx('listBox', 'scroll')}>
                { choiceList.map((choiceItem) =>
                  <div key={choiceItem.id} onClick={() => onChoiceItemClick(choiceItem.id)} className={cx('items')}>
                    <img src={selectedChoiceIdArr.includes(choiceItem.id) ? PCheckSquare : GCheckSquare} alt='CheckSquare'/>
                    <p>{ choiceItem.txt }</p>
                  </div>
                ) }
              </div>
              <div className={cx('reset', selectedChoiceIdArr.length && 'active')} onClick={() => setSelectedChoiceIdArr([])}>
                <div></div>
                <span>초기화</span>
              </div>
              <div className={cx('done', selectedChoiceIdArr.length && 'active')} onClick={() => setIsDropdownMultipleChoiceOpen(false)}>
                <span>선택완료</span>
              </div>
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default DropdownMultipleChoice;