import React from 'react';
import styles from './buttonPrimarySmall.module.scss'
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

const ButtonPrimarySmall = ({ text, onBtnPrimarySmallClick, btnDisabled = false }) => {
  return (
    <button className={cx(`buttonPrimarySmall`, btnDisabled ? `btnDisabled` : '' )} onClick={onBtnPrimarySmallClick}>
      { text }
    </button>
  );
};

export default ButtonPrimarySmall;