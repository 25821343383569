import React, {useEffect, useRef, useState} from 'react';
import styles from './modalPlanDetail.module.scss'
import classnames from 'classnames/bind';
import JobIcon from "../../jobIcon/jobIcon";
import { makeSetEx, planCreateDate} from "../../../../helpers/common";
import tagKor from '@/data/tagKor'
import {useSelector} from "react-redux";
import api from "../../../../utils/api";
import ExerciseItemDetail from "../modalProgramDetail/exerciseItemDetail/exerciseItemDetail";

const BCloseSharp = `${process.env.REACT_APP_RESOURCE_URL}/B_closeSharp.svg`
const Share = `${process.env.REACT_APP_RESOURCE_URL}/share.svg`
const GArrowLeftSharp = `${process.env.REACT_APP_RESOURCE_URL}/G_arrow_left_sharp.svg`
const LGArrowRightSharp = `${process.env.REACT_APP_RESOURCE_URL}/LG_arrow_right_sharp.svg`

const cx = classnames.bind(styles);
const ModalPlanDetail = ({ planId, isModalPlanDetailOpen, setIsModalPlanDetailOpen }) => {
  const ref = useRef()
  const planSharedStaffRef = useRef()

  const staff = useSelector(state => {
    return state.staff.value
  })

  const [planSharedStaffList, setPlanSharedStaffList] = useState([])
  const [compressedExScript, setCompressedExScript] = useState([])
  const [showPlanSharedStaff, setShowPlanSharedStaff] = useState(false)
  const [planDetail, setPlanDetail] = useState({})
  const [dailyDateArrInArr, setDailyDateArrInArr] = useState([])
  const [dailyDateFlatArr, setDailyDateFlatArr] = useState([])
  const [activeDate, setActiveDate] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [activeDateArrIdx, setActiveDateArrIdx] = useState(0)


  const closeModal = (e) => {
    e.stopPropagation()
    setIsModalPlanDetailOpen(false)
  }

  const onClickDateArrow = (arrow) => {
    if(arrow === 'back' && activeDateArrIdx) {
      setActiveDateArrIdx(activeDateArrIdx - 1)
    } else if(arrow === 'next' && activeDateArrIdx !== dailyDateArrInArr.length - 1){
      setActiveDateArrIdx(activeDateArrIdx + 1)
    }
  }

  const getPlanDetail = () => {
    setIsLoading(true)
    api.getPlanDetail(planId).then((res) => {
      let data = res.data
      setPlanDetail(data)
      let dates = Object.keys(data.postScript.dailyPatientInfo)
      setCompressedExScript(makeSetEx(data.preScript.schedule[dates[0]]))
      setDailyDateFlatArr(dates)

      let tempDateArrInArr = []
      let tempDateArr = []
      let idx = 0
      for(let i = 0; i < dates.length; i++) {
        tempDateArr.push(dates[i])
        if((i + 1) % 7 === 0) {
          idx++
          tempDateArrInArr.push(tempDateArr)
          tempDateArr = []
        } else if(i + 1 === dates.length) {
          tempDateArrInArr.push(tempDateArr)
        }
      }

      setDailyDateArrInArr(tempDateArrInArr)
      setActiveDate(dates[0])
    }).catch((e) => {
      console.log(e)
    })
  }

  const getPlanSharedStaffList = (planId) => {
    if(staff.id !== null && planId !== null) {
      api.getPlanSharedStaffList(planId).then((res) => {
        let tempPlanSharedStaffList = res.data.filter((val) => val.id !== staff.id)
        setPlanSharedStaffList(tempPlanSharedStaffList)
        setIsLoading(false)
      })
    }
  }

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden'
    const checkIfClickedOutside = (e) => {
      if (isModalPlanDetailOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalPlanDetailOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
      document.documentElement.style.overflow = 'auto'
    }
  }, [isModalPlanDetailOpen])

  useEffect(() => {
    if(planDetail.id) {
      getPlanSharedStaffList(planDetail.id)
    }
  }, [planDetail])

  useEffect(() => {
    if(planDetail.id) {
      setCompressedExScript(makeSetEx(planDetail.preScript.schedule[activeDate]))
    }
  }, [activeDate])

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showPlanSharedStaff && planSharedStaffRef.current && !planSharedStaffRef.current.contains(e.target)) {
        setShowPlanSharedStaff(false)
      } else if(!showPlanSharedStaff && e.target.className.includes('share')){
        setShowPlanSharedStaff(true)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showPlanSharedStaff])

  useEffect(() => {
    getPlanDetail()
  }, [])

  return (
    <>
      { !isLoading && (
        <div className={cx('modalPlanDetail')}>
          <div ref={ref}>
            <div className={cx('header')}>
              <p>미리보기</p>
              <img onClick={(e) => closeModal(e)} src={BCloseSharp} alt='BCloseSharp'/>
            </div>
            <div className={cx('body')}>
              <div className={cx('title')}>
                <p className={cx('type')}>
                  <span className={cx('planName')}>{ planDetail.name }</span>
                  <span className={cx('dateLength')}>총 <span>{ dailyDateFlatArr.length }일</span></span>
                </p>
                <div className={cx('planWrap')}>
                  <span>통증 부위 :</span>
                  <span className={cx('bold')}>{ tagKor['MP'][planDetail.mainPart] }</span>
                  <span>질환 및 수술명 :</span>
                  <span className={cx('bold', 'diagnosis')}>{ planDetail.diagnosis.trim() ? planDetail.diagnosis : '-' }</span>
                  <span className={cx('marginRight')}>담당자 :</span>
                  <JobIcon job={planDetail.creatorJob} isCertified={planDetail.creatorIsCertified}/>
                  <span className={cx('bold', 'marginRight')}>{ planDetail.creatorName }({ planDetail.creatorPhoneNumber.slice(7, 11) })</span>

                  <div className={cx('shareWrap')}>
                    <img className={cx('shareIcon')} src={Share} alt='share'/>
                    { planSharedStaffList.length ? (
                      <div className={cx('share')} >
                        <span className={cx('bold', 'mint', 'share')}>{ planSharedStaffList.length }</span>
                        <div className={cx('tooltiptextName', showPlanSharedStaff && 'show')} ref={planSharedStaffRef}>
                          { planSharedStaffList.map((staff) => (
                            <div className={cx('staffName')} key={staff.id}>
                              <JobIcon job={staff.job} isCertified={staff.isCertified}/>
                              <p className={cx('planSharedStaffName')}>{ staff.name }({ staff.phoneNumber.slice(7, 11) })</p>
                            </div>
                          )) }
                        </div>
                      </div>
                    ) : (
                      <div className={cx('share')}>
                        <span className={cx('bold')}>-</span>
                      </div>
                    )}
                  </div>

                  <span>배정일 :</span>
                  <span className={cx('bold', 'dateTime')}>{ planCreateDate(planDetail.createDateTime) }</span>
                </div>
              </div>
              <div className={cx('content')}>
                <div className={cx('datesHorizon')}>
                  <img
                    className={cx(!activeDateArrIdx && 'rotate')}
                    src={activeDateArrIdx ? GArrowLeftSharp : LGArrowRightSharp}
                    alt='arrow'
                    onClick={() => onClickDateArrow('back')}
                  />
                  <div className={cx('dateWrap')}>
                    { dailyDateArrInArr[activeDateArrIdx].map((daily, idx) => (
                      <p
                        key={daily}
                        className={cx(activeDate === daily && 'active')}
                        onClick={() => setActiveDate(daily)}>
                        { (activeDateArrIdx * 7) + idx + 1 }일차
                      </p>
                    )) }
                  </div>
                  <img
                    className={cx((activeDateArrIdx !== dailyDateArrInArr.length - 1) && 'rotate')}
                    onClick={() => onClickDateArrow('next')}
                    src={(activeDateArrIdx === dailyDateArrInArr.length - 1) ? LGArrowRightSharp : GArrowLeftSharp}
                    alt='arrow'
                  />
                </div>
                <div className={cx('listContainer')}>
                  { compressedExScript.map((exercise, idx) => (
                    <ExerciseItemDetail program={planDetail} exercise={ planDetail.exerciseMap[exercise.id]} rep={exercise} key={idx}/>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) }

    </>
  );
};

export default ModalPlanDetail;