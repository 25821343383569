import React from 'react';
import styles from './leftTabs.module.scss'
import classnames from 'classnames/bind';

const RWarning = `${process.env.REACT_APP_RESOURCE_URL}/R_warning.svg`

const cx = classnames.bind(styles)
const LeftTabs = ({ tabItems, activeTabId, onTabItemClick, unpaidAmount }) => {

  return (
    <div className={cx('leftTabs')}>
      { tabItems.map((item) =>
        <div className={cx('tabWrapper', (item.tab === '운동 동작' || item.tab === '최근 배정한 플랜') && 'borderTop' )} key={item.id}>
          <div
            className={cx(item.id === activeTabId ? 'active' : 'inactive')}
            onClick={() => onTabItemClick(item.id)}
          >
            <div className={cx('tabName')}>
            <span>
              { item.tab }
              { (unpaidAmount && item.tab === '결제 내역') ? (
                <img src={RWarning} alt='chk' className={cx('imgWarning')}/>
              ) : '' }
            </span>
              <div className={cx(item.id === activeTabId && 'active')}></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftTabs;