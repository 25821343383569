import {createSlice} from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
  name: 'subscriptionSlice',
  initialState: { value: {
      subscriptionInfo: {},
    } },
  reducers: {
    setSubscriptionInfo: (state, action) => {
      state.value.subscriptionInfo = action.payload
    },
  }
})

export default subscriptionSlice
export const { setSubscriptionInfo } = subscriptionSlice.actions